import { DropDownButton } from "components";
import { free_stock_page_link } from "routers/paths";
import { FREE_STOCK, PERFORMANCE } from "shared/constants";
import { ModalHandles } from "hooks/useModal";

const freeStockList = [
  {
    label: FREE_STOCK,
    link: free_stock_page_link,
    title: PERFORMANCE,
  },
];

interface freeStockNavProps extends ModalHandles {}

function FreeStockNav({ open, openModal, closeModal }: freeStockNavProps) {
  return (
    <DropDownButton
      label={FREE_STOCK}
      onClose={closeModal}
      open={open}
      onOpen={openModal}
      list={freeStockList}
      link={free_stock_page_link}
      withoutSelect
    />
  );
}

export default FreeStockNav;
