import * as yup from "yup";
import * as filterFieldsNames from "components/FiltersBlock/fieldsNames";
import * as messages from "shared/messages";
import { dateLowerLimit } from "./constants";

export const getMustBeAfterTheLowerLimit = () => {
  return yup.date().min(dateLowerLimit, messages.MUST_BE_GREATER_THAN_LIMIT);
};

export const getCannotBeInTheFuture = () => {
  return yup.date().max(new Date(), messages.CANNOT_BE_IN_THE_FUTURE);
};

export const getMustBeTheSameYear = (
  otherFieldName: string,
  message: string,
) => {
  return yup.date().test("has-the-same-year", message, (value, context) => {
    return (
      !value ||
      !context.parent[otherFieldName] ||
      value.getFullYear() === context.parent[otherFieldName].getFullYear()
    );
  });
};

export const getDateFromAndDateToMustBeTheSameYearValidators = (
  dateFromFieldName: string = filterFieldsNames.DATE_FROM,
  dateToFieldName: string = filterFieldsNames.DATE_TO,
) => {
  const mustBeTheSameYearAsDateTo = getMustBeTheSameYear(
    dateToFieldName,
    messages.MUST_BE_THE_SAME_YEAR_AS_DATE_TO,
  );
  const mustBeTheSameYearAsDateFrom = getMustBeTheSameYear(
    dateFromFieldName,
    messages.MUST_BE_THE_SAME_YEAR_AS_DATE_FROM,
  );
  return [mustBeTheSameYearAsDateTo, mustBeTheSameYearAsDateFrom];
};

export const defaultValidators = Object.freeze({
  [filterFieldsNames.DATE_FROM]: [
    getMustBeAfterTheLowerLimit(),
    getCannotBeInTheFuture,
  ],
  [filterFieldsNames.DATE_TO]: [
    getMustBeAfterTheLowerLimit(),
    getCannotBeInTheFuture,
  ],
});

export const defaultLazyFieldNames = Object.freeze([
  filterFieldsNames.DATE_FROM,
  filterFieldsNames.DATE_TO,
]);
