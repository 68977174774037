export const formatCellContent = (
  content: any,
  substitutions: { values: any[]; substitution: string }[],
  format?: (content: any) => string,
): any => {
  for (let { values, substitution } of substitutions) {
    if (values.includes(content)) {
      return substitution;
    }
  }

  if (format) {
    return format(content);
  }

  return content;
};

export const currencyFormatterEuro = Intl.NumberFormat("nl", {
  style: "currency",
  currency: "EUR",
}).format;
