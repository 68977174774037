import { mainColor, mainWhite } from "shared/colors";

export const customTextFieldStyle = {
  textFieldStyle: {
    backgroundColor: mainColor,
    color: mainWhite,
    marginBottom: "20px",
    borderRadius: "4px",
    marginRight: "16px",
    fontSize: "14px",
    fontFamily: "HyundaiFontMediumText",
    "&.MuiTextField-root label": {
      color: mainWhite,
      lineHeight: "normal",
      fontWeight: 600,
      "&.MuiInputLabel-shrink": {
        color: mainColor,
        fontSize: "14px",
        top: "-8px",
        letterSpacing: "1px",
      },
    },
    "& .MuiSelect-icon": {
      color: "white",
    },
  },
  iconStyle: {
    color: mainWhite,
  },
  inputStyle: {
    fontFamily: "HyundaiFontMediumText",
    color: mainWhite,
    fontWeight: 600,
    "&.MuiOutlinedInput-root": {
      height: "48px",
    },
  },
  menuStyle: {
    "& .MuiPaper-root": { maxHeight: 400 },
  },
};
