import { DropDownButton } from "components";
import { reservation_page_link } from "routers/paths";
import { RESERVATION, PERFORMANCE } from "shared/constants";
import { ModalHandles } from "hooks/useModal";

const reservationList = [
  {
    label: RESERVATION,
    link: reservation_page_link,
    title: PERFORMANCE,
  },
];

interface reservationNavProps extends ModalHandles {}

function ReservationNav({ open, openModal, closeModal }: reservationNavProps) {
  return (
    <DropDownButton
      label={RESERVATION}
      onClose={closeModal}
      open={open}
      onOpen={openModal}
      list={reservationList}
      link={reservation_page_link}
      withoutSelect
    />
  );
}

export default ReservationNav;
