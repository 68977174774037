import {
  BaseQueryFn,
  createApi,
  FetchArgs,
} from "@reduxjs/toolkit/dist/query/react";
import { ChangePassword, ChangePasswordWithKey } from "models/ChangePassword";
import { CustomError } from "models/CustomError";
import { User } from "models/User";
import { baseQueryWithReauth } from "./baseQueryWithReauth";

export const userAPI = createApi({
  reducerPath: "userAPI",
  baseQuery: baseQueryWithReauth as BaseQueryFn<
    string | FetchArgs,
    unknown,
    CustomError,
    {}
  >,
  tagTypes: ["User"],
  endpoints: (build) => ({
    self: build.query<User, void>({
      query: () => ({
        url: "user/self/",
      }),
    }),
    changePassword: build.mutation<User, ChangePassword>({
      query: (passwords) => ({
        url: "user/change-password/",
        method: "PATCH",
        body: passwords,
      }),
    }),
    resetPassword: build.mutation<any, { email: string }>({
      query: ({ email }) => ({
        url: "user/reset-password/",
        method: "POST",
        body: { email },
      }),
    }),
    setNewPassword: build.mutation<any, ChangePasswordWithKey>({
      query: (passwords_and_key) => ({
        url: "user/set-new-password/",
        method: "POST",
        body: passwords_and_key,
      }),
    }),
    deleteAccount: build.mutation<any, void>({
      query: (logIn) => ({
        url: "user/delete-user/",
        method: "DELETE",
      }),
    }),
  }),
});
