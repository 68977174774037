import { actionType } from "./actions";

const INITIAL_STATE = {
  isSignedIn: false,
  user: null,
};

export const userReducer = (state = INITIAL_STATE, action: actionType) => {
  switch (action.type) {
    case "user/userSignedIn":
      return { ...state, isSignedIn: true, user: action.payload };
    case "user/userSignedOut":
      return { ...state, isSignedIn: false, user: null };
    default:
      return state;
  }
};
