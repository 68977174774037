export interface actionType {
  type: string;
  payload?: object;
}

export const signInUser = (user: object | undefined) => {
  return {
    type: "user/userSignedIn",
    payload: user,
  };
};

export const signOutUser = () => {
  return {
    type: "user/userSignedOut",
  };
};
