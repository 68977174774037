import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  CustomTable,
  FiltersBlock,
  MainColorButton,
  ExportDataBlock,
  BasicModal,
  DateBlock,
  Loader,
} from "components";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { ReservationData } from "models/ReservationsData";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import useModal from "hooks/useModal";
import { useSortData } from "hooks/useSortData";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import dayjs from "dayjs";
import { dateToOperationalFormat } from "shared/utils";
import * as filterFieldsNames from "components/FiltersBlock/fieldsNames";
import * as buildUtils from "shared/validation/buildUtils";
import {
  defaultLazyFieldNames,
  defaultValidators,
} from "shared/validation/filterValidators";
import { useFormik } from "formik";
import { useGetLastValid } from "hooks/useGetLastValid";
import { dispatchFilterChangedEvent } from "shared/events";

function ReservationPage() {
  const defaultDateFrom = useMemo(() => dayjs().startOf("year").toDate(), []);
  const defaultDateTo = useMemo(() => new Date(), []);

  const filterSchema = useMemo(
    () =>
      buildUtils.buildObjectSchema(defaultValidators, defaultLazyFieldNames),
    [],
  );
  const formik = useFormik({
    initialValues: {
      [filterFieldsNames.DATE_FROM]: defaultDateFrom,
      [filterFieldsNames.DATE_TO]: defaultDateTo,
    },
    validate: buildUtils.buildValidateFunc(filterSchema),
    onSubmit: () => {},
  });

  const setDateFrom = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_FROM, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const setDateTo = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_TO, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const [dealers, setDealers] = useState<Array<OptionsProps>>([]);
  const [brands, setBrands] = useState<Array<OptionsProps>>([]);
  const [factories, setFactories] = useState<Array<OptionsProps>>([]);
  const [reservationNumbers, setReservationNumbers] = useState<Array<string>>(
    [],
  );
  const { open, openModal, closeModal } = useModal();
  const { sortData, ordering } = useSortData();
  const [skipExportTable, setSkipExportTable] = useState(true);
  const [skipExportData, setSkipExportData] = useState(true);

  const queryParams = useMemo(
    () => ({
      dealer: dealers.map((item: OptionsProps) => item.id).toString(),
      car_model: brands.map((item: OptionsProps) => item.id).toString(),
      factory: factories.map((item: OptionsProps) => item.id).toString(),
      reservation_number: reservationNumbers.toString(),
      date_from: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_FROM],
      ),
      date_to: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_TO],
      ),
    }),
    [brands, formik.values, dealers, factories, reservationNumbers],
  );
  const validatedQueryParams = useGetLastValid(
    queryParams,
    formik.isValid,
    formik.isValidating,
  );
  useEffect(() => {
    dispatchFilterChangedEvent();
  }, [validatedQueryParams]);
  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetReservationsDataQuery({
      ...(validatedQueryParams || queryParams),
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const { data: exportTableData, isFetching: exportTableDataIsFetching } =
    dealerPerformanceAPI.useGetReservationExportTableQuery(
      { ...(validatedQueryParams || queryParams), ordering: ordering },
      { skip: skipExportTable || !tableData?.results.length },
    );
  const { data: exportAllData, isFetching: exportAllDataIsFetching } =
    dealerPerformanceAPI.useGetReservationExportDataQuery(
      validatedQueryParams || queryParams,
      {
        skip: skipExportData,
      },
    );

  const generateDetailsViewURL = (
    usage_status: string,
    dealers: Array<string | any> = [],
    car_models: Array<string | any> = [],
    factories: Array<string | any> = [],
    exclude: object = {},
    grand_total: Boolean = false,
  ) => {
    let url =
      `/main/reservation-details-page?` +
      `dealers=${dealers.join(",") ? dealers : ""}&` +
      `car_models=${car_models.join(",") ? car_models : ""}&` +
      `factories=${factories.join(",") ? factories : ""}&` +
      `reservation_number=${
        reservationNumbers.join(",") ? reservationNumbers : ""
      }&` +
      `usage_status=${usage_status}&` +
      `date_from=${dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_FROM],
      )}&` +
      `date_to=${dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_TO],
      )}`;
    const exclude_filters = JSON.stringify(exclude);
    if (exclude_filters !== "{}") {
      url += `&exclude=${exclude_filters}`;
    }
    if (grand_total) {
      url += `&grand_total=${grand_total}`;
    }
    return url;
  };

  const reservationReturnHeaders: Header[] = [
    { id: "dealer_name", label: constants.DEALER, sortable: true },
    { id: "factory", label: constants.FACTORY, sortable: true },
    { id: "brand_code", label: constants.MODEL, sortable: true },
    {
      id: "country",
      label: constants.VEHICLE_USAGE,
      subHeaders: [
        { label: constants.SOLD, id: "sold_count", sortable: true },
        { label: constants.FLEET, id: "fleet_count", sortable: true },
        { label: constants.STOCK, id: "stock_count", sortable: true },
        { label: constants.DEMO, id: "demo_count", sortable: true },
        { label: constants.SHOW, id: "show_count", sortable: true },
        { label: constants.TOTAL, id: "total_count", sortable: true },
      ],
    },
  ];

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map((reservation: ReservationData) => ({
        values: [
          reservation.dealer_name,
          reservation.factory,
          reservation.brand_code,
          {
            value: reservation.sold_count,
            href: generateDetailsViewURL(
              "sold",
              [reservation.dealerID],
              [reservation.brand_code],
              [reservation.factory],
            ),
          },
          {
            value: reservation.fleet_count,
            href: generateDetailsViewURL(
              "fleet",
              [reservation.dealerID],
              [reservation.brand_code],
              [reservation.factory],
            ),
          },
          {
            value: reservation.stock_count,
            href: generateDetailsViewURL(
              "stock",
              [reservation.dealerID],
              [reservation.brand_code],
              [reservation.factory],
            ),
          },
          {
            value: reservation.demo_count,
            href: generateDetailsViewURL(
              "demo",
              [reservation.dealerID],
              [reservation.brand_code],
              [reservation.factory],
            ),
          },
          {
            value: reservation.show_count,
            href: generateDetailsViewURL(
              "show",
              [reservation.dealerID],
              [reservation.brand_code],
              [reservation.factory],
            ),
          },
          {
            value: reservation.total_count,
            href: generateDetailsViewURL(
              "",
              [reservation.dealerID],
              [reservation.brand_code],
              [reservation.factory],
            ),
          },
        ],
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const getDealers = () => {
    if (tableData) {
      return [
        tableData.results.map(
          (reservation: ReservationData) => reservation.dealerID,
        ),
      ];
    }
  };

  const getCarModels = () => {
    if (tableData) {
      return [
        tableData.results.map(
          (reservation: ReservationData) => reservation.brand_code,
        ),
      ];
    }
  };

  const getFactories = () => {
    if (tableData) {
      return [
        tableData.results.map(
          (reservation: ReservationData) => reservation.factory,
        ),
      ];
    }
  };

  const getFilterValue = (items: Array<any>, attr: string = "label") => {
    return items.map((item: any) => item[attr]);
  };

  function openExportsBlock() {
    setSkipExportTable(false);
    setSkipExportData(false);
    openModal();
  }

  return (
    <Box sx={{ marginTop: "120px" }}>
      <PageTitle title={constants.RESERVATION} />
      <Box>
        <LastUpdateBlock fileType="order_intake" />
        <DateBlock />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <FiltersBlock
          hideCountryFilter
          setSelectedBrands={setBrands}
          setSelectedFactories={setFactories}
          setSelectedDealers={setDealers}
          setSelectedReservationNumbers={setReservationNumbers}
          setSelectedDateFrom={setDateFrom}
          setSelectedDateTo={setDateTo}
          defaultDateFrom={defaultDateFrom}
          defaultDateTo={defaultDateTo}
          selectedBrands={brands}
          selectedFactories={factories}
          selectedDealers={dealers}
          selectedReservationNumbers={reservationNumbers}
          errors={formik.errors as { [field: string]: string[] }}
        />
        <MainColorButton
          onClick={() => openExportsBlock()}
          label={constants.EXPORT}
        />
        <BasicModal open={open} handleClose={() => closeModal()} centerPosition>
          <>
            <Loader
              isLoading={exportTableDataIsFetching || exportAllDataIsFetching}
            />
            <ExportDataBlock
              exportAllData={exportAllData}
              exportTableData={exportTableData}
              onClose={closeModal}
              exportTableName="Reservation_table"
              exportDataName="Reservation_data"
            />
          </>
        </BasicModal>
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={reservationReturnHeaders}
        rows={tableRows}
        count={tableData?.count}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        pageTotals={
          tableData && [
            {
              value: tableData.page_total.sold,
              href: generateDetailsViewURL(
                "sold",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.fleet,
              href: generateDetailsViewURL(
                "fleet",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.stock,
              href: generateDetailsViewURL(
                "stock",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.demo,
              href: generateDetailsViewURL(
                "demo",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.show,
              href: generateDetailsViewURL(
                "show",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.total,
              href: generateDetailsViewURL(
                "",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
          ]
        }
        grandTotals={
          tableData && [
            {
              value: tableData.full_total.sold,
              href: generateDetailsViewURL(
                "sold",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.fleet,
              href: generateDetailsViewURL(
                "fleet",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.stock,
              href: generateDetailsViewURL(
                "stock",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.demo,
              href: generateDetailsViewURL(
                "demo",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.show,
              href: generateDetailsViewURL(
                "show",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.total,
              href: generateDetailsViewURL(
                "",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
          ]
        }
        differenceTotals={
          tableData && [
            {
              value: tableData.difference_with_page.sold,
              href: generateDetailsViewURL(
                "sold",
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.fleet,
              href: generateDetailsViewURL(
                "fleet",
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.stock,
              href: generateDetailsViewURL(
                "stock",
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.demo,
              href: generateDetailsViewURL(
                "demo",
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.show,
              href: generateDetailsViewURL(
                "show",
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.total,
              href: generateDetailsViewURL(
                "",
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
          ]
        }
        totalsColSpan={3}
        stickyColumnIndex={2}
        getItemsForPage={(items: number) => setSkip(items)}
        getSortedValue={(value: string) => sortData(value)}
      />
    </Box>
  );
}

export default ReservationPage;
