import { Button, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import { CustomButtonStyle } from "./CustomButton.style";

interface CustomButtonProps {
  sx?: SxProps<Theme> | undefined;
  label: string;
  icon?: ReactNode;
  onClick?: () => void;
}

function CustomButton({ label, icon, onClick, sx }: CustomButtonProps) {
  const { buttonStyle } = CustomButtonStyle;
  return (
    <Button
      variant="outlined"
      sx={{ ...buttonStyle, ...sx }}
      startIcon={icon && icon}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}

export default CustomButton;
