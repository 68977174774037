import { Box, Button, Link, TextField, Typography } from "@mui/material";
import { AlertInfo, BasicModal, FormWrapper, Loader, Logo } from "components";
import { useFormik } from "formik";
import useModal from "hooks/useModal";
import { login_page_link } from "routers/paths";
import {
  BACK_TO_LOG_IN,
  EMAIL,
  EMAIL_IS_INCORRECT,
  ENTER_YOUR_EMAIL,
  PASSWORD_RESET_INSTRUCTIONS_ARE_SENT,
  RESET_PASSWORD,
} from "shared/constants";
import { resetPasswordValidationSchema } from "shared/validation/ResetPasswordValidationSchema";
import { forgotPasswordPageStyle } from "./ForgotPasswordPage.style";
import { userAPI } from "api/services/User";
import { ResetPassword } from "models/ResetPassword";

function ForgotPasswordPage() {
  const [resetPassword, status] = userAPI.useResetPasswordMutation();

  const {
    open: openErrorMessage,
    openModal: openErrorMessageModal,
    closeModal: closeErrorModal,
  } = useModal();
  const {
    open: openSucessMessage,
    openModal: openSucessMessageModal,
    closeModal: closeSuccessModal,
  } = useModal();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: resetPasswordValidationSchema,
    onSubmit: async (formData: ResetPassword, { resetForm }) => {
      const response = await resetPassword(formData);
      if ("data" in response && response.data) {
        openSucessMessageModal();
        resetForm();
      }
      if ("error" in response) {
        openErrorMessageModal();
      }
    },
  });

  const { touched, errors } = formik;

  const {
    inputStyle,
    buttonStyle,
    backgroundStyle,
    loginFormWrapper,
    logInLinkStyle,
    titleStyle,
  } = forgotPasswordPageStyle;

  return (
    <Box sx={backgroundStyle}>
      <FormWrapper handleSubmit={formik.handleSubmit}>
        <Loader isLoading={status.isLoading} />
        <Box sx={loginFormWrapper}>
          <BasicModal open={openSucessMessage} handleClose={closeSuccessModal}>
            <AlertInfo message={PASSWORD_RESET_INSTRUCTIONS_ARE_SENT} />
          </BasicModal>
          <BasicModal open={openErrorMessage} handleClose={closeErrorModal}>
            <AlertInfo message={EMAIL_IS_INCORRECT} />
          </BasicModal>
          <Logo blueLogoColor />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={titleStyle} variant="regular">
              {ENTER_YOUR_EMAIL}
            </Typography>
            <TextField
              label={EMAIL}
              variant="outlined"
              sx={inputStyle}
              helperText={touched.email && errors.email ? errors.email : null}
              error={Boolean(touched.email && errors.email)}
              {...formik.getFieldProps("email")}
            />
          </Box>
          <Button variant="main_button" type="submit" sx={buttonStyle}>
            {RESET_PASSWORD}
          </Button>
          <Link sx={logInLinkStyle} href={login_page_link}>
            {BACK_TO_LOG_IN}
          </Link>
        </Box>
      </FormWrapper>
    </Box>
  );
}

export default ForgotPasswordPage;
