import { ErrorOutline } from "@mui/icons-material";
import { Box, CircularProgress, MenuItem } from "@mui/material";
import { useMemo } from "react";

interface DownloadDropdownMenuItemProps {
  text: string;
  filename: string;
  data: any;
  isFetching: boolean;
  isError: boolean;
}

function DownloadDropdownMenuItem({
  text,
  filename,
  data,
  isFetching,
  isError,
}: DownloadDropdownMenuItemProps) {
  const icon = useMemo(() => {
    let icon = null;
    if (isError) {
      icon = <ErrorOutline />;
    } else if (isFetching) {
      icon = <CircularProgress color="inherit" size={"1rem"} thickness={6} />;
    }
    return <Box padding={"0px 4px"}>{icon}</Box>;
  }, [isFetching, isError]);

  return (
    <a
      href={!isFetching && !isError && data}
      download={filename}
      style={{ all: "unset" }}
    >
      <MenuItem>
        {text}
        {icon}
      </MenuItem>
    </a>
  );
}

export default DownloadDropdownMenuItem;
