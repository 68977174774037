import {
  BaseQueryFn,
  createApi,
  FetchArgs,
} from "@reduxjs/toolkit/dist/query/react";
import {
  AllocationData,
  DetailedAllocationData,
  RFTData,
  DetailedRFTData,
} from "models/AllocationData";
import { CustomError } from "models/CustomError";
import { FutureSales, FutureSalesData } from "models/FutureSales";
import { Round } from "models/Round";
import { MonitoringsData, DetailedMonitoringsData } from "models/Monitoring";
import { OrdersData } from "models/Orders";
import {
  defaultQueryModel,
  getDefaultParam,
  OTDDetailsQueryModel,
  getOTDDetailsDefaultParam,
  AllocationDetailsQueryModel,
  getAllocationDetailsDefaultParam,
  RFTDetailsQueryModel,
  getRFTDetailsDefaultParam,
} from "models/Tables";
import { baseQueryWithReauth } from "./baseQueryWithReauth";

export const orderAPI = createApi({
  reducerPath: "orderAPI",
  baseQuery: baseQueryWithReauth as BaseQueryFn<
    string | FetchArgs,
    unknown,
    CustomError,
    {}
  >,
  tagTypes: ["Order", "Monitoring"],
  endpoints: (build) => ({
    getOrder: build.query<
      OrdersData,
      {
        limit: number;
        offset: number;
        dealer?: string;
        dealer_location?: string;
      }
    >({
      query: ({ limit, offset, dealer, dealer_location }) => ({
        url: "order",
        params: {
          dealer_location: dealer_location,
          dealer: dealer,
          limit: limit,
          offset: offset,
        },
      }),
    }),
    getFutureSalesDocument: build.query<FutureSales, void>({
      query: () => ({
        url: "order/future_sales_document",
      }),
    }),
    matchOrders: build.mutation<any, void>({
      query: () => ({
        url: "order/matching/run/",
        method: "POST",
      }),
    }),
    getOrderMatchingStatus: build.mutation<{ status: string }, void>({
      query: () => ({
        url: "order/matching/status/",
        method: "GET",
      }),
    }),
    getOrderMatchingLastSucceededAt: build.query<
      { succeeded_at: string },
      void
    >({
      query: () => ({
        url: "order/matching/last_succeeded_at/",
        method: "GET",
      }),
      providesTags: ["Monitoring"],
    }),
    getMonitoringData: build.query<MonitoringsData, defaultQueryModel>({
      query: ({ ...params }) => ({
        url: "order/monitoring/",
        params: {
          ...getDefaultParam(params),
        },
      }),
      providesTags: ["Monitoring"],
    }),
    getDetailedMonitoringData: build.query<
      DetailedMonitoringsData,
      OTDDetailsQueryModel
    >({
      query: ({ ...params }) => ({
        url: "order/monitoring_details/",
        params: {
          ...getOTDDetailsDefaultParam(params),
        },
      }),
    }),
    getDetailedAllocatieData: build.query<
      DetailedAllocationData,
      AllocationDetailsQueryModel
    >({
      query: ({ ...params }) => ({
        url: "order/allocatie_details/",
        params: {
          ...getAllocationDetailsDefaultParam(params),
        },
      }),
    }),
    getDetailedAllocatieExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "order/allocatie_details/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getAllocationDetailsDefaultParam(params) },
      }),
    }),

    getDetailedMonitoringExportTable: build.query<any, OTDDetailsQueryModel>({
      query: ({ ...params }) => ({
        url: "order/monitoring_details/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getOTDDetailsDefaultParam(params) },
      }),
    }),
    getMonitoringExportTable: build.query<any, defaultQueryModel>({
      query: ({ ...params }) => ({
        url: "order/monitoring/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getDefaultParam(params) },
      }),
    }),
    getMonitoringExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "order/monitoring/data_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getDefaultParam(params) },
      }),
    }),
    getAllocationData: build.query<AllocationData, defaultQueryModel>({
      query: ({ ...params }) => ({
        url: "order/allocations/",
        params: {
          ...getDefaultParam(params),
        },
      }),
    }),
    getAllocationExportTable: build.query<any, defaultQueryModel>({
      query: ({ ...params }) => ({
        url: "order/allocations/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getDefaultParam(params) },
      }),
    }),
    getAllocationExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "order/allocations/data_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getDefaultParam(params) },
      }),
    }),
    getFutureSales: build.query<
      FutureSalesData,
      {
        limit: number;
        offset: number;
        dealer?: string;
        car_model?: string;
        factory?: string;
      }
    >({
      query: ({ limit, offset, car_model, factory, dealer }) => ({
        url: "order/future_sales/",
        params: {
          limit: limit,
          offset: offset,
          car_model: car_model,
          factory: factory,
          dealer: dealer,
        },
      }),
    }),
    getFutureSalesExportData: build.query<
      any,
      {
        report_type: "xlsx" | "pdf";
      }
    >({
      query: ({ report_type }) => ({
        url: "order/future_sales/data_export/",
        params: {
          report_type: report_type,
        },
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: `data:${response.headers.get("content-type")}`,
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
      }),
    }),
    getRFTData: build.query<RFTData, defaultQueryModel>({
      query: ({ ...params }) => ({
        url: "order/allocations/rft/",
        params: {
          ...getDefaultParam(params),
        },
      }),
    }),
    getRFTExportData: build.query<any, defaultQueryModel>({
      query: ({ ...params }) => ({
        url: "order/allocations/rft_export/",
        params: {
          ...getDefaultParam(params),
        },
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
      }),
    }),
    getDetailedRFTData: build.query<DetailedRFTData, RFTDetailsQueryModel>({
      query: ({ ...params }) => ({
        url: "order/allocations/rft_details/",
        params: {
          ...getRFTDetailsDefaultParam(params),
        },
      }),
    }),
    getDetailedRFTExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "order/allocations/rft_details_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getRFTDetailsDefaultParam(params) },
      }),
    }),
    getRoundsList: build.query<Array<Round>, void>({
      query: () => ({
        url: "order/rounds_list/",
      }),
    }),
  }),
});
