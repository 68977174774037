export const basicModalStyle = {
  modalStyle: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: "3%",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
  },
};
