import styled from "@emotion/styled";
import { TableCell, tableCellClasses, TableRow } from "@mui/material";
import { mainColor, mainWhite } from "shared/colors";

export const performanceOverviewPageStyle = {
  rowStyle: {
    marginRight: "100px",
    marginLeft: "100px",
  },
  defaultCellStyle: {
    padding: "8px",
    lineHeight: "1.43",
  },
  mainTitleStyle: {
    fontWeight: 800,
    fontSize: "14px",
    paddingTop: "10px",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  doelstellingDropdown: {
    color: "white !important",
    height: "30px",
    marginLeft: "2px",
    fontSize: "0.875rem",
  },
};

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: mainColor,
    color: mainWhite,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  "&": {
    border: 0,
  },
}));
