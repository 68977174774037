import DownloadIcon from "@mui/icons-material/Download";
import { ErrorOutline } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { CustomButton } from "../index";
import { DOWNLOAD_XLSX } from "shared/constants";
import { useMemo } from "react";

interface DownloadXlsxButtonProps {
  filename: string;
  data: any;
  isFetching: boolean;
  isError: boolean;
}

function DownloadXlsxButton({
  filename,
  data,
  isFetching,
  isError,
}: DownloadXlsxButtonProps) {
  const icon = useMemo(() => {
    if (isError) {
      return <ErrorOutline />;
    }
    if (isFetching) {
      return <CircularProgress color="inherit" size={"1rem"} thickness={6} />;
    }
    return <DownloadIcon />;
  }, [isFetching, isError]);

  return (
    <a href={!isFetching && !isError && data} download={filename}>
      <CustomButton sx={{ marginRight: 0 }} label={DOWNLOAD_XLSX} icon={icon} />
    </a>
  );
}

export default DownloadXlsxButton;
