import { Box, Button, Link, TextField } from "@mui/material";
import { authAPI } from "api/services/Auth";
import { AlertInfo, BasicModal, FormWrapper, Loader, Logo } from "components";
import { useFormik } from "formik";
import useModal from "hooks/useModal";
import { LogIn } from "models/LogIn";
import { useNavigate } from "react-router-dom";
import { main_page_link, forgot_password_page_link } from "routers/paths";
import { WRONG_CREDENTIALS } from "shared/messages";
import {
  EMAIL,
  LOGIN_TO_YOUR_ACCOUNT,
  PASSWORD,
  FORGOT_PASSWORD,
} from "shared/constants";
import { loginValidationSchema } from "shared/validation/LoginValidationSchema";
import { loginPageStyle } from "./LoginPage.style";

function LoginPage() {
  const navigate = useNavigate();
  const [singIn, status] = authAPI.useSignInMutation();
  const { open, openModal, closeModal } = useModal();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      handleClick(values);
    },
  });

  const { touched, errors } = formik;

  const {
    inputStyle,
    buttonStyle,
    backgroundStyle,
    loginFormWrapper,
    forgotPasswordLinkStyle,
  } = loginPageStyle;

  const handleClick = async (values: LogIn) => {
    const response = await singIn(values);
    if ("data" in response && response.data) {
      localStorage.setItem("REFRESH_TOKEN", response.data.refresh);
      localStorage.setItem("ACCESS_TOKEN", response.data.access);
      navigate(`${main_page_link}`);
    }
    if ("error" in response) {
      openModal();
    }
  };

  return (
    <Box sx={backgroundStyle}>
      <FormWrapper handleSubmit={formik.handleSubmit}>
        <Loader isLoading={status.isLoading} />
        <Box sx={loginFormWrapper}>
          <BasicModal open={open} handleClose={closeModal}>
            <AlertInfo message={WRONG_CREDENTIALS} />
          </BasicModal>
          <Logo blueLogoColor />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <TextField
              label={EMAIL}
              variant="outlined"
              sx={inputStyle}
              helperText={touched.email && errors.email ? errors.email : null}
              error={Boolean(touched.email && errors.email)}
              {...formik.getFieldProps("email")}
            />
            <TextField
              type="password"
              label={PASSWORD}
              variant="outlined"
              sx={inputStyle}
              helperText={
                touched.password && errors.password ? errors.password : null
              }
              error={Boolean(touched.password && errors.password)}
              {...formik.getFieldProps("password")}
            />
          </Box>
          <Button variant="main_button" type="submit" sx={buttonStyle}>
            {LOGIN_TO_YOUR_ACCOUNT}
          </Button>
          <Link sx={forgotPasswordLinkStyle} href={forgot_password_page_link}>
            {FORGOT_PASSWORD}
          </Link>
        </Box>
      </FormWrapper>
    </Box>
  );
}

export default LoginPage;
