import { Box } from "@mui/material";
import blueLogo from "shared/images/logo-h-without-bg.png";
import whiteLogo from "shared/images/white_logo.png";
import { logoStyle } from "./Logo.style";

interface LogoProps {
  smallVersion?: boolean;
  blueLogoColor?: boolean;
}

function Logo({ smallVersion, blueLogoColor }: LogoProps) {
  const { logoWrapper, smallLogoWrapper } = logoStyle;
  return (
    <Box
      sx={smallVersion ? smallLogoWrapper : logoWrapper}
      component="img"
      alt="Logo"
      src={blueLogoColor ? blueLogo : whiteLogo}
    />
  );
}

export default Logo;
