import { Box, Typography } from "@mui/material";
import { LogoH } from "components";
import useGetViewPort from "hooks/useGetViewPort";
import { mainColor } from "shared/colors";

const WelcomePage = () => {
  const { isMobile } = useGetViewPort();
  return (
    <Box
      sx={{
        marginTop: "150px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {isMobile ? <LogoH midiumLogo /> : <LogoH bigLogo />}
      <Typography
        sx={{ color: mainColor, textAlign: "center" }}
        variant={isMobile ? "h5" : "h3"}
      >
        Welcome to Hyndai-Navigator
      </Typography>
    </Box>
  );
};

export default WelcomePage;
