import FiltersChips, { ConcreteFiltersChipsProps } from "./FiltersChips";
import { OptionsProps } from "../AutocompleteTextField/AutocompleteTextField";

function OptionsFiltersChips({
  filters,
  onDelete,
  filter,
  onClear,
}: ConcreteFiltersChipsProps<OptionsProps>) {
  return (
    <FiltersChips<OptionsProps>
      filters={filters}
      onDelete={onDelete}
      filter={filter}
      onClear={onClear}
      getKey={(filter) => filter.id}
      getLabel={(filter) => filter.label}
    />
  );
}

export default OptionsFiltersChips;
