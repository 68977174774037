import { Backdrop, CircularProgress } from "@mui/material";

interface LoaderProps {
  isLoading: boolean;
  boundToParent?: boolean;
}

// !!! To have the loader bound to parent, set boundToParent prop to true and make sure that the parent has
// {position: "relative"} in its style

function Loader({ isLoading, boundToParent }: LoaderProps) {
  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: boundToParent ? "absolute" : "",
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Loader;
