import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import {
  ALL,
  GRAND_TOTAL,
  OTHER,
  PAGE_TOTAL,
  ROWS_PER_PAGE_ALL,
} from "shared/constants";
import { formatCellContent } from "shared/formatters";
import { colouredTableStyle } from "./ColouredTable.style";
import SortIcon from "@mui/icons-material/Sort";
import { Loader } from "components";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { mainWhite } from "shared/colors";
import { getPercentageBackgroundColor } from "helper/getPercentageBackgroundColor";

import { CustomTotal, Header, Row } from "../interfaces";
import { buildTableSubHeaders, getColSpanForHeader } from "../utils";
import EmptyContentPlaceholder from "../EmptyContentPlaceholder/EmptyContentPlaceholder";

interface ColouredTableProps {
  headers: Array<Header>;
  colouredColumnsIndex: Array<Number>;
  rows: Array<Row>;
  count?: number;
  rowsPerPage: number;
  pageTotals?: Array<number | string | object>;
  grandTotals?: Array<number | string | object>;
  differenceTotals?: Array<number | string | object>;
  customTotals?: Array<CustomTotal>;
  totalsColSpan?: number;
  getItemsForPage?: (item: number) => void;
  getSortedValue?: (value: string) => void;
  isFetching: boolean;
  stickyColumnIndex?: number;
  changeRowsPerPage: (value: number) => void;
  cellFormatters?: (((cellContent: any) => string) | undefined)[];
}

function ColouredTable({
  headers,
  colouredColumnsIndex,
  rows,
  count,
  rowsPerPage,
  pageTotals,
  grandTotals,
  differenceTotals,
  customTotals,
  totalsColSpan,
  getItemsForPage,
  getSortedValue,
  isFetching,
  stickyColumnIndex,
  changeRowsPerPage,
  cellFormatters,
}: ColouredTableProps) {
  const [page, setPage] = useState(0);
  const [rowPerPage, setRowsPerPage] = useState(rowsPerPage);

  useEffect(() => {
    window.addEventListener("filterChanged", (event) => {
      if (getItemsForPage) {
        setPage(0);
        getItemsForPage(0);
      }
    });
  }, [getItemsForPage]);

  const getSortedValueAndResetPage = useCallback(
    (value: string) => {
      if (getSortedValue) {
        getSortedValue(value);
      }
      if (getItemsForPage) {
        setPage(0);
        getItemsForPage(0);
      }
    },
    [getSortedValue, getItemsForPage],
  );

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
    if (getItemsForPage) {
      getItemsForPage(newPage * rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const parsedRowsPerPage = parseInt(event.target.value, 10);
    changeRowsPerPage(parsedRowsPerPage);
    setRowsPerPage(parsedRowsPerPage);
    setPage(0);
    if (getItemsForPage) {
      getItemsForPage(0);
    }
  };

  const {
    paginationStyle,
    tableStyle,
    mainTitleCell,
    mainTitleStyle,
    subTitleStyle,
    textStyle,
    sortIconStyle,
    sortBlockWrapper,
    titleStyle,
  } = colouredTableStyle;

  const substitutions = [{ values: [0, "0%"], substitution: "-" }];
  const totalsRow = (title: string, totals: Array<number | string | any>) => (
    <TableRow>
      <TableCell
        colSpan={totalsColSpan}
        align="center"
        sx={{
          ...subTitleStyle,
          textTransform: "uppercase",
          fontSize: "12px",
        }}
      >
        {title}
      </TableCell>
      {totals.map((total: number | string | any, index: number) => (
        <TableCell
          key={index}
          align="center"
          sx={{
            ...subTitleStyle,
            padding: "4px 8px",
          }}
        >
          {total && total.href && total.value > 0 ? (
            <Link
              variant="regular"
              underline="hover"
              href={total.href}
              target="_blank"
            >
              {formatCellContent(
                total.value,
                substitutions,
                cellFormatters?.at(index),
              )}
            </Link>
          ) : (
            <Typography variant="regular">
              {formatCellContent(
                typeof total === "object" ? total.value : total,
                substitutions,
                cellFormatters?.at(index),
              )}
            </Typography>
          )}
        </TableCell>
      ))}
    </TableRow>
  );

  const noSubtitles = headers.every(
    (header) => header.subHeaders === undefined,
  );

  return (
    <Paper sx={{ marginTop: "32px", overflowX: "scroll" }}>
      <TableContainer>
        <Table stickyHeader sx={tableStyle}>
          <TableHead sx={{ position: "relative" }}>
            <TableRow>
              {headers.map((header: Header, index: number) => (
                <TableCell
                  key={header.id}
                  sx={{
                    ...mainTitleCell,
                    borderRight:
                      header.subHeaders && header.subHeaders?.length > 1
                        ? `solid 2px ${mainWhite}`
                        : "none",
                    borderLeft:
                      header.subHeaders && header.subHeaders?.length > 1
                        ? `solid 2px ${mainWhite}`
                        : "none",
                    ...(index === stickyColumnIndex && {
                      position: "sticky",
                      left: "0",
                      zIndex: 11,
                    }),
                    ...(header.minWidth && { minWidth: header.minWidth }),
                  }}
                  colSpan={getColSpanForHeader(header)}
                  align="center"
                >
                  {header.expandable && header.setExpanded && (
                    <Box
                      sx={{ lineHeight: "0.5rem" }}
                      onClick={() =>
                        header.setExpanded &&
                        header.setExpanded(!header.expanded)
                      }
                    >
                      {header.expanded ? (
                        <UnfoldLess sx={{ transform: "rotate(90deg)" }} />
                      ) : (
                        <UnfoldMore sx={{ transform: "rotate(90deg)" }} />
                      )}
                    </Box>
                  )}
                  {header.sortable && !header.expanded && getSortedValue ? (
                    <Box
                      sx={{
                        position:
                          header.subHeaders || noSubtitles
                            ? "static"
                            : "absolute",
                        cursor: "pointer",
                        ...sortBlockWrapper,
                        paddingTop: header.expandable ? "0px" : "8px",
                      }}
                      onClick={() => getSortedValueAndResetPage(header.id)}
                    >
                      <Typography variant="regular" sx={titleStyle}>
                        {header.label}
                        <SortIcon sx={sortIconStyle} />
                      </Typography>
                    </Box>
                  ) : (
                    <Typography
                      variant="regular"
                      sx={{
                        ...mainTitleStyle,
                        position: header.subHeaders ? "static" : "absolute",
                      }}
                    >
                      {header.label}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
            {buildTableSubHeaders(
              headers,
              getSortedValueAndResetPage,
              stickyColumnIndex,
            )}
          </TableHead>
          <TableBody>
            {isFetching && !rows?.length && <EmptyContentPlaceholder />}
            <Loader isLoading={isFetching} boundToParent />
            <>
              {rows.map((row: Row, index: number) => {
                return (
                  <TableRow hover key={index}>
                    {row.values.map((item: any, index: number) => (
                      <TableCell
                        key={index}
                        align="center"
                        sx={{
                          ...textStyle,
                          ...(index === stickyColumnIndex && {
                            position: "sticky",
                            left: "0",
                            backgroundColor: "white",
                          }),
                          ...(colouredColumnsIndex.includes(index) && {
                            left: "0",
                            backgroundColor:
                              item && getPercentageBackgroundColor(item),
                            borderRight: {
                              xs: `solid 2px ${mainWhite}`,
                              md: "none",
                            },
                          }),
                        }}
                      >
                        {item && item.href && item.value > 0 ? (
                          <Link
                            variant="regular"
                            underline="hover"
                            href={item.href}
                            target="_blank"
                          >
                            {formatCellContent(
                              item.value,
                              substitutions,
                              cellFormatters?.at(index),
                            )}
                          </Link>
                        ) : (
                          <Typography variant="regular">
                            {formatCellContent(
                              typeof item === "object" &&
                                item !== null &&
                                "value" in item
                                ? item.value
                                : item,
                              substitutions,
                              cellFormatters?.at(index),
                            )}
                          </Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {totalsColSpan && (
                <>
                  {pageTotals &&
                    rowsPerPage !== ROWS_PER_PAGE_ALL &&
                    totalsRow(PAGE_TOTAL, pageTotals)}
                  {differenceTotals &&
                    rowsPerPage !== ROWS_PER_PAGE_ALL &&
                    totalsRow(OTHER, differenceTotals)}
                  {grandTotals && totalsRow(GRAND_TOTAL, grandTotals)}
                  {customTotals &&
                    customTotals.map((customTotal: CustomTotal) =>
                      totalsRow(customTotal.title, customTotal.totals),
                    )}
                </>
              )}
            </>
          </TableBody>
        </Table>
      </TableContainer>
      {!!count && (
        <TablePagination
          component="div"
          count={count}
          labelRowsPerPage=""
          rowsPerPage={rowPerPage}
          rowsPerPageOptions={[
            20,
            50,
            100,
            { value: ROWS_PER_PAGE_ALL, label: ALL },
          ]}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={paginationStyle}
        />
      )}
    </Paper>
  );
}

export default ColouredTable;
