import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import { useEffect, useMemo, useState } from "react";
import * as models from "models";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";

interface ClaimCodesFiltersSynchronizerProps {
  allDealers?: Array<models.Dealer>;
  selectedDealers?: Array<OptionsProps>;
  allClaimCodes?: Array<models.ClaimCode>;
  selectedClaimCodes?: Array<OptionsProps>;
  allSalesOpportunities?: Array<models.SalesOpportunity>;
  selectedSalesOpportunities?: Array<OptionsProps>;
  allClientCorporateNames?: Array<models.ClientCorporateName>;
  selectedClientCorporateNames?: Array<OptionsProps>;
}

function ClaimCodesFiltersSynchronizer({
  allDealers,
  selectedDealers,
  allClaimCodes,
  selectedClaimCodes,
  allSalesOpportunities,
  selectedSalesOpportunities,
  allClientCorporateNames,
  selectedClientCorporateNames,
}: ClaimCodesFiltersSynchronizerProps) {
  const { data: filterGroups, isLoading: filterGroupsIsLoading } =
    dealerPerformanceAPI.useGetClaimCodesFilterGroupsQuery();
  const [dealerRelevantValues, setDealerRelevantValues] = useState(allDealers);
  const [claimCodeRelevantValues, setClaimCodeRelevantValues] =
    useState(allClaimCodes);
  const [salesOpportunityRelevantValues, setSalesOpportunityRelevantValues] =
    useState(allSalesOpportunities);
  const [
    clientCorporateNameRelevantValues,
    setClientCorporateNameRelevantValues,
  ] = useState(allClientCorporateNames);

  const filterCategories = useMemo(
    () => [
      {
        name: "dealer",
        field: "id",
        allOptions: allDealers,
        selectedOptions: selectedDealers,
        setRelevantOptions: setDealerRelevantValues,
      },
      {
        name: "claim_code",
        field: "claim_code",
        allOptions: allClaimCodes,
        selectedOptions: selectedClaimCodes,
        setRelevantOptions: setClaimCodeRelevantValues,
      },
      {
        name: "sales_opportunity",
        field: "sales_opportunity",
        allOptions: allSalesOpportunities,
        selectedOptions: selectedSalesOpportunities,
        setRelevantOptions: setSalesOpportunityRelevantValues,
      },
      {
        name: "client_corporate_name",
        field: "client_corporate_name",
        allOptions: allClientCorporateNames,
        selectedOptions: selectedClientCorporateNames,
        setRelevantOptions: setClientCorporateNameRelevantValues,
      },
    ],
    [
      allDealers,
      selectedDealers,
      setDealerRelevantValues,
      allClaimCodes,
      selectedClaimCodes,
      setClaimCodeRelevantValues,
      allSalesOpportunities,
      selectedSalesOpportunities,
      setSalesOpportunityRelevantValues,
      allClientCorporateNames,
      selectedClientCorporateNames,
      setClientCorporateNameRelevantValues,
    ],
  );

  useEffect(() => {
    if (!filterGroups) return;
    for (let childIdx = 0; childIdx < filterCategories.length; ++childIdx) {
      const child = filterCategories[childIdx];
      if (!child.allOptions) continue;
      let relevantChildOptions: any[] = child.allOptions;

      for (
        let parentIdx = 0;
        parentIdx < filterCategories.length;
        ++parentIdx
      ) {
        if (parentIdx === childIdx) continue;
        const parent = filterCategories[parentIdx];
        if (!parent.selectedOptions?.length) continue;
        const groups = filterGroups[child.name + "_by_" + parent.name];

        relevantChildOptions = relevantChildOptions.filter(
          (childOption) =>
            parent.selectedOptions?.some(
              (selectedParentOption) =>
                selectedParentOption.id in groups &&
                groups[selectedParentOption.id].includes(
                  childOption[child.field],
                ),
            ),
        );
      }

      child.setRelevantOptions(relevantChildOptions);
    }
  }, [filterGroups, filterCategories]);

  return {
    dealerRelevantValues,
    claimCodeRelevantValues,
    salesOpportunityRelevantValues,
    clientCorporateNameRelevantValues,
    isLoading: filterGroupsIsLoading,
  };
}

export default ClaimCodesFiltersSynchronizer;
