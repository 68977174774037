import { mainColor, mainWhite } from "shared/colors";

export const userInfoPageStyle = {
  blockWrapper: {
    backgroundColor: mainColor,
    width: { md: "500px", xs: "350px" },
    padding: "20px",
    borderRadius: 1,
  },
  titleStyle: {
    color: mainWhite,
    fontWeight: 800,
    textAlign: "center",
    textTransform: "uppercase",
  },
  buttonsWrapper: {
    width: "100%",
    minWidth: "200px",
    marginTop: "60px",
    display: "table",
    tableLayout: "fixed",
    borderCollapse: "separate",
    borderSpacing: "16px 0px",
    "& div": {
      display: "table-cell",
    },
  },
  rowWrapper: {
    padding: "12px 0",
  },
  cardStyle: {
    marginTop: "10%",
    padding: "30px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    borderRadius: "2px",
    width: { md: "500px", xs: "350px" },
  },
  containerStyle: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
  },
  buttonStyle: {
    width: "100%",
    minWidth: "100%",
  },
};
