import { mainColor, mainWhite } from "shared/colors";

export const mainColorButtonStyle = {
  buttonStyle: {
    backgroundColor: mainColor,
    color: mainWhite,
    width: { xs: "100%", sm: "calc(50% - 16px)", md: "230px" },
    minWidth: { xs: "100%", sm: "calc(50% - 16px)", md: "230px" },
    height: 48,
    fontWeight: 800,
    "&:hover": {
      backgroundColor: mainColor,
      color: mainWhite,
    },
  },
};
