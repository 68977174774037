import { Box, Container, FormControl } from "@mui/material";
import React, { ReactNode } from "react";
import { formWrapperStyle } from "./FormWrapper.style";

interface FormWrapperProps {
  children: ReactNode;
  handleSubmit: () => void;
}

const FormWrapper: React.FC<FormWrapperProps> = ({
  children,
  handleSubmit,
}) => {
  const { formStyle, formWrapper, containerStyle } = formWrapperStyle;
  return (
    <Container sx={containerStyle}>
      <form onSubmit={handleSubmit} style={formWrapper}>
        <FormControl>
          <Box sx={formStyle}>{children}</Box>
        </FormControl>
      </form>
    </Container>
  );
};

export default FormWrapper;
