import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  BasicModal,
  CustomTable,
  DateBlock,
  ExportDataBlock,
  Loader,
  MainColorButton,
} from "components";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import { orderAPI } from "api/services/Order";
import { DetailedMonitoringData } from "models/Monitoring";
import { useSearchParams } from "react-router-dom";
import useModal from "hooks/useModal";
import { useSortData } from "hooks/useSortData";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";

function BackorderDetailsPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const { open, openModal, closeModal } = useModal();
  const [skipExportTable, setSkipExportTable] = useState(true);
  const [skipExportData, setSkipExportData] = useState(true);
  const { sortData, ordering } = useSortData();
  const [searchParams] = useSearchParams();

  const queryParams = useMemo(
    () => ({
      dealers: searchParams.get("dealers") || "",
      car_models: searchParams.get("car_models") || "",
      usage_status: searchParams.get("usage_status") || "",
      order_status: searchParams.get("order_status") || "",
      factories: searchParams.get("factories") || "",
      reservation_number: searchParams.get("reservation_number") || "",
      date_from: searchParams.get("date_from") || "",
      date_to: searchParams.get("date_to") || "",
      exclude: searchParams.get("exclude") || "",
      grand_total: searchParams.get("grand_total") || "",
    }),
    [searchParams],
  );
  const { data: tableData, isFetching: tableDataIsFetching } =
    orderAPI.useGetDetailedMonitoringDataQuery({
      ...queryParams,
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const { data: exportTableData, isFetching: exportTableDataIsFetching } =
    orderAPI.useGetDetailedMonitoringExportTableQuery(
      { ...queryParams, ordering: ordering },
      { skip: skipExportTable || !tableData?.results.length },
    );
  const { data: exportAllData, isFetching: exportAllDataIsFetching } =
    orderAPI.useGetDetailedMonitoringExportTableQuery(queryParams, {
      skip: skipExportData,
    });

  const otdMonitoringHeaders: Header[] = [
    {
      id: "sales_document",
      label: constants.RESERVATION_NUMBER,
      sortable: true,
    },
    {
      id: "brand_type_description",
      label: constants.MODEL_DESCRIPTION,
      sortable: true,
    },
    { id: "exterior_colour", label: constants.EXTERIOR_COLOUR, sortable: true },
    { id: "interior_colour", label: constants.INTERIOR_COLOUR, sortable: true },
    { id: "vehicle_usage", label: constants.VEHICLE_USAGE, sortable: true },
    { id: "document_date", label: constants.ORDER_DATE, sortable: true },
    {
      id: "vehicle_usage_status",
      label: constants.VEHICLE_USAGE,
      sortable: true,
    },
    { id: "estimation_of_arrival", label: constants.ETA, sortable: true },
  ];

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map((otdMonitoring: DetailedMonitoringData) => ({
        values: [
          otdMonitoring.sales_document,
          otdMonitoring.brand_type_description,
          otdMonitoring.exterior_colour,
          otdMonitoring.interior_colour,
          otdMonitoring.vehicle_usage,
          otdMonitoring.document_date,
          otdMonitoring.vehicle_usage_status,
          otdMonitoring.estimation_of_arrival,
        ],
      }));
    }
    return [];
  }, [tableData]);

  function openExportsBlock() {
    setSkipExportTable(false);
    setSkipExportData(true);
    openModal();
  }

  const getPageTitle = () => {
    const page_title = searchParams.get("page_title") || "";
    let usage_status: string = searchParams.get("usage_status") || "";
    let order_status = searchParams.get("order_status") || "";
    const car_model = searchParams.get("car_models") || "";

    const rename_dict = {
      stock: constants.STOCK,
      not_in_production: constants.NOT_PRODUCTION,
      in_production: constants.IN_PRODUCTION,
      shipped: constants.SHIPPED,
      awaiting_shipment: constants.AWAITING_SHIPMENT,
    };

    if (usage_status in rename_dict) {
      usage_status = rename_dict[usage_status as keyof typeof rename_dict];
    }
    if (order_status in rename_dict) {
      order_status = rename_dict[order_status as keyof typeof rename_dict];
    }

    let title = `${constants.OTD_MONITORING}`;
    if (page_title) {
      title += ` - ${page_title}`;
    }
    if (car_model && car_model.split(",").length === 1) {
      title += ` - ${car_model}`;
    }
    if (usage_status) {
      title += ` - ${usage_status}`;
    }
    if (order_status) {
      title += ` - ${order_status}`;
    }
    return title.replace("_", " ").replace("_", " ");
  };

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={getPageTitle()} />
        <Box>
          <LastUpdateBlock fileType="order_backorders" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <MainColorButton
            onClick={() => openExportsBlock()}
            label={constants.EXPORT}
          />
          <BasicModal
            open={open}
            handleClose={() => closeModal()}
            centerPosition
          >
            <>
              <Loader
                isLoading={exportTableDataIsFetching || exportAllDataIsFetching}
              />
              <ExportDataBlock
                exportAllData={exportAllData}
                exportTableData={exportTableData}
                onClose={closeModal}
                exportTableName="OTD_monitoring_table"
                exportDataName="OTD_monitoring_data"
                skipExportData={skipExportData}
              />
            </>
          </BasicModal>
        </Box>
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={otdMonitoringHeaders}
        rows={tableRows}
        count={tableData?.count}
        getItemsForPage={(items: number) => setSkip(items)}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        getSortedValue={(value: string) => sortData(value)}
        totalsColSpan={3}
        stickyColumnIndex={1}
      />
    </Box>
  );
}

export default BackorderDetailsPage;
