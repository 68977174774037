import { ReactNode } from "react";
import { Box, Drawer } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { customDrawerStyle } from "./CustomDrawer.style";

interface DrawerActionProps {
  handleClose: () => void;
  open: boolean;
  children: ReactNode;
  anchor?: "left" | "top" | "right" | "bottom";
}

export const CustomDrawer: React.FC<DrawerActionProps> = ({
  children,
  open,
  handleClose,
  anchor,
}) => {
  const { drawerWrapper, contentWrapper, closeIconStyle } = customDrawerStyle;
  return (
    <Drawer
      anchor={anchor || "left"}
      sx={drawerWrapper}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
    >
      <Box sx={contentWrapper}>
        <CloseIcon onClick={handleClose} sx={closeIconStyle} />
        {children}
      </Box>
    </Drawer>
  );
};

export default CustomDrawer;
