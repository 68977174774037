import {
  matchRoutes,
  resolvePath,
  useLocation,
  useResolvedPath,
} from "react-router-dom";

const useMatchLinks = (links: string[]) => {
  const location = useLocation();
  const { pathname: pathSoFar } = useResolvedPath("");
  const routes = links.map((link) => ({
    path: resolvePath(link, pathSoFar).pathname,
  }));
  return Boolean(matchRoutes(routes, location));
};

export default useMatchLinks;
