import { useMemo, useState } from "react";
import { Header } from "components/tables/interfaces";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import { useSortData } from "hooks/useSortData";
import { getPercentageValue100 } from "helper/getPercentageValue100";
import { Box } from "@mui/material";
import * as constants from "shared/constants";
import { getFilterValue, dateToFilenameFormat } from "shared/utils";
import { ColouredTable, DateBlock, FiltersBlock } from "components";
import { DemoShowRoomData } from "models/Tables";
import DownloadXlsxButton from "components/DownloadXlsxButton/DownloadXlsxButton";
import { dispatchFilterChangedEvent } from "shared/events";

function DemoShowRoomPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const [dealers, setDealers] = useState<Array<OptionsProps>>([]);
  const [modelTypes, setModelTypes] = useState<Array<OptionsProps>>([]);
  const [factories, setFactories] = useState<Array<OptionsProps>>([]);
  const [vinNumbers, setVinNumbers] = useState<Array<string>>([]);
  const [reservationNumbers, setReservationNumbers] = useState<Array<string>>(
    [],
  );
  const { sortData, ordering } = useSortData();

  const queryParams = useMemo(() => {
    dispatchFilterChangedEvent();
    return {
      dealer: dealers.map((item: OptionsProps) => item.id).toString(),
      car_model: modelTypes.map((item: OptionsProps) => item.id).toString(),
      factory: factories.map((item: OptionsProps) => item.id).toString(),
      vin_number: vinNumbers.toString(),
      reservation_number: reservationNumbers.toString(),
    };
  }, [dealers, factories, modelTypes, reservationNumbers, vinNumbers]);
  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetDemoShowRoomQuery({
      ...queryParams,
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const {
    data: exportAllData,
    isFetching: exportAllDataIsFetching,
    isError: exportAllDataIsError,
  } = dealerPerformanceAPI.useGetDemoShowRoomExportDataQuery({
    ...queryParams,
    sales_order: queryParams.reservation_number,
  });

  const generateDetailsViewURL = (
    page_title: string,
    dealer_ids: Array<string | any> = [],
    model_types: Array<string | any> = [],
    factories: Array<string | any> = [],
    soort: string,
    grand_total: Boolean = false,
    page_total: Boolean = false,
  ) => {
    let url =
      `/main/demo-showroom-details-page?` +
      `page_title=${page_title}&` +
      `dealers=${dealer_ids}&` +
      `car_models=${model_types}&` +
      `factories=${factories}&` +
      `sales_order=${reservationNumbers.join(",") ? reservationNumbers : ""}&` +
      `vin_code=${vinNumbers.join(",") ? vinNumbers : ""}&` +
      `soort=${soort}`;

    if (grand_total) {
      url += `&grand_total=${grand_total}`;
    }

    if (page_total) {
      url += `&page_total=${page_total}`;
    }
    return url;
  };

  const getDealers = () => {
    if (tableData) {
      return [
        tableData.results.map(
          (demo_showroom: DemoShowRoomData) => demo_showroom.dealer_id,
        ),
      ];
    }
  };

  const getModelTypes = () => {
    if (tableData) {
      return [
        tableData.results.map(
          (demo_showroom: DemoShowRoomData) => demo_showroom.model_type,
        ),
      ];
    }
  };

  const getFactories = () => {
    if (tableData) {
      return [
        tableData.results.map(
          (demo_showroom: DemoShowRoomData) => demo_showroom.factory,
        ),
      ];
    }
  };

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map((demoShowRoom: DemoShowRoomData) => ({
        values: [
          demoShowRoom.dealer_name,
          demoShowRoom.factory,
          demoShowRoom.model_type,
          demoShowRoom.demo_goal,
          {
            value: demoShowRoom.demo_actual,
            href: generateDetailsViewURL(
              `${constants.DEMO_SHOWROOM_OVERVIEW} - Demo - ${demoShowRoom.model_type}`,
              [demoShowRoom.dealer_id],
              [demoShowRoom.model_type],
              [demoShowRoom.factory],
              "demo",
            ),
          },
          typeof demoShowRoom.demo_diff == "number"
            ? getPercentageValue100(demoShowRoom.demo_diff)
            : demoShowRoom.demo_diff,
          demoShowRoom.showroom_goal,
          {
            value: demoShowRoom.showroom_actual,
            href: generateDetailsViewURL(
              `${constants.DEMO_SHOWROOM_OVERVIEW} - Show - ${demoShowRoom.model_type}`,
              [demoShowRoom.dealer_id],
              [demoShowRoom.model_type],
              [demoShowRoom.factory],
              "show",
            ),
          },
          typeof demoShowRoom.showroom_diff == "number"
            ? getPercentageValue100(demoShowRoom.showroom_diff)
            : demoShowRoom.showroom_diff,
        ],
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const DemoShowRoomHeaders: Header[] = [
    { id: "dealer_name", label: constants.DEALER, sortable: true },
    { id: "factory", label: constants.FACTORY, sortable: true },
    { id: "model_type", label: constants.MODEL_TYPE, sortable: true },

    {
      id: "demo",
      label: constants.DEMO,
      subHeaders: [
        { id: "demo_goal", label: constants.TARGET, sortable: true },
        { id: "demo_actual", label: constants.DEMO, sortable: true },
        { id: "demo_diff", label: constants.VS_TARGET, sortable: true },
      ],
    },
    {
      id: "showroom",
      label: constants.SHOW,
      subHeaders: [
        { id: "showroom_goal", label: constants.TARGET, sortable: true },
        { id: "showroom_actual", label: constants.SHOW, sortable: true },
        { id: "showroom_diff", label: constants.VS_TARGET, sortable: true },
      ],
    },
  ];

  const date = dateToFilenameFormat(new Date());

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={constants.DEMO_SHOWROOM_OVERVIEW} />
        <Box>
          <LastUpdateBlock fileType="demo_showroom" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <FiltersBlock
          hideCountryFilter
          setSelectedModelTypes={setModelTypes}
          setSelectedFactories={setFactories}
          setSelectedDealers={setDealers}
          setSelectedReservationNumbers={setReservationNumbers}
          setSelectedVinNumbers={setVinNumbers}
          selectedModelTypes={modelTypes}
          selectedFactories={factories}
          selectedDealers={dealers}
          selectedReservationNumbers={reservationNumbers}
          selectedVinNumbers={vinNumbers}
        />

        <Box>
          <Box sx={{ display: "grid" }}>
            <DownloadXlsxButton
              filename={`Demo_ShowRoom_data_${date}.xlsx`}
              data={exportAllData}
              isFetching={exportAllDataIsFetching}
              isError={exportAllDataIsError}
            />
          </Box>
        </Box>
      </Box>
      <ColouredTable
        isFetching={tableDataIsFetching}
        headers={DemoShowRoomHeaders}
        colouredColumnsIndex={[5, 8]}
        rows={tableRows}
        count={tableData?.count}
        getSortedValue={(value: string) => sortData(value)}
        getItemsForPage={(items: number) => setSkip(items)}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        pageTotals={
          tableData && [
            tableData.page_total.demo_goal || 0,
            {
              value: tableData.page_total.demo_actual,
              href: generateDetailsViewURL(
                `${constants.DEMO_SHOWROOM_OVERVIEW} - Demo - TOTAAL PAGINA`,
                getDealers(),
                getModelTypes(),
                getFactories(),
                "demo",
                false,
                true,
              ),
            } || 0,
            getPercentageValue100(tableData.page_total.demo_diff || 0),
            tableData.page_total.showroom_goal || 0,
            {
              value: tableData.page_total.showroom_actual,
              href: generateDetailsViewURL(
                `${constants.DEMO_SHOWROOM_OVERVIEW} - Show - TOTAAL PAGINA`,
                getDealers(),
                getModelTypes(),
                getFactories(),
                "show",
                false,
                true,
              ),
            } || 0,
            getPercentageValue100(tableData.page_total.showroom_diff || 0),
          ]
        }
        grandTotals={
          tableData && [
            tableData.full_total.demo_goal || 0,
            {
              value: tableData.full_total.demo_actual,
              href: generateDetailsViewURL(
                `${constants.DEMO_SHOWROOM_OVERVIEW} - Demo - TOTAAL`,
                getFilterValue(dealers, "id"),
                getFilterValue(modelTypes, "label"),
                getFilterValue(factories, "label"),
                "demo",
                true,
                false,
              ),
            } || 0,
            getPercentageValue100(tableData.full_total.demo_diff || 0),
            tableData.full_total.showroom_goal || 0,
            {
              value: tableData.full_total.showroom_actual,
              href: generateDetailsViewURL(
                `${constants.DEMO_SHOWROOM_OVERVIEW} - Show - TOTAAL`,
                getFilterValue(dealers, "id"),
                getFilterValue(modelTypes, "label"),
                getFilterValue(factories, "label"),
                "show",
                true,
                false,
              ),
            } || 0,
            getPercentageValue100(tableData.full_total.showroom_diff || 0),
          ]
        }
        totalsColSpan={3}
        stickyColumnIndex={2}
      />
    </Box>
  );
}

export default DemoShowRoomPage;
