import { Button } from "@mui/material";
import useMatchLinks from "hooks/useMatchLinks";
import { mainButtonStyle } from "./MainButton.style";

interface MainButtonProps {
  label: string;
  onClick?: () => void;
  customStyle?: Object;
  link?: string;
}

function MainButton({ label, onClick, customStyle, link }: MainButtonProps) {
  const selectedButton = useMatchLinks(link ? [link] : []);
  const { buttonStyle } = mainButtonStyle;

  return (
    <Button
      variant={selectedButton ? "selected_button" : "text"}
      sx={{ ...buttonStyle, ...customStyle }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}

export default MainButton;
