import {
  BaseQueryFn,
  createApi,
  FetchArgs,
} from "@reduxjs/toolkit/dist/query/react";
import { CustomError } from "models/CustomError";
import { LogIn } from "models/LogIn";
import { Tokens } from "models/Tokens";
import { baseQueryWithReauth } from "./baseQueryWithReauth";

export const authAPI = createApi({
  reducerPath: "authAPI",
  baseQuery: baseQueryWithReauth as BaseQueryFn<
    string | FetchArgs,
    unknown,
    CustomError,
    {}
  >,
  tagTypes: ["Auth"],
  endpoints: (build) => ({
    signIn: build.mutation<Tokens, LogIn>({
      query: (logIn) => ({
        url: "auth/token/",
        method: "POST",
        body: logIn,
      }),
    }),
  }),
});
