import { useMediaQuery } from "@mui/material";
import { theme } from "helper/getTheme";

const useGetViewPort = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletAndMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  return { isMobile, isTabletAndMobile };
};

export default useGetViewPort;
