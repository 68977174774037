import { boxShadow, mainWhite } from "shared/colors";

export const formWrapperStyle = {
  formStyle: {
    backgroundColor: mainWhite,
    marginTop: "30%",
    padding: "30px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    boxShadow: boxShadow,
    borderRadius: "2px",
  },
  formWrapper: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
  },
  containerStyle: {
    padding: 0,
  },
};
