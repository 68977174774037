export const logoStyle = {
  logoWrapper: {
    width: "180px",
    height: "30px",
    alignSelf: "center",
  },

  smallLogoWrapper: {
    width: "130px",
    height: "20px",
    marginTop: "5px",
    alignSelf: "center",
  },
};
