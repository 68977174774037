import { useEffect } from "react";
import { toast } from "react-toastify";
import { NO_INTERNET_CONNECTION } from "shared/messages";
import { useLocation } from "react-router-dom";

const toastId = "networkStatus";
const showNoConnectionToast = () => {
  toast.error(NO_INTERNET_CONNECTION, {
    toastId: toastId,
  });
};

function NetworkStatusNotifier() {
  useEffect(() => {
    window.addEventListener("offline", () => {
      showNoConnectionToast();
    });
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (!navigator.onLine) {
      showNoConnectionToast();
    }
  }, [location]);

  return <div />;
}

export default NetworkStatusNotifier;
