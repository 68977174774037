export const mainColor = "#002c5f";
export const mainWhite = "#f5f5f5";
export const sandColor = "#e4dcd3";
export const lightSandColor = "#f6f3f2";
export const darkRed = "rgba(222, 52, 43, 1)";
export const goldColor = "#a36b4f";
export const progressiveBlue = "#00aad2";
export const performanceBlue = "#aacae6";
export const mintColor = "#f5fffa";
export const boxShadow =
  "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset";
export const yellowColor = "rgb(255, 219, 77, 0.8)";
export const greenColor = "rgb(38, 115, 77, 0.8)";
export const purpleColor = "rgb(102, 0, 153, 0.6)";
export const greyBlue = "rgb(51, 102, 153, 0.2)";
export const darkBlue = "#0066cc";
export const lightBlueColor = "rgb(179, 204, 230)";
export const darkBrown = "rgb(51,26,0)";

export const redColor = "#ff1a1a";
export const orangeColor = "#ffb84d";
export const greenLightColor = "#66cc66";
