import { mainColor, mainWhite } from "shared/colors";

export const autocompleteTextFieldStyle = {
  iconStyle: {
    color: mainWhite,
  },
  inputStyle: {
    fontFamily: "HyundaiFontMediumText",
    color: mainWhite,
    fontWeight: 600,
    "&.MuiOutlinedInput-root": {
      height: "48px",
    },
  },
  autocompleteStyle: {
    width: { xs: "100%", sm: "calc(50% - 16px)", md: "230px" },
    minWidth: { xs: "100%", sm: "calc(50% - 16px)", md: "230px" },
    color: mainWhite,
    marginRight: "16px",
    marginBottom: "16px",
  },
  textFieldStyle: {
    backgroundColor: mainColor,
    color: mainWhite,
    borderRadius: 2,
    marginRight: "16px",
    fontSize: "14px",
    "& .MuiAutocomplete-clearIndicator": { color: mainWhite },
    "& input": {
      color: mainWhite,
      fontSize: "14px",
    },
    "& input::placeholder": {
      color: mainWhite,
      fontSize: "14px",
      opacity: 0.8,
    },
  },
  filterIconStyle: {
    color: mainWhite,
  },
};
