import { Box, Tooltip } from "@mui/material";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import {
  BasicModal,
  CustomTable,
  DateBlock,
  ExportDataBlock,
  FiltersBlock,
  Loader,
  MainColorButton,
} from "components";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import { Header } from "components/tables/interfaces";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import PageTitle from "components/PageTitle/PageTitle";
import dayjs from "dayjs";
import { getPercentageValue } from "helper/getPercentageValue";
import useModal from "hooks/useModal";
import { useSortData } from "hooks/useSortData";
import { LicenseReturn } from "models/LicenseReturnData";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as constants from "shared/constants";
import { dateToOperationalFormat, getFilterValue } from "shared/utils";
import * as filterFieldsNames from "components/FiltersBlock/fieldsNames";
import * as buildUtils from "shared/validation/buildUtils";
import {
  defaultLazyFieldNames,
  defaultValidators,
} from "shared/validation/filterValidators";
import { useFormik } from "formik";
import { useGetLastValid } from "hooks/useGetLastValid";
import { dispatchFilterChangedEvent } from "shared/events";

function LicenseReturnPage() {
  const defaultDateFrom = useMemo(() => dayjs().startOf("month").toDate(), []);
  const defaultDateTo = useMemo(() => new Date(), []);

  const filterSchema = useMemo(
    () =>
      buildUtils.buildObjectSchema(defaultValidators, defaultLazyFieldNames),
    [],
  );
  const formik = useFormik({
    initialValues: {
      [filterFieldsNames.DATE_FROM]: defaultDateFrom,
      [filterFieldsNames.DATE_TO]: defaultDateTo,
    },
    validate: buildUtils.buildValidateFunc(filterSchema),
    onSubmit: () => {},
  });

  const setDateFrom = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_FROM, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const setDateTo = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_TO, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const [dealers, setDealers] = useState<Array<OptionsProps>>([]);
  const [brands, setBrands] = useState<Array<OptionsProps>>([]);
  const [factories, setFactories] = useState<Array<OptionsProps>>([]);
  const [reservationNumbers, setReservationNumbers] = useState<Array<string>>(
    [],
  );
  const [vinNumbers, setVinNumbers] = useState<Array<string>>([]);
  const { open, openModal, closeModal } = useModal();
  const { sortData, ordering } = useSortData();
  const [skipExportTable, setSkipExportTable] = useState(true);
  const [skipExportData, setSkipExportData] = useState(true);

  const queryParams = useMemo(
    () => ({
      dealer: dealers.map((item: OptionsProps) => item.id).toString(),
      car_model: brands.map((item: OptionsProps) => item.id).toString(),
      factory: factories.map((item: OptionsProps) => item.id).toString(),
      date_from: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_FROM],
      ),
      date_to: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_TO],
      ),
      vin_number: vinNumbers.toString(),
      reservation_number: reservationNumbers.toString(),
    }),
    [brands, formik.values, dealers, factories, reservationNumbers, vinNumbers],
  );
  const validatedQueryParams = useGetLastValid(
    queryParams,
    formik.isValid,
    formik.isValidating,
  );
  useEffect(() => {
    dispatchFilterChangedEvent();
  }, [validatedQueryParams]);
  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetLicenseReturnQuery({
      ...(validatedQueryParams || queryParams),
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const { data: exportTableData, isFetching: exportTableDataIsFetching } =
    dealerPerformanceAPI.useGetLicenseReturnExportTableQuery(
      { ...(validatedQueryParams || queryParams), ordering: ordering },
      { skip: skipExportTable || !tableData?.results.length },
    );
  const { data: exportAllData, isFetching: exportAllDataIsFetching } =
    dealerPerformanceAPI.useGetLicenseReturnExportDataQuery(
      validatedQueryParams || queryParams,
      {
        skip: skipExportData,
      },
    );

  function openExportsBlock() {
    setSkipExportTable(false);
    setSkipExportData(false);
    openModal();
  }

  const generateDetailsViewURL = (
    dealers: Array<string | any> = [],
    car_models: Array<string | any> = [],
    factories: Array<string | any> = [],
    grand_total = false,
  ) => {
    let url =
      `/main/license-return-details-page?` +
      `dealers=${dealers.join(",") ? dealers : ""}&` +
      `car_models=${car_models.join(",") ? car_models : ""}&` +
      `factories=${factories.join(",") ? factories : ""}&` +
      `reservation_number=${
        reservationNumbers.join(",") ? reservationNumbers : ""
      }&` +
      `vin_number=${vinNumbers.join(",") ? vinNumbers : ""}&` +
      `date_from=${dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_FROM],
      )}&` +
      `date_to=${dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_TO],
      )}`;

    if (grand_total) {
      url += `&grand_total=${grand_total}`;
    }
    return url;
  };

  const licenseReturnHeaders: Header[] = [
    { id: "dealer_name", label: constants.DEALER, sortable: true },
    { id: "factory", label: constants.FACTORY, sortable: true },
    { id: "brand_code", label: constants.MODEL, sortable: true },
    {
      id: "dealerPerformance",
      label: constants.DEALER_PERFORMANCE,
      subHeaders: [
        { label: constants.MTD, id: "current_month_sales", sortable: true },
      ],
    },
    {
      id: "achievement",
      label: constants.ACHIEVEMENT,
      subHeaders: [{ label: constants.PERCENTAGE, id: "achievement" }],
    },
  ];

  const goalsTooltip = (monthlyGoal: number, annualGoal: number) => (
    <Box sx={{ fontSize: "14px" }}>
      <Box sx={{ padding: "4px 10px" }}>
        {constants.DEALER_TARGET}: {monthlyGoal}
      </Box>
      <Box sx={{ padding: "4px 10px" }}>
        {constants.ANNUAL_TARGET}: {annualGoal}
      </Box>
    </Box>
  );

  const licenseReturnRow = useMemo(() => {
    if (tableData) {
      return tableData.results.map((licenseReturn: LicenseReturn) => ({
        values: [
          <Tooltip
            title={goalsTooltip(
              licenseReturn.month_goal,
              licenseReturn.year_goal,
            )}
            arrow
          >
            <div>{licenseReturn.dealer_name}</div>
          </Tooltip>,
          licenseReturn.factory,
          licenseReturn.brand_code,
          {
            value: licenseReturn.current_month_sales,
            href: generateDetailsViewURL(
              [licenseReturn.dealerID],
              [licenseReturn.brand_code],
              [licenseReturn.factory],
            ),
          },
          getPercentageValue(licenseReturn.month_sales_percent),
        ],
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={constants.LICENSE_RETURN} />
        <Box>
          <LastUpdateBlock fileType="license_requests" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <FiltersBlock
          hideCountryFilter
          setSelectedBrands={setBrands}
          setSelectedFactories={setFactories}
          setSelectedDealers={setDealers}
          setSelectedReservationNumbers={setReservationNumbers}
          setSelectedVinNumbers={setVinNumbers}
          defaultDateFrom={defaultDateFrom}
          defaultDateTo={defaultDateTo}
          setSelectedDateFrom={setDateFrom}
          setSelectedDateTo={setDateTo}
          selectedBrands={brands}
          selectedFactories={factories}
          selectedDealers={dealers}
          selectedVinNumbers={vinNumbers}
          selectedReservationNumbers={reservationNumbers}
          errors={formik.errors as { [field: string]: string[] }}
        />
        <MainColorButton
          onClick={() => openExportsBlock()}
          label={constants.EXPORT}
        />
        <BasicModal open={open} handleClose={() => closeModal()} centerPosition>
          <>
            <Loader
              isLoading={exportTableDataIsFetching || exportAllDataIsFetching}
            />
            <ExportDataBlock
              exportAllData={exportAllData}
              exportTableData={exportTableData}
              onClose={closeModal}
              exportTableName="License_return_table"
              exportDataName="License_return_data"
            />
          </>
        </BasicModal>
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={licenseReturnHeaders}
        rows={licenseReturnRow}
        count={tableData?.count}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        customTotals={
          tableData
            ? [
                {
                  title: constants.GRAND_TOTAL,
                  totals: [
                    {
                      value: tableData.full_total.dealer_performance,
                      href: generateDetailsViewURL(
                        getFilterValue(dealers, "id"),
                        getFilterValue(brands),
                        getFilterValue(factories),
                        true,
                      ),
                    } || 0,
                    getPercentageValue(tableData.full_total.current_percent),
                  ],
                },
                {
                  title: constants.TO_DO,
                  totals: [
                    tableData.full_total.left_to_meet_target | 0,
                    tableData.full_total.left_to_meet_target_percentage
                      ? getPercentageValue(
                          tableData.full_total.left_to_meet_target_percentage,
                        )
                      : "0%",
                  ],
                },
                {
                  title: constants.TOTAL_MONTHLY_GOAL,
                  totals: [tableData.full_total.dealer_target, ""],
                },
                {
                  title: constants.TOTAL_ANNUAL_GOAL,
                  totals: [tableData.full_total.annual_target, ""],
                },
              ]
            : []
        }
        totalsColSpan={3}
        stickyColumnIndex={2}
        getItemsForPage={(items: number) => setSkip(items)}
        getSortedValue={(value: string) => sortData(value)}
      />
    </Box>
  );
}

export default LicenseReturnPage;
