export interface defaultQueryModel {
  limit?: number;
  offset?: number;
  dealer?: string;
  factory?: string;
  car_model?: string;
  order_status?: string;
  usage_status?: string;
  date_from?: string;
  date_to?: string;
  ordering?: string;
  reservation_number?: string;
  vin_number?: string;
}

export function getDefaultParam(params: defaultQueryModel) {
  return {
    reservation_number: params.reservation_number,
    limit: params.limit,
    offset: params.offset,
    car_model: params.car_model,
    order_status: params.order_status,
    usage_status: params.usage_status,
    factory: params.factory,
    dealer: params.dealer,
    date_from: params.date_from,
    date_to: params.date_to,
    ordering: params.ordering,
    vin_number: params.vin_number,
  };
}

export interface OTDDetailsQueryModel {
  limit?: number;
  offset?: number;
  dealers?: string;
  factories?: string;
  car_models?: string;
  order_status?: string;
  usage_status?: string;
  date_from?: string;
  date_to?: string;
  ordering?: string;
  reservation_number?: string;
  vin_number?: string;
  exclude?: string;
  grand_total?: string;
}

export function getOTDDetailsDefaultParam(params: OTDDetailsQueryModel) {
  return {
    reservation_number: params.reservation_number,
    limit: params.limit,
    offset: params.offset,
    car_models: params.car_models,
    order_status: params.order_status,
    usage_status: params.usage_status,
    factories: params.factories,
    dealers: params.dealers,
    date_from: params.date_from,
    date_to: params.date_to,
    ordering: params.ordering,
    vin_number: params.vin_number,
    exclude: params.exclude,
    grand_total: params.grand_total,
  };
}

export interface AllocationDetailsQueryModel {
  limit?: number;
  offset?: number;
  dealers?: string;
  factories?: string;
  car_models?: string;
  order_status?: string;
  usage_status?: string;
  date_from?: string;
  date_to?: string;
  ordering?: string;
  reservation_number?: string;
  vin_number?: string;
  exclude?: string;
  grand_total?: string;
  round?: string;
}

export function getAllocationDetailsDefaultParam(
  params: AllocationDetailsQueryModel,
) {
  return {
    reservation_number: params.reservation_number,
    limit: params.limit,
    offset: params.offset,
    car_models: params.car_models,
    order_status: params.order_status,
    usage_status: params.usage_status,
    factories: params.factories,
    dealers: params.dealers,
    date_from: params.date_from,
    date_to: params.date_to,
    ordering: params.ordering,
    vin_number: params.vin_number,
    exclude: params.exclude,
    grand_total: params.grand_total,
    round: params.round,
  };
}

export interface LicenseReturnDetailsQueryModel {
  limit?: number;
  offset?: number;
  dealers?: string;
  factories?: string;
  car_models?: string;
  date_from?: string;
  date_to?: string;
  ordering?: string;
  reservation_number?: string;
  vin_number?: string;
  grand_total?: string;
}

export function getLicenseReturnDetailsDefaultParam(
  params: LicenseReturnDetailsQueryModel,
) {
  return {
    reservation_number: params.reservation_number,
    limit: params.limit,
    offset: params.offset,
    car_models: params.car_models,
    factories: params.factories,
    dealers: params.dealers,
    date_from: params.date_from,
    date_to: params.date_to,
    ordering: params.ordering,
    vin_number: params.vin_number,
    grand_total: params.grand_total,
  };
}

export interface ReservationDetailsQueryModel {
  limit?: number;
  offset?: number;
  dealers?: string;
  factories?: string;
  car_models?: string;
  date_from?: string;
  date_to?: string;
  ordering?: string;
  usage_status?: string;
  reservation_number?: string;
  exclude?: string;
  grand_total?: string;
}

export function getReservationDetailsDefaultParam(
  params: ReservationDetailsQueryModel,
) {
  return {
    reservation_number: params.reservation_number,
    limit: params.limit,
    offset: params.offset,
    car_models: params.car_models,
    factories: params.factories,
    dealers: params.dealers,
    date_from: params.date_from,
    date_to: params.date_to,
    ordering: params.ordering,
    usage_status: params.usage_status,
    exclude: params.exclude,
    grand_total: params.grand_total,
  };
}

export interface StockDetailsQueryModel {
  limit?: number;
  offset?: number;
  dealers?: string;
  factories?: string;
  car_models?: string;
  date_from?: string;
  date_to?: string;
  ordering?: string;
  age_from?: string;
  age_to?: string;
  vin_number?: string;
  exclude?: string;
  grand_total?: string;
}

export function getStockDetailsDefaultParam(params: StockDetailsQueryModel) {
  return {
    vin_number: params.vin_number,
    limit: params.limit,
    offset: params.offset,
    car_models: params.car_models,
    factories: params.factories,
    dealers: params.dealers,
    date_from: params.date_from,
    date_to: params.date_to,
    ordering: params.ordering,
    age_from: params.age_from,
    age_to: params.age_to,
    exclude: params.exclude,
    grand_total: params.grand_total,
  };
}

export interface RegistrationDetailsQueryModel {
  limit?: number;
  offset?: number;
  dealers?: string;
  factories?: string;
  car_models?: string;
  date_from?: string;
  date_to?: string;
  ordering?: string;
  age_from?: string;
  age_to?: string;
  vin_number?: string;
  exclude?: string;
  grand_total?: string;
  reservation_number?: string;
  sale_type?: string;
  or_status?: string;
}

export function getRegistrationDetailsDefaultParam(
  params: RegistrationDetailsQueryModel,
) {
  return {
    vin_number: params.vin_number,
    reservation_number: params.reservation_number,
    limit: params.limit,
    offset: params.offset,
    car_models: params.car_models,
    factories: params.factories,
    dealers: params.dealers,
    date_from: params.date_from,
    date_to: params.date_to,
    ordering: params.ordering,
    age_from: params.age_from,
    age_to: params.age_to,
    sale_type: params.sale_type,
    or_status: params.or_status,
    exclude: params.exclude,
    grand_total: params.grand_total,
  };
}

export interface RFTDetailsQueryModel {
  limit?: number;
  offset?: number;
  dealers?: string;
  factories?: string;
  car_models?: string;
  ordering?: string;
  day_start?: string;
  day_end?: string;
  vin_number?: string;
  exclude?: string;
  grand_total?: string;
  reservation_number?: string;
}

export function getRFTDetailsDefaultParam(params: RFTDetailsQueryModel) {
  return {
    vin_number: params.vin_number,
    reservation_number: params.reservation_number,
    limit: params.limit,
    offset: params.offset,
    car_models: params.car_models,
    factories: params.factories,
    dealers: params.dealers,
    ordering: params.ordering,
    day_start: params.day_start,
    day_end: params.day_end,
    exclude: params.exclude,
    grand_total: params.grand_total,
  };
}

export interface DemoShowRoomData {
  dealer_name: string;
  dealer_id: string;
  factory: string;
  model_type: string;
  demo_goal: number;
  demo_actual: number;
  demo_diff: number;
  showroom_goal: number;
  showroom_actual: number;
  showroom_diff: number;
}

export interface performanceOverviewQueryModel {
  dealers?: string;
  dealer_zones?: string;
  date_from?: string;
  date_to?: string;
  doelstelling?: string;
}

export function getPerformanceOverviewParam(
  params: performanceOverviewQueryModel,
) {
  return {
    dealers: params.dealers,
    dealer_zones: params.dealer_zones,
    date_from: params.date_from,
    date_to: params.date_to,
    doelstelling: params.doelstelling,
  };
}

export interface PerformanceOverviewExportQueryModel {
  dealers: string;
  dealer_zones: string;
  date_from: string;
  date_to: string;
  doelstelling: string;
}

export function getPerformanceOverviewExportParam(
  params: PerformanceOverviewExportQueryModel,
) {
  return {
    dealers: params.dealers,
    dealer_zones: params.dealer_zones,
    date_from: params.date_from,
    date_to: params.date_to,
    doelstelling: params.doelstelling,
  };
}

export interface DemoShowRoomDetailsQueryModel {
  dealers: string;
  car_models: string;
  factories: string;
  sales_order: string;
  vin_code: string;
  soort: string;
  grand_total: string;
  page_total: string;
  limit?: number;
  offset?: number;
  ordering?: string;
}

export function getShowRoomDetailsDefaultParam(
  params: DemoShowRoomDetailsQueryModel,
) {
  return {
    dealers: params.dealers,
    car_models: params.car_models,
    factories: params.factories,
    sales_order: params.sales_order,
    vin_code: params.vin_code,
    soort: params.soort,
    grand_total: params.grand_total,
    page_total: params.page_total,
    limit: params.limit,
    offset: params.offset,
    ordering: params.ordering,
  };
}

export interface DemoShowRoomExportQueryModel {
  dealer: string;
  car_model: string;
  factory: string;
  sales_order: string;
  vin_number: string;
}

export function getDemoShowRoomExportParam(
  params: DemoShowRoomExportQueryModel,
) {
  return {
    dealer: params.dealer,
    car_model: params.car_model,
    factory: params.factory,
    sales_order: params.sales_order,
    vin_code: params.vin_number,
  };
}

export interface AllocationOverviewQueryModel {
  dealers: string;
  dealer_zones: string;
  round: string;
  date_from: string;
  date_to: string;
  doelstelling?: string;
  ordering?: string;
}

export interface AllocationOverviewDetailsQueryModel {
  dealers: string;
  dealer_zones: string;
  round: string;
  date_from: string;
  date_to: string;
  doelstelling?: string;
  factories?: string;
  car_models?: string;
  grand_total?: string;
  limit?: number;
  offset?: number;
  ordering?: string;
}

export function getAllocationOverviewDefaultParam(
  params: AllocationOverviewQueryModel,
) {
  return {
    dealers: params.dealers,
    dealer_zones: params.dealer_zones,
    round: params.round,
    date_from: params.date_from,
    date_to: params.date_to,
    doelstelling: params.doelstelling,
    ordering: params.ordering,
  };
}

export function getAllocationOverviewDetailsDefaultParam(
  params: AllocationOverviewDetailsQueryModel,
) {
  return {
    dealers: params.dealers,
    factories: params.factories,
    car_models: params.car_models,
    dealer_zones: params.dealer_zones,
    round: params.round,
    date_from: params.date_from,
    date_to: params.date_to,
    grand_total: params.grand_total,
    ordering: params.ordering,
    limit: params.limit,
    offset: params.offset,
  };
}

export interface DemoShowroomOverviewQueryModel {
  soort: string;
  dealer_zones: string;
  dealers: string;
}

export function getDemoShowroomOverviewDefaultQueryParam(
  params: DemoShowroomOverviewQueryModel,
) {
  return {
    soort: params.soort,
    dealer_zones: params.dealer_zones,
    dealers: params.dealers,
  };
}

export interface DetailedAllocationOverview {
  dealer_name: string;
  sales_document: string;
  vin: string;
  vehicle_usage: string;
  brand_code: string;
  model_description: string;
  round: number;
}

export interface DetailedAllocationOverviewData {
  count: number;
  next: string;
  previous: string;
  results: Array<DetailedAllocationOverview>;
}

export interface PerformanceOverviewDetailsQueryModel {
  dealers: string;
  dealer_zones: string;
  date_from: string;
  date_to: string;
  limit?: number;
  offset?: number;
  ordering?: string;
}

export function getPerformanceOverviewDetailsDefaultParam(
  params: PerformanceOverviewDetailsQueryModel,
) {
  return {
    dealers: params.dealers,
    dealer_zones: params.dealer_zones,
    date_from: params.date_from,
    date_to: params.date_to,
    ordering: params.ordering,
    limit: params.limit,
    offset: params.offset,
  };
}

export interface DetailedRetailPerformanceOverview {
  dealer_name: string;
  sales_order: string;
  vin_code: string;
  vehicle_usage: string;
  model_description: string;
  description_of_vehicle_location: number;
}

export interface DetailedRetailPerformanceOverviewData {
  count: number;
  next: string;
  previous: string;
  results: Array<DetailedRetailPerformanceOverview>;
}

export interface DetailedLicenseRequestsPerformanceOverview {
  dealer_name: string;
  vin_code: string;
  vehicle_usage: string;
  model_description: string;
  sales_order: string;
  dealer_license_reque: string;
}

export interface DetailedLicenseRequestsPerformanceOverviewData {
  count: number;
  next: string;
  previous: string;
  results: Array<DetailedLicenseRequestsPerformanceOverview>;
}

export interface DetailedClaimsData {
  count: number;
  next: string;
  previous: string;
  results: Array<any>;
}

export interface ClaimsDetailsQueryModel {
  dealers: string;
  car_models?: string;
  category: string;
  grand_total?: string;
  page_total?: string;
  limit?: number;
  offset?: number;
  ordering?: string;
}

export function getClaimsDefaultQueryParam(params: ClaimsDetailsQueryModel) {
  return {
    dealers: params.dealers,
    car_models: params.car_models,
    category: params.category,
    grand_total: params.grand_total,
    page_total: params.page_total,
    limit: params.limit,
    offset: params.offset,
    ordering: params.ordering,
  };
}

export interface ClaimsDetailedOverview {
  vin_code: string;
  sales_order: string;
  model_description: string;
  dealer_code: string;
  category: string;
  incentive_plan_description: string;
  incentive_plan_number: string;
  text: string;
  document_number: string;
  net_amount: string;
}

export interface CheckCarResponse {
  order_number: string;
  vin: string;
  available: boolean;
  message: string;
}

export interface DemoShowRoomRequestsData {
  id: number;
  order_number: string;
  vin_code: string;
  dealer_name: string;
  created_at: string;
  created_by: string;
  car_model: string;
  processed_at: string;
  model_type: string;
  soort: string;
  status: string;
  vehicle_usage: string;
}

export interface DemoShowRoomRequestSoortList {
  soort: string;
}

export interface DemoShowRoomRequestStatusList {
  status: string;
}

export interface demoShowRoomRequestsQueryModel {
  limit?: number;
  offset?: number;
  dealer?: string;
  car_model?: string;
  status?: string;
  soort?: string;
  usage_status?: string;
  ordering?: string;
  reservation_number?: string;
  vin_number?: string;
}

export function getDemoShowRoomRequestsParam(
  params: demoShowRoomRequestsQueryModel,
) {
  return {
    reservation_number: params.reservation_number,
    vin_number: params.vin_number,
    limit: params.limit,
    offset: params.offset,
    car_model: params.car_model,
    status: params.status,
    soort: params.soort,
    usage_status: params.usage_status,

    dealer: params.dealer,
    ordering: params.ordering,
  };
}

export interface ClaimCodesOverviewQueryModel {
  claim_code: string;
  sales_opportunity: string;
  client_corporate_name: string;
  dealer?: string;
  limit?: number;
  offset?: number;
  ordering?: string;
}

export function getClaimCodesOverviewRequestsParam(
  params: ClaimCodesOverviewQueryModel,
) {
  return {
    dealer: params.dealer,
    claim_code: params.claim_code,
    sales_opportunity: params.sales_opportunity,
    client_corporate_name: params.client_corporate_name,
    limit: params.limit,
    offset: params.offset,
    ordering: params.ordering,
  };
}

export interface DemoShowRoomRequests {
  count: number;
  next: string;
  previous: string;
  results: Array<DemoShowRoomRequestsData>;
}

export interface SalesOpportunity {
  sales_opportunity: string;
}

export interface ClientCorporateName {
  client_corporate_name: string;
}

export interface FreeStockProductionDataItem {
  brand_type_description: string;
  colorData: {
    [key: string]: string;
  };
}

export interface FreeStockColor {
  [key: string]: string[];
}

export interface FreeStockProductionData {
  colors: FreeStockColor;
  data: Array<FreeStockProductionDataItem>;
}
