import {
  BaseQueryFn,
  createApi,
  FetchArgs,
} from "@reduxjs/toolkit/dist/query/react";
import { CarBrand } from "models/CarBrand";
import { CustomError } from "models/CustomError";
import { Factory } from "models/Factory";
import { baseQueryWithReauth } from "./baseQueryWithReauth";
import { CarModelType } from "models/CarModelType";

export const carModelAPI = createApi({
  reducerPath: "carModelAPI",
  baseQuery: baseQueryWithReauth as BaseQueryFn<
    string | FetchArgs,
    unknown,
    CustomError,
    {}
  >,
  tagTypes: ["CarModel"],
  endpoints: (build) => ({
    getCarModelBrandList: build.query<Array<CarBrand>, void>({
      query: () => ({
        url: "car_model/brand_code_list/",
      }),
    }),
    getCarModelTypeList: build.query<Array<CarModelType>, void>({
      query: () => ({
        url: "car_model/model_type_list/",
      }),
    }),
    getCarModelFactoryList: build.query<Array<Factory>, void>({
      query: () => ({
        url: "car_model/factory_list/",
      }),
    }),
  }),
});
