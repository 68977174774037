import {
  BackorderOverviewPage,
  BackOrderAllocatieDetailsPage,
  BackorderDetailsPage,
  BackorderPage,
  ChangePasswordPage,
  DealerPerfomancePage,
  FutureSalesPage,
  LicenseReturnPage,
  LicenseReturnDetailsPage,
  LoginPage,
  MainPage,
  RegistrationPage,
  ReservationPage,
  ReservationDetailsPage,
  StockPage,
  StockDetailsPage,
  UserInfoPage,
  WelcomePage,
  ForgotPasswordPage,
  SetNewPasswordPage,
  RegistrationDetailsPage,
  RFTPage,
  RFTDetailsPage,
  DemoShowRoomPage,
  PerformanceOverviewPage,
  DemoShowRoomDetailsPage,
  AllocationOverviewPage,
  AllocationOverviewDetailsPage,
  ClaimsPage,
  PerformanceOverviewRetailDetailsPage,
  PerformanceOverviewLicenseRequestsDetailsPage,
  DemoAndShowroomOverviewPage,
  ClaimsDetailsPage,
  DemoShowRoomRequestsPage,
  ClaimCodesOverviewPage,
  ClaimCodesOverviewDetailsPage,
  FreeStockPage,
} from "pages";
import { Route, Routes } from "react-router-dom";
import * as links from "./paths";
import PrivateRoute from "./PrivateRoute";
import {
  ADMIN_ROLE,
  DEALER_ADMIN_ROLE,
  DEALER_SALES_ROLE,
  ACCOUNT_MANAGER_ROLE,
} from "shared/constants";

const ROLES_DOWN_TO_ADMIN = [ADMIN_ROLE];
const ROLES_DOWN_TO_ACCOUNT_MANAGER = [
  ...ROLES_DOWN_TO_ADMIN,
  ACCOUNT_MANAGER_ROLE,
];
const ROLES_DOWN_TO_DEALER_ADMIN = [
  ...ROLES_DOWN_TO_ACCOUNT_MANAGER,
  DEALER_ADMIN_ROLE,
];
const ROLES_DOWN_TO_DEALER_SALES = [
  ...ROLES_DOWN_TO_DEALER_ADMIN,
  DEALER_SALES_ROLE,
];

function Routers() {
  return (
    <Routes>
      <Route path={links.login_page_link} element={<LoginPage />} />
      <Route
        path={links.forgot_password_page_link}
        element={<ForgotPasswordPage />}
      />
      <Route
        path={links.set_new_password_page_link}
        element={<SetNewPasswordPage />}
      />
      <Route
        path={links.main_page_link}
        element={
          <PrivateRoute>
            <MainPage />
          </PrivateRoute>
        }
      >
        <Route
          index
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <DealerPerfomancePage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.welcome_page_link}
          element={
            <PrivateRoute>
              <WelcomePage />
            </PrivateRoute>
          }
        />

        <Route
          path={links.dealer_performance_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <DealerPerfomancePage />
            </PrivateRoute>
          }
        />

        {/*Overview section*/}
        <Route
          path={links.allocation_overview_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_ACCOUNT_MANAGER}>
              <AllocationOverviewPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.allocation_overview_details_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_ACCOUNT_MANAGER}>
              <AllocationOverviewDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.performance_overview_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_ACCOUNT_MANAGER}>
              <PerformanceOverviewPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.performance_overview_retail_details_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_ACCOUNT_MANAGER}>
              <PerformanceOverviewRetailDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.performance_overview_license_requests_details_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_ACCOUNT_MANAGER}>
              <PerformanceOverviewLicenseRequestsDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.demo_and_showroom_overview_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_ACCOUNT_MANAGER}>
              <DemoAndShowroomOverviewPage />
            </PrivateRoute>
          }
        />

        {/*Performance section*/}
        <Route
          path={links.reservation_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_SALES}>
              <ReservationPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.reservation_details_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_SALES}>
              <ReservationDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.license_return_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <LicenseReturnPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.license_return_details_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <LicenseReturnDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.stock_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <StockPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.stock_details_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <StockDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.free_stock_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_SALES}>
              <FreeStockPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.registration_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <RegistrationPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.registration_details_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <RegistrationDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.demo_showroom_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <DemoShowRoomPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.demo_showroom_details_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <DemoShowRoomDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.demo_showroom_requests_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_SALES}>
              <DemoShowRoomRequestsPage />
            </PrivateRoute>
          }
        />

        {/*Order to delivery section*/}
        <Route
          path={links.backorder_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <BackorderPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.backorder_allocatie_details_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <BackOrderAllocatieDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.backorder_overview_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_SALES}>
              <BackorderOverviewPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.backorder_details_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_SALES}>
              <BackorderDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.rft_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_SALES}>
              <RFTPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.rft_details_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_SALES}>
              <RFTDetailsPage />
            </PrivateRoute>
          }
        />

        {/*Future sales section*/}
        <Route
          path={links.future_sales_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_SALES}>
              <FutureSalesPage />
            </PrivateRoute>
          }
        />

        {/*Claims section*/}
        <Route
          path={links.claims_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <ClaimsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.claims_details_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <ClaimsDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.claim_codes_overview_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <ClaimCodesOverviewPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.claim_codes_overview_details_page_link}
          element={
            <PrivateRoute roles={ROLES_DOWN_TO_DEALER_ADMIN}>
              <ClaimCodesOverviewDetailsPage />
            </PrivateRoute>
          }
        />

        {/*User section*/}
        <Route
          path={links.user_info_page_link}
          element={
            <PrivateRoute>
              <UserInfoPage />
            </PrivateRoute>
          }
        />
        <Route
          path={links.change_password_page_link}
          element={
            <PrivateRoute>
              <ChangePasswordPage />
            </PrivateRoute>
          }
        />
      </Route>
    </Routes>
  );
}

export default Routers;
