import {
  BaseQueryFn,
  createApi,
  FetchArgs,
} from "@reduxjs/toolkit/dist/query/react";
import { CustomError } from "models/CustomError";
import { DealerPerformanceByMonth, StockDetails } from "models/Dealer";
import {
  LicenseReturnData,
  DetailedLicenseReturnData,
} from "models/LicenseReturnData";
import {
  RegistrationData,
  DetailedRegistrationData,
} from "models/RegistrationData";
import {
  ReservationsData,
  DetailedReservationsData,
} from "models/ReservationsData";
import { StockData, DetailedStockData } from "models/Stock";
import {
  defaultQueryModel,
  getDefaultParam,
  LicenseReturnDetailsQueryModel,
  getLicenseReturnDetailsDefaultParam,
  ReservationDetailsQueryModel,
  getReservationDetailsDefaultParam,
  StockDetailsQueryModel,
  getStockDetailsDefaultParam,
  RegistrationDetailsQueryModel,
  getRegistrationDetailsDefaultParam,
  performanceOverviewQueryModel,
  getPerformanceOverviewParam,
  DemoShowRoomDetailsQueryModel,
  getShowRoomDetailsDefaultParam,
  getDemoShowRoomExportParam,
  getAllocationOverviewDefaultParam,
  AllocationOverviewQueryModel,
  AllocationOverviewDetailsQueryModel,
  DetailedAllocationOverviewData,
  getAllocationOverviewDetailsDefaultParam,
  PerformanceOverviewDetailsQueryModel,
  getPerformanceOverviewDetailsDefaultParam,
  DetailedRetailPerformanceOverviewData,
  DetailedLicenseRequestsPerformanceOverviewData,
  getDemoShowroomOverviewDefaultQueryParam,
  getPerformanceOverviewExportParam,
  DetailedClaimsData,
  ClaimsDetailsQueryModel,
  getClaimsDefaultQueryParam,
  CheckCarResponse,
  getDemoShowRoomRequestsParam,
  getClaimCodesOverviewRequestsParam,
  DemoShowRoomRequests,
  FreeStockProductionData,
} from "models/Tables";
import { baseQueryWithReauth } from "./baseQueryWithReauth";

export const dealerPerformanceAPI = createApi({
  reducerPath: "dealerPerformanceAPI",
  baseQuery: baseQueryWithReauth as BaseQueryFn<
    string | FetchArgs,
    unknown,
    CustomError,
    {}
  >,
  tagTypes: ["DealerPerformance"],
  endpoints: (build) => ({
    getDealerPerformanceByMounth: build.query<
      Array<DealerPerformanceByMonth>,
      {
        dealer_id?: string;
        limit?: number;
        offset?: number;
        date_from?: string;
        date_to?: string;
      }
    >({
      query: ({ dealer_id, limit, offset, date_from, date_to }) => ({
        url: "dealer_performance/dashboard/sales_performance_by_month",
        params: {
          dealer_id: dealer_id,
          limit: limit,
          offset: offset,
          date_from: date_from,
          date_to: date_to,
        },
      }),
    }),
    getDealerOpenRegistrationGraph: build.query<
      any,
      {
        dealer_id?: string;
        limit?: number;
        offset?: number;
        date_from?: string;
        date_to?: string;
      }
    >({
      query: ({ dealer_id, limit, offset, date_from, date_to }) => ({
        url: "dealer_performance/dashboard/open_registration_graph/",
        params: {
          dealer_id: dealer_id,
          limit: limit,
          offset: offset,
          date_from: date_from,
          date_to: date_to,
        },
      }),
    }),
    getDealerRegistrationGraph: build.query<
      any,
      {
        dealer_id?: string;
        limit?: number;
        offset?: number;
        date_from?: string;
        date_to?: string;
      }
    >({
      query: ({ dealer_id, limit, offset, date_from, date_to }) => ({
        url: "dealer_performance/dashboard/registration_graph/",
        params: {
          dealer_id: dealer_id,
          limit: limit,
          offset: offset,
          date_from: date_from,
          date_to: date_to,
        },
      }),
    }),
    getDealerReservationGraph: build.query<
      any,
      {
        dealer_id?: string;
        limit?: number;
        offset?: number;
        date_from?: string;
        date_to?: string;
      }
    >({
      query: ({ dealer_id, limit, offset, date_from, date_to }) => ({
        url: "dealer_performance/dashboard/reservation_graph/",
        params: {
          dealer_id: dealer_id,
          limit: limit,
          offset: offset,
          date_from: date_from,
          date_to: date_to,
        },
      }),
    }),
    getDealerPerformanceDetails: build.query<
      any,
      { dealer_id?: string; limit?: number; offset?: number }
    >({
      query: ({ dealer_id, limit, offset }) => ({
        url: "dealer_performance/dashboard/sales_performance_detail",
        params: {
          dealer_id: dealer_id,
          limit: limit,
          offset: offset,
        },
      }),
    }),
    getStockPerformanceDetails: build.query<
      StockDetails,
      { dealer_id?: string; limit?: number; offset?: number }
    >({
      query: ({ dealer_id, limit, offset }) => ({
        url: "dealer_performance/dashboard/stock_performance_detail/",
        params: {
          dealer_id: dealer_id,
          limit: limit,
          offset: offset,
        },
      }),
    }),
    getReservationsData: build.query<ReservationsData, defaultQueryModel>({
      query: ({ ...params }) => ({
        url: "dealer_performance/reservations/",
        params: {
          ...getDefaultParam(params),
        },
      }),
    }),
    getReservationExportTable: build.query<any, defaultQueryModel>({
      query: ({ ...params }) => ({
        url: "dealer_performance/reservations/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getDefaultParam(params) },
      }),
    }),
    getReservationExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/reservations/data_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getDefaultParam(params) },
      }),
    }),
    getDetailedReservationsData: build.query<
      DetailedReservationsData,
      ReservationDetailsQueryModel
    >({
      query: ({ ...params }) => ({
        url: "dealer_performance/reservations_details/",
        params: {
          ...getReservationDetailsDefaultParam(params),
        },
      }),
    }),
    getDetailedReservationsDataExportTable: build.query<
      any,
      ReservationDetailsQueryModel
    >({
      query: ({ ...params }) => ({
        url: "dealer_performance/reservations_details/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getReservationDetailsDefaultParam(params) },
      }),
    }),
    getRegistrationData: build.query<RegistrationData, defaultQueryModel>({
      query: ({ ...params }) => ({
        url: "dealer_performance/registrations/",
        params: {
          ...getDefaultParam(params),
        },
      }),
    }),
    getRegistrationExportTable: build.query<any, defaultQueryModel>({
      query: ({ ...params }) => ({
        url: "dealer_performance/registrations/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getDefaultParam(params) },
      }),
    }),
    getRegistrationExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/registrations/data_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getDefaultParam(params) },
      }),
    }),
    getDetailedRegistrationData: build.query<
      DetailedRegistrationData,
      RegistrationDetailsQueryModel
    >({
      query: ({ ...params }) => ({
        url: "dealer_performance/registrations_details/",
        params: {
          ...getRegistrationDetailsDefaultParam(params),
        },
      }),
    }),
    getDetailedRegistrationExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/registrations_details/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getRegistrationDetailsDefaultParam(params) },
      }),
    }),
    getStockData: build.query<StockData, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/stock_page/",
        params: {
          ...getDefaultParam(params),
        },
      }),
    }),
    getStockExportTable: build.query<any, defaultQueryModel>({
      query: ({ ...params }) => ({
        url: "dealer_performance/stock_page/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getDefaultParam(params) },
      }),
    }),
    getStockExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/stock_page/data_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getDefaultParam(params) },
      }),
    }),
    getDetailedStockData: build.query<
      DetailedStockData,
      StockDetailsQueryModel
    >({
      query: ({ ...params }) => ({
        url: "dealer_performance/stock_details_page/",
        params: {
          ...getStockDetailsDefaultParam(params),
        },
      }),
    }),
    getDetailedStockDataExportTable: build.query<any, StockDetailsQueryModel>({
      query: ({ ...params }) => ({
        url: "dealer_performance/stock_details_page/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getStockDetailsDefaultParam(params) },
      }),
    }),
    getLicenseReturn: build.query<LicenseReturnData, defaultQueryModel>({
      query: ({ ...params }) => ({
        url: "dealer_performance/license_return_page/",
        params: {
          ...getDefaultParam(params),
        },
      }),
    }),
    getDetailedLicenseReturn: build.query<
      DetailedLicenseReturnData,
      LicenseReturnDetailsQueryModel
    >({
      query: ({ ...params }) => ({
        url: "dealer_performance/license_return_page_details/",
        params: {
          ...getLicenseReturnDetailsDefaultParam(params),
        },
      }),
    }),
    getDetailedLicenseReturnExportTable: build.query<
      any,
      LicenseReturnDetailsQueryModel
    >({
      query: ({ ...params }) => ({
        url: "dealer_performance/license_return_page_details/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getLicenseReturnDetailsDefaultParam(params) },
      }),
    }),
    getLicenseReturnExportTable: build.query<any, defaultQueryModel>({
      query: ({ ...params }) => ({
        url: "dealer_performance/license_return_page/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getDefaultParam(params) },
      }),
    }),
    getLicenseReturnExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/license_return_page/data_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getDefaultParam(params) },
      }),
    }),
    getDemoShowRoom: build.query<any, defaultQueryModel>({
      query: ({ ...params }) => ({
        url: "dealer_performance/demo_showroom_page/",
        params: {
          ...getDefaultParam(params),
        },
      }),
    }),
    getDemoShowRoomExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/demo_showroom_page/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getDemoShowRoomExportParam(params) },
      }),
    }),
    getPerformanceOverviewData: build.query<any, performanceOverviewQueryModel>(
      {
        query: ({ ...params }) => ({
          url: "dealer_performance/performance_overview/",
          params: {
            ...getPerformanceOverviewParam(params),
          },
        }),
      },
    ),
    getPerformanceOverviewExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/performance_overview/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getPerformanceOverviewExportParam(params) },
      }),
    }),
    getDemoShowRoomDetails: build.query<any, DemoShowRoomDetailsQueryModel>({
      query: ({ ...params }) => ({
        url: "dealer_performance/demo_showroom_details_page/",
        params: {
          ...getShowRoomDetailsDefaultParam(params),
        },
      }),
    }),
    getDetailedDemoShowRoomExportData: build.query<
      any,
      DemoShowRoomDetailsQueryModel
    >({
      query: ({ ...params }) => ({
        url: "dealer_performance/demo_showroom_details_page/table_export",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getShowRoomDetailsDefaultParam(params) },
      }),
    }),
    getAllocationOverviewData: build.query<any, AllocationOverviewQueryModel>({
      query: ({ ...params }) => ({
        url: "dealer_performance/allocation_overview_page/",
        params: {
          ...getAllocationOverviewDefaultParam(params),
        },
      }),
    }),
    getClaimsData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/claims_page/",
        params: { ...getDefaultParam(params) },
      }),
    }),
    getAllocationOverviewExportData: build.query<
      any,
      AllocationOverviewQueryModel
    >({
      query: ({ ...params }) => ({
        url: "dealer_performance/allocation_overview_page/table_export",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getAllocationOverviewDefaultParam(params) },
      }),
    }),
    getDetailedAllocationOverviewData: build.query<
      DetailedAllocationOverviewData,
      AllocationOverviewDetailsQueryModel
    >({
      query: ({ ...params }) => ({
        url: "dealer_performance/allocation_overview_details_page/",
        params: {
          ...getAllocationOverviewDetailsDefaultParam(params),
        },
      }),
    }),
    getDetailedAllocationOverviewExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/allocation_overview_details_page/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getAllocationOverviewDetailsDefaultParam(params) },
      }),
    }),
    getDetailedRetailPerformanceOverviewData: build.query<
      DetailedRetailPerformanceOverviewData,
      PerformanceOverviewDetailsQueryModel
    >({
      query: ({ ...params }) => ({
        url: "dealer_performance/performance_overview_retail_details_page/",
        params: {
          ...getPerformanceOverviewDetailsDefaultParam(params),
        },
      }),
    }),
    getDetailedRetailPerformanceOverviewExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/performance_overview_retail_details_page/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getAllocationOverviewDetailsDefaultParam(params) },
      }),
    }),
    getDetailedLicenseRequestsPerformanceOverviewData: build.query<
      DetailedLicenseRequestsPerformanceOverviewData,
      PerformanceOverviewDetailsQueryModel
    >({
      query: ({ ...params }) => ({
        url: "dealer_performance/performance_overview_license_requests_details_page/",
        params: {
          ...getPerformanceOverviewDetailsDefaultParam(params),
        },
      }),
    }),
    getDetailedLicenseRequestsPerformanceOverviewExportData: build.query<
      any,
      any
    >({
      query: ({ ...params }) => ({
        url: "dealer_performance/performance_overview_license_requests_details_page/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getAllocationOverviewDetailsDefaultParam(params) },
      }),
    }),
    getDemoShowroomOverviewData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/demo_showroom_overview_page/",
        params: {
          ...getDemoShowroomOverviewDefaultQueryParam(params),
        },
      }),
    }),
    getClaimsExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/claims_page/table_export/",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getDefaultParam(params) },
      }),
    }),
    getDetailedClaimsData: build.query<
      DetailedClaimsData,
      ClaimsDetailsQueryModel
    >({
      query: ({ ...params }) => ({
        url: "dealer_performance/claims_details_page/",
        params: {
          ...getClaimsDefaultQueryParam(params),
        },
      }),
    }),
    getDetailedClaimsExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/claims_details_page/table_export",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getClaimsDefaultQueryParam(params) },
      }),
    }),
    postDemoShowRoomRequest: build.mutation<any, any>({
      query: (body) => ({
        method: "post",
        url: "dealer_performance/demo_showroom_page/post_request",
        body: body,
      }),
    }),
    checkCar: build.mutation<CheckCarResponse, any>({
      query: (params) => ({
        url: "dealer_performance/check_car",
        params: params,
      }),
    }),
    getDemoShowroomRequestsData: build.query<DemoShowRoomRequests, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/demo_showroom_requests_page/",
        params: {
          ...getDemoShowRoomRequestsParam(params),
        },
      }),
    }),
    getDemoShowRoomRequestsSoortList: build.query<Array<any>, void>({
      query: () => ({
        url: "dealer_performance/demo_showroom_requests_soort_list/",
      }),
    }),
    getDemoShowRoomRequestsStatusList: build.query<Array<any>, void>({
      query: () => ({
        url: "dealer_performance/demo_showroom_requests_status_list/",
      }),
    }),
    deleteDemoShowRoomRequest: build.mutation<any, any>({
      query: (params) => ({
        url: "dealer_performance/demo_showroom_requests_page/",
        method: "delete",
        params: params,
        body: params,
      }),
    }),
    getClaimCodesOverviewData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/claim_codes_overview_page/",
        params: { ...getClaimCodesOverviewRequestsParam(params) },
      }),
    }),
    getClaimCodeList: build.query<Array<any>, void>({
      query: () => ({
        url: "dealer_performance/claim_code_list/",
      }),
    }),
    getSalesOpportunityList: build.query<Array<any>, void>({
      query: () => ({
        url: "dealer_performance/sales_opportunity_list/",
      }),
    }),
    getClientCorporateNameList: build.query<Array<any>, void>({
      query: () => ({
        url: "dealer_performance/client_corporate_name_list/",
      }),
    }),
    getClaimCodesFilterGroups: build.query<
      { [grouping: string]: { [parent: string]: any[] } },
      void
    >({
      query: () => ({
        url: "dealer_performance/claim_codes_filter_groups/",
      }),
    }),
    getDetailedClaimCodesOverviewData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/claim_code_overview_details_page/",
        params: { ...getClaimCodesOverviewRequestsParam(params) },
      }),
    }),
    getDetailedClaimCodesOverviewExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/claim_code_overview_details_page/table_export",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getClaimCodesOverviewRequestsParam(params) },
      }),
    }),
    getClaimCodesOverviewExportData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/claim_codes_overview_page/table_export",
        responseHandler: (response) =>
          response.blob().then(
            (data) =>
              new Promise((resolve) => {
                const blob = new Blob([data], {
                  type: "data:application/vnd.ms-excel",
                });
                const objectURL = URL.createObjectURL(blob);
                resolve(objectURL);
              }),
          ),
        params: { ...getClaimCodesOverviewRequestsParam(params) },
      }),
    }),
    getFreeStockData: build.query<any, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/free_stock_page/",
        params: { ...getDefaultParam(params) },
      }),
    }),
    getFreeStockProductionData: build.query<FreeStockProductionData, any>({
      query: ({ ...params }) => ({
        url: "dealer_performance/free_stock_production/",
        params: { ...getDefaultParam(params) },
      }),
    }),
  }),
});
