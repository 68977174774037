import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { mainColor, performanceBlue } from "shared/colors";
import { OR_MORE_30_DAYS, TOTAL_OPEN_REGISTRATION } from "shared/constants";

interface chartProps {
  chartData: any;
}

function OpenRegistrationGraph({ chartData }: chartProps) {
  const renderLegendText = (value: string) => {
    return <span style={{ paddingRight: "100px" }}>{value}</span>;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        barGap={8}
        data={chartData}
        margin={{
          top: 20,
          right: -10,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3" vertical={false} />
        <XAxis dataKey="month" />
        <YAxis tickLine={false} axisLine={false} />
        <Tooltip />
        <Legend
          style={{ paddingRight: "100px" }}
          formatter={renderLegendText}
        />
        <Bar
          dataKey="totalOpenRegistrations"
          name={TOTAL_OPEN_REGISTRATION}
          fill={mainColor}
          barSize={40}
          style={{
            filter: `drop-shadow(2px 0px 2px ${mainColor})`,
          }}
        />
        <Bar
          dataKey="openRegistrationsTarget"
          name={OR_MORE_30_DAYS}
          fill={performanceBlue}
          barSize={40}
          style={{
            filter: `drop-shadow(2px 0px 2px ${performanceBlue})`,
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default OpenRegistrationGraph;
