import { Box, Tooltip } from "@mui/material";
import {
  BasicModal,
  CustomTable,
  DateBlock,
  ExportDataBlock,
  FiltersBlock,
  Loader,
  MainColorButton,
} from "components";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { orderAPI } from "api/services/Order";
import { Allocation } from "models/AllocationData";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import useModal from "hooks/useModal";
import { getPercentageValue } from "helper/getPercentageValue";
import { useSortData } from "hooks/useSortData";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import dayjs from "dayjs";
import { dateToOperationalFormat, getFilterValue } from "shared/utils";
import * as filterFieldsNames from "components/FiltersBlock/fieldsNames";
import * as buildUtils from "shared/validation/buildUtils";
import {
  defaultLazyFieldNames,
  defaultValidators,
} from "shared/validation/filterValidators";
import { useFormik } from "formik";
import { useGetLastValid } from "hooks/useGetLastValid";
import { dispatchFilterChangedEvent } from "shared/events";

function BackorderPage() {
  const defaultDateFrom = useMemo(() => dayjs().startOf("year").toDate(), []);
  const defaultDateTo = useMemo(() => new Date(), []);

  const filterSchema = useMemo(
    () =>
      buildUtils.buildObjectSchema(defaultValidators, defaultLazyFieldNames),
    [],
  );
  const formik = useFormik({
    initialValues: {
      [filterFieldsNames.DATE_FROM]: defaultDateFrom,
      [filterFieldsNames.DATE_TO]: defaultDateTo,
    },
    validate: buildUtils.buildValidateFunc(filterSchema),
    onSubmit: () => {},
  });

  const setDateFrom = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_FROM, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const setDateTo = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_TO, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const [dealers, setDealers] = useState<Array<OptionsProps>>([]);
  const [brands, setBrands] = useState<Array<OptionsProps>>([]);
  const [factories, setFactories] = useState<Array<OptionsProps>>([]);
  const [vinNumbers, setVinNumbers] = useState<Array<string>>([]);
  const [reservationNumbers, setReservationNumbers] = useState<Array<string>>(
    [],
  );
  const { open, openModal, closeModal } = useModal();
  const [skipExportTable, setSkipExportTable] = useState(true);
  const [skipExportData, setSkipExportData] = useState(true);
  const [expandedRounds, setExpandedRounds] = useState(false);
  const { sortData, ordering } = useSortData();

  const queryParams = useMemo(
    () => ({
      dealer: dealers.map((item: OptionsProps) => item.id).toString(),
      car_model: brands.map((item: OptionsProps) => item.id).toString(),
      factory: factories.map((item: OptionsProps) => item.id).toString(),
      date_from: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_FROM],
      ),
      date_to: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_TO],
      ),
      reservation_number: reservationNumbers.toString(),
      vin_number: vinNumbers.toString(),
    }),
    [brands, formik.values, dealers, factories, reservationNumbers, vinNumbers],
  );
  const validatedQueryParams = useGetLastValid(
    queryParams,
    formik.isValid,
    formik.isValidating,
  );
  useEffect(() => {
    dispatchFilterChangedEvent();
  }, [validatedQueryParams]);
  const { data: tableData, isFetching: tableDataIsFetching } =
    orderAPI.useGetAllocationDataQuery({
      ...(validatedQueryParams || queryParams),
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const { data: exportTableData, isFetching: exportTableDataIsFetching } =
    orderAPI.useGetAllocationExportTableQuery(
      { ...(validatedQueryParams || queryParams), ordering: ordering },
      { skip: skipExportTable || !tableData?.results.length },
    );
  const { data: exportAllData, isFetching: exportAllDataIsFetching } =
    orderAPI.useGetAllocationExportDataQuery(
      validatedQueryParams || queryParams,
      {
        skip: skipExportData,
      },
    );

  const allocationHeaders: Header[] = [
    { id: "dealer_name", label: constants.DEALER, sortable: true },
    { id: "factory", label: constants.FACTORY, sortable: true },
    { id: "brand_code", label: constants.MODEL, sortable: true },
    { id: "total", label: constants.TOTAL, sortable: true },
    { id: "target_total_diff", label: constants.VS_TARGET, sortable: true },
    {
      id: "all_rounds",
      label: constants.ALLOCATION,
      sortable: true,
      expanded: expandedRounds,
      expandable: true,
      setExpanded: setExpandedRounds,
      ...(expandedRounds && {
        subHeaders: [
          {
            label: constants.ROUND_1_NL,
            id: "round_1",
            sortable: true,
          },
          {
            label: constants.ROUND_2_NL,
            id: "round_2",
            sortable: true,
          },
          {
            label: constants.ROUND_3_NL,
            id: "round_3",
            sortable: true,
          },
          {
            label: constants.ROUND_4_NL,
            id: "round_4",
            sortable: true,
          },
        ],
      }),
    },
  ];

  const generateDetailsViewURL = (
    page_title: string | any,
    round: number | any,
    dealers: Array<string | any> = [],
    car_models: Array<string | any> = [],
    factories: Array<string | any> = [],
    exclude: object = {},
    grand_total: Boolean = false,
  ) => {
    let url =
      `/main/allocatie-details-page?` +
      `dealers=${dealers.join(",") ? dealers : ""}&` +
      `car_models=${car_models.join(",") ? car_models : ""}&` +
      `factories=${factories.join(",") ? factories : ""}&` +
      `round=${round}&` +
      `reservation_number=${
        reservationNumbers.join(",") ? reservationNumbers : ""
      }&` +
      `date_from=${dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_FROM],
      )}&` +
      `date_to=${dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_TO],
      )}&` +
      `page_title=${page_title}&` +
      `vin_number=${vinNumbers.join(",") ? vinNumbers : ""}`;
    const exclude_filters = JSON.stringify(exclude);
    if (exclude_filters !== "{}") {
      url += `&exclude=${exclude_filters}`;
    }
    if (grand_total) {
      url += `&grand_total=${grand_total}`;
    }
    return url;
  };

  const goalsTooltip = (goal: number) => (
    <Box sx={{ fontSize: "14px" }}>
      <Box sx={{ padding: "4px 10px" }}>
        {constants.TOTAL_TARGET}: {goal}
      </Box>
    </Box>
  );

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map((allocation: Allocation) => ({
        values: [
          <Tooltip title={goalsTooltip(allocation.target)} arrow>
            <div>{allocation.dealer_name}</div>
          </Tooltip>,
          allocation.factory,
          allocation.brand_code,
          {
            value: allocation.total,
            href: generateDetailsViewURL(
              "totaal",
              "",
              [allocation.dealerID],
              [allocation.brand_code],
              [allocation.factory],
            ),
          },
          getPercentageValue(allocation.target_total_diff),
          ...(expandedRounds
            ? [
                {
                  value: allocation.round_1,
                  href: generateDetailsViewURL(
                    constants.ROUND_1_NL,
                    1,
                    [allocation.dealerID],
                    [allocation.brand_code],
                    [allocation.factory],
                  ),
                },
                {
                  value: allocation.round_2,
                  href: generateDetailsViewURL(
                    constants.ROUND_2_NL,
                    2,
                    [allocation.dealerID],
                    [allocation.brand_code],
                    [allocation.factory],
                  ),
                },
                {
                  value: allocation.round_3,
                  href: generateDetailsViewURL(
                    constants.ROUND_3_NL,
                    3,
                    [allocation.dealerID],
                    [allocation.brand_code],
                    [allocation.factory],
                  ),
                },
                {
                  value: allocation.round_4,
                  href: generateDetailsViewURL(
                    constants.ROUND_4_NL,
                    4,
                    [allocation.dealerID],
                    [allocation.brand_code],
                    [allocation.factory],
                  ),
                },
              ]
            : [
                {
                  value: allocation.all_rounds,
                  href: generateDetailsViewURL(
                    "allocatie",
                    "",
                    [allocation.dealerID],
                    [allocation.brand_code],
                    [allocation.factory],
                  ),
                },
              ]),
        ],
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, expandedRounds]);

  function openExportsBlock() {
    setSkipExportTable(false);
    setSkipExportData(false);
    openModal();
  }

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={constants.ALLOCATIE} />
        <Box>
          <LastUpdateBlock fileType="order_allocation" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          justifyContent: "space-between",
        }}
      >
        <FiltersBlock
          hideCountryFilter
          setSelectedBrands={setBrands}
          setSelectedFactories={setFactories}
          setSelectedDealers={setDealers}
          setSelectedReservationNumbers={setReservationNumbers}
          setSelectedVinNumbers={setVinNumbers}
          defaultDateFrom={defaultDateFrom}
          defaultDateTo={defaultDateTo}
          setSelectedDateFrom={setDateFrom}
          setSelectedDateTo={setDateTo}
          selectedBrands={brands}
          selectedFactories={factories}
          selectedDealers={dealers}
          selectedReservationNumbers={reservationNumbers}
          selectedVinNumbers={vinNumbers}
          errors={formik.errors as { [field: string]: string[] }}
        />
        <MainColorButton
          onClick={() => openExportsBlock()}
          label={constants.EXPORT}
        />
        <BasicModal open={open} handleClose={() => closeModal()} centerPosition>
          <>
            <Loader
              isLoading={exportTableDataIsFetching || exportAllDataIsFetching}
            />
            <ExportDataBlock
              exportAllData={exportAllData}
              exportTableData={exportTableData}
              onClose={closeModal}
              exportTableName="Allocation_table"
              exportDataName="Allocation_data"
            />
          </>
        </BasicModal>
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={allocationHeaders}
        rows={tableRows}
        count={tableData?.count}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        customTotals={
          tableData
            ? [
                {
                  title: constants.GRAND_TOTAL,
                  totals: [
                    {
                      value: tableData.full_total.total_total,
                      href: generateDetailsViewURL(
                        "totaal",
                        "",
                        getFilterValue(dealers, "id"),
                        getFilterValue(brands),
                        getFilterValue(factories),
                        {},
                        true,
                      ),
                    } || 0,
                    tableData.full_total.total_target_total_diff
                      ? getPercentageValue(
                          tableData.full_total.total_target_total_diff,
                        )
                      : "0%",
                    ...(expandedRounds
                      ? [
                          {
                            value: tableData.full_total.total_round_1,
                            href: generateDetailsViewURL(
                              `totaal ${constants.ROUND_1_NL}`,
                              "1",
                              getFilterValue(dealers, "id"),
                              getFilterValue(brands),
                              getFilterValue(factories),
                              {},
                              true,
                            ),
                          } || 0,
                          {
                            value: tableData.full_total.total_round_2,
                            href: generateDetailsViewURL(
                              `totaal ${constants.ROUND_2_NL}`,
                              "2",
                              getFilterValue(dealers, "id"),
                              getFilterValue(brands),
                              getFilterValue(factories),
                              {},
                              true,
                            ),
                          } || 0,
                          {
                            value: tableData.full_total.total_round_3,
                            href: generateDetailsViewURL(
                              `totaal ${constants.ROUND_3_NL}`,
                              "3",
                              getFilterValue(dealers, "id"),
                              getFilterValue(brands),
                              getFilterValue(factories),
                              {},
                              true,
                            ),
                          } || 0,
                          {
                            value: tableData.full_total.total_round_4,
                            href: generateDetailsViewURL(
                              `totaal ${constants.ROUND_4_NL}`,
                              "4",
                              getFilterValue(dealers, "id"),
                              getFilterValue(brands),
                              getFilterValue(factories),
                              {},
                              true,
                            ),
                          } || 0,
                        ]
                      : [
                          {
                            value: tableData.full_total.total_all_rounds,
                            href: generateDetailsViewURL(
                              "totaal allocatie",
                              "",
                              getFilterValue(dealers, "id"),
                              getFilterValue(brands),
                              getFilterValue(factories),
                              {},
                              true,
                            ),
                          } || 0,
                        ]),
                  ],
                },
                {
                  title: constants.TO_DO,
                  totals: [
                    tableData.full_total.left_to_meet_target || 0,
                    tableData.full_total.left_to_meet_target_percentage
                      ? getPercentageValue(
                          tableData.full_total.left_to_meet_target_percentage,
                        )
                      : "0%",
                    ...(expandedRounds ? ["", "", "", ""] : [""]),
                  ],
                },
                {
                  title: constants.TOTAL_MONTHLY_GOAL,
                  totals: [
                    tableData.full_total.total_target || 0,
                    "",
                    ...(expandedRounds ? ["", "", "", ""] : [""]),
                  ],
                },
              ]
            : []
        }
        getSortedValue={(value: string) => sortData(value)}
        totalsColSpan={3}
        stickyColumnIndex={2}
        getItemsForPage={(items: number) => setSkip(items)}
      />
    </Box>
  );
}

export default BackorderPage;
