import { useMemo, useState } from "react";
import { useSortData } from "hooks/useSortData";
import useModal from "hooks/useModal";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import * as constants from "shared/constants";
import {
  BasicModal,
  CustomTable,
  DateBlock,
  ExportDataBlock,
  Loader,
  MainColorButton,
} from "components";
import { Header } from "components/tables/interfaces";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import { DemoShowRoomDetails } from "models/DemoShowRoom";

function DemoShowRoomDetailsPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const { open, openModal, closeModal } = useModal();
  const [skipExportTable, setSkipExportTable] = useState(true);
  const [skipExportData, setSkipExportData] = useState(true);
  const [searchParams] = useSearchParams();
  const { sortData, ordering } = useSortData();

  const queryParams = useMemo(
    () => ({
      dealers: searchParams.get("dealers") || "",
      car_models: searchParams.get("car_models") || "",
      soort: searchParams.get("soort") || "",
      factories: searchParams.get("factories") || "",
      sales_order: searchParams.get("sales_order") || "",
      vin_code: searchParams.get("vin_code") || "",
      grand_total: searchParams.get("grand_total") || "",
      page_total: searchParams.get("page_total") || "",
    }),
    [searchParams],
  );
  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetDemoShowRoomDetailsQuery({
      ...queryParams,
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const { data: exportTableData, isFetching: exportTableDataIsFetching } =
    dealerPerformanceAPI.useGetDetailedDemoShowRoomExportDataQuery(
      {
        ...queryParams,
        ordering: ordering,
      },
      { skip: skipExportTable || !tableData?.results.length },
    );
  const { data: exportAllData, isFetching: exportAllDataIsFetching } =
    dealerPerformanceAPI.useGetDetailedDemoShowRoomExportDataQuery(
      queryParams,
      { skip: skipExportData },
    );

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map(
        (demoShowRoomDetails: DemoShowRoomDetails) => ({
          values: [
            demoShowRoomDetails.vin_code,
            demoShowRoomDetails.brand_code,
            demoShowRoomDetails.exterior_colour,
            demoShowRoomDetails.interior_colour,
            demoShowRoomDetails.sales_order,
          ],
        }),
      );
    }
    return [];
  }, [tableData]);

  function openExportsBlock() {
    setSkipExportTable(false);
    setSkipExportData(true);
    openModal();
  }

  const DemoShowRoomDetailsHeaders: Header[] = [
    { id: "vin_code", label: constants.VIN, sortable: true },
    { id: "brand_code", label: constants.MODEL, sortable: true },
    { id: "exterior_colour", label: constants.EXTERIOR_COLOUR, sortable: true },
    { id: "interior_colour", label: constants.INTERIOR_COLOUR, sortable: true },
    { id: "sales_order", label: constants.SALES_DOCUMENT, sortable: true },
  ];

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={`${searchParams.get("page_title")}`} />
        <Box>
          <LastUpdateBlock fileType="demo_showroom" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <MainColorButton
            onClick={() => openExportsBlock()}
            label={constants.EXPORT}
          />
          <BasicModal
            open={open}
            handleClose={() => closeModal()}
            centerPosition
          >
            <>
              <Loader
                isLoading={exportTableDataIsFetching || exportAllDataIsFetching}
              />
              <ExportDataBlock
                exportAllData={exportAllData}
                exportTableData={exportTableData}
                onClose={closeModal}
                exportTableName="registration_table"
                exportDataName="registration_data"
                skipExportData={skipExportData}
              />
            </>
          </BasicModal>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      ></Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={DemoShowRoomDetailsHeaders}
        rows={tableRows}
        count={tableData?.count}
        getSortedValue={(value: string) => sortData(value)}
        getItemsForPage={(items: number) => setSkip(items)}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        totalsColSpan={5}
        stickyColumnIndex={2}
      />
    </Box>
  );
}

export default DemoShowRoomDetailsPage;
