import { mainColor, mainWhite } from "shared/colors";
import image from "shared/images/mainImage.jpg";

export const forgotPasswordPageStyle = {
  inputStyle: {
    marginBottom: 1,
    marginTop: 2,
    width: { xs: "316px", sm: "416px" },
  },
  buttonStyle: {
    width: { xs: "300px", sm: "416px" },
    marginBottom: 1,
  },
  backgroundStyle: {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    height: "100vh",
    color: mainWhite,
  },
  loginFormWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  logInLinkStyle: {
    margin: "0 auto",
  },
  titleStyle: {
    marginTop: 2,
    color: mainColor,
    fontWeight: 600,
    width: { xs: "316px", sm: "416px" },
  },
};
