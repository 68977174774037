import { useState } from "react";

export interface ModalHandles {
  open: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const useModal = (): ModalHandles => {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return { open, openModal, closeModal };
};

export default useModal;
