import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import { mainColor } from "shared/colors";
import { REGISTERED_CARS } from "shared/constants";

interface chartProps {
  chartData: any;
}

function RegistrationGraph({ chartData }: chartProps) {
  const renderLegendText = (value: string) => {
    return <span style={{ paddingRight: "100px" }}>{value}</span>;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={chartData}
        margin={{
          top: 20,
          right: -10,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3" vertical={false} />
        <XAxis dataKey="month" />
        <YAxis tickLine={false} axisLine={false} />
        <Tooltip />
        <Legend
          style={{ paddingRight: "100px" }}
          formatter={renderLegendText}
        />
        <Bar
          dataKey="monthRegistration"
          name={REGISTERED_CARS}
          stackId="a"
          fill={mainColor}
          barSize={80}
          style={{
            filter: `drop-shadow(3px 0px 3px ${mainColor})`,
          }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default RegistrationGraph;
