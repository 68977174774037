import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { CustomTable, DateBlock, FiltersBlock } from "components";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import { dateToFilenameFormat } from "shared/utils";
import { orderAPI } from "api/services/Order";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import { useSortData } from "hooks/useSortData";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import { RFT } from "models/AllocationData";
import { getFilterValue } from "shared/utils";
import DownloadXlsxButton from "components/DownloadXlsxButton/DownloadXlsxButton";
import { dispatchFilterChangedEvent } from "shared/events";

function BackorderOverviewPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const [dealers, setDealers] = useState<Array<OptionsProps>>([]);
  const [brands, setBrands] = useState<Array<OptionsProps>>([]);
  const [factories, setFactories] = useState<Array<OptionsProps>>([]);
  const [vinNumbers, setVinNumbers] = useState<Array<string>>([]);
  const [reservationNumbers, setReservationNumbers] = useState<Array<string>>(
    [],
  );
  const { sortData, ordering } = useSortData();

  const queryParams = useMemo(() => {
    dispatchFilterChangedEvent();
    return {
      dealer: dealers.map((item: OptionsProps) => item.id).toString(),
      car_model: brands.map((item: OptionsProps) => item.id).toString(),
      factory: factories.map((item: OptionsProps) => item.id).toString(),
      reservation_number: reservationNumbers.toString(),
      vin_number: vinNumbers.toString(),
    };
  }, [brands, dealers, factories, reservationNumbers, vinNumbers]);
  const { data: tableData, isFetching: tableDataIsFetching } =
    orderAPI.useGetRFTDataQuery({
      ...queryParams,
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const {
    data: exportAllData,
    isFetching: exportAllDataIsFetching,
    isError: exportAllDataIsError,
  } = orderAPI.useGetRFTExportDataQuery(queryParams);

  const RFTHeaders: Header[] = [
    { id: "dealer_name", label: constants.DEALER, sortable: true },
    { id: "factory", label: constants.FACTORY, sortable: true },
    { id: "brand_code", label: constants.MODEL, sortable: true },
    {
      id: "total_count",
      label: "Total Count",
      sortable: true,
    },
    {
      id: "expectedDelivery",
      label: constants.EXPECTED_DELIVERY_DAYS,
      subHeaders: [
        { label: constants.delivery_due, id: "delivery_due", sortable: true },
        { label: constants.days_1_2, id: "days_1_2", sortable: true },
        { label: constants.days_3_4, id: "days_3_4", sortable: true },
        { label: constants.days_5_6, id: "days_5_6", sortable: true },
        { label: constants.days_6_plus, id: "days_6_plus", sortable: true },
      ],
    },
  ];

  const generateDetailsViewURL = (
    page_title: string,
    day_start: string | null,
    day_end: string | null,
    dealers: Array<string | any> = [],
    car_models: Array<string | any> = [],
    factories: Array<string | any> = [],
    grand_total: Boolean = false,
  ) => {
    let url =
      `/main/rft-details-page?` +
      `dealers=${dealers.join(",") ? dealers : ""}&` +
      `car_models=${car_models.join(",") ? car_models : ""}&` +
      `factories=${factories.join(",") ? factories : ""}&` +
      `vin_number=${vinNumbers.join(",") ? vinNumbers : ""}&` +
      `reservation_number=${
        reservationNumbers.join(",") ? reservationNumbers : ""
      }&` +
      `page_title=${page_title}&`;
    if (day_start) {
      url += `&day_start=${day_start}`;
    }
    if (day_end) {
      url += `&day_end=${day_end}`;
    }
    if (grand_total) {
      url += `&grand_total=${grand_total}`;
    }
    return url;
  };

  const getDealers = () => {
    if (tableData) {
      return [tableData.results.map((rft: RFT) => rft.dealerID)];
    }
  };

  const getCarModels = () => {
    if (tableData) {
      return [tableData.results.map((rft: RFT) => rft.brand_code)];
    }
  };

  const getFactories = () => {
    if (tableData) {
      return [tableData.results.map((rft: RFT) => rft.factory)];
    }
  };

  const otdRFTData = useMemo(() => {
    if (tableData) {
      return tableData.results.map((rft: RFT) => ({
        values: [
          rft.dealer_name || 0,
          rft.factory || 0,
          rft.brand_code || 0,
          {
            value: rft.total_count,
            href: generateDetailsViewURL(
              `Totaal`,
              null,
              null,
              [rft.dealerID],
              [rft.brand_code],
              [rft.factory],
            ),
          } || 0,
          {
            value: rft.delivery_due,
            href: generateDetailsViewURL(
              constants.delivery_due,
              null,
              "0",
              [rft.dealerID],
              [rft.brand_code],
              [rft.factory],
            ),
          } || 0,
          {
            value: rft.days_1_2,
            href: generateDetailsViewURL(
              constants.days_1_2,
              "1",
              "2",
              [rft.dealerID],
              [rft.brand_code],
              [rft.factory],
            ),
          } || 0,
          {
            value: rft.days_3_4,
            href: generateDetailsViewURL(
              constants.days_3_4,
              "3",
              "4",
              [rft.dealerID],
              [rft.brand_code],
              [rft.factory],
            ),
          } || 0,
          {
            value: rft.days_5_6,
            href: generateDetailsViewURL(
              constants.days_5_6,
              "5",
              "6",
              [rft.dealerID],
              [rft.brand_code],
              [rft.factory],
            ),
          } || 0,
          {
            value: rft.days_6_plus,
            href: generateDetailsViewURL(
              constants.days_6_plus,
              "6",
              null,
              [rft.dealerID],
              [rft.brand_code],
              [rft.factory],
            ),
          } || 0,
        ],
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const date = dateToFilenameFormat(new Date());
  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title="RFT lijst" />
        <Box>
          <LastUpdateBlock fileType="rft" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <FiltersBlock
          hideCountryFilter
          setSelectedBrands={setBrands}
          setSelectedFactories={setFactories}
          setSelectedDealers={setDealers}
          setSelectedVinNumbers={setVinNumbers}
          setSelectedReservationNumbers={setReservationNumbers}
          selectedBrands={brands}
          selectedFactories={factories}
          selectedDealers={dealers}
          selectedReservationNumbers={reservationNumbers}
          selectedVinNumbers={vinNumbers}
        />
        <Box>
          <DownloadXlsxButton
            filename={`RFT_data_${date}.xlsx`}
            data={exportAllData}
            isFetching={exportAllDataIsFetching}
            isError={exportAllDataIsError}
          />
        </Box>
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={RFTHeaders}
        rows={otdRFTData}
        count={tableData?.count}
        getItemsForPage={(items: number) => setSkip(items)}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        getSortedValue={(value: string) => sortData(value)}
        pageTotals={
          tableData && [
            {
              value: tableData.page_total.total,
              href: generateDetailsViewURL(
                `Totaal`,
                null,
                null,
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.delivery_due,
              href: generateDetailsViewURL(
                constants.delivery_due,
                null,
                "0",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.days_1_2,
              href: generateDetailsViewURL(
                constants.days_1_2,
                "1",
                "2",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.days_3_4,
              href: generateDetailsViewURL(
                constants.days_3_4,
                "3",
                "4",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.days_5_6,
              href: generateDetailsViewURL(
                constants.days_5_6,
                "5",
                "6",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.days_6_plus,
              href: generateDetailsViewURL(
                constants.days_6_plus,
                "6",
                null,
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
          ]
        }
        grandTotals={
          tableData && [
            {
              value: tableData.full_total.total,
              href: generateDetailsViewURL(
                `Totaal`,
                null,
                null,
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.delivery_due,
              href: generateDetailsViewURL(
                `Totaal - ${constants.delivery_due}`,
                null,
                "0",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.days_1_2,
              href: generateDetailsViewURL(
                `Totaal - ${constants.days_1_2}`,
                "1",
                "2",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.days_3_4,
              href: generateDetailsViewURL(
                `Totaal - ${constants.days_3_4}`,
                "3",
                "4",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.days_5_6,
              href: generateDetailsViewURL(
                `Totaal - ${constants.days_5_6}`,
                "5",
                "6",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.days_6_plus,
              href: generateDetailsViewURL(
                `Totaal - ${constants.days_6_plus}`,
                "6",
                null,
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                true,
              ),
            } || 0,
          ]
        }
        totalsColSpan={3}
        stickyColumnIndex={2}
      />
    </Box>
  );
}

export default BackorderOverviewPage;
