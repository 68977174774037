import { mainColor, mainWhite } from "shared/colors";

export const exportDataBlockStyle = {
  blockWrapper: {
    backgroundColor: mainColor,
    width: { md: "500px", xs: "350px" },
    padding: "20px",
    borderRadius: 1,
  },
  closeIconWrapperStyle: {
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    marginBottom: "10px",
  },
  closeIconStyle: {
    color: mainWhite,
  },
  titleStyle: {
    color: mainWhite,
    fontWeight: 800,
    textAlign: "center",
    textTransform: "uppercase",
  },
  linkStyle: {
    textDecoration: "none",
  },
  buttonsWrapper: {
    display: "flex",
    marginTop: "60px",
    flexDirection: "column",
    alignItems: "center",
  },
};
