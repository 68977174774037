import {
  BaseQueryFn,
  createApi,
  FetchArgs,
} from "@reduxjs/toolkit/dist/query/react";
import { CustomError } from "models/CustomError";
import { ImportedFile } from "models/ImportedFile";
import { baseQueryWithReauth } from "./baseQueryWithReauth";

export const importerAPI = createApi({
  reducerPath: "importerAPI",
  baseQuery: baseQueryWithReauth as BaseQueryFn<
    string | FetchArgs,
    unknown,
    CustomError,
    {}
  >,
  tagTypes: ["Importer"],
  endpoints: (build) => ({
    getLastProcessed: build.query<ImportedFile, string>({
      query: (fileType) => ({
        url: `importer/last_processed/${fileType}`,
      }),
    }),
  }),
});
