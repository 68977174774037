import { progressiveBlue } from "shared/colors";

export const dropDownButtonStyle = {
  inputStyle: {
    "&.MuiSelect-select": {
      textTransform: "uppercase",
      textAlign: "center",
      padding: "10px 0 10px 20px ",
      fontWeight: 500,
      fontFamily: "HyundaiFontHead",
      paddingRight: "20px !important",
    },
  },
  wrapper: {
    margin: "8px",
  },
  menuStyle: {
    marginTop: "10px",
    paddingBottom: "20px",
    paddingTop: "20px",
  },
  menuItemStyle: {
    textTransform: "uppercase",
    paddingLeft: "30px",
    paddingTop: "25px",
    paddingBottom: "15px",
  },
  selectStyle: {
    "&::before": {
      borderBottom: `2px solid ${progressiveBlue}`,
      color: "yellow",
    },
    "&::after": {
      borderBottom: `2px solid ${progressiveBlue}`,
      color: "green",
    },
  },
};
