import {
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { customTextFieldStyle } from "./CustomTextField.style";

interface CustomTextFieldProps {
  select?: boolean;
  list?: Array<{ id: string; name: string }>;
  label?: string;
  disabled?: boolean;
  setSelectedValue?: (item: string) => void;
  customWidth?: string;
  customPaperWidth?: string;
}

function CustomTextField({
  list,
  select,
  label,
  disabled,
  setSelectedValue,
  customWidth,
  customPaperWidth,
}: CustomTextFieldProps) {
  const { textFieldStyle, iconStyle, inputStyle, menuStyle } =
    customTextFieldStyle;

  const handleChange = (event: SelectChangeEvent) => {
    if (setSelectedValue) {
      if (event.target.value === "all") {
        setSelectedValue("");
        return;
      }
      setSelectedValue(event.target.value);
    }
  };

  return (
    <TextField
      label={label}
      defaultValue={list && list[0].id}
      sx={{
        ...textFieldStyle,
        width: customWidth
          ? customWidth
          : {
              xs: "100%",
              sm: "calc(50% - 16px)",
              md: "230px",
            },
      }}
      select={select}
      disabled={disabled}
      onChange={(event: any) => handleChange(event)}
      InputProps={{
        sx: inputStyle,
        endAdornment: !select && (
          <InputAdornment position="end">
            <FilterListIcon sx={iconStyle} />
          </InputAdornment>
        ),
      }}
      SelectProps={{
        IconComponent: FilterListIcon,
        onChange: (event: any) => handleChange(event),
        MenuProps: {
          sx: menuStyle,
          PaperProps: {
            sx: {
              width: customPaperWidth
                ? customPaperWidth
                : { xs: "100%", sm: "calc(50% - 16px)", md: "230px" },
            },
          },
        },
      }}
    >
      {list &&
        select &&
        list.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
    </TextField>
  );
}

export default CustomTextField;
