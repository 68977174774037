import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useCallback, useState } from "react";
import { mainWhite } from "shared/colors";
import {
  ALL,
  GRAND_TOTAL,
  OTHER,
  PAGE_TOTAL,
  ROWS_PER_PAGE_ALL,
} from "shared/constants";
import { formatCellContent } from "shared/formatters";
import { freeStockTableStyle } from "./FreeStockTable.style";
import SortIcon from "@mui/icons-material/Sort";
import { Loader } from "components";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { SetStateAction } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { CustomTotal, Header, Row } from "../interfaces";
import { getColSpanForHeader } from "../utils";
import EmptyContentPlaceholder from "../EmptyContentPlaceholder/EmptyContentPlaceholder";
import { CarModel } from "pages/FreeStockPage/FreeStockPage";

export interface CarBrand {
  brand_code: string;
  factory: string;
}

interface FreeStockTableProps {
  headers: Array<Header>;
  rows: Array<Row>;
  count?: number;
  rowsPerPage: number;
  pageTotals?: Array<number | string | object>;
  grandTotals?: Array<number | string | object>;
  differenceTotals?: Array<number | string | object>;
  customTotals?: Array<CustomTotal>;
  totalsColSpan?: number;
  getItemsForPage?: (item: number) => void;
  getSortedValue?: (value: string) => void;
  isFetching: boolean;
  stickyColumnIndex?: number;
  changeRowsPerPage: (value: number) => void;
  cellFormatters?: (((cellContent: any) => string) | undefined)[];
  selectedBrands?: (i: SetStateAction<CarModel | undefined>) => void;
  brandList: Array<CarBrand>;
  selectedValue?: { label: string };
}

function FreeStockTable({
  headers,
  rows,
  count,
  rowsPerPage,
  pageTotals,
  grandTotals,
  differenceTotals,
  customTotals,
  totalsColSpan,
  getItemsForPage,
  getSortedValue,
  isFetching,
  stickyColumnIndex,
  changeRowsPerPage,
  cellFormatters,
  selectedBrands,
  brandList,
  selectedValue,
}: FreeStockTableProps) {
  const [page, setPage] = useState(0);
  const [rowPerPage, setRowsPerPage] = useState(rowsPerPage);

  const getSortedValueAndResetPage = useCallback(
    (value: string) => {
      if (getSortedValue) {
        getSortedValue(value);
      }
      if (getItemsForPage) {
        setPage(0);
        getItemsForPage(0);
      }
    },
    [getSortedValue, getItemsForPage],
  );

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
    if (getItemsForPage) {
      getItemsForPage(newPage * rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const parsedRowsPerPage = parseInt(event.target.value, 10);
    changeRowsPerPage(parsedRowsPerPage);
    setRowsPerPage(parsedRowsPerPage);
    setPage(0);
    if (getItemsForPage) {
      getItemsForPage(0);
    }
  };

  function handleChange(value: any) {
    if (selectedBrands) {
      selectedBrands(value);
    }
  }

  const {
    paginationStyle,
    tableStyle,
    mainTitleCell,
    subTitleStyle,
    defaultCellStyle,
    textStyle,
    miniSortIconStyle,
    sortIconStyle,
    sortBlockWrapper,
    titleStyle,
    textFieldStyle,
    yellowDotStyle,
    greenDotStyle,
    orangeDotStyle,
    blueDotStyle,
  } = freeStockTableStyle;

  const substitutions = [{ values: [0, "0%"], substitution: "-" }];
  const totalsRow = (title: string, totals: Array<number | string | any>) => (
    <TableRow>
      <TableCell
        colSpan={totalsColSpan}
        align="center"
        sx={{
          ...subTitleStyle,
          textTransform: "uppercase",
          fontSize: "12px",
        }}
      >
        {title}
      </TableCell>
      {totals.map((total: number | string | any, index: number) => (
        <TableCell
          key={index}
          align="center"
          sx={{
            ...subTitleStyle,
            padding: "4px 8px",
          }}
        >
          {total && total.href && total.value > 0 ? (
            <Link
              variant="regular"
              underline="hover"
              href={total.href}
              target="_blank"
            >
              {formatCellContent(
                total.value,
                substitutions,
                cellFormatters?.at(
                  cellFormatters.length - totals.length + index,
                ),
              )}
            </Link>
          ) : (
            <Typography variant="regular">
              {formatCellContent(
                typeof total === "object" ? total.value : total,
                substitutions,
                cellFormatters?.at(
                  cellFormatters.length - totals.length + index,
                ),
              )}
            </Typography>
          )}
        </TableCell>
      ))}
    </TableRow>
  );

  const noSubtitles = headers.every(
    (header) => header.subHeaders === undefined,
  );

  let additionalProps = {};
  if (selectedValue) {
    additionalProps = { value: selectedValue };
  }
  return (
    <Paper sx={{ marginTop: "32px", overflowX: "scroll" }}>
      <TableContainer>
        <Table stickyHeader sx={tableStyle}>
          <TableHead sx={{ position: "relative" }}>
            <TableRow>
              <TableCell
                sx={{
                  ...mainTitleCell,
                  borderRight: "none",
                  borderLeft: "none",
                  position: "sticky",
                  left: "0",
                  zIndex: 11,
                }}
                align="center"
              >
                {selectedBrands && brandList && (
                  <Autocomplete
                    readOnly={brandList.length === 0}
                    sx={{ minWidth: "200px" }}
                    options={brandList.map((option: CarBrand) => ({
                      label: option.brand_code,
                      id: option.brand_code,
                      factory: option.factory,
                    }))}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={"Brand"}
                        sx={textFieldStyle}
                      />
                    )}
                    defaultValue={
                      brandList.length
                        ? {
                            label: brandList[0].brand_code,
                            factory: brandList[0].factory,
                            id: brandList[0].brand_code,
                          }
                        : { label: "", factory: "", id: "" }
                    }
                    onChange={(event, value) => handleChange(value)}
                    popupIcon={
                      brandList.length ? (
                        <FilterListIcon sx={{ color: mainWhite }} />
                      ) : null
                    }
                    {...additionalProps}
                  />
                )}
              </TableCell>
              {headers.map((header: Header, index: number) => (
                <TableCell
                  key={header.id}
                  sx={{
                    ...mainTitleCell,
                    borderRight: `solid 2px ${mainWhite}`,
                    borderLeft: `solid 2px ${mainWhite}`,
                  }}
                  colSpan={getColSpanForHeader(header)}
                  align="center"
                >
                  {header.expandable && header.setExpanded && (
                    <Box
                      sx={{ lineHeight: "0.5rem" }}
                      onClick={() =>
                        header.setExpanded &&
                        header.setExpanded(!header.expanded)
                      }
                    >
                      {header.expanded ? (
                        <UnfoldLess sx={{ transform: "rotate(90deg)" }} />
                      ) : (
                        <UnfoldMore sx={{ transform: "rotate(90deg)" }} />
                      )}
                    </Box>
                  )}
                  {header.sortable && !header.expanded && getSortedValue ? (
                    <Box
                      sx={{
                        position:
                          header.subHeaders || noSubtitles
                            ? "static"
                            : "absolute",
                        cursor: "pointer",
                        ...sortBlockWrapper,
                        paddingTop: header.expandable ? "0px" : "8px",
                      }}
                      onClick={() => getSortedValueAndResetPage(header.id)}
                    >
                      <Typography variant="regular" sx={titleStyle}>
                        {header.label}
                        <SortIcon sx={sortIconStyle} />
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        position:
                          header.subHeaders || noSubtitles
                            ? "static"
                            : "absolute",
                        ...sortBlockWrapper,
                        paddingTop: header.expandable ? "0px" : "8px",
                      }}
                    >
                      <Typography variant="regular" sx={titleStyle}>
                        {header.label}
                      </Typography>
                    </Box>
                  )}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  ...defaultCellStyle,
                  position: "sticky",
                  left: "0",
                  zIndex: 10,
                }}
              />
              {headers.map((header, headerIndex: number) =>
                header.subHeaders ? (
                  header.subHeaders.map((subHeader: Header, index: number) => (
                    <TableCell
                      align="center"
                      sx={{
                        ...subTitleStyle,
                        borderRight: `solid 2px ${mainWhite}`,
                        borderLeft: `solid 2px ${mainWhite}`,
                        margin: "auto",
                      }}
                      key={index}
                    >
                      {subHeader.sortable && getSortedValue ? (
                        <Box
                          onClick={() =>
                            getSortedValueAndResetPage(subHeader.id)
                          }
                          sx={{ cursor: "pointer" }}
                        >
                          <Typography variant="regular" sx={titleStyle}>
                            {subHeader.label}
                            <SortIcon sx={miniSortIconStyle} />
                          </Typography>
                        </Box>
                      ) : (
                        <Typography variant="regular" padding="auto">
                          {subHeader.label}
                        </Typography>
                      )}
                    </TableCell>
                  ))
                ) : (
                  <TableCell
                    sx={{
                      ...defaultCellStyle,
                      ...(headerIndex === stickyColumnIndex && {
                        position: "sticky",
                        left: "0",
                        zIndex: 10,
                      }),
                    }}
                  />
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching && !rows?.length && <EmptyContentPlaceholder />}
            <Loader isLoading={isFetching} boundToParent />
            <>
              {rows.map((row: Row, index: number) => {
                return (
                  <TableRow hover key={index}>
                    {row.values.map((item: any, index: number) => (
                      <TableCell
                        key={index}
                        align="center"
                        sx={{
                          ...textStyle,
                          border: "1px solid lightgrey",
                          ...(index === stickyColumnIndex && {
                            position: "sticky",
                            left: "0",
                            backgroundColor: "white",
                          }),
                        }}
                      >
                        <Typography variant="regular">
                          {item && item === "low" ? (
                            <div style={yellowDotStyle}></div>
                          ) : item && item === "high" ? (
                            <div style={greenDotStyle}></div>
                          ) : item && item === "precise_low" ? (
                            <div style={orangeDotStyle}></div>
                          ) : item && item === "precise_mid_low" ? (
                            <div style={yellowDotStyle}></div>
                          ) : item && item === "precise_mid_high" ? (
                            <div style={greenDotStyle}></div>
                          ) : item && item === "precise_high" ? (
                            <div style={blueDotStyle}></div>
                          ) : (
                            <div>{item}</div>
                          )}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {totalsColSpan && (
                <>
                  {pageTotals &&
                    rowsPerPage !== ROWS_PER_PAGE_ALL &&
                    totalsRow(PAGE_TOTAL, pageTotals)}
                  {differenceTotals &&
                    rowsPerPage !== ROWS_PER_PAGE_ALL &&
                    totalsRow(OTHER, differenceTotals)}
                  {grandTotals && totalsRow(GRAND_TOTAL, grandTotals)}
                  {customTotals &&
                    customTotals.map((customTotal: CustomTotal) =>
                      totalsRow(customTotal.title, customTotal.totals),
                    )}
                </>
              )}
            </>
          </TableBody>
        </Table>
      </TableContainer>
      {!!count && (
        <TablePagination
          component="div"
          count={count}
          labelRowsPerPage=""
          rowsPerPage={rowPerPage}
          rowsPerPageOptions={[
            20,
            50,
            100,
            { value: ROWS_PER_PAGE_ALL, label: ALL },
          ]}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={paginationStyle}
        />
      )}
    </Paper>
  );
}

export default FreeStockTable;
