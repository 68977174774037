import { mainColor, mainWhite, progressiveBlue } from "shared/colors";

export const filtersChipsStyle = {
  filtersWrapper: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: { xs: "calc(100% - 86px)", md: "100%" },
  },
  chipStyle: {
    flexDirection: "unset",
    color: mainColor,
    cursor: "pointer",
    backgroundColor: mainWhite,
    marginRight: "8px",
    marginTop: "8px",
  },
  clearWrapper: {
    marginTop: "12px",
    marginLeft: { md: "8px", xs: "0px" },
    cursor: "pointer",
    display: "flex",
  },
  clearTextStyle: {
    color: progressiveBlue,
    fontSize: "14px",
  },
  clearIconStyle: {
    color: progressiveBlue,
    marginRight: "4px",
  },
  filterTextStyle: {
    color: mainColor,
    fontSize: "14px",
    fontWeight: 600,
  },
  filterWrapper: {
    marginTop: "13px",
    marginRight: "8px",
  },
  wrapper: {
    display: "flex",
  },
};
