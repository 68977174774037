import { darkBrown, mainColor, mainWhite, sandColor } from "shared/colors";

export const tableStyle = {
  paginationStyle: {
    "& .MuiTablePagination-displayedRows": {
      fontFamily: "HyundaiFontMediumText",
    },
  },
  tableStyle: {
    minWidth: 400,
    overflowX: "scroll",
    "& .MuiTableRow-hover:hover": {
      backgroundColor: "transparent !important",
    },
  },
  mainTitleCell: {
    backgroundColor: mainColor,
    color: mainWhite,
    padding: "4px 0px",
    borderBottom: "none",
    minWidth: "122px",
    whiteSpace: "nowrap",
    zIndex: "10",
  },
  mainTitleStyle: {
    fontWeight: 800,
    fontSize: "14px",
    paddingTop: "10px",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  subTitleStyle: {
    borderTop: `4px solid ${mainWhite}`,
    backgroundColor: sandColor,
    color: "black",
    fontWeight: 800,
    fontSize: "12px",
    padding: "4px 8px",
    borderBottom: "none",
    whiteSpace: "nowrap",
    minWidth: "fit-content",
  },
  get subTitleContinuationStyle() {
    return {
      ...this.subTitleStyle,
      borderTop: "none",
    };
  },
  defaultCellStyle: {
    backgroundColor: mainColor,
    color: "black",
    fontWeight: 800,
    padding: "4px 16px",
    borderBottom: "none",
  },
  textStyle: {
    padding: "8px",
    borderBottom: "none",
    color: darkBrown,
    width: "fit-content",
  },
  miniSortIconStyle: {
    marginLeft: "2px",
    cursor: "pointer",
    width: "14px",
    height: "14px",
    color: mainColor,
  },
  sortBlockWrapper: {
    paddingTop: "10px",
    left: 0,
    right: 0,
    margin: "0px 8px",
  },
  sortIconStyle: {
    marginLeft: "4px",
    cursor: "pointer",
    width: "14px",
    height: "14px",
  },
  titleStyle: {
    fontWeight: 800,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
