import { Box } from "@mui/material";
import { pageWrapperStyle } from "./PageWrapper.style";

interface PageWrapperProps {
  children: React.ReactNode;
}

export const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  const { wrapper } = pageWrapperStyle;
  return <Box sx={wrapper}>{children}</Box>;
};

export default PageWrapper;
