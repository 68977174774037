import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { SetStateAction, useState, SyntheticEvent } from "react";
import { demoShowRoomModalWindowStyle } from "./DemoShowRoomModalWindow.style";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import PageTitle from "components/PageTitle/PageTitle";
import { CustomButtonStyle } from "components/CustomButton/CustomButton.style";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  Chip,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";

interface DemoShowRoomModalWindowProps {
  setOpen: (i: SetStateAction<boolean>) => void;
  setShowSuccessAlert: (i: SetStateAction<boolean>) => void;
  setShowErrorAlert: (i: SetStateAction<boolean>) => void;
  open: boolean;
}

interface SelectedCar {
  order_number?: string;
  vin?: string;
  soort: string;
  available?: boolean;
}

function DemoShowRoomModalWindow({
  setOpen,
  setShowSuccessAlert,
  setShowErrorAlert,
  open,
}: DemoShowRoomModalWindowProps) {
  const [orderNumber, setOrderNumber] = useState<string>();
  const [vin, setVin] = useState<string>();
  const [soort, setSoort] = useState<"DEMO" | "SHOW">("DEMO");
  const [selectedCars, setSelectedCars] = useState<Array<SelectedCar>>(
    [] as SelectedCar[],
  );
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [postRequest] =
    dealerPerformanceAPI.usePostDemoShowRoomRequestMutation();
  const [checkCar] = dealerPerformanceAPI.useCheckCarMutation();
  const { buttonStyle } = CustomButtonStyle;
  const handleClose = () => {
    setOpen(false);
    setSelectedCars([]);
    setSoort("DEMO");
    setOrderNumber("");
    setVin("");
    setErrorMessage("");
  };

  const { modalWindowStyle, chipStyle, closeIconStyle } =
    demoShowRoomModalWindowStyle;

  const submitRequest = (event: SyntheticEvent) => {
    if (errorMessage) {
      return;
    }
    if (selectedCars.length) {
      postRequest(selectedCars).then((response) => {
        if ("error" in response) {
          setShowErrorAlert(true);
          setTimeout(() => {
            setShowErrorAlert(false);
          }, 5000);
        } else {
          setSelectedCars([]);
          setShowSuccessAlert(true);
          setTimeout(() => {
            setShowSuccessAlert(false);
          }, 5000);
        }
      });
      handleClose();
    } else {
      setDisplayError(true);
      setErrorMessage("Empty list");
    }
  };

  const addCar = (event: SyntheticEvent) => {
    event.preventDefault();
    setDisplayError(false);
    setErrorMessage("");
    if (
      selectedCars.some((object) => {
        const orderNumberExists =
          orderNumber && object.order_number === orderNumber;
        const vinExists = vin && object.vin === vin;
        return orderNumberExists || vinExists;
      })
    ) {
      setDisplayError(true);
      setErrorMessage("Already in the list");
      return;
    }
    checkCar({
      ...(orderNumber ? { order_number: orderNumber } : {}),
      ...(vin ? { vin: vin } : {}),
      soort: soort,
    }).then((response) => {
      if ("data" in response) {
        const available = response.data.available;
        if (!available) {
          setDisplayError(true);
          setErrorMessage(response.data.message);
          return;
        } else {
          setSelectedCars([
            ...selectedCars,
            {
              order_number: response.data.order_number,
              vin: response.data.vin,
              soort: soort,
              available: available,
            },
          ]);
          setOrderNumber("");
          setVin("");
        }
      }
    });
  };

  function removeSelectedCar(removedOrderNumber: string | undefined) {
    if (selectedCars) {
      setSelectedCars(
        selectedCars.filter((car) => car.order_number !== removedOrderNumber),
      );
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalWindowStyle}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={closeIconStyle}
        >
          <CloseIcon />
        </IconButton>
        <PageTitle title="Request for adding demo and showroom cars" />
        <form onSubmit={addCar}>
          <TextField
            style={{ width: "100%", margin: "25px 0 15px 0" }}
            required
            type="text"
            label="Order Number or VIN"
            variant="outlined"
            value={orderNumber || vin}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d+$/.test(value)) {
                // if the value is a number
                setOrderNumber(value);
                setVin("");
              } else {
                setOrderNumber("");
                setVin(value);
              }
              setErrorMessage("");
            }}
            InputProps={
              (orderNumber || vin)?.length
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <KeyboardReturnIcon
                          onClick={addCar}
                          cursor={"pointer"}
                        />
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
          />
          {displayError && (
            <Typography sx={{ color: "red" }}>{errorMessage}</Typography>
          )}
          <Box style={{ marginBottom: "15px" }}>
            <RadioGroup
              defaultValue="DEMO"
              row
              onChange={(e) => {
                if (
                  e.currentTarget.value === "DEMO" ||
                  e.currentTarget.value === "SHOW"
                ) {
                  setSoort(e.currentTarget.value);
                  setDisplayError(false);
                  setErrorMessage("");
                }
              }}
            >
              <FormControlLabel value="DEMO" control={<Radio />} label="Demo" />
              <FormControlLabel
                value="SHOW"
                control={<Radio />}
                label="Showroom"
              />
            </RadioGroup>
          </Box>
          <Box>
            <Button
              sx={{ ...buttonStyle, marginBottom: "0" }}
              onClick={submitRequest}
            >
              Submit
            </Button>
            {selectedCars.map((car) => (
              <Chip
                sx={{
                  ...chipStyle,
                  ...(car?.available === false && { color: "red" }),
                }}
                label={`${car.order_number} (VIN: ${car.vin}): ${car.soort}`}
                onDelete={() => removeSelectedCar(car.order_number)}
              />
            ))}
          </Box>
        </form>
      </Box>
    </Modal>
  );
}

export default DemoShowRoomModalWindow;
