import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { free_stock_page_link, login_page_link, main_page_link } from "./paths";
import { useSelector } from "react-redux";
import { userAPI } from "../api/services/User";
import {
  ACCOUNT_MANAGER_ROLE,
  ADMIN_ROLE,
  DEALER_ADMIN_ROLE,
  DEALER_SALES_ROLE,
} from "../shared/constants";

interface PrivateRouteProps {
  children: JSX.Element;
  roles?: Array<string>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  roles = null,
}) => {
  const { data: userData } = userAPI.useSelfQuery();
  const redirectLink = useMemo(() => {
    const userType = userData?.user_type;
    switch (userType) {
      case ADMIN_ROLE:
      case ACCOUNT_MANAGER_ROLE:
      case DEALER_ADMIN_ROLE:
        return `/${main_page_link}`;
      case DEALER_SALES_ROLE:
        return `/${main_page_link}/${free_stock_page_link}`;
      default:
        return login_page_link;
    }
  }, [userData]);

  const token = localStorage.getItem("ACCESS_TOKEN");
  const user = useSelector((state: any) => state.user).user;

  if (!token) {
    return <Navigate to={login_page_link} />;
  }

  if (roles && !roles.includes(user.user_type)) {
    return <Navigate to={redirectLink} />;
  }

  return children;
};

export default PrivateRoute;
