import { MainButton } from "components";
import { useNavigate } from "react-router-dom";
import {
  backorder_overview_page_link,
  backorder_page_link,
  future_sales_page_link,
  license_return_page_link,
  registration_page_link,
  reservation_page_link,
  stock_page_link,
  rft_page_link,
  performance_overview_link,
  demo_showroom_page_link,
  claims_page_link,
  demo_and_showroom_overview_page_link,
  allocation_overview_page_link,
  demo_showroom_requests_page_link,
  claim_codes_overview_page_link,
  free_stock_page_link,
} from "routers/paths";
import {
  FUTURE_SALES,
  LICENSE_RETURN,
  ORDER_TO_DELIVERY,
  OTD_MONITORING,
  PERFORMANCE,
  REGISTRATION,
  RESERVATION,
  DEALER_SALES_ROLE,
  ADMIN_ROLE,
  ACCOUNT_MANAGER_ROLE,
  RFT,
  PERFORMANCE_OVERVIEW,
  OVERVIEW,
  DEMO_SHOWROOM_OVERVIEW,
  CLAIMS_PAGE,
  DEMO_AND_SHOWROOM_OVERVIEW,
  ALLOCATIE,
  POTENTIAL,
  ALLOCATION,
  DEMO_SHOWROOM_REQUESTS,
  CLAIM_CODES_OVERVIEW,
  FREE_STOCK,
} from "shared/constants";
import { useSelector } from "react-redux";
import { Item } from "components/DropDownButton/DropDownButton";

function MobileNav() {
  const user = useSelector((state: any) => state.user).user;
  let mobileNavList: Array<Item> = [];
  const preferredOrder = [
    ALLOCATION,
    PERFORMANCE_OVERVIEW,
    DEMO_AND_SHOWROOM_OVERVIEW,
    RESERVATION,
    LICENSE_RETURN,
    POTENTIAL,
    FREE_STOCK,
    REGISTRATION,
    DEMO_SHOWROOM_OVERVIEW,
    DEMO_SHOWROOM_REQUESTS,
    ALLOCATIE,
    OTD_MONITORING,
    RFT,
    FUTURE_SALES,
    CLAIMS_PAGE,
    CLAIM_CODES_OVERVIEW,
  ];
  if (
    user.user_type === ADMIN_ROLE ||
    user.user_type === ACCOUNT_MANAGER_ROLE
  ) {
    mobileNavList.push({
      label: PERFORMANCE_OVERVIEW,
      link: performance_overview_link,
      title: OVERVIEW,
    });
  }
  if (user.user_type !== DEALER_SALES_ROLE) {
    mobileNavList.push(
      {
        label: ALLOCATION,
        link: allocation_overview_page_link,
        title: OVERVIEW,
      },
      {
        label: DEMO_AND_SHOWROOM_OVERVIEW,
        link: demo_and_showroom_overview_page_link,
        title: OVERVIEW,
      },
      {
        label: DEMO_SHOWROOM_OVERVIEW,
        link: demo_showroom_page_link,
        title: PERFORMANCE,
      },
      {
        label: DEMO_SHOWROOM_REQUESTS,
        link: demo_showroom_requests_page_link,
        title: PERFORMANCE,
      },
      {
        label: RESERVATION,
        link: reservation_page_link,
        title: PERFORMANCE,
      },
      {
        label: LICENSE_RETURN,
        link: license_return_page_link,
        title: PERFORMANCE,
      },
      {
        label: POTENTIAL,
        link: stock_page_link,
        title: PERFORMANCE,
      },
      {
        label: FREE_STOCK,
        link: free_stock_page_link,
        title: PERFORMANCE,
      },
      {
        label: REGISTRATION,
        link: registration_page_link,
        title: PERFORMANCE,
      },
      {
        label: ALLOCATIE,
        link: backorder_page_link,
        title: ORDER_TO_DELIVERY,
      },
      {
        label: OTD_MONITORING,
        link: backorder_overview_page_link,
        title: ORDER_TO_DELIVERY,
      },
      {
        label: RFT,
        link: rft_page_link,
        title: ORDER_TO_DELIVERY,
      },
      {
        label: FUTURE_SALES,
        link: future_sales_page_link,
        title: FUTURE_SALES,
      },
      {
        label: CLAIMS_PAGE,
        link: claims_page_link,
        title: CLAIMS_PAGE,
      },
      {
        label: CLAIM_CODES_OVERVIEW,
        link: claim_codes_overview_page_link,
        title: CLAIMS_PAGE,
      },
    );
  } else {
    mobileNavList.push(
      {
        label: RESERVATION,
        link: reservation_page_link,
        title: PERFORMANCE,
      },
      {
        label: FREE_STOCK,
        link: free_stock_page_link,
        title: PERFORMANCE,
      },
      {
        label: OTD_MONITORING,
        link: backorder_overview_page_link,
        title: ORDER_TO_DELIVERY,
      },
      {
        label: RFT,
        link: rft_page_link,
        title: ORDER_TO_DELIVERY,
      },
      {
        label: FUTURE_SALES,
        link: future_sales_page_link,
        title: FUTURE_SALES,
      },
    );
  }

  mobileNavList.sort((a, b) => {
    return preferredOrder.indexOf(a.label) - preferredOrder.indexOf(b.label);
  });

  const navigate = useNavigate();
  return (
    <>
      {mobileNavList.map((item) => (
        <MainButton
          label={item.label}
          link={item.link}
          onClick={() => navigate(`${item.link}`)}
        />
      ))}
    </>
  );
}

export default MobileNav;
