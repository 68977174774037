import { mainColor, mainWhite } from "shared/colors";

export const CustomButtonStyle = {
  buttonStyle: {
    backgroundColor: mainColor,
    marginBottom: "20px",
    border: `solid 2px ${mainWhite}`,
    borderRadius: 0,
    color: mainWhite,
    width: { md: 240, sm: 170, xs: 170 },
    fontSize: { md: "12px", xs: "10px" },
    height: 48,
    fontWeight: 800,
    fontFamily: "HyundaiFontMediumText",
    marginRight: 2,
    "&:hover": {
      border: `solid 2px ${mainWhite}`,
      backgroundColor: mainColor,
      color: mainWhite,
    },
  },
};
