import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { basicModalStyle } from "./BasicModal.style";

interface BasicModalProps {
  handleClose: () => void;
  open: boolean;
  children: React.ReactNode;
  centerPosition?: boolean;
}

const BasicModal: React.FC<BasicModalProps> = ({
  children,
  handleClose,
  open,
  centerPosition,
}) => {
  const { modalStyle } = basicModalStyle;
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...modalStyle,
          padding: centerPosition
            ? { md: "150px", xs: "140px" }
            : { md: "40px", xs: "0px" },
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

export default BasicModal;
