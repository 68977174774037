import { Box, Typography } from "@mui/material";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import * as constants from "shared/constants";

import { FreeStockTable, DateBlock } from "components";

import { SetStateAction, useEffect, useMemo, useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import ColorsLegend from "components/ColorsLegend/ColorsLegend";
import { Header } from "components/tables/interfaces";
import { FreeStockColor } from "models/Tables";

export interface CarModel {
  label: string;
  factory: string;
  id: string;
}

function FreeStockPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [brands, setBrands] = useState<CarModel | undefined>();
  const [requiredProductionData, setRequiredProductionData] = useState(false);

  const { data: freeStockData, isFetching: freeStockDataIsFetching } =
    dealerPerformanceAPI.useGetFreeStockDataQuery({
      car_model: brands?.id,
    });

  const { data: productionData, isFetching: productionDataIsFetching } =
    dealerPerformanceAPI.useGetFreeStockProductionDataQuery(
      {
        car_model: brands?.id,
      },
      {
        skip: !requiredProductionData,
      },
    );

  const freeStockColors = freeStockData?.colors;
  const productionColors = productionData?.colors;

  useEffect(() => {
    if (freeStockData?.cars.length && !brands) {
      setBrands({
        id: freeStockData.cars[0].brand_code,
        factory: freeStockData.cars[0].factory,
        label: freeStockData.cars[0].brand_code,
      });
    }
    // eslint-disable-next-line
  }, [freeStockData]);

  useEffect(() => {
    if (brands?.factory === "HAOS") {
      setRequiredProductionData(true);
    } else {
      setRequiredProductionData(false);
    }
  }, [brands]);

  function convertData(inputData: FreeStockColor | undefined) {
    let outputData = [];
    if (inputData) {
      for (let key in inputData) {
        for (let i = 0; i < inputData[key].length; i++) {
          let mainTitle = key;
          let subTitle = inputData[key][i];
          outputData.push([mainTitle, subTitle]);
        }
      }
    }

    return outputData;
  }

  const convertedFreeStockColors = useMemo(
    () => convertData(freeStockColors),
    [freeStockColors],
  );

  const convertedProductionColors = useMemo(
    () => convertData(productionColors),
    [productionColors],
  );

  function convertCarsData(carsData: any, colors: Array<Array<string>>) {
    let result = [];
    for (let i = 0; i < carsData?.length; i++) {
      let temp = [];
      temp.push(carsData[i]["brand_type_description"]);
      for (let j = 0; j < colors?.length; j++) {
        if (
          carsData[i][colors[j][0]] &&
          carsData[i][colors[j][0]][colors[j][1]]
        ) {
          temp.push(carsData[i][colors[j][0]][colors[j][1]]);
        } else {
          temp.push(null);
        }
      }
      result.push({ values: temp });
    }
    return result;
  }

  const freeStockDataConverted = useMemo(
    () => convertCarsData(freeStockData?.data, convertedFreeStockColors),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [freeStockData],
  );

  const productionDataConverted = useMemo(
    () => convertCarsData(productionData?.data, convertedProductionColors),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productionData],
  );

  function createHeaders(inputData: { [key: string]: string[] } | undefined) {
    const outputData = [];

    if (inputData) {
      for (let key in inputData) {
        const id = key;
        const label = key;
        const subHeaders: Array<Header> = [];

        for (let i = 0; i < inputData[key].length; i++) {
          const label = inputData[key][i];
          const subId = label;
          const sortable = false;

          subHeaders.push({
            label: label,
            id: subId,
            sortable: sortable,
          });
        }

        let convertedObject = {
          id: id,
          label: label,
          subHeaders: subHeaders,
        };

        outputData.push(convertedObject);
      }
    }

    return outputData;
  }

  const freeStockHeaders: Header[] = useMemo(
    () => createHeaders(freeStockColors),
    [freeStockColors],
  );

  const productionHeaders: Header[] = useMemo(
    () => createHeaders(productionColors),
    [productionColors],
  );

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={constants.FREE_STOCK_HMNL} />
        <Box>
          <LastUpdateBlock fileType="stock_hmnl" />
          <DateBlock />
        </Box>
      </Box>
      <FreeStockTable
        isFetching={freeStockDataIsFetching}
        headers={freeStockHeaders}
        rows={freeStockDataConverted}
        count={0}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        totalsColSpan={3}
        stickyColumnIndex={0}
        selectedBrands={(item: SetStateAction<CarModel | undefined>) =>
          setBrands(item)
        }
        brandList={freeStockData?.cars}
      />
      {brands?.factory === "HAOS" &&
        (productionDataConverted.length ? (
          <FreeStockTable
            selectedValue={{ label: `${brands.label} productie` }}
            isFetching={productionDataIsFetching}
            headers={productionHeaders}
            rows={productionDataConverted}
            count={0}
            rowsPerPage={rowPerPage}
            changeRowsPerPage={setRowPerPage}
            totalsColSpan={3}
            stickyColumnIndex={0}
            selectedBrands={() => {}}
            // brandList={freeStockData?.cars}
            brandList={[]}
          />
        ) : (
          <Box
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography variant="regular" sx={{ fontSize: 25 }}>
              Op dit moment kan de productie voor het geselecteerde model niet
              worden weergegeven
            </Typography>
          </Box>
        ))}

      {!brands?.id.startsWith("Santa Fe") && brands?.factory !== "HAOS" && (
        <ColorsLegend
          colors={[
            { text: "Aantal: 5-25", color: "yellow" },
            { text: "Aantal: 26+", color: "green" },
          ]}
        />
      )}
      {brands?.factory === "HAOS" && (
        <ColorsLegend
          colors={[
            { text: "Aantal: 1-10", color: "orange" },
            { text: "Aantal: 11-30", color: "yellow" },
            { text: "Aantal: 31-50", color: "green" },
            { text: "Aantal: 51+", color: "blue" },
          ]}
        />
      )}
    </Box>
  );
}

export default FreeStockPage;
