import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { SelectChangeEvent } from "@mui/material/Select";
import { Box } from "@mui/material";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import { Header } from "components/tables/interfaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSortData } from "hooks/useSortData";
import dayjs from "dayjs";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import PageTitle from "components/PageTitle/PageTitle";
import { DateBlock, FiltersBlock, ZonedTable } from "components";
import * as constants from "shared/constants";
import { getPercentageValue } from "helper/getPercentageValue";
import { dateToOperationalFormat, dateToFilenameFormat } from "shared/utils";
import DownloadXlsxButton from "components/DownloadXlsxButton/DownloadXlsxButton";
import * as buildUtils from "shared/validation/buildUtils";
import {
  defaultLazyFieldNames,
  defaultValidators,
} from "shared/validation/filterValidators";
import * as filterFieldsNames from "components/FiltersBlock/fieldsNames";
import { useFormik } from "formik";
import { useGetLastValid } from "hooks/useGetLastValid";

function AllocationOverviewPage() {
  const HMNL_DEALER_DEFAULT_ROUND = "1";

  const defaultDateFrom = useMemo(() => dayjs().startOf("month").toDate(), []);
  const defaultDateTo = useMemo(() => new Date(), []);

  const filterSchema = useMemo(
    () =>
      buildUtils.buildObjectSchema(defaultValidators, defaultLazyFieldNames),
    [],
  );
  const formik = useFormik({
    initialValues: {
      [filterFieldsNames.DATE_FROM]: defaultDateFrom,
      [filterFieldsNames.DATE_TO]: defaultDateTo,
    },
    validate: buildUtils.buildValidateFunc(filterSchema),
    onSubmit: () => {},
  });

  const setDateFrom = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_FROM, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const setDateTo = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_TO, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [dealers, setDealers] = useState<Array<OptionsProps>>([]);
  const [zones, setZones] = useState<Array<OptionsProps>>([]);
  const [round, setRound] = useState("1");
  const [doelstelling, setDoelstelling] = useState<string>("100");
  const { sortData, ordering } = useSortData();

  const queryParams = useMemo(
    () => ({
      dealers: dealers.map((item: OptionsProps) => item.id).toString(),
      dealer_zones: zones.map((item: OptionsProps) => item.id).toString(),
      round: round,
      doelstelling: doelstelling,
      date_from: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_FROM],
      ),
      date_to: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_TO],
      ),
    }),
    [formik.values, dealers, doelstelling, round, zones],
  );
  const validatedQueryParams = useGetLastValid(
    queryParams,
    formik.isValid,
    formik.isValidating,
  );
  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetAllocationOverviewDataQuery({
      ...(validatedQueryParams || queryParams),
      ordering: ordering,
    });
  const {
    data: exportTableData,
    isFetching: exportTableDataIsFetching,
    isError: exportTableDataIsError,
  } = dealerPerformanceAPI.useGetAllocationOverviewExportDataQuery({
    ...(validatedQueryParams || queryParams),
    ordering: ordering,
  });

  const generateDetailsViewURL = (
    page_title: string,
    day_start: Date | null,
    day_end: Date | null,
    dealers: Array<string | any> = [],
    factories: Array<string | any> = [],
    car_models: Array<string | any> = [],
    zones: Array<string | any> = [],
    round: string | null,
    grand_total: Boolean = false,
  ) => {
    let url =
      `/main/allocation-overview-details-page?` +
      `dealers=${dealers.join(",") ? dealers : ""}&` +
      `zones=${zones.join(",") ? zones : ""}&` +
      `round=${round}&` +
      `factories=${factories.join(",") ? factories : ""}&` +
      `car_models=${car_models.join(",") ? car_models : ""}&` +
      `page_title=${page_title}&`;
    if (day_start) {
      url += `&day_start=${dateToOperationalFormat(day_start)}`;
    }
    if (day_end) {
      url += `&day_end=${dateToOperationalFormat(day_end)}`;
    }
    if (grand_total) {
      url += `&grand_total=${grand_total}`;
    }
    return url;
  };

  const getDealers = () => {
    let result = [];
    if (tableData) {
      for (let zone of tableData.zones) {
        for (let dealer of zone.dealers) {
          result.push(dealer.id);
        }
      }
      result.push(tableData.hmnl_company_cars?.id);
    }

    return result;
  };

  const transformData = (
    data: any,
  ): [values: any, missing_goal_global: boolean] => {
    const values: any = [];

    let missing_goal_global = false;
    data.zones.forEach((zone: any) => {
      let missing_goal_in_zone = false;
      const { dealers, ...zoneWithoutDealers } = zone;
      zone.dealers.forEach((dealer: any) => {
        values.push({ ...dealer, type: "dealer" });
        if (dealer.goal === null) {
          missing_goal_in_zone = true;
          missing_goal_global = true;
        }
      });
      if (missing_goal_in_zone) {
        zoneWithoutDealers.goal = null;
      }
      values.push({ ...zoneWithoutDealers, type: "zone" });
    });

    return [values, missing_goal_global];
  };

  const factories = useMemo<string[]>(() => {
    return tableData?.factories_names_ordered;
  }, [tableData]);

  const brandsByFactories = useMemo<{ [factory: string]: string[] }>(() => {
    return tableData?.brand_codes_by_factories;
  }, [tableData]);

  const brandsByFactoriesExtracted = useMemo(() => {
    const result: { [factory: string]: { id: string; label: string }[] } = {};
    for (const [factory, brandCodes] of Object.entries(
      brandsByFactories || {},
    )) {
      result[factory] = brandCodes.map((brandCode) => ({
        id: `${factory} - ${brandCode}`,
        label: brandCode,
      }));
    }
    return result;
  }, [brandsByFactories]);

  const [expandedBrands, setExpandedBrands] = useState<{
    [factory: string]: boolean;
  }>();
  useEffect(() => {
    setExpandedBrands(
      factories?.reduce(
        (accumulator: { [factory: string]: boolean }, curr) => ({
          ...accumulator,
          [curr]: curr === constants.UNKNOWN_FACTORY,
        }),
        {},
      ),
    );
  }, [factories]);

  const tableRows = useMemo(() => {
    if (tableData && brandsByFactories) {
      const [transformedData, missing_goal_global] = transformData(tableData);
      const data = transformedData.map((data: any) => ({
        type: data.type,
        values: [
          data.name,
          data.goal || "missing",
          {
            value: data.total,
            href: generateDetailsViewURL(
              `Allocatie Rapport - Totaal - ${data.name}`,
              formik.values[filterFieldsNames.DATE_FROM],
              formik.values[filterFieldsNames.DATE_TO],
              data.type === "dealer" ? [data.id] : getDealers(),
              [],
              [],
              data.type === "dealer"
                ? zones.map((zone) => zone.label)
                : [data.name],
              "",
              data.type !== "dealer",
            ),
          },
          data.goal ? getPercentageValue(data.goal_diff) : "missing",
          {
            value: data.round,
            href: generateDetailsViewURL(
              `Allocatie Rapport - Round ${round} - ${data.name}`,
              formik.values[filterFieldsNames.DATE_FROM],
              formik.values[filterFieldsNames.DATE_TO],
              data.type === "dealer" ? [data.id] : getDealers(),
              [],
              [],
              data.type === "dealer"
                ? zones.map((zone) => zone.label)
                : [data.name],
              round,
              data.type !== "dealer",
            ),
          },
          ...factories
            ?.map((factory) =>
              expandedBrands && expandedBrands[factory]
                ? brandsByFactories[factory]?.map((brand: string) => ({
                    value: data.factories[factory]
                      ? data.factories[factory][brand]
                      : null,
                    href: generateDetailsViewURL(
                      `Allocatie Rapport - ${factory} - ${brand} - ${data.name}`,
                      formik.values[filterFieldsNames.DATE_FROM],
                      formik.values[filterFieldsNames.DATE_TO],
                      data.type === "dealer" ? [data.id] : getDealers(),
                      [factory],
                      [brand],
                      data.type === "dealer"
                        ? zones.map((zone) => zone.label)
                        : [data.name],
                      round,
                      data.type !== "dealer",
                    ),
                  }))
                : [
                    {
                      value: data.factories[factory]
                        ? data.factories[factory]._total
                        : null,
                      href: generateDetailsViewURL(
                        `Allocatie Rapport - ${factory} - ${data.name}`,
                        formik.values[filterFieldsNames.DATE_FROM],
                        formik.values[filterFieldsNames.DATE_TO],
                        data.type === "dealer" ? [data.id] : getDealers(),
                        [factory],
                        [],
                        data.type === "dealer"
                          ? zones.map((zone) => zone.label)
                          : [data.name],
                        round,
                        data.type !== "dealer",
                      ),
                    },
                  ],
            )
            .reduce((accumulator, curr) => accumulator?.concat(curr), []),
        ],
      }));
      data.push({
        type: "total",
        values: [
          "TOTAAL",
          missing_goal_global ? "missing" : tableData.goal,
          {
            value: tableData.total,
            href: generateDetailsViewURL(
              `Allocatie Rapport - Totaal`,
              formik.values[filterFieldsNames.DATE_FROM],
              formik.values[filterFieldsNames.DATE_TO],
              getDealers(),
              [],
              [],
              zones.map((zone) => zone.label),
              "",
              true,
            ),
          },
          missing_goal_global
            ? "missing"
            : getPercentageValue(tableData.goal_diff),
          {
            value: tableData.round,
            href: generateDetailsViewURL(
              `Allocatie Rapport - Round ${round} - Totaal`,
              formik.values[filterFieldsNames.DATE_FROM],
              formik.values[filterFieldsNames.DATE_TO],
              getDealers(),
              [],
              [],
              zones.map((zone) => zone.label),
              round,
              true,
            ),
          },
          ...factories
            ?.map((factory) =>
              expandedBrands && expandedBrands[factory]
                ? brandsByFactories[factory]?.map((brand: any) => ({
                    value: tableData.factories[factory]
                      ? tableData.factories[factory][brand]
                      : null,
                    href: generateDetailsViewURL(
                      `Allocatie Rapport - ${factory} - ${brand} - Totaal`,
                      formik.values[filterFieldsNames.DATE_FROM],
                      formik.values[filterFieldsNames.DATE_TO],
                      getDealers(),
                      [factory],
                      [brand],
                      zones.map((zone) => zone.label),
                      round,
                      true,
                    ),
                  }))
                : [
                    {
                      value: tableData.factories[factory]
                        ? tableData.factories[factory]._total
                        : null,
                      href: generateDetailsViewURL(
                        `Allocatie Rapport - ${factory} - Totaal`,
                        formik.values[filterFieldsNames.DATE_FROM],
                        formik.values[filterFieldsNames.DATE_TO],
                        getDealers(),
                        [factory],
                        [],
                        zones.map((zone) => zone.label),
                        round,
                        true,
                      ),
                    },
                  ],
            )
            .reduce((accumulator, curr) => accumulator?.concat(curr), []),
        ],
      });
      if (tableData.hmnl_company_cars) {
        data.push({
          type: "dealer",
          values: [
            tableData.hmnl_company_cars.name,
            tableData.hmnl_company_cars.goal,
            {
              value: tableData.hmnl_company_cars.total,
              href: generateDetailsViewURL(
                `Allocatie Rapport - Totaal - ${tableData.hmnl_company_cars.name}`,
                formik.values[filterFieldsNames.DATE_FROM],
                formik.values[filterFieldsNames.DATE_TO],
                [tableData.hmnl_company_cars.id],
                [],
                [],
                data.type === "dealer"
                  ? zones.map((zone) => zone.label)
                  : [data.name],
                "",
              ),
            },
            getPercentageValue(tableData.hmnl_company_cars.goal_diff),
            {
              value: tableData.hmnl_company_cars.round,
              href: generateDetailsViewURL(
                `Allocatie Rapport - Round ${HMNL_DEALER_DEFAULT_ROUND} - ${tableData.hmnl_company_cars.name}`,
                formik.values[filterFieldsNames.DATE_FROM],
                formik.values[filterFieldsNames.DATE_TO],
                [tableData.hmnl_company_cars.id],
                [],
                [],
                [],
                HMNL_DEALER_DEFAULT_ROUND,
              ),
            },
            ...factories
              ?.map((factory) =>
                expandedBrands && expandedBrands[factory]
                  ? brandsByFactories[factory]?.map((brand: any) => ({
                      value: tableData?.hmnl_company_cars.factories[factory]
                        ? tableData.hmnl_company_cars.factories[factory][brand]
                        : null,
                      href: generateDetailsViewURL(
                        `Allocatie Rapport - ${factory} - ${brand} - ${tableData.hmnl_company_cars.name}`,
                        formik.values[filterFieldsNames.DATE_FROM],
                        formik.values[filterFieldsNames.DATE_TO],
                        [tableData.hmnl_company_cars.id],
                        [factory],
                        [brand],
                        [],
                        HMNL_DEALER_DEFAULT_ROUND,
                      ),
                    }))
                  : [
                      {
                        value: tableData?.hmnl_company_cars.factories[factory]
                          ? tableData.hmnl_company_cars.factories[factory]
                              ._total
                          : null,
                        href: generateDetailsViewURL(
                          `Allocatie Rapport - ${factory} - ${tableData.hmnl_company_cars.name}`,
                          formik.values[filterFieldsNames.DATE_FROM],
                          formik.values[filterFieldsNames.DATE_TO],
                          [tableData.hmnl_company_cars.id],
                          [factory],
                          [],
                          [],
                          HMNL_DEALER_DEFAULT_ROUND,
                        ),
                      },
                    ],
              )
              .reduce((accumulator, curr) => accumulator?.concat(curr), []),
          ],
        });
      }
      return data;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, expandedBrands]);

  const handleChange = (event: SelectChangeEvent) => {
    setDoelstelling(event.target.value as string);
  };

  const allocationOverviewHeaders: Header[] = [
    { id: "dealer_name", label: constants.DEALER },
    { id: "target", label: constants.TARGET },
    { id: "total", label: constants.TOTAL },
    { id: "vs_target", label: constants.VS_TARGET },
    { id: "round", label: `${constants.ROUND} ${round}` },
    ...(factories?.map((factory) => ({
      id: factory,
      label: factory,
      expanded: expandedBrands && expandedBrands[factory],
      expandable: true,
      setExpanded:
        factory !== constants.UNKNOWN_FACTORY
          ? (expanded: boolean) => {
              setExpandedBrands((oldState) => ({
                ...oldState,
                [factory]: expanded,
              }));
            }
          : undefined,
      ...(expandedBrands &&
        expandedBrands[factory] && {
          subHeaders: brandsByFactoriesExtracted[factory],
        }),
    })) || []),
  ];
  const date = dateToFilenameFormat(new Date());

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={constants.ALLOCATION} />
        <Box>
          <LastUpdateBlock fileType="order_allocation" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <FiltersBlock
          hideCountryFilter
          setSelectedDealers={setDealers}
          setSelectedZones={setZones}
          setSelectedRound={setRound}
          defaultDateFrom={defaultDateFrom}
          defaultDateTo={defaultDateTo}
          setSelectedDateFrom={setDateFrom}
          setSelectedDateTo={setDateTo}
          selectedDealers={dealers}
          selectedZones={zones}
          errors={formik.errors as { [field: string]: string[] }}
        />
        <DownloadXlsxButton
          filename={`Allocation_Overview_data_${date}.xlsx`}
          data={exportTableData}
          isFetching={exportTableDataIsFetching}
          isError={exportTableDataIsError}
        />
      </Box>
      <Box>
        <ZonedTable
          headers={allocationOverviewHeaders}
          isFetching={tableDataIsFetching}
          stickyColumnIndex={0}
          rows={tableRows}
          count={1}
          getSortedValue={(value: string) => sortData(value)}
          colouredColumnsIndex={[]}
          handleChange={handleChange}
          doelstelling={doelstelling}
        />
      </Box>
    </Box>
  );
}

export default AllocationOverviewPage;
