import { Header } from "./interfaces";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { mainWhite } from "shared/colors";
import SortIcon from "@mui/icons-material/Sort";
import { zonedTableStyle } from "./ZonedTable/ZonedTable.style";
import { ReactNode } from "react";
import * as constants from "shared/constants";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";

const {
  subTitleStyle,
  subTitleContinuationStyle,
  defaultCellStyle,
  miniSortIconStyle,
  titleStyle,
} = zonedTableStyle;

export const getColSpanForHeader = (header: Header) => {
  if (!header.subHeaders) {
    return 1;
  }

  let result = 0;
  for (let subHeader of header.subHeaders) {
    result += getColSpanForHeader(subHeader);
  }
  return result;
};

export const buildTableSubHeaders = (
  headers: Header[],
  getSortedValue?: (value: string) => void,
  stickyColumnIndex?: number,
) => {
  const PRIMARY_COLOR_PLACEHOLDER = 1;
  const SECONDARY_COLOR_PLACEHOLDER = 2;

  const MIDDLE_OR_NONE_SUBHEADER = 1;
  const LEFTMOST_SUBHEADER = 2;
  const RIGHTMOST_SUBHEADER = 3;
  const THE_ONLY_SUBHEADER = 4;

  const rows: ReactNode[] = [];
  let prevHeaders: (Header | number)[] = [];
  let currHeaders: (Header | number)[] = headers;
  let depth = 0;
  let rowExists;
  while (true) {
    prevHeaders = currHeaders;
    currHeaders = [];
    depth += 1;
    rowExists = false;
    const borderMap: number[] = [];
    for (let header of prevHeaders) {
      if (typeof header === "object") {
        if (header.subHeaders) {
          currHeaders.push(...header.subHeaders);
          rowExists = true;
          if (header.subHeaders.length === 1) {
            borderMap.push(THE_ONLY_SUBHEADER);
          } else {
            borderMap.push(LEFTMOST_SUBHEADER);
            borderMap.push(
              ...new Array(header.subHeaders.length - 2).fill(
                MIDDLE_OR_NONE_SUBHEADER,
              ),
            );
            borderMap.push(RIGHTMOST_SUBHEADER);
          }
        } else {
          currHeaders.push(
            depth === 1
              ? PRIMARY_COLOR_PLACEHOLDER
              : SECONDARY_COLOR_PLACEHOLDER,
          );
          borderMap.push(MIDDLE_OR_NONE_SUBHEADER);
        }
      } else {
        // If curr item is not an object, so it is a number, describing a placeholder of some color. So copy it
        //  as is.
        currHeaders.push(header);
        borderMap.push(MIDDLE_OR_NONE_SUBHEADER);
      }
    }

    if (!rowExists) {
      break;
    }

    rows.push(
      <TableRow>
        {currHeaders.map((header, index: number) =>
          typeof header === "object" ? (
            <TableCell
              key={header.id}
              align="center"
              sx={{
                ...subTitleStyle,
                borderLeft:
                  header.expanded || borderMap[index] === LEFTMOST_SUBHEADER
                    ? `solid 2px ${mainWhite}`
                    : "none",
                borderRight:
                  header.expanded || borderMap[index] === RIGHTMOST_SUBHEADER
                    ? `solid 2px ${mainWhite}`
                    : "none",
                ...(header.minWidth && { minWidth: header.minWidth }),
              }}
              colSpan={getColSpanForHeader(header)}
            >
              {header.expandable && header.setExpanded && (
                <Box
                  sx={{ lineHeight: "0.5rem" }}
                  onClick={() =>
                    header.setExpanded && header.setExpanded(!header.expanded)
                  }
                >
                  {header.expanded ? (
                    <UnfoldLess sx={{ transform: "rotate(90deg)" }} />
                  ) : (
                    <UnfoldMore sx={{ transform: "rotate(90deg)" }} />
                  )}
                </Box>
              )}

              {header.sortable && getSortedValue ? (
                <Box
                  onClick={() => getSortedValue(header.id)}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography variant="regular" sx={titleStyle}>
                    {header.label.replace(
                      constants.UNKNOWN_FACTORY_OR_MODEL_REGEX,
                      "-",
                    )}
                    <SortIcon sx={miniSortIconStyle} />
                  </Typography>
                </Box>
              ) : (
                <Typography variant="regular">
                  {header.label.replace(
                    constants.UNKNOWN_FACTORY_OR_MODEL_REGEX,
                    "-",
                  )}
                </Typography>
              )}
            </TableCell>
          ) : (
            <TableCell
              sx={{
                ...(header === PRIMARY_COLOR_PLACEHOLDER
                  ? defaultCellStyle
                  : subTitleContinuationStyle),
                ...(index === stickyColumnIndex && {
                  position: "sticky",
                  left: "0",
                  zIndex: 10,
                }),
              }}
            />
          ),
        )}
      </TableRow>,
    );
  }
  return rows;
};
