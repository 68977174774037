import { tableStyle } from "../style";

export const zonedTableStyle = {
  ...tableStyle,
  doelstellingDropdown: {
    color: "white",
    height: "30px",
    marginLeft: "2px",
    marginRight: "2px",
    fontSize: "0.875rem",
  },
};
