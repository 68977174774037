import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { dealerAPI } from "api/services/Dealer";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import {
  Chart,
  CustomDatePicker,
  Loader,
  OpenRegistrationGraph,
  RegistrationGraph,
  ReservationGraph,
} from "components";
import AutocompleteTextField, {
  OptionsProps,
} from "components/AutocompleteTextField/AutocompleteTextField";
import OptionsFiltersChips from "components/FiltersChips/OptionsFiltersChips";
import { getMonthByIndex } from "helper/getMonthByIndex";
import { getPercentageValue } from "helper/getPercentageValue";
import useGetViewPort from "hooks/useGetViewPort";
import { Dealer } from "models/Dealer";
import { useCallback, useMemo, useState } from "react";
import {
  greenLightColor,
  mainWhite,
  orangeColor,
  redColor,
} from "shared/colors";
import {
  CURRENT,
  DEALERS,
  DEALER_PERFORMANCE,
  FOR_DATE,
  FROM,
  OPEN_REGISTRATIONS,
  OR_MORE_THAN_30_BRACKETS,
  PERFORMANCE,
  PROGRESSION,
  REGISTRATIONS,
  RESERVATIONS,
  SALES_PERFORMANCE,
  STOCK_AGING_GRAND_TOTAL,
  STOCK_MANAGEMENT,
  TARGET,
  THIS_MONTH,
  THIS_QUARTER,
  THIS_YEAR,
  TO,
  RETAIL_POTENTIAL,
  REAL_RETAILS,
  OVERSCORE,
} from "shared/constants";
import {
  dealerPerformancePageStyle,
  StyledTableCell,
  StyledTableRow,
} from "./DealerPerformancePage.style";
import { dateToOperationalFormat } from "shared/utils";
import * as buildUtils from "shared/validation/buildUtils";
import {
  defaultLazyFieldNames,
  defaultValidators,
} from "shared/validation/filterValidators";
import * as filterFieldsNames from "components/FiltersBlock/fieldsNames";
import { useFormik } from "formik";
import { useGetLastValid } from "hooks/useGetLastValid";

function DealerPerformancePage() {
  const filterSchema = useMemo(
    () =>
      buildUtils.buildObjectSchema(defaultValidators, defaultLazyFieldNames, [
        filterFieldsNames.DATE_FROM,
        filterFieldsNames.DATE_TO,
      ]),
    [],
  );
  const formik = useFormik({
    initialValues: {
      [filterFieldsNames.DATE_FROM]: null,
      [filterFieldsNames.DATE_TO]: null,
    },
    validate: buildUtils.buildValidateFunc(filterSchema),
    onSubmit: () => {},
  });

  const setDateFrom = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_FROM, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const setDateTo = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_TO, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { isMobile } = useGetViewPort();
  const [selectedDealers, setSelectedDealers] = useState<Array<OptionsProps>>(
    [],
  );
  const [selectedGraph, setSelectedGraph] = useState(SALES_PERFORMANCE);
  const queryParams = useMemo(
    () => ({
      dealer_id: selectedDealers.map((i: OptionsProps) => i.id).toString(),
    }),
    [selectedDealers],
  );

  const { data, isLoading: isLoadingDealerPerformanceDetails } =
    dealerPerformanceAPI.useGetDealerPerformanceDetailsQuery(queryParams);

  const { data: stockDetails, isLoading: isLoadingStockPerformanceDetails } =
    dealerPerformanceAPI.useGetStockPerformanceDetailsQuery(queryParams);

  const { data: dealers, isLoading: isLoadingDealers } =
    dealerAPI.useGetDealersListQuery();

  const graphsQueryParams = useMemo(
    () => ({
      ...queryParams,
      date_from: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_FROM],
      ),
      date_to: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_TO],
      ),
    }),
    [formik.values, queryParams],
  );
  const validatedGraphsQueryParams = useGetLastValid(
    graphsQueryParams,
    formik.isValid,
    formik.isValidating,
  );

  const {
    data: dealerPerformanceByMonth,
    isLoading: isLoadingDealerPerformanceByMonth,
  } = dealerPerformanceAPI.useGetDealerPerformanceByMounthQuery({
    ...(validatedGraphsQueryParams || graphsQueryParams),
  });

  const { data: reservationGraph } =
    dealerPerformanceAPI.useGetDealerReservationGraphQuery({
      ...(validatedGraphsQueryParams || graphsQueryParams),
    });

  const { data: openRegistration } =
    dealerPerformanceAPI.useGetDealerOpenRegistrationGraphQuery({
      ...(validatedGraphsQueryParams || graphsQueryParams),
    });

  const { data: registrationGraph } =
    dealerPerformanceAPI.useGetDealerRegistrationGraphQuery({
      ...(validatedGraphsQueryParams || graphsQueryParams),
    });

  const selectedGraphData = [
    {
      label: SALES_PERFORMANCE,
      id: "salesPerformance",
    },
    {
      label: RESERVATIONS,
      id: "reservations",
    },
    {
      label: REGISTRATIONS,
      id: "registrations",
    },
    {
      label: OPEN_REGISTRATIONS,
      id: "openRegitration",
    },
  ];

  const {
    dealerPerformancePageWrapper,
    dividerStyle,
    titleStyle,
    titleWrapper,
    chartWrapper,
    tablesWrapper,
    performanceContainerStyle,
    rowStyle,
  } = dealerPerformancePageStyle;

  const headersTitles = [
    DEALER_PERFORMANCE,
    TARGET,
    REAL_RETAILS,
    OVERSCORE,
    PERFORMANCE,
    PROGRESSION,
  ];

  const headersStockTitles = [STOCK_MANAGEMENT, ""];

  const chartData = useMemo(() => {
    if (dealerPerformanceByMonth) {
      return dealerPerformanceByMonth.map((item: any) => ({
        month: getMonthByIndex(item.month),
        soldCars: item.cars_to_sell ? item.month_sales : item.month_goal,
        monthGoal: item.month_goal,
        carsToSell: item.cars_to_sell,
        soldAboveTarget: item.sold_about_target,
      }));
    }
  }, [dealerPerformanceByMonth]);

  const chartDataRegistration = useMemo(() => {
    if (registrationGraph) {
      return registrationGraph.map((item: any) => ({
        month: getMonthByIndex(item.month),
        monthRegistration: item.month_registration,
      }));
    }
  }, [registrationGraph]);

  const chartDataOpenRegistation = useMemo(() => {
    if (openRegistration) {
      return openRegistration.map((item: any) => ({
        month: getMonthByIndex(item.month),
        totalOpenRegistrations: item.total_open_request,
        openRegistrationsTarget: item.total_open_request_above_30,
      }));
    }
  }, [openRegistration]);

  const chartDataReservation = useMemo(() => {
    if (reservationGraph) {
      return reservationGraph.map((item: any, index: number) => ({
        month: getMonthByIndex(item.month),
        overigCount: item.overig_count,
        fleetCount: item.fleet_count,
        soldCount: item.sold_count,
        totalReservation: item.month_reservation,
      }));
    }
  }, [reservationGraph]);

  function clearDealerFilters() {
    setSelectedDealers([]);
  }

  function removeDealerFilter(removedDealer: string) {
    setSelectedDealers(
      selectedDealers.filter(
        (dealer: OptionsProps) => dealer.id !== removedDealer,
      ),
    );
  }

  const getRowsForDetails = useMemo(() => {
    if (data) {
      return [
        [
          THIS_MONTH,
          data.month_goal,
          data.current_month_real_retails,
          data.current_month_overscore,
          data.current_month_sales,
          getPercentageValue(data.month_sales_percent),
        ],
        [
          THIS_QUARTER,
          data.quarter_goal,
          data.current_quarter_real_retails,
          data.current_quarter_overscore,
          data.current_quarter_sales,
          getPercentageValue(data.quarter_sales_percent),
        ],
        [
          THIS_YEAR,
          data.year_goal,
          data.current_year_real_retails,
          data.current_year_overscore,
          data.current_year_sales,
          getPercentageValue(data.year_sales_percent),
        ],
      ];
    }
    return [];
  }, [data]);

  const getRowsForStock = useMemo(() => {
    if (stockDetails) {
      return [
        [RETAIL_POTENTIAL, stockDetails.stock],
        [STOCK_AGING_GRAND_TOTAL, stockDetails.aging_stock],
        [OR_MORE_THAN_30_BRACKETS, stockDetails.o_r],
      ];
    }
    return [];
  }, [stockDetails]);

  function getBackgroundColor(value: string | number) {
    const numberValue = typeof value === "string" && parseFloat(value);

    if (numberValue < 50) {
      return redColor;
    }
    if (numberValue >= 50 && numberValue < 70) {
      return orangeColor;
    }
    if (numberValue >= 70) {
      return greenLightColor;
    }
  }

  return (
    <Box sx={dealerPerformancePageWrapper}>
      <Loader
        isLoading={
          isLoadingDealerPerformanceByMonth ||
          isLoadingDealerPerformanceDetails ||
          isLoadingStockPerformanceDetails ||
          isLoadingDealers
        }
      />
      {!isLoadingDealerPerformanceByMonth &&
        !isLoadingDealerPerformanceDetails &&
        !isLoadingStockPerformanceDetails &&
        !isLoadingDealers && (
          <>
            {dealers && (
              <>
                <AutocompleteTextField
                  options={dealers.map((option: Dealer) => ({
                    label: option.name,
                    id: option.id,
                  }))}
                  label={DEALERS}
                  addSelectedValue={(selectedValues: OptionsProps) =>
                    setSelectedDealers((current: Array<OptionsProps>) => [
                      ...current,
                      selectedValues,
                    ])
                  }
                  selectedValues={selectedDealers}
                />
                {!!selectedDealers.length && (
                  <OptionsFiltersChips
                    filters={selectedDealers}
                    onDelete={removeDealerFilter}
                    filter={DEALERS}
                    onClear={clearDealerFilters}
                  />
                )}
              </>
            )}
            <Box sx={titleWrapper}>
              <Typography sx={titleStyle} variant={isMobile ? "h5" : "h3"}>
                {CURRENT}
              </Typography>
              <Divider orientation="horizontal" sx={dividerStyle} />
            </Box>
            <Box sx={tablesWrapper}>
              <TableContainer component={Paper} sx={performanceContainerStyle}>
                <Table sx={{ minWidth: 300 }}>
                  <TableHead>
                    <TableRow>
                      {headersTitles.map((title: string, index: number) => (
                        <StyledTableCell
                          align={index === 0 ? "left" : "center"}
                          key={index}
                        >
                          <Typography variant="regular">{title}</Typography>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getRowsForDetails.map((row, index: number) => (
                      <StyledTableRow key={index}>
                        {row.map((i: number | string, index: number) => (
                          <StyledTableCell
                            align={index === 0 ? "left" : "center"}
                            sx={{
                              backgroundColor:
                                index === 5 ? getBackgroundColor(i) : "none",
                              opacity: 0.9,
                              color: index === 5 ? mainWhite : "none",
                              ...rowStyle,
                            }}
                          >
                            <Typography variant="regular"> {i}</Typography>
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer
                component={Paper}
                sx={{ width: { md: "40%", xs: "100%" } }}
              >
                <Table sx={{ minWidth: 200 }}>
                  <TableHead>
                    <TableRow>
                      {headersStockTitles.map(
                        (title: string, index: number) => (
                          <StyledTableCell align="left" key={index}>
                            <Typography variant="regular">{title}</Typography>
                          </StyledTableCell>
                        ),
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getRowsForStock.map((row, index: number) => (
                      <StyledTableRow key={index}>
                        {row.map((i: number | string, index: number) => (
                          <StyledTableCell align="left">
                            <Typography variant="regular"> {i}</Typography>
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={titleWrapper}>
              <Typography sx={titleStyle} variant={isMobile ? "h5" : "h3"}>
                {FOR_DATE}
              </Typography>
              <Divider orientation="horizontal" sx={dividerStyle} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <AutocompleteTextField
                options={selectedGraphData.map((option: any) => ({
                  label: option.label,
                  id: option.id,
                }))}
                label={selectedGraph}
                addSelectedValue={(selectedValues: OptionsProps) =>
                  setSelectedGraph(selectedValues.label)
                }
                keepInputValueOnChange
              />
              <Box>
                <CustomDatePicker
                  label={FROM}
                  getDate={setDateFrom}
                  errorMessages={
                    formik.errors[filterFieldsNames.DATE_FROM] as
                      | string[]
                      | undefined
                  }
                />
                <CustomDatePicker
                  label={TO}
                  getDate={setDateTo}
                  errorMessages={
                    formik.errors[filterFieldsNames.DATE_TO] as
                      | string[]
                      | undefined
                  }
                />
              </Box>
            </Box>
            <Box sx={chartWrapper}>
              {selectedGraph === SALES_PERFORMANCE && (
                <Chart chartData={chartData} />
              )}
              {selectedGraph === OPEN_REGISTRATIONS && (
                <OpenRegistrationGraph chartData={chartDataOpenRegistation} />
              )}
              {selectedGraph === REGISTRATIONS && (
                <RegistrationGraph chartData={chartDataRegistration} />
              )}
              {selectedGraph === RESERVATIONS && (
                <ReservationGraph chartData={chartDataReservation} />
              )}
            </Box>
          </>
        )}
    </Box>
  );
}

export default DealerPerformancePage;
