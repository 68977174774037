import { DropDownButton } from "components";
import {
  claims_page_link,
  claim_codes_overview_page_link,
} from "routers/paths";
import { CLAIMS, CLAIMS_PAGE, CLAIM_CODES_OVERVIEW } from "shared/constants";
import { ModalHandles } from "hooks/useModal";

interface deliveryNavProps extends ModalHandles {}

function ClaimsNav({ open, openModal, closeModal }: deliveryNavProps) {
  const claimsList = [
    {
      label: CLAIMS_PAGE,
      link: claims_page_link,
      title: CLAIMS,
    },
    {
      label: CLAIM_CODES_OVERVIEW,
      link: claim_codes_overview_page_link,
      title: CLAIMS,
    },
  ];

  return (
    <DropDownButton
      label={CLAIMS}
      onClose={closeModal}
      open={open}
      onOpen={openModal}
      list={claimsList}
    />
  );
}

export default ClaimsNav;
