import { Button, SxProps, Theme } from "@mui/material";
import { mainColorButtonStyle } from "./MainColorButton.style";

interface mainColorButtonProps {
  label: string;
  onClick: () => void;
  sx?: SxProps<Theme> | undefined;
}

function MainColorButton({ onClick, label, sx }: mainColorButtonProps) {
  const { buttonStyle } = mainColorButtonStyle;
  return (
    <Button variant="outlined" sx={{ ...buttonStyle, ...sx }} onClick={onClick}>
      {label}
    </Button>
  );
}

export default MainColorButton;
