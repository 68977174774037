import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type PasswordFieldProps = Omit<TextFieldProps, "type">;

function PasswordField(props: PasswordFieldProps) {
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  const handleClickShowPassword = () => setShouldShowPassword((show) => !show);
  const handleMouseDownShowPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };
  const handleChange = (event: any) => {
    setValue(event.target.value);
    props.onChange?.(event);
  };
  const handleFocus = (event: any) => {
    setIsFocused(true);
    props.onFocus?.(event);
  };
  const handleBlur = (event: any) => {
    setIsFocused(false);
    props.onBlur?.(event);
  };
  return (
    <TextField
      {...props}
      type={shouldShowPassword ? "text" : "password"}
      InputProps={{
        ...(props.InputProps || {}),
        endAdornment: isFocused && (shouldShowPassword || value.length > 0) && (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownShowPassword}
              edge="end"
              tabIndex={-1}
            >
              {shouldShowPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={props.value || value}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
}

export default PasswordField;
