import {
  CONFIRM_PASSWORD,
  ENTER_NEW_PASSWORD,
  passwordIsTooShort,
  PASSWORDS_MUST_MATCH,
  PASSWORD_MUST_BE_LETTERS_AND_NUMBERS,
} from "shared/constants";
import * as yup from "yup";
import { passwordMinLength, passwordRegex } from "./constants";

export const changePasswordValidationSchema = yup.object().shape({
  old_password: yup.string().required("Please enter your password"),
  new_password: yup
    .string()
    .required(ENTER_NEW_PASSWORD)
    .matches(passwordRegex, PASSWORD_MUST_BE_LETTERS_AND_NUMBERS)
    .min(passwordMinLength, passwordIsTooShort(passwordMinLength)),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD)
    .oneOf([yup.ref("new_password")], PASSWORDS_MUST_MATCH),
});
