import { mainColor, mainWhite } from "shared/colors";
import { tableStyle } from "../style";

export const freeStockTableStyle = {
  ...tableStyle,
  textFieldStyle: {
    backgroundColor: mainColor,
    color: mainWhite,
    borderRadius: 2,
    marginRight: "16px",
    fontSize: "14px",
    "& .MuiAutocomplete-clearIndicator": { color: mainWhite },
    "& input": {
      color: mainWhite,
      fontSize: "14px",
    },
    "& input::placeholder": {
      color: mainWhite,
      fontSize: "14px",
      opacity: 0.8,
    },
  },
  filterIconStyle: {
    color: mainWhite,
  },
  yellowDotStyle: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    margin: "auto",
    backgroundColor: "yellow",
  },
  greenDotStyle: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    margin: "auto",
    backgroundColor: "green",
  },
  orangeDotStyle: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    margin: "auto",
    backgroundColor: "orange",
  },
  blueDotStyle: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    margin: "auto",
    backgroundColor: "blue",
  },
};
