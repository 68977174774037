export const tooltipStyle = {
  containerStyle: {
    backgroundColor: "white",
    border: "1px solid rgb(204, 204, 204)",
    padding: "16px",
  },
  labelStyle: {
    paddingBottom: "8px",
  },
  payloadStyle: {
    color: "rgb(102, 102, 102)",
    paddingBottom: "4px",
  },
};
