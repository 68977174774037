import { DropDownButton } from "components";
import { useNavigate } from "react-router-dom";
import { login_page_link, user_info_page_link } from "routers/paths";
import { SIGN_OUT, USER_INFO } from "shared/constants";
import { dealerAPI } from "api/services/Dealer";
import { carModelAPI } from "api/services/CarModel";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { orderAPI } from "api/services/Order";
import { userAPI } from "api/services/User";
import { importerAPI } from "api/services/Importer";
import { store } from "api/services/store";
import { signOutUser } from "reducers/actions";
import { ModalHandles } from "hooks/useModal";

interface UserNavProps extends ModalHandles {
  title: string;
}

function UserNav({ title, open, openModal, closeModal }: UserNavProps) {
  const navigate = useNavigate();

  function logOut() {
    localStorage.clear();
    store.dispatch(dealerAPI.util.resetApiState());
    store.dispatch(carModelAPI.util.resetApiState());
    store.dispatch(dealerPerformanceAPI.util.resetApiState());
    store.dispatch(orderAPI.util.resetApiState());
    store.dispatch(userAPI.util.resetApiState());
    store.dispatch(importerAPI.util.resetApiState());
    store.dispatch(signOutUser());
    navigate(login_page_link);
  }

  const dealerPerformanceList = [
    {
      label: USER_INFO,
      link: user_info_page_link,
      title: title,
    },
    {
      label: SIGN_OUT,
      link: "sign_out",
      title: title,
      onItemClick: logOut,
    },
  ];

  return (
    <DropDownButton
      label={title}
      onClose={closeModal}
      open={open}
      onOpen={openModal}
      list={dealerPerformanceList}
    />
  );
}

export default UserNav;
