import * as constants from "shared/constants";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSortData } from "hooks/useSortData";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import CustomTable from "components/tables/CustomTable/CustomTable";
import { Header } from "components/tables/interfaces";
import { ClaimCodesOverviewDetails } from "models/ClaimCodesOverviewDetails";
import { Box } from "@mui/material";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import { DateBlock } from "components";
import { dateToFilenameFormat } from "shared/utils";
import DownloadXlsxButton from "components/DownloadXlsxButton/DownloadXlsxButton";

function ClaimCodesOverviewDetailsPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const [searchParams] = useSearchParams();
  const { sortData, ordering } = useSortData();

  const queryParams = useMemo(
    () => ({
      dealer: searchParams.get("dealer") || "",
      client_corporate_name: searchParams.get("client_corporate_name"),
      sales_opportunity: searchParams.get("sales_opportunity"),
    }),
    [searchParams],
  );
  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetDetailedClaimCodesOverviewDataQuery({
      ...queryParams,
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const {
    data: exportTableData,
    isFetching: exportTableDataIsFetching,
    isError: exportTableDataIsError,
  } = dealerPerformanceAPI.useGetDetailedClaimCodesOverviewExportDataQuery({
    ...queryParams,
    ordering: ordering,
  });

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map(
        (claimCodeDetail: ClaimCodesOverviewDetails) => ({
          values: [claimCodeDetail.model, claimCodeDetail.discount],
        }),
      );
    }
    return [];
  }, [tableData]);
  const claimCodesOverviewDetailsHeaders: Header[] = [
    { id: "model", label: constants.MODEL, sortable: true },
    { id: "discount", label: constants.DISCOUNT, sortable: true },
  ];

  const date = dateToFilenameFormat(new Date());
  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={searchParams.get("page_title") || ""} />
        <Box>
          <LastUpdateBlock fileType="claim_codes" />
          <DateBlock />
        </Box>
      </Box>
      <Box>
        <DownloadXlsxButton
          filename={`Claim_codes_overview_data_${date}.xlsx`}
          data={exportTableData}
          isFetching={exportTableDataIsFetching}
          isError={exportTableDataIsError}
        />
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={claimCodesOverviewDetailsHeaders}
        rows={tableRows}
        count={tableData?.count}
        getItemsForPage={(items: number) => setSkip(items)}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        getSortedValue={(value: string) => sortData(value)}
        totalsColSpan={3}
        stickyColumnIndex={2}
      />
    </Box>
  );
}

export default ClaimCodesOverviewDetailsPage;
