import { Box, Typography } from "@mui/material";
import { CustomButton } from "components";
import {
  EXPORT_DATA,
  EXPORT_TABLE,
  PLEASE_CHOOSE_OPTION,
} from "shared/constants";
import { dateToFilenameFormat } from "shared/utils";
import { exportDataBlockStyle } from "./ExportDataBlock.style";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";

interface ExportDataBlockProps {
  exportTableData: string;
  exportAllData: string;
  skipExportTable?: (i: boolean) => void;
  skipExportData?: boolean;
  onClose: () => void;
  exportTableName: string;
  exportDataName: string;
}

function ExportDataBlock({
  exportAllData,
  exportTableData,
  skipExportTable,
  skipExportData,
  exportTableName,
  exportDataName,
  onClose,
}: ExportDataBlockProps) {
  const {
    blockWrapper,
    closeIconWrapperStyle,
    closeIconStyle,
    titleStyle,
    linkStyle,
    buttonsWrapper,
  } = exportDataBlockStyle;

  const date = dateToFilenameFormat(new Date());

  return (
    <Box sx={blockWrapper}>
      <Box sx={closeIconWrapperStyle}>
        <CloseIcon onClick={onClose} sx={closeIconStyle} />
      </Box>
      <Typography sx={titleStyle}>{PLEASE_CHOOSE_OPTION}</Typography>
      <Box sx={buttonsWrapper}>
        <Box>
          <a
            href={exportTableData}
            style={linkStyle}
            download={`${exportTableName}_${date}.xlsx`}
          >
            <CustomButton label={EXPORT_TABLE} icon={<DownloadIcon />} />
          </a>
        </Box>
        {!skipExportData && (
          <Box>
            <a
              href={exportAllData}
              style={linkStyle}
              download={`${exportDataName}_${date}.xlsx`}
            >
              <CustomButton label={EXPORT_DATA} icon={<DownloadIcon />} />
            </a>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ExportDataBlock;
