import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/nl";

const DEFAULT_LOCALE = "nl";

let isSetUp = false;

const setupDayjs = () => {
  if (!isSetUp) {
    dayjs.locale(DEFAULT_LOCALE);

    // to display dates in format according to a locale (dd-mm-yyyy, mm/dd/yyyy, ...)
    dayjs.extend(localizedFormat);
    // to display Q in the date block
    dayjs.extend(quarterOfYear);
    // to display Wk in the date block
    dayjs.extend(weekOfYear);
    // to parse dates from strings in different formats
    dayjs.extend(customParseFormat);

    isSetUp = true;
  }
};

export default setupDayjs;
