import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { theme } from "./material-ui/theme";
import { store } from "api/services/store";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Routers from "routers/Routers";
import NetworkStatusNotifier from "./components/NetworkStatusNotifier/NetworkStatusNotifier";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routers />
            <NetworkStatusNotifier />
          </BrowserRouter>
          <ToastContainer autoClose={3000} />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
