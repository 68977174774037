import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";

function DateBlock() {
  const dateObj = dayjs();
  const date = dateObj.format("LL");
  const quarter = dateObj.quarter();
  const week = dateObj.week();
  return (
    <Box
      sx={{
        marginBottom: "16px",
        display: "flex",
        justifyContent: { md: "flex-end", xs: "flex-start" },
      }}
    >
      <Typography variant="regular">
        {`${date} - 
       Q${quarter} -
      Wk ${week}`}
      </Typography>
    </Box>
  );
}

export default DateBlock;
