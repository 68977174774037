import { mainColor } from "shared/colors";

export const alertInfoStyle = {
  alertStyle: {
    top: "50px",
    position: "absolute",
    width: "max-content",
    "& .MuiAlert-icon": { color: mainColor },
  },
};
