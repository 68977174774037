import { Autocomplete, TextField } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { autocompleteTextFieldStyle } from "./AutocompleteTextField.style";
import { SyntheticEvent, useEffect, useState } from "react";

export interface OptionsProps {
  label: string;
  id: string;
}

interface AutoCompleteTextFieldProps {
  options: Array<OptionsProps>;
  label: string;
  addSelectedValue: (item: OptionsProps) => void;
  selectedValues?: Array<OptionsProps>;
  keepInputValueOnChange?: boolean;
}

function AutocompleteTextField({
  options,
  label,
  addSelectedValue,
  selectedValues,
  keepInputValueOnChange,
}: AutoCompleteTextFieldProps) {
  const [inputValue, setInputValue] = useState("");
  const { autocompleteStyle, textFieldStyle, filterIconStyle } =
    autocompleteTextFieldStyle;

  function handleChange(event: SyntheticEvent, value: OptionsProps) {
    addSelectedValue(value);
    if (!keepInputValueOnChange) {
      setInputValue("");
    }
    if (event.target instanceof HTMLElement) {
      event.target.focus();
    }
  }

  useEffect(() => {
    if (keepInputValueOnChange) {
      setInputValue(
        selectedValues && selectedValues.length > 0
          ? selectedValues[selectedValues.length - 1].label
          : "",
      );
    }
  }, [keepInputValueOnChange, selectedValues]);

  return (
    <Autocomplete
      disableClearable
      autoComplete
      inputValue={inputValue}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === "input") {
          setInputValue(newInputValue);
        }
      }}
      options={
        selectedValues
          ? options.filter(
              (x) => !selectedValues.some((y: OptionsProps) => y.id === x.id),
            )
          : options
      }
      onChange={handleChange}
      sx={autocompleteStyle}
      popupIcon={<FilterListIcon sx={filterIconStyle} />}
      renderInput={(params) => (
        <TextField {...params} placeholder={label} sx={textFieldStyle} />
      )}
    />
  );
}

export default AutocompleteTextField;
