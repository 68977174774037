import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import * as constants from "shared/constants";
import { GRAND_TOTAL, OTHER, PAGE_TOTAL } from "shared/constants";
import { formatCellContent } from "shared/formatters";
import { zonedTableStyle } from "./ZonedTable.style";
import SortIcon from "@mui/icons-material/Sort";
import { Loader } from "components";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { mainWhite, sandColor } from "shared/colors";
import { getPercentageBackgroundColor } from "helper/getPercentageBackgroundColor";
import { CustomTotal, Header, Row } from "../interfaces";
import { buildTableSubHeaders, getColSpanForHeader } from "../utils";
import EmptyContentPlaceholder from "../EmptyContentPlaceholder/EmptyContentPlaceholder";

interface ZonedTableProps {
  headers: Array<Header>;
  colouredColumnsIndex: Array<Number>;
  rows: Array<Row>;
  count?: number;
  pageTotals?: Array<number | string | object>;
  grandTotals?: Array<number | string | object>;
  differenceTotals?: Array<number | string | object>;
  customTotals?: Array<CustomTotal>;
  totalsColSpan?: number;
  getItemsForPage?: (item: number) => void;
  getSortedValue?: (value: string) => void;
  isFetching: boolean;
  stickyColumnIndex?: number;
  handleChange?: (event: SelectChangeEvent<any>) => void;
  doelstelling?: string;
  cellFormatters?: (((cellContent: any) => string) | undefined)[];
}

function ZonedTable({
  headers,
  colouredColumnsIndex,
  rows,
  count,
  pageTotals,
  grandTotals,
  differenceTotals,
  customTotals,
  totalsColSpan,
  getItemsForPage,
  getSortedValue,
  isFetching,
  stickyColumnIndex,
  handleChange,
  doelstelling,
  cellFormatters,
}: ZonedTableProps) {
  const {
    tableStyle,
    mainTitleCell,
    subTitleStyle,
    textStyle,
    sortIconStyle,
    sortBlockWrapper,
    titleStyle,
    doelstellingDropdown,
  } = zonedTableStyle;

  const substitutions = [
    { values: [0, "0%"], substitution: "-" },
    { values: [null], substitution: "n/a" },
  ];

  const getBackgroundColor = (
    row: any,
    index: number,
    value: number | string,
  ) => {
    if (row.type !== "dealer") {
      return sandColor;
    } else if (colouredColumnsIndex.includes(index)) {
      return getPercentageBackgroundColor(value);
    } else {
      return "white";
    }
  };

  const totalsRow = (title: string, totals: Array<number | string | any>) => (
    <TableRow>
      <TableCell
        colSpan={totalsColSpan}
        align="center"
        sx={{
          ...subTitleStyle,
          textTransform: "uppercase",
          fontSize: "12px",
        }}
      >
        {title}
      </TableCell>
      {totals.map((total: number | string | any, index: number) => (
        <TableCell
          key={index}
          align="center"
          sx={{
            ...subTitleStyle,
            padding: "4px 8px",
          }}
        >
          {total && total.href && total.value > 0 ? (
            <Link
              variant="regular"
              underline="hover"
              href={total.href}
              target="_blank"
            >
              {formatCellContent(
                total.value,
                substitutions,
                cellFormatters?.at(index),
              )}
            </Link>
          ) : (
            <Typography variant="regular">
              {formatCellContent(
                typeof total === "object" ? total.value : total,
                substitutions,
                cellFormatters?.at(index),
              )}
            </Typography>
          )}
        </TableCell>
      ))}
    </TableRow>
  );

  const noSubtitles = headers.every(
    (header) => header.subHeaders === undefined,
  );

  return (
    <Paper sx={{ marginTop: "32px", overflowX: "scroll" }}>
      <TableContainer>
        <Table stickyHeader sx={tableStyle}>
          <TableHead sx={{ position: "relative" }}>
            <TableRow>
              {headers.map((header: Header, index: number) => (
                <TableCell
                  key={header.id}
                  sx={{
                    ...mainTitleCell,
                    borderRight:
                      header.subHeaders && header.subHeaders?.length > 1
                        ? `solid 2px ${mainWhite}`
                        : "none",
                    borderLeft:
                      header.subHeaders && header.subHeaders?.length > 1
                        ? `solid 2px ${mainWhite}`
                        : "none",
                    ...(index === stickyColumnIndex && {
                      position: "sticky",
                      left: "0",
                      zIndex: 11,
                    }),
                    ...(header.minWidth && { minWidth: header.minWidth }),
                  }}
                  colSpan={getColSpanForHeader(header)}
                  align="center"
                >
                  {header.expandable && header.setExpanded && (
                    <Box
                      sx={{ lineHeight: "0.5rem" }}
                      onClick={() =>
                        header.setExpanded &&
                        header.setExpanded(!header.expanded)
                      }
                    >
                      {header.expanded ? (
                        <UnfoldLess sx={{ transform: "rotate(90deg)" }} />
                      ) : (
                        <UnfoldMore sx={{ transform: "rotate(90deg)" }} />
                      )}
                    </Box>
                  )}

                  {header.label === constants.TARGET ? (
                    <Box
                      sx={{
                        position:
                          header.subHeaders || noSubtitles
                            ? "static"
                            : "absolute",
                        ...sortBlockWrapper,
                        paddingTop: header.expandable ? "0px" : "8px",
                      }}
                    >
                      <Typography variant="regular" sx={titleStyle}>
                        {header.label}
                      </Typography>
                      <Select
                        labelId="doelstelling-select-label"
                        id="doelstelling-select"
                        value={doelstelling}
                        label={constants.TARGET}
                        onChange={handleChange}
                        sx={{
                          ...doelstellingDropdown,
                        }}
                      >
                        <MenuItem value={70}>70%</MenuItem>
                        <MenuItem value={100}>100%</MenuItem>
                        <MenuItem value={110}>110%</MenuItem>
                        <MenuItem value={120}>120%</MenuItem>
                      </Select>
                    </Box>
                  ) : header.sortable && !header.expanded && getSortedValue ? (
                    <Box
                      sx={{
                        position:
                          header.subHeaders || noSubtitles
                            ? "static"
                            : "absolute",
                        cursor: "pointer",
                        ...sortBlockWrapper,
                        paddingTop: header.expandable ? "0px" : "8px",
                      }}
                      onClick={() => getSortedValue(header.id)}
                    >
                      <Typography variant="regular" sx={titleStyle}>
                        {header.label.replace(constants.UNKNOWN_FACTORY, "-")}
                        <SortIcon sx={sortIconStyle} />
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        position:
                          header.subHeaders || noSubtitles
                            ? "static"
                            : "absolute",
                        ...sortBlockWrapper,
                        paddingTop: header.expandable ? "0px" : "8px",
                      }}
                    >
                      <Typography variant="regular" sx={titleStyle}>
                        {header.label.replace(constants.UNKNOWN_FACTORY, "-")}
                      </Typography>
                    </Box>
                  )}
                </TableCell>
              ))}
            </TableRow>
            {buildTableSubHeaders(headers, getSortedValue, stickyColumnIndex)}
          </TableHead>
          <TableBody>
            {isFetching && !rows?.length && <EmptyContentPlaceholder />}
            <Loader isLoading={isFetching} boundToParent />
            <>
              {rows.map((row: Row, index: number) => {
                return (
                  <TableRow hover key={index}>
                    {row.values.map((item: any, index: number) => (
                      <TableCell
                        key={index}
                        align="center"
                        sx={{
                          ...textStyle,
                          backgroundColor: getBackgroundColor(row, index, item),
                          ...(index === stickyColumnIndex && {
                            position: "sticky",
                            left: "0",
                          }),
                          ...(colouredColumnsIndex.includes(index) && {
                            left: "0",
                            borderRight: {
                              xs: `solid 2px ${mainWhite}`,
                              md: "none",
                            },
                          }),
                        }}
                      >
                        {item && item.href && item.value > 0 ? (
                          <Link
                            variant="regular"
                            underline="hover"
                            href={item.href}
                            target="_blank"
                          >
                            {formatCellContent(
                              item.value,
                              substitutions,
                              cellFormatters?.at(index),
                            )}
                          </Link>
                        ) : (
                          <Typography
                            variant="regular"
                            sx={{
                              ...(item === "missing" && { color: "red" }),
                            }}
                          >
                            {formatCellContent(
                              typeof item === "object" &&
                                item !== null &&
                                "value" in item
                                ? item.value
                                : item,
                              substitutions,
                              cellFormatters?.at(index),
                            )}
                          </Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {totalsColSpan && (
                <>
                  {pageTotals && totalsRow(PAGE_TOTAL, pageTotals)}
                  {differenceTotals && totalsRow(OTHER, differenceTotals)}
                  {grandTotals && totalsRow(GRAND_TOTAL, grandTotals)}
                  {customTotals &&
                    customTotals.map((customTotal: CustomTotal) =>
                      totalsRow(customTotal.title, customTotal.totals),
                    )}
                </>
              )}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default ZonedTable;
