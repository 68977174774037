import {
  Box,
  InputAdornment,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { consumingTextFieldStyle } from "./ConsumingTextField.style";
import { KeyboardEvent, useCallback, useMemo, useState } from "react";

interface CustomTextFieldProps {
  label?: string;
  disabled?: boolean;
  addSelectedValue?: (item: string) => void;
  customWidth?: string;
  customPaperWidth?: string;
}

const { textFieldStyle, iconStyle, inputStyle, menuStyle } =
  consumingTextFieldStyle;

function ConsumingTextField({
  label,
  disabled,
  addSelectedValue,
  customWidth,
  customPaperWidth,
}: CustomTextFieldProps) {
  const [text, setText] = useState("");

  const proceedAddSelectedValue = useCallback(
    (value: string) => {
      if (addSelectedValue && value) {
        addSelectedValue(value);
        setText("");
      }
    },
    [addSelectedValue],
  );
  const adornmentIcon = useMemo(
    () =>
      text ? (
        <KeyboardReturnIcon
          sx={iconStyle}
          cursor={"pointer"}
          onClick={() => proceedAddSelectedValue(text)}
        />
      ) : (
        <FilterListIcon sx={iconStyle} />
      ),
    [text, proceedAddSelectedValue],
  );

  const handleChange = (event: SelectChangeEvent) => {
    setText(event.target.value);
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key.toLowerCase() === "enter") {
      proceedAddSelectedValue(text);
      if (event.target instanceof HTMLElement) {
        event.target.blur();
      }
    }
  };

  return (
    <Box>
      <TextField
        value={text}
        label={label}
        sx={{
          ...textFieldStyle,
          width: customWidth || {
            xs: "100%",
            sm: "calc(50% - 16px)",
            md: "230px",
          },
        }}
        disabled={disabled}
        onChange={(event: any) => handleChange(event)}
        onKeyUp={(event: any) => handleKeyUp(event)}
        InputProps={{
          sx: inputStyle,
          endAdornment: (
            <InputAdornment position="end">{adornmentIcon}</InputAdornment>
          ),
        }}
        inputProps={{
          enterKeyHint: "done",
        }}
        SelectProps={{
          IconComponent: FilterListIcon,
          onChange: (event: any) => handleChange(event),
          MenuProps: {
            sx: menuStyle,
            PaperProps: {
              sx: {
                width: customPaperWidth
                  ? customPaperWidth
                  : { xs: "100%", sm: "calc(50% - 16px)", md: "230px" },
              },
            },
          },
        }}
      />
    </Box>
  );
}

export default ConsumingTextField;
