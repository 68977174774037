import { mainColor, mainWhite } from "shared/colors";

export const customDatePickerStyle = {
  inputStyle: {
    width: "100%",
    minWidth: "100%",
    color: mainWhite,
    backgroundColor: mainColor,
  },
  labelStyle: {
    fontFamily: "HyundaiFontMediumText",
    color: mainWhite,
    fontSize: "14px",
    top: "-2px",
    letterSpacing: "1px",
    fontWeight: 800,
    "&.MuiInputLabel-shrink": {
      color: mainColor,
      top: "-8px",
    },
  },
  iconStyle: {
    color: mainWhite,
  },
  containerStyle: {
    position: "relative",
    display: "inline-block",
    width: { xs: "100%", sm: "calc(50% - 16px)", md: "230px" },
    minWidth: { xs: "100%", sm: "calc(50% - 16px)", md: "230px" },
    marginRight: "16px",
    marginBottom: "20px",
  },
  clearButtonStyle: {
    position: "absolute",
    top: ".25rem",
    margin: "auto",
    right: "2rem",
    color: mainWhite,
  },
};
