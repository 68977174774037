import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  BasicModal,
  CustomTable,
  DateBlock,
  ExportDataBlock,
  Loader,
  MainColorButton,
} from "components";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import { orderAPI } from "api/services/Order";
import { DetailedRFT } from "models/AllocationData";
import { useSearchParams } from "react-router-dom";
import useModal from "hooks/useModal";
import { useSortData } from "hooks/useSortData";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";

function RFTDetailsPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const { open, openModal, closeModal } = useModal();
  const [skipExportTable, setSkipExportTable] = useState(true);
  const [skipExportData, setSkipExportData] = useState(true);
  const { sortData, ordering } = useSortData();
  const [searchParams] = useSearchParams();

  const queryParams = useMemo(
    () => ({
      dealers: searchParams.get("dealers") || "",
      car_models: searchParams.get("car_models") || "",
      day_start: searchParams.get("day_start") || "",
      day_end: searchParams.get("day_end") || "",
      factories: searchParams.get("factories") || "",
      vin_number: searchParams.get("vin_number") || "",
      reservation_number: searchParams.get("reservation_number") || "",
      exclude: searchParams.get("exclude") || "",
      grand_total: searchParams.get("grand_total") || "",
    }),
    [searchParams],
  );
  const { data: tableData, isFetching: tableDataIsFetching } =
    orderAPI.useGetDetailedRFTDataQuery({
      ...queryParams,
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const { data: exportTableData, isFetching: exportTableDataIsFetching } =
    orderAPI.useGetDetailedRFTExportDataQuery(
      {
        ...queryParams,
        ordering: ordering,
      },
      { skip: skipExportTable || !tableData?.results.length },
    );
  const { data: exportAllData, isFetching: exportAllDataIsFetching } =
    orderAPI.useGetDetailedRFTExportDataQuery(queryParams, {
      skip: skipExportData,
    });

  const rftHeaders: Header[] = [
    { id: "dealer_name", label: "Afleverlocatie", sortable: true },
    {
      id: "expected_delivery",
      label: "Verwachte levering dealer",
      sortable: true,
    },
    { id: "vin", label: constants.VIN, sortable: true },
    { id: "brand_code", label: "Model", sortable: true },
    {
      id: "sales_document",
      label: constants.RESERVATION_NUMBER,
      sortable: true,
    },
    { id: "vehicle_usage", label: constants.VEHICLE_USAGE, sortable: true },
    {
      id: "license_request_date",
      label: "Kentekenaanvraag datum",
      sortable: true,
    },
  ];

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map((rft: DetailedRFT) => ({
        values: [
          rft.dealer_name,
          rft.expected_delivery,
          rft.vin,
          rft.brand_code,
          rft.sales_document,
          rft.vehicle_usage,
          rft.license_request_date,
        ],
      }));
    }
    return [];
  }, [tableData]);

  function openExportsBlock() {
    setSkipExportTable(false);
    setSkipExportData(true);
    openModal();
  }

  const getPageTitle = () => {
    const car_model = searchParams.get("car_models") || "";

    let title = `${constants.RFT_DETAILS} - `;

    if (car_model && car_model.split(",").length === 1) {
      title += `${car_model} - `;
    }

    title += searchParams.get("page_title") || "";
    return title;
  };

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={getPageTitle()} />
        <Box>
          <LastUpdateBlock fileType="rft" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <MainColorButton
            onClick={() => openExportsBlock()}
            label={constants.EXPORT}
          />
          <BasicModal
            open={open}
            handleClose={() => closeModal()}
            centerPosition
          >
            <>
              <Loader
                isLoading={exportTableDataIsFetching || exportAllDataIsFetching}
              />
              <ExportDataBlock
                exportAllData={exportAllData}
                exportTableData={exportTableData}
                onClose={closeModal}
                exportTableName="registration_table"
                exportDataName="registration_data"
                skipExportData={skipExportData}
              />
            </>
          </BasicModal>
        </Box>
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={rftHeaders}
        rows={tableRows}
        count={tableData?.count}
        getItemsForPage={(items: number) => setSkip(items)}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        getSortedValue={(value: string) => sortData(value)}
        totalsColSpan={3}
        stickyColumnIndex={3}
      />
    </Box>
  );
}

export default RFTDetailsPage;
