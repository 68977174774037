import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import {
  BasicModal,
  CustomTable,
  DateBlock,
  ExportDataBlock,
  FiltersBlock,
  Loader,
  MainColorButton,
} from "components";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import { orderAPI } from "api/services/Order";
import { MonitoringData } from "models/Monitoring";
import { useOutletContext } from "react-router-dom";
import { Roles, User } from "models/User";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import useModal from "hooks/useModal";
import { useSortData } from "hooks/useSortData";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import { dateToOperationalFormat } from "shared/utils";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import * as filterFieldsNames from "components/FiltersBlock/fieldsNames";
import * as buildUtils from "shared/validation/buildUtils";
import {
  defaultLazyFieldNames,
  defaultValidators,
} from "shared/validation/filterValidators";
import { useFormik } from "formik";
import { useGetLastValid } from "hooks/useGetLastValid";
import { dispatchFilterChangedEvent } from "shared/events";

function BackorderOverviewPage() {
  const filterSchema = useMemo(
    () =>
      buildUtils.buildObjectSchema(defaultValidators, defaultLazyFieldNames, [
        filterFieldsNames.DATE_FROM,
        filterFieldsNames.DATE_TO,
      ]),
    [],
  );
  const formik = useFormik({
    initialValues: {
      [filterFieldsNames.DATE_FROM]: null,
      [filterFieldsNames.DATE_TO]: null,
    },
    validate: buildUtils.buildValidateFunc(filterSchema),
    onSubmit: () => {},
  });

  const setDateFrom = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_FROM, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const setDateTo = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_TO, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const data: User = useOutletContext();
  const [matchOrders] = orderAPI.useMatchOrdersMutation();
  const [getOrderMatchingStatus] = orderAPI.useGetOrderMatchingStatusMutation();
  const [dealers, setDealers] = useState<Array<OptionsProps>>([]);
  const [brands, setBrands] = useState<Array<OptionsProps>>([]);
  const [factories, setFactories] = useState<Array<OptionsProps>>([]);
  const [reservationNumbers, setReservationNumbers] = useState<Array<string>>(
    [],
  );
  const { open, openModal, closeModal } = useModal();
  const [skipExportTable, setSkipExportTable] = useState(true);
  const [skipExportData, setSkipExportData] = useState(true);
  const { sortData, ordering } = useSortData();

  const queryParams = useMemo(
    () => ({
      dealer: dealers.map((item: OptionsProps) => item.id).toString(),
      car_model: brands.map((item: OptionsProps) => item.id).toString(),
      factory: factories.map((item: OptionsProps) => item.id).toString(),
      reservation_number: reservationNumbers.toString(),
      date_from: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_FROM],
      ),
      date_to: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_TO],
      ),
    }),
    [brands, formik.values, dealers, factories, reservationNumbers],
  );
  const validatedQueryParams = useGetLastValid(
    queryParams,
    formik.isValid,
    formik.isValidating,
  );
  useEffect(() => {
    dispatchFilterChangedEvent();
  }, [validatedQueryParams]);
  const { data: tableData, isFetching: tableDataIsFetching } =
    orderAPI.useGetMonitoringDataQuery({
      ...(validatedQueryParams || queryParams),
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const { data: exportTableData, isFetching: exportTableDataIsFetching } =
    orderAPI.useGetMonitoringExportTableQuery(
      { ...(validatedQueryParams || queryParams), ordering: ordering },
      { skip: skipExportTable || !tableData?.results.length },
    );
  const { data: exportAllData, isFetching: exportAllDataIsFetching } =
    orderAPI.useGetMonitoringExportDataQuery(
      validatedQueryParams || queryParams,
      {
        skip: skipExportData,
      },
    );

  const { data: orderMatchingLastSucceededAtData } =
    orderAPI.useGetOrderMatchingLastSucceededAtQuery();
  const orderMatchingLastSucceededAtLabel = useMemo(() => {
    return orderMatchingLastSucceededAtData?.succeeded_at
      ? dayjs(orderMatchingLastSucceededAtData.succeeded_at).format("L LT")
      : constants.NONE;
  }, [orderMatchingLastSucceededAtData]);

  const otdMonitoringHeaders: Header[] = [
    { id: "dealer_name", label: constants.DEALER, sortable: true },
    { id: "factory", label: constants.FACTORY, sortable: true },
    { id: "brand_code", label: constants.MODEL, sortable: true },
    {
      id: "backorder_count",
      label: constants.TOTAL_BACKORDERS,
      sortable: true,
    },
    {
      id: "vehicleUsage",
      label: constants.VEHICLE_USAGE,
      subHeaders: [
        { label: constants.SOLD, id: "vehicle_sold", sortable: true },
        { label: constants.FLEET, id: "vehicle_fleet", sortable: true },
        { label: constants.STOCK, id: "vehicle_stock", sortable: true },
        { label: constants.DEMO, id: "vehicle_demo", sortable: true },
        { label: constants.SHOW, id: "vehicle_show", sortable: true },
      ],
    },
    {
      id: "status",
      label: constants.STATUS,
      subHeaders: [
        {
          label: constants.BACKORDERS,
          id: "status_backorder",
          sortable: true,
        },
        {
          label: constants.FACTORY,
          id: "status_factory",
          sortable: true,
        },
      ],
    },
  ];

  const generateDetailsViewURL = (
    order_status: string,
    usage_status: string,
    page_title: string | any,
    dealers: Array<string | any> = [],
    car_models: Array<string | any> = [],
    factories: Array<string | any> = [],
    exclude: object = {},
    grand_total: Boolean = false,
  ) => {
    let url =
      `/main/backorder-details-page?` +
      `dealers=${dealers.join(",") ? dealers : ""}&` +
      `car_models=${car_models.join(",") ? car_models : ""}&` +
      `factories=${factories.join(",") ? factories : ""}&` +
      `order_status=${order_status}&` +
      `usage_status=${usage_status}&` +
      `reservation_number=${
        reservationNumbers.join(",") ? reservationNumbers : ""
      }&` +
      `date_from=${dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_FROM],
      )}&` +
      `date_to=${dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_TO],
      )}&` +
      `page_title=${page_title}`;
    const exclude_filters = JSON.stringify(exclude);
    if (exclude_filters !== "{}") {
      url += `&exclude=${exclude_filters}`;
    }
    if (grand_total) {
      url += `&grand_total=${grand_total}`;
    }
    return url;
  };

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map((otdMonitoring: MonitoringData) => ({
        values: [
          otdMonitoring.dealer_name,
          otdMonitoring.factory,
          otdMonitoring.brand_code,
          {
            value: otdMonitoring.backorder_count,
            href: generateDetailsViewURL(
              "",
              "",
              "",
              [otdMonitoring.dealerID],
              [otdMonitoring.brand_code],
              [otdMonitoring.factory],
            ),
          },
          {
            value: otdMonitoring.vehicle_sold,
            href: generateDetailsViewURL(
              "",
              "sold",
              "",
              [otdMonitoring.dealerID],
              [otdMonitoring.brand_code],
              [otdMonitoring.factory],
            ),
          },
          {
            value: otdMonitoring.vehicle_fleet,
            href: generateDetailsViewURL(
              "",
              "fleet",
              "",
              [otdMonitoring.dealerID],
              [otdMonitoring.brand_code],
              [otdMonitoring.factory],
            ),
          },
          {
            value: otdMonitoring.vehicle_stock,
            href: generateDetailsViewURL(
              "",
              "stock",
              "",
              [otdMonitoring.dealerID],
              [otdMonitoring.brand_code],
              [otdMonitoring.factory],
            ),
          },
          {
            value: otdMonitoring.vehicle_demo,
            href: generateDetailsViewURL(
              "",
              "demo",
              "",
              [otdMonitoring.dealerID],
              [otdMonitoring.brand_code],
              [otdMonitoring.factory],
            ),
          },
          {
            value: otdMonitoring.vehicle_show,
            href: generateDetailsViewURL(
              "",
              "show",
              "",
              [otdMonitoring.dealerID],
              [otdMonitoring.brand_code],
              [otdMonitoring.factory],
            ),
          },
          {
            value: otdMonitoring.status_backorder,
            href: generateDetailsViewURL(
              "backorder",
              "",
              "",
              [otdMonitoring.dealerID],
              [otdMonitoring.brand_code],
              [otdMonitoring.factory],
            ),
          },
          {
            value: otdMonitoring.status_factory,
            href: generateDetailsViewURL(
              "factory",
              "",
              "",
              [otdMonitoring.dealerID],
              [otdMonitoring.brand_code],
              [otdMonitoring.factory],
            ),
          },
        ],
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  function openExportsBlock() {
    setSkipExportTable(false);
    setSkipExportData(false);
    openModal();
  }

  const getDealers = () => {
    if (tableData) {
      return [
        tableData.results.map(
          (otdMonitoring: MonitoringData) => otdMonitoring.dealerID,
        ),
      ];
    }
  };

  const getCarModels = () => {
    if (tableData) {
      return [
        tableData.results.map(
          (otdMonitoring: MonitoringData) => otdMonitoring.brand_code,
        ),
      ];
    }
  };

  const getFactories = () => {
    if (tableData) {
      return [
        tableData.results.map(
          (otdMonitoring: MonitoringData) => otdMonitoring.factory,
        ),
      ];
    }
  };

  const getFilterValue = (items: Array<any>, attr: string = "label") => {
    return items.map((item: any) => item[attr]);
  };

  const orderMatchingStatusToastId = "orderMatchingStatus";
  const statusInterval = useRef<NodeJS.Timer | null>(null);
  const displayToastFunctionLastRunAt = useRef(0);
  const displayToastFunctionIsRecalled = useRef(false);
  const dispatch = useDispatch();

  const ORDER_MATCHING_STATUS_CHECK_INTERVAL_MS = 5000;
  const displayOrderMatchingToast = useCallback(() => {
    if (statusInterval.current || toast.isActive(orderMatchingStatusToastId)) {
      displayToastFunctionIsRecalled.current = true;
      toast.dismiss(orderMatchingStatusToastId);
      return;
    }

    const thisTimeRunAt = new Date().getTime();
    displayToastFunctionLastRunAt.current = thisTimeRunAt;
    displayToastFunctionIsRecalled.current = false;
    const toastIsRelevant = () =>
      displayToastFunctionLastRunAt.current === thisTimeRunAt;
    const onSuccess = (resolve: (reason?: any) => void) => {
      if (toastIsRelevant()) {
        resolve();
      }
      clearStatusInterval();
      dispatch(orderAPI.util?.invalidateTags(["Monitoring"]));
    };
    const onError = (reject: (reason?: any) => void) => {
      if (toastIsRelevant()) {
        reject();
      }
      clearStatusInterval();
    };
    const onStatusUnknown = () => {
      if (toastIsRelevant()) {
        toast.dismiss(orderMatchingStatusToastId);
      }
      clearStatusInterval();
    };

    const orderMatchingPromise = new Promise<void>((resolve, reject) => {
      let initialStatusResponseCounter = 0;
      const maxInitialStatusResponses = 3;
      statusInterval.current = setInterval(() => {
        getOrderMatchingStatus()
          .then((response) => {
            if ("data" in response) {
              switch (response.data.status) {
                case "COMPLETED":
                  onSuccess(resolve);
                  break;
                case "FAILED":
                  onError(reject);
                  break;
                case "INITIAL":
                  if (
                    ++initialStatusResponseCounter > maxInitialStatusResponses
                  ) {
                    onStatusUnknown();
                  }
                  break;
              }
            } else {
              console.error(
                "getOrderMatchingStatus() has returned an error:",
                response,
              );
              onStatusUnknown();
            }
          })
          .catch((response) => {
            onError(reject);
          });
      }, ORDER_MATCHING_STATUS_CHECK_INTERVAL_MS);
    });

    toast.promise(
      orderMatchingPromise,
      {
        pending: constants.ORDER_MATCHING_IS_BEING_PROCESSED,
        success: constants.ORDER_MATCHING_HAS_BEEN_COMPLETED,
        error: constants.ORDER_MATCHING_HAS_BEEN_FAILED,
      },
      {
        toastId: orderMatchingStatusToastId,
        closeOnClick: true,
      },
    );
  }, [getOrderMatchingStatus, dispatch]);

  useEffect(() => {
    const unsubscribe = toast.onChange((state) => {
      if (
        state.id === orderMatchingStatusToastId &&
        state.status === "removed"
      ) {
        clearStatusInterval();
        if (displayToastFunctionIsRecalled.current) {
          displayOrderMatchingToast();
        }
      }
    });
    return () => {
      unsubscribe();
      toast.dismiss(orderMatchingStatusToastId);
    };
  }, [displayOrderMatchingToast]);

  const clearStatusInterval = () => {
    if (statusInterval.current) {
      clearInterval(statusInterval.current);
      statusInterval.current = null;
    }
  };

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title="OTD Monitor" />
        <Box>
          <LastUpdateBlock fileType="order_backorders" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <FiltersBlock
          hideCountryFilter
          setSelectedBrands={setBrands}
          setSelectedFactories={setFactories}
          setSelectedDealers={setDealers}
          setSelectedReservationNumbers={setReservationNumbers}
          setSelectedDateFrom={setDateFrom}
          setSelectedDateTo={setDateTo}
          selectedBrands={brands}
          selectedFactories={factories}
          selectedDealers={dealers}
          selectedReservationNumbers={reservationNumbers}
          errors={formik.errors as { [field: string]: string[] }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <MainColorButton
            onClick={() => openExportsBlock()}
            label={constants.EXPORT}
          />
          <BasicModal
            open={open}
            handleClose={() => closeModal()}
            centerPosition
          >
            <>
              <Loader
                isLoading={exportTableDataIsFetching || exportAllDataIsFetching}
              />
              <ExportDataBlock
                exportAllData={exportAllData}
                exportTableData={exportTableData}
                onClose={closeModal}
                exportTableName="OTD_monitoring_table"
                exportDataName="OTD_monitoring_data"
              />
            </>
          </BasicModal>
          {(data.user_type === Roles.HTML_MANAGER ||
            data.user_type === Roles.HTML_ADMIN) && (
            <Tooltip
              title={`${constants.LAST_MATCH}: ${orderMatchingLastSucceededAtLabel}`}
              arrow
            >
              <div style={{ marginTop: "16px" }}>
                <MainColorButton
                  onClick={() => {
                    matchOrders();
                    displayOrderMatchingToast();
                  }}
                  label={constants.MATCH_ORDERS}
                />
              </div>
            </Tooltip>
          )}
        </Box>
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={otdMonitoringHeaders}
        rows={tableRows}
        count={tableData?.count}
        getItemsForPage={(items: number) => setSkip(items)}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        getSortedValue={(value: string) => sortData(value)}
        pageTotals={
          tableData && [
            {
              value: tableData.page_total.total_backorders,
              href: generateDetailsViewURL(
                "",
                "",
                "totaal pagina",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.total_factory_sold,
              href: generateDetailsViewURL(
                "",
                "sold",
                "totaal pagina",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.total_factory_fleet,
              href: generateDetailsViewURL(
                "",
                "fleet",
                "totaal pagina",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.total_factory_stock,
              href: generateDetailsViewURL(
                "",
                "stock",
                "totaal pagina",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.total_factory_demo,
              href: generateDetailsViewURL(
                "",
                "demo",
                "totaal pagina",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.total_factory_show,
              href: generateDetailsViewURL(
                "",
                "show",
                "totaal pagina",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.total_status_backorder,
              href: generateDetailsViewURL(
                "backorder",
                "",
                "totaal pagina",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.total_status_factory,
              href: generateDetailsViewURL(
                "factory",
                "",
                "totaal pagina",
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
          ]
        }
        grandTotals={
          tableData && [
            {
              value: tableData.full_total.total_backorders,
              href: generateDetailsViewURL(
                "",
                "",
                "totaal",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.total_factory_sold,
              href: generateDetailsViewURL(
                "",
                "sold",
                "totaal",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.total_factory_fleet,
              href: generateDetailsViewURL(
                "",
                "fleet",
                "totaal",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.total_factory_stock,
              href: generateDetailsViewURL(
                "",
                "stock",
                "totaal",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.total_factory_demo,
              href: generateDetailsViewURL(
                "",
                "demo",
                "totaal",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.total_factory_show,
              href: generateDetailsViewURL(
                "",
                "show",
                "totaal",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.total_status_backorder,
              href: generateDetailsViewURL(
                "backorder",
                "",
                "totaal",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.total_status_factory,
              href: generateDetailsViewURL(
                "factory",
                "",
                "totaal",
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
          ]
        }
        differenceTotals={
          tableData && [
            {
              value: tableData.difference_with_page.total_backorders,
              href: generateDetailsViewURL(
                "",
                "",
                "overige",
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.total_factory_sold,
              href: generateDetailsViewURL(
                "",
                "sold",
                "overige",
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.total_factory_fleet,
              href: generateDetailsViewURL(
                "",
                "fleet",
                "overige",
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.total_factory_stock,
              href: generateDetailsViewURL(
                "",
                "stock",
                "overige",
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.total_factory_demo,
              href: generateDetailsViewURL(
                "",
                "demo",
                "overige",
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.total_factory_show,
              href: generateDetailsViewURL(
                "",
                "show",
                "overige",
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.total_status_backorder,
              href: generateDetailsViewURL(
                "backorder",
                "",
                "overige",
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.total_status_factory,
              href: generateDetailsViewURL(
                "factory",
                "",
                "overige",
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
          ]
        }
        totalsColSpan={3}
        stickyColumnIndex={2}
      />
    </Box>
  );
}

export default BackorderOverviewPage;
