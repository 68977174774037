import { Box, MenuItem, Select, Stack } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
} from "@mui/material";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { DateBlock, FiltersBlock, Loader } from "components";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import PageTitle from "components/PageTitle/PageTitle";
import dayjs from "dayjs";
import { getPercentageValue } from "helper/getPercentageValue";
import {
  PerformanceOverviewDealer,
  PerformanceOverviewZone,
  PerformanceOverviewData,
} from "models/PerformanceOverviewData";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as constants from "shared/constants";
import { formatCellContent } from "shared/formatters";
import { dateToFilenameFormat, dateToOperationalFormat } from "shared/utils";
import { getPercentageBackgroundColor } from "helper/getPercentageBackgroundColor";
import { mainWhite, sandColor } from "shared/colors";

import {
  StyledTableCell,
  StyledTableRow,
  performanceOverviewPageStyle,
} from "./PerformanceOverviewPage.style";
import DownloadXlsxButton from "components/DownloadXlsxButton/DownloadXlsxButton";
import EmptyContentPlaceholder from "components/tables/EmptyContentPlaceholder/EmptyContentPlaceholder";
import * as filterFieldsNames from "components/FiltersBlock/fieldsNames";
import * as buildUtils from "shared/validation/buildUtils";
import {
  defaultLazyFieldNames,
  defaultValidators,
} from "shared/validation/filterValidators";
import { useFormik } from "formik";
import { useGetLastValid } from "hooks/useGetLastValid";
import { KeyboardCapslock, LastPage } from "@mui/icons-material";

const { rowStyle, mainTitleStyle, defaultCellStyle, doelstellingDropdown } =
  performanceOverviewPageStyle;

function PerformanceOverviewPage() {
  const defaultDateFrom = useMemo(() => dayjs().startOf("month").toDate(), []);
  const defaultDateTo = useMemo(() => new Date(), []);

  const filterSchema = useMemo(
    () =>
      buildUtils.buildObjectSchema(defaultValidators, defaultLazyFieldNames),
    [],
  );
  const formik = useFormik({
    initialValues: {
      [filterFieldsNames.DATE_FROM]: defaultDateFrom,
      [filterFieldsNames.DATE_TO]: defaultDateTo,
    },
    validate: buildUtils.buildValidateFunc(filterSchema),
    onSubmit: () => {},
  });

  const setDateFrom = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_FROM, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const setDateTo = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_TO, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [dealers, setDealers] = useState<Array<OptionsProps>>([]);
  const [zones, setZones] = useState<Array<OptionsProps>>([]);
  const [doelstelling, setDoelstelling] = useState<string>("100");

  const queryParams = useMemo(
    () => ({
      dealers: dealers.map((item: OptionsProps) => item.id).toString(),
      dealer_zones: zones.map((item: OptionsProps) => item.id).toString(),
      date_from: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_FROM],
      ),
      date_to: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_TO],
      ),
      doelstelling: doelstelling,
    }),
    [formik.values, dealers, doelstelling, zones],
  );
  const validatedQueryParams = useGetLastValid(
    queryParams,
    formik.isValid,
    formik.isValidating,
  );
  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetPerformanceOverviewDataQuery(
      validatedQueryParams || queryParams,
    );
  const {
    data: exportTableData,
    isFetching: exportTableDataIsFetching,
    isError: exportTableDataIsError,
  } = dealerPerformanceAPI.useGetPerformanceOverviewExportDataQuery(
    validatedQueryParams || queryParams,
  );

  const performanceOverviewHeaders = [
    constants.DEALER,
    constants.DOELSTELLING,
    "Retail PT + Retail",
    "vs Doelstelling %",
    "To allocate",
    "Retail Potential",
    "License Requests",
    "Overscore from last month",
  ];

  const [expandedZones, setExpandedZones] = useState<{
    [zone: string]: boolean;
  }>();
  useEffect(() => {
    setExpandedZones(
      (prevState) =>
        tableData?.zones.reduce(
          (
            accumulator: { [zoneName: string]: boolean },
            zone: PerformanceOverviewZone,
          ) => ({
            ...accumulator,
            [zone.name]: prevState?.[zone.name] ?? true,
          }),
          {},
        ),
    );
  }, [tableData]);

  type MergedElement =
    | (PerformanceOverviewDealer & { type: "dealer" })
    | (Omit<PerformanceOverviewZone, "dealers"> & { type: "zone" | "total" });

  const transformData = useCallback(
    (
      data: PerformanceOverviewData,
    ): [values: MergedElement[], missing_goal_global: boolean] => {
      const values: MergedElement[] = [];
      let missing_goal_global = false;
      data.zones.forEach((zone) => {
        let missing_goal_in_zone = false;
        const { dealers, ...zoneWithoutDealers } = zone;
        if (expandedZones?.[zoneWithoutDealers.name]) {
          zone.dealers.forEach((dealer) => {
            values.push({ ...dealer, type: "dealer" });
          });
        }
        if (zone.dealers.some((dealer) => dealer.doelstelling === null)) {
          missing_goal_in_zone = true;
          missing_goal_global = true;
        }
        if (missing_goal_in_zone) {
          zoneWithoutDealers.doelstelling = null;
        }
        values.push({ ...zoneWithoutDealers, type: "zone" });
      });

      return [values, missing_goal_global];
    },
    [expandedZones],
  );

  const generateDetailsViewURL = (
    page_url: string,
    page_title: string,
    day_start: Date | null,
    day_end: Date | null,
    dealers: Array<string | any> = [],
    zones: Array<string | any> = [],
  ) => {
    let url =
      `/main/${page_url}?` +
      `dealers=${dealers.join(",") ? dealers : ""}&` +
      `zones=${zones.join(",") ? zones : ""}&` +
      `page_title=${page_title}&`;
    if (day_start) {
      url += `&day_start=${dateToOperationalFormat(day_start)}`;
    }
    if (day_end) {
      url += `&day_end=${dateToOperationalFormat(day_end)}`;
    }
    return url;
  };

  const getDealers = () => {
    let result = [];
    if (tableData) {
      for (let zone of tableData.zones) {
        for (let dealer of zone.dealers) {
          result.push(dealer.dealerID);
        }
      }
    }
    return result;
  };

  const substitutions = [{ values: [null], substitution: "-" }];
  const tableRows = useMemo(() => {
    if (tableData) {
      const [transformedData, missing_goal_global] = transformData(tableData);
      const data = transformedData.map((data: MergedElement) => ({
        type: data.type,
        values: [
          data.type === "zone" ? (
            <Stack
              alignItems="center"
              direction="row"
              sx={{ cursor: "pointer" }}
              onClick={(event) =>
                setExpandedZones(
                  (prevState) =>
                    prevState && {
                      ...prevState,
                      [data.name]: !prevState[data.name],
                    },
                )
              }
            >
              <Typography variant="regular" sx={{ marginLeft: "8px" }}>
                {data.name}
              </Typography>
              {expandedZones?.[data.name] ? (
                <LastPage sx={{ transform: "rotate(90deg)" }} />
              ) : (
                <KeyboardCapslock />
              )}
            </Stack>
          ) : (
            data.name
          ),
          data.doelstelling || "missing",
          data.retails,
          data.doelstelling ? data.vs_doelstelling : "missing",
          data.doelstelling ? data.to_allocate : "missing",
          {
            value: data.retail_potential,
            href: generateDetailsViewURL(
              "performance-overview-retail-details-page",
              data.type === "zone"
                ? `Retail Potential - ${data.name}`
                : `Retail Potential`,
              formik.values[filterFieldsNames.DATE_FROM],
              formik.values[filterFieldsNames.DATE_TO],
              data.type === "dealer" ? [data.dealerID] : getDealers(),
              data.type === "dealer"
                ? zones.map((zone) => zone.label)
                : [data.name],
            ),
          },
          {
            value: data.license_requests,
            href: generateDetailsViewURL(
              "performance-overview-license-requests-details-page",
              data.type === "zone"
                ? `License Requests - ${data.name}`
                : `License Requests`,
              formik.values[filterFieldsNames.DATE_FROM],
              formik.values[filterFieldsNames.DATE_TO],
              data.type === "dealer" ? [data.dealerID] : getDealers(),
              data.type === "dealer"
                ? zones.map((zone) => zone.label)
                : [data.name],
            ),
          },
          data.overscore_last_month,
        ],
      }));
      data.push({
        type: "total",
        values: [
          "TOTAAL",
          missing_goal_global === true
            ? "missing"
            : tableData.total_doelstelling,
          tableData.total_retails,
          missing_goal_global === true
            ? "missing"
            : tableData.total_vs_doelstelling,
          missing_goal_global === true
            ? "missing"
            : tableData.total_to_allocate,
          {
            value: tableData.total_retail_potential,
            href: generateDetailsViewURL(
              "performance-overview-retail-details-page",
              `Retail Potential - Totaal`,
              formik.values[filterFieldsNames.DATE_FROM],
              formik.values[filterFieldsNames.DATE_TO],
              getDealers(),
              zones.map((zone) => zone.label),
            ),
          },
          {
            value: tableData.total_license_requests,
            href: generateDetailsViewURL(
              "performance-overview-license-requests-details-page",
              `License Requests - Totaal`,
              formik.values[filterFieldsNames.DATE_FROM],
              formik.values[filterFieldsNames.DATE_TO],
              getDealers(),
              zones.map((zone) => zone.label),
            ),
          },
          tableData.total_overscore_last_month,
        ],
      });
      return data;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, transformData]);

  const handleChange = (event: SelectChangeEvent) => {
    setDoelstelling(event.target.value as string);
  };

  const getBackgroundColor = (
    row: any,
    index: number,
    value: number | string,
  ) => {
    if (row.type !== "dealer") {
      return sandColor;
    } else if (index === 3) {
      return getPercentageBackgroundColor(value);
    } else if (index === 4) {
      // Color of "to allocate" should be the same as color for percentage
      return getPercentageBackgroundColor(row.values[3]);
    } else {
      return "white";
    }
  };

  const date = dateToFilenameFormat(new Date());

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={constants.PERFORMANCE_OVERVIEW} />
        <Box>
          <LastUpdateBlock fileType="stock" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <FiltersBlock
          hideCountryFilter
          setSelectedDealers={setDealers}
          setSelectedZones={setZones}
          defaultDateFrom={defaultDateFrom}
          defaultDateTo={defaultDateTo}
          setSelectedDateFrom={setDateFrom}
          setSelectedDateTo={setDateTo}
          selectedDealers={dealers}
          selectedZones={zones}
          errors={formik.errors as { [field: string]: string[] }}
        />
        <Box>
          <DownloadXlsxButton
            filename={`Performance_Overview_data_${date}.xlsx`}
            data={exportTableData}
            isFetching={exportTableDataIsFetching}
            isError={exportTableDataIsError}
          />
        </Box>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 300 }}>
            <TableHead>
              <TableRow>
                {performanceOverviewHeaders.map(
                  (title: string, index: number) => (
                    <StyledTableCell align={"center"} key={index}>
                      {title !== constants.DOELSTELLING && (
                        <Typography
                          variant="regular"
                          sx={{
                            ...mainTitleStyle,
                          }}
                        >
                          {title}
                        </Typography>
                      )}
                      {title === constants.DOELSTELLING && (
                        <Box>
                          <Typography
                            variant="regular"
                            sx={{
                              ...mainTitleStyle,
                            }}
                          >
                            {title}
                          </Typography>
                          <Select
                            labelId="doelstelling-select-label"
                            id="doelstelling-select"
                            value={doelstelling}
                            label={constants.DOELSTELLING}
                            onChange={handleChange}
                            sx={{
                              ...doelstellingDropdown,
                            }}
                          >
                            <MenuItem value={70}>70%</MenuItem>
                            <MenuItem value={100}>100%</MenuItem>
                            <MenuItem value={110}>110%</MenuItem>
                            <MenuItem value={120}>120%</MenuItem>
                          </Select>
                        </Box>
                      )}
                    </StyledTableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableDataIsFetching && !tableRows?.length && (
                <EmptyContentPlaceholder />
              )}
              <Loader isLoading={tableDataIsFetching} boundToParent />
              {tableRows.map((row, index: number) => (
                <StyledTableRow key={index}>
                  {row.values.map((item: any, index: number) => (
                    <StyledTableCell
                      align={index === 0 ? "left" : "center"}
                      sx={{
                        backgroundColor: getBackgroundColor(row, index, item),
                        opacity: 0.9,
                        color:
                          (index === 3 || index === 4) && row.type === "dealer"
                            ? mainWhite
                            : "none",
                        ...rowStyle,
                        border: 0,
                        textAlign: "center",
                        fontWeight: row.type !== "dealer" ? "800" : "400",
                        ...defaultCellStyle,
                      }}
                    >
                      {item && item.href && item.value > 0 ? (
                        <Link
                          variant="regular"
                          underline="hover"
                          href={item.href}
                          target="_blank"
                        >
                          {formatCellContent(item.value, substitutions)}
                        </Link>
                      ) : item === "missing" ? (
                        <Typography
                          variant="regular"
                          sx={{ ...(item === "missing" && { color: "red" }) }}
                        >
                          {formatCellContent(
                            typeof item === "object" &&
                              item !== null &&
                              "value" in item
                              ? item.value
                              : index === 3
                                ? item
                                : item,
                            substitutions,
                          )}
                        </Typography>
                      ) : (
                        <Typography variant="regular">
                          {formatCellContent(
                            typeof item === "object" &&
                              item !== null &&
                              "value" in item
                              ? item.value
                              : index === 3
                                ? getPercentageValue(item)
                                : item,
                            substitutions,
                          )}
                        </Typography>
                      )}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default PerformanceOverviewPage;
