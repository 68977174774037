import { mainColor, mintColor } from "shared/colors";

export const customDrawerStyle = {
  drawerWrapper: {
    "& .MuiDrawer-paper": {
      width: "350px",
      paddingTop: "60px",
      backgroundColor: mintColor,
    },
  },
  contentWrapper: {
    margin: "5% 5% 20% 6%",
    display: "flex",
    flexDirection: "column",
  },
  closeIconStyle: {
    cursor: "pointer",
    alignSelf: "flex-end",
    color: mainColor,
  },
};
