import { DropDownButton } from "components";
import { future_sales_page_link } from "routers/paths";
import { FUTURE_SALES } from "shared/constants";
import { ModalHandles } from "hooks/useModal";

const orderToDeliveryList = [
  {
    label: FUTURE_SALES,
    link: future_sales_page_link,
    title: FUTURE_SALES,
  },
];

interface futureSalesNavProps extends ModalHandles {}

function FutureSalesNav({ open, openModal, closeModal }: futureSalesNavProps) {
  return (
    <DropDownButton
      label={FUTURE_SALES}
      onClose={closeModal}
      open={open}
      onOpen={openModal}
      list={orderToDeliveryList}
      link={future_sales_page_link}
      withoutSelect
    />
  );
}

export default FutureSalesNav;
