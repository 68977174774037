import { DropDownButton } from "components";
import { Item } from "components/DropDownButton/DropDownButton";
import {
  backorder_overview_page_link,
  backorder_page_link,
  rft_page_link,
} from "routers/paths";
import {
  ALLOCATIE,
  ORDER_TO_DELIVERY,
  OTD_MONITORING,
  RFT,
} from "shared/constants";
import { DEALER_SALES_ROLE } from "shared/constants";
import { useSelector } from "react-redux";
import { ModalHandles } from "hooks/useModal";

interface deliveryNavProps extends ModalHandles {}

function OrderDeliveryNav({ open, openModal, closeModal }: deliveryNavProps) {
  const orderToDeliveryList: Array<Item> = [];
  const user = useSelector((state: any) => state.user).user;
  if (user.user_type !== DEALER_SALES_ROLE) {
    orderToDeliveryList.push({
      label: ALLOCATIE,
      link: backorder_page_link,
      title: ORDER_TO_DELIVERY,
    });
  }
  orderToDeliveryList.push({
    label: OTD_MONITORING,
    link: backorder_overview_page_link,
    title: ORDER_TO_DELIVERY,
  });
  orderToDeliveryList.push({
    label: RFT,
    link: rft_page_link,
    title: ORDER_TO_DELIVERY,
  });
  return (
    <DropDownButton
      label={ORDER_TO_DELIVERY}
      onClose={closeModal}
      open={open}
      onOpen={openModal}
      list={orderToDeliveryList}
    />
  );
}

export default OrderDeliveryNav;
