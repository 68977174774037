import { Box, Typography } from "@mui/material";
import { pageTitleStyle } from "./PageTitle.style";

interface PageTitleProps {
  title: string;
}

function PageTitle({ title }: PageTitleProps) {
  const { titleStyle } = pageTitleStyle;

  return (
    <Box>
      <Typography variant="h5" sx={titleStyle}>
        {title}
      </Typography>
    </Box>
  );
}

export default PageTitle;
