import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  BasicModal,
  CustomTable,
  DateBlock,
  ExportDataBlock,
  Loader,
  MainColorButton,
} from "components";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { DetailedRegistration } from "models/RegistrationData";
import { useSearchParams } from "react-router-dom";
import useModal from "hooks/useModal";
import { useSortData } from "hooks/useSortData";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";

function RegistrationDetailsPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const { open, openModal, closeModal } = useModal();
  const [skipExportTable, setSkipExportTable] = useState(true);
  const [skipExportData, setSkipExportData] = useState(true);
  const { sortData, ordering } = useSortData();
  const [searchParams] = useSearchParams();

  const queryParams = useMemo(
    () => ({
      dealers: searchParams.get("dealers") || "",
      car_models: searchParams.get("car_models") || "",
      age_from: searchParams.get("age_from") || "",
      age_to: searchParams.get("age_to") || "",
      factories: searchParams.get("factories") || "",
      reservation_number: searchParams.get("reservation_number") || "",
      vin_number: searchParams.get("vin_number") || "",
      date_from: searchParams.get("date_from") || "",
      date_to: searchParams.get("date_to") || "",
      exclude: searchParams.get("exclude") || "",
      grand_total: searchParams.get("grand_total") || "",
      or_status: searchParams.get("or_status") || "",
      sale_type: searchParams.get("sale_type") || "",
    }),
    [searchParams],
  );
  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetDetailedRegistrationDataQuery({
      ...queryParams,
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });

  const { data: exportTableData, isFetching: exportTableDataIsFetching } =
    dealerPerformanceAPI.useGetDetailedRegistrationExportDataQuery(
      { ...queryParams, ordering: ordering },
      { skip: skipExportTable || !tableData?.results.length },
    );
  const { data: exportAllData, isFetching: exportAllDataIsFetching } =
    dealerPerformanceAPI.useGetDetailedRegistrationExportDataQuery(
      queryParams,
      { skip: skipExportData },
    );

  const stockHeaders: Header[] = [
    { id: "vin_code", label: constants.VIN, sortable: true },
    { id: "sales_order", label: constants.RESERVATION_NUMBER, sortable: true },
    {
      id: "brand_type_description",
      label: constants.MODEL_DESCRIPTION,
      sortable: true,
    },
    { id: "exterior_colour", label: constants.EXTERIOR_COLOUR, sortable: true },
    { id: "interior_colour", label: constants.INTERIOR_COLOUR, sortable: true },
    { id: "vehicle_usage", label: constants.VEHICLE_USAGE, sortable: true },
    { id: "or_days", label: "O/R Days", sortable: true },
    {
      id: "dealer_license_reque",
      label: "Dealer license request",
      sortable: true,
    },
    {
      id: "registration",
      label: constants.REGISTRATION,
      sortable: true,
    },
    {
      id: "registration_date_2",
      label: "Registration date 2",
      sortable: true,
    },
  ];

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map((reservation: DetailedRegistration) => ({
        values: [
          reservation.vin_code,
          reservation.sales_order,
          reservation.brand_type_description,
          reservation.exterior_colour,
          reservation.interior_colour,
          reservation.vehicle_usage,
          reservation.registration_date_selected
            ? 0
            : reservation.or_days.split(" ")[0],
          reservation.dealer_license_reque,
          reservation.registration,
          reservation.registration_date_2,
        ],
      }));
    }
    return [];
  }, [tableData]);

  function openExportsBlock() {
    setSkipExportTable(false);
    setSkipExportData(true);
    openModal();
  }

  const getPageTitle = () => {
    const car_model = searchParams.get("car_models") || "";

    let title = `${constants.REGISTRATION} - `;

    if (car_model && car_model.split(",").length === 1) {
      title += `${car_model} - `;
    }

    title += searchParams.get("page_title") || "";
    return title;
  };

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={getPageTitle()} />
        <Box>
          <LastUpdateBlock fileType="license_requests" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <MainColorButton
            onClick={() => openExportsBlock()}
            label={constants.EXPORT}
          />
          <BasicModal
            open={open}
            handleClose={() => closeModal()}
            centerPosition
          >
            <>
              <Loader
                isLoading={exportTableDataIsFetching || exportAllDataIsFetching}
              />
              <ExportDataBlock
                exportAllData={exportAllData}
                exportTableData={exportTableData}
                onClose={closeModal}
                exportTableName="registration_table"
                exportDataName="registration_data"
                skipExportData={skipExportData}
              />
            </>
          </BasicModal>
        </Box>
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={stockHeaders}
        rows={tableRows}
        count={tableData?.count}
        getItemsForPage={(items: number) => setSkip(items)}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        getSortedValue={(value: string) => sortData(value)}
        totalsColSpan={3}
        stickyColumnIndex={2}
      />
    </Box>
  );
}

export default RegistrationDetailsPage;
