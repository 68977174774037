import { CustomButtonStyle } from "../CustomButton/CustomButton.style";

const buttonStyle = CustomButtonStyle.buttonStyle;

export const DropDownRegularButtonStyle = {
  buttonStyle: {
    ...buttonStyle,
    ".MuiButton-endIcon": {
      position: "absolute",
      right: "1em",
    },
  },
};
