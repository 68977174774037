import styled from "@emotion/styled";
import { TableCell, tableCellClasses, TableRow } from "@mui/material";
import { greyBlue, mainColor, mainWhite } from "shared/colors";

export const dealerPerformancePageStyle = {
  dealerPerformancePageWrapper: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "column",
  },
  dividerStyle: {
    flex: 1,
    borderColor: mainColor,
    marginLeft: "20px",
    borderBlockWidth: "revert",
  },
  titleStyle: {
    color: mainColor,
    textTransform: "uppercase",
    fontSize: "20px",
    fontFamily: "HyundaiFontHead",
  },
  titleWrapper: {
    marginBottom: "45px",
    display: "flex",
    alignItems: "center",
    marginTop: "45px",
  },
  chartWrapper: {
    width: "100%",
    height: "350px",
  },
  tablesWrapper: {
    display: "flex",
    flexDirection: { md: "row", xs: "column" },
  },
  performanceContainerStyle: {
    marginRight: "40px",
    marginBottom: { xs: "40px", md: "0" },
    width: { md: "60%", xs: "100%" },
  },
  rowStyle: {
    marginRight: "100px",
    marginLeft: "100px",
  },
};

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: mainColor,
    color: mainWhite,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: greyBlue,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
