export interface User {
  id: string;
  email: string;
  user_type: string;
  dealer: string;
  dealer_zone: string;
  first_name: string;
  last_name: string;
  is_active: string;
}

export enum Roles {
  HTML_MANAGER = "hmnl_manager",
  HTML_ADMIN = "hmnl_admin",
}
