import { useEffect, useMemo, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, IconButton, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { customDatePickerStyle } from "./CustomDatePicker.style";
import { nl } from "date-fns/locale";
import dayjs from "dayjs";

interface CustomDatePickerProps {
  label: string;
  defaultDate?: Date;
  getDate: (date: Date | null) => void;
  minDate?: Date;
  errorMessages?: string[];
}

function CustomDatePicker({
  label,
  defaultDate,
  getDate,
  minDate,
  errorMessages,
}: CustomDatePickerProps) {
  const defaultValue = useMemo(() => defaultDate || null, [defaultDate]);
  const [value, setValue] = useState<Date | null>(defaultValue);
  const {
    iconStyle,
    inputStyle,
    labelStyle,
    clearButtonStyle,
    containerStyle,
  } = customDatePickerStyle;

  const handleChange = (newValue: Date | null) => {
    setValue(newValue);
    getDate(newValue);
  };

  const onClear = () => {
    setValue(defaultValue);
    getDate(defaultValue);
  };

  useEffect(() => {
    if (defaultValue && value === null) {
      setValue(defaultValue);
      getDate(defaultValue);
    }
  }, [value, defaultValue, getDate]);

  const inputFormat = dayjs.Ls[dayjs.locale()].formats.L?.replace(
    "DD",
    "dd",
  )?.replace("YYYY", "yyyy");
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
      <DesktopDatePicker
        label={label}
        inputFormat={inputFormat}
        value={value}
        onChange={handleChange}
        minDate={minDate}
        disableFuture
        renderInput={(params) => {
          return (
            <Box sx={containerStyle}>
              <TextField
                {...params}
                sx={{
                  svg: iconStyle,
                  label: labelStyle,
                  width: { xs: "100%", sm: "100%", md: "230px" },
                }}
                error={Boolean(errorMessages?.length)}
                helperText={
                  <>
                    {errorMessages?.map((e) => (
                      <>
                        {e}
                        <br />
                      </>
                    ))}
                  </>
                }
              />
              {value && value !== defaultDate && (
                <IconButton sx={clearButtonStyle} onClick={() => onClear()}>
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          );
        }}
        InputProps={{
          sx: inputStyle,
        }}
      />
    </LocalizationProvider>
  );
}

export default CustomDatePicker;
