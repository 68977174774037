import { mainColor, mainWhite } from "shared/colors";

export const demoShowRoomModalWindowStyle = {
  modalWindowStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    maxWidth: "700px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  chipStyle: {
    flexDirection: "unset",
    color: mainColor,
    cursor: "pointer",
    backgroundColor: mainWhite,
    marginRight: "8px",
    marginTop: "8px",
  },
  closeIconStyle: {
    position: "absolute",
    right: 5,
    top: 5,
  },
};
