import { DATE_FILENAME_FORMAT, DATE_OPERATIONAL_FORMAT } from "./constants";
import dayjs from "dayjs";

export const getFilterValue = (items: Array<any>, attr: string = "label") => {
  return items.map((item: any) => item[attr]);
};

export const getFrom = (range: string) => {
  return range.split("-")[0];
};

export const getTo = (range: string) => {
  return range.split("-")[1];
};

export const dateToOperationalFormat = (date: Date | null) => {
  if (date) {
    return dayjs(date).format(DATE_OPERATIONAL_FORMAT);
  } else {
    return "";
  }
};

export const dateToFilenameFormat = (date: Date) => {
  return dayjs(date).format(DATE_FILENAME_FORMAT);
};

export const mergeObjectOfArrays = (
  obj1: { [key: string]: any },
  obj2: { [key: string]: any },
) => {
  const result: { [key: string]: any } = {};
  Object.assign(result, obj1);
  for (let fieldName of Object.keys(obj2)) {
    result[fieldName] = (result[fieldName] || []).concat(obj2[fieldName]);
  }
  return result;
};
