import { Box, Button, Typography } from "@mui/material";
import { userAPI } from "api/services/User";
import { AlertInfo, BasicModal, FormWrapper } from "components";
import { useFormik } from "formik";
import useModal from "hooks/useModal";
import { PASSWORD_IS_CHANGED } from "shared/messages";
import {
  CHANGE,
  CHANGE_PASSWORD,
  CONFIRM_NEW_PASSWORD,
  INCORRECT_PASSWORD,
  NEW_PASSWORD,
  YOUR_PASSWORD,
} from "shared/constants";
import { changePasswordValidationSchema } from "shared/validation/ChangePasswordValidationSchema";
import { changePasswordPageStyle } from "./ChangePasswordPage.style";
import PasswordField from "components/PasswordField/PasswordField";

function ChangePasswordPage() {
  const [changePassword] = userAPI.useChangePasswordMutation();

  const {
    open: openErrorMessage,
    openModal: openErrorMessageModal,
    closeModal: closeErrorModal,
  } = useModal();
  const {
    open: openSucessMessage,
    openModal: openSucessMessageModal,
    closeModal: closeSuccessModal,
  } = useModal();

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: changePasswordValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await changePassword({
        old_password: values.old_password,
        new_password: values.new_password,
      });
      if ("data" in response && response.data) {
        openSucessMessageModal();
        resetForm();
      }
      if ("error" in response) {
        openErrorMessageModal();
      }
    },
  });

  const { touched, errors } = formik;

  const { inputStyle, buttonStyle, titleStyle } = changePasswordPageStyle;

  return (
    <FormWrapper handleSubmit={formik.handleSubmit}>
      <Typography variant="regular" sx={titleStyle}>
        {" "}
        {CHANGE_PASSWORD}
      </Typography>
      <BasicModal open={openSucessMessage} handleClose={closeSuccessModal}>
        <AlertInfo message={PASSWORD_IS_CHANGED} />
      </BasicModal>
      <BasicModal open={openErrorMessage} handleClose={closeErrorModal}>
        <AlertInfo message={INCORRECT_PASSWORD} />
      </BasicModal>
      <Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <PasswordField
            label={YOUR_PASSWORD}
            variant="outlined"
            sx={inputStyle}
            helperText={
              touched.old_password && errors.old_password
                ? errors.old_password
                : null
            }
            error={!!(touched.old_password && errors.old_password)}
            {...formik.getFieldProps("old_password")}
          />
          <PasswordField
            label={NEW_PASSWORD}
            variant="outlined"
            sx={inputStyle}
            helperText={
              touched.new_password && errors.new_password
                ? errors.new_password
                : null
            }
            error={!!(touched.new_password && errors.new_password)}
            {...formik.getFieldProps("new_password")}
          />
          <PasswordField
            label={CONFIRM_NEW_PASSWORD}
            variant="outlined"
            sx={inputStyle}
            helperText={
              touched.confirm_password && errors.confirm_password
                ? errors.confirm_password
                : null
            }
            error={!!(touched.confirm_password && errors.confirm_password)}
            {...formik.getFieldProps("confirm_password")}
          />
        </Box>
        <Button variant="main_button" type="submit" sx={buttonStyle}>
          {CHANGE}
        </Button>
      </Box>
    </FormWrapper>
  );
}

export default ChangePasswordPage;
