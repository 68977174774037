import { greenLightColor, orangeColor, redColor } from "shared/colors";

export function getPercentageBackgroundColor(value: string | number) {
  const numberValue = typeof value === "string" ? parseFloat(value) : value;

  if (numberValue >= 0 && numberValue < 70) {
    return redColor;
  }
  if (numberValue >= 70 && numberValue < 100) {
    return orangeColor;
  }
  if (numberValue >= 100) {
    return greenLightColor;
  }
}
