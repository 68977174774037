import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  BasicModal,
  CustomTable,
  DateBlock,
  ExportDataBlock,
  Loader,
  MainColorButton,
} from "components";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import { DetailedAllocatie } from "models/AllocationData";
import { useSearchParams } from "react-router-dom";
import useModal from "hooks/useModal";
import { useSortData } from "hooks/useSortData";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import { orderAPI } from "api/services/Order";

function BackOrderAllocatieDetailsPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const { open, openModal, closeModal } = useModal();
  const [skipExportTable, setSkipExportTable] = useState(true);
  const [skipExportData, setSkipExportData] = useState(true);
  const { sortData, ordering } = useSortData();
  const [searchParams] = useSearchParams();

  const queryParams = useMemo(
    () => ({
      dealers: searchParams.get("dealers") || "",
      car_models: searchParams.get("car_models") || "",
      factories: searchParams.get("factories") || "",
      vin_number: searchParams.get("vin_number") || "",
      reservation_number: searchParams.get("reservation_number") || "",
      round: searchParams.get("round") || "",
      date_from: searchParams.get("date_from") || "",
      date_to: searchParams.get("date_to") || "",
      exclude: searchParams.get("exclude") || "",
      grand_total: searchParams.get("grand_total") || "",
    }),
    [searchParams],
  );
  const { data: tableData, isFetching: tableDataIsFetching } =
    orderAPI.useGetDetailedAllocatieDataQuery({
      ...queryParams,
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const { data: exportTableData, isFetching: exportTableDataIsFetching } =
    orderAPI.useGetDetailedAllocatieExportDataQuery(
      { ...queryParams, ordering: ordering },
      { skip: skipExportTable || !tableData?.results.length },
    );
  const { data: exportAllData, isFetching: exportAllDataIsFetching } =
    orderAPI.useGetDetailedAllocatieExportDataQuery(queryParams, {
      skip: skipExportData,
    });

  const AllocatieHeaders: Header[] = [
    { id: "vin", label: constants.VIN, sortable: true },
    {
      id: "sales_document",
      label: constants.RESERVATION_NUMBER,
      sortable: true,
    },
    {
      id: "brand_type_description",
      label: constants.MODEL_DESCRIPTION,
      sortable: true,
    },
    { id: "exterior_colour", label: constants.EXTERIOR_COLOUR, sortable: true },
    { id: "interior_colour", label: constants.INTERIOR_COLOUR, sortable: true },
    { id: "vehicle_usage", label: constants.VEHICLE_USAGE, sortable: true },
    { id: "document_date", label: constants.ALLOCATION_DATE, sortable: true },
  ];

  const isTotalPage = searchParams.get("page_title") === "totaal";

  if (isTotalPage) {
    AllocatieHeaders.push({
      id: "round",
      label: constants.ROUND,
      sortable: true,
    });
  }

  const getValues = (row: DetailedAllocatie) => {
    const values = [
      row.vin,
      row.sales_document,
      row.brand_type_description,
      row.exterior_colour,
      row.interior_colour,
      row.vehicle_usage,
      row.document_date,
    ];
    if (isTotalPage) {
      values.push(row.round);
    }
    return values;
  };

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map((allocate: DetailedAllocatie) => ({
        values: getValues(allocate),
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  function openExportsBlock() {
    setSkipExportTable(false);
    setSkipExportData(true);
    openModal();
  }

  const getPageTitle = () => {
    const car_model = searchParams.get("car_models") || "";
    const page_title = searchParams.get("page_title") || "";

    let title = `${constants.ALLOCATIE}`;

    if (car_model && car_model.split(",").length === 1) {
      title += ` - ${car_model}`;
    }
    if (page_title) {
      title += ` - ${page_title}`;
    }
    return title;
  };

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={getPageTitle()} />
        <Box>
          <LastUpdateBlock fileType="order_allocation" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <MainColorButton
            onClick={() => openExportsBlock()}
            label={constants.EXPORT}
          />
          <BasicModal
            open={open}
            handleClose={() => closeModal()}
            centerPosition
          >
            <>
              <Loader
                isLoading={exportTableDataIsFetching || exportAllDataIsFetching}
              />
              <ExportDataBlock
                exportAllData={exportAllData}
                exportTableData={exportTableData}
                onClose={closeModal}
                exportTableName="allocatie_table"
                exportDataName="allocatie_data"
                skipExportData={skipExportData}
              />
            </>
          </BasicModal>
        </Box>
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={AllocatieHeaders}
        rows={tableRows}
        count={tableData?.count}
        getItemsForPage={(items: number) => setSkip(items)}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        getSortedValue={(value: string) => sortData(value)}
        totalsColSpan={3}
        stickyColumnIndex={2}
      />
    </Box>
  );
}

export default BackOrderAllocatieDetailsPage;
