import { Box, Typography } from "@mui/material";

interface Color {
  text: string;
  color: string;
}

interface ColorsLegendProps {
  colors: Color[];
}

function ColorsLegend({ colors }: ColorsLegendProps) {
  return (
    <Box sx={{ marginTop: "20px" }}>
      {colors.map((color) => (
        <Typography sx={{ marginBottom: 1 }}>
          <div
            style={{
              display: "inline-block",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              backgroundColor: color.color,
              verticalAlign: "middle",
            }}
          ></div>
          <span
            style={{
              display: "inline-block",
              marginLeft: "10px",
              verticalAlign: "middle",
            }}
          >
            {color.text}
          </span>
        </Typography>
      ))}
    </Box>
  );
}

export default ColorsLegend;
