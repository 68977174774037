import { Box, Button, Link, TextField, Typography } from "@mui/material";
import { userAPI } from "api/services/User";
import { AlertInfo, BasicModal, FormWrapper } from "components";
import { useFormik } from "formik";
import useModal from "hooks/useModal";
import { useParams } from "react-router-dom";
import { login_page_link } from "routers/paths";
import {
  CHANGE,
  CHANGE_PASSWORD,
  CONFIRM_NEW_PASSWORD,
  LOG_IN_PAGE,
  NEW_PASSWORD,
  PASSWORD_IS_SAVED,
  RESET_LINK_IS_EXPIRED,
} from "shared/constants";
import { setNewPasswordValidationSchema } from "shared/validation/SetNewPasswordValidationSchema";
import { newPasswordPageStyle } from "./SetNewPasswordPage.style";

function SetNewPasswordPage() {
  const params = useParams();
  const [setNewPassword] = userAPI.useSetNewPasswordMutation();

  const {
    open: openErrorMessage,
    openModal: openErrorMessageModal,
    closeModal: closeErrorModal,
  } = useModal();
  const {
    open: openSucessMessage,
    openModal: openSucessMessageModal,
    closeModal: closeSuccessModal,
  } = useModal();

  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: setNewPasswordValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await setNewPassword({
        key: params.key || "",
        new_password: values.new_password,
        confirm_password: values.confirm_password,
      });
      if ("data" in response && response.data) {
        openSucessMessageModal();
        resetForm();
      }
      if ("error" in response) {
        openErrorMessageModal();
      }
    },
  });

  const { touched, errors } = formik;

  const {
    backgroundStyle,
    inputStyle,
    buttonStyle,
    titleStyle,
    logInLinkStyle,
  } = newPasswordPageStyle;

  return (
    <Box sx={backgroundStyle}>
      <FormWrapper handleSubmit={formik.handleSubmit}>
        <Typography variant="regular" sx={titleStyle}>
          {" "}
          {CHANGE_PASSWORD}
        </Typography>
        <BasicModal open={openSucessMessage} handleClose={closeSuccessModal}>
          <AlertInfo message={PASSWORD_IS_SAVED} />
        </BasicModal>
        <BasicModal open={openErrorMessage} handleClose={closeErrorModal}>
          <AlertInfo message={RESET_LINK_IS_EXPIRED} />
        </BasicModal>
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <TextField
              type="password"
              label={NEW_PASSWORD}
              variant="outlined"
              sx={inputStyle}
              helperText={
                touched.new_password && errors.new_password
                  ? errors.new_password
                  : null
              }
              error={!!(touched.new_password && errors.new_password)}
              {...formik.getFieldProps("new_password")}
            />
            <TextField
              type="password"
              label={CONFIRM_NEW_PASSWORD}
              variant="outlined"
              sx={inputStyle}
              helperText={
                touched.confirm_password && errors.confirm_password
                  ? errors.confirm_password
                  : null
              }
              error={!!(touched.confirm_password && errors.confirm_password)}
              {...formik.getFieldProps("confirm_password")}
            />
          </Box>
          <Button variant="main_button" type="submit" sx={buttonStyle}>
            {CHANGE}
          </Button>
          <Box sx={{ display: "flex" }}>
            <Link sx={logInLinkStyle} href={login_page_link}>
              {LOG_IN_PAGE}
            </Link>
          </Box>
        </Box>
      </FormWrapper>
    </Box>
  );
}

export default SetNewPasswordPage;
