import { DropDownButton } from "components";
import { Item } from "components/DropDownButton/DropDownButton";
import {
  performance_overview_link,
  allocation_overview_page_link,
  demo_and_showroom_overview_page_link,
} from "routers/paths";
import {
  PERFORMANCE_OVERVIEW,
  OVERVIEW,
  ALLOCATION,
  DEMO_AND_SHOWROOM_OVERVIEW,
} from "shared/constants";
import { ModalHandles } from "hooks/useModal";

interface performanceNavProps extends ModalHandles {}

function OverviewNav({ open, openModal, closeModal }: performanceNavProps) {
  const performanceOverviewList: Array<Item> = [];
  performanceOverviewList.push(
    {
      label: ALLOCATION,
      link: allocation_overview_page_link,
      title: OVERVIEW,
    },
    {
      label: PERFORMANCE_OVERVIEW,
      link: performance_overview_link,
      title: OVERVIEW,
    },
    {
      label: DEMO_AND_SHOWROOM_OVERVIEW,
      link: demo_and_showroom_overview_page_link,
      title: OVERVIEW,
    },
  );
  return (
    <DropDownButton
      label={OVERVIEW}
      onClose={closeModal}
      open={open}
      onOpen={openModal}
      list={performanceOverviewList}
    />
  );
}

export default OverviewNav;
