import { Box } from "@mui/material";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart,
} from "recharts";
import {
  darkBlue,
  mainColor,
  orangeColor,
  performanceBlue,
} from "shared/colors";
import {
  CARS_TO_SELL,
  RETAILS_OVER_120,
  SOLD_CARS,
  TARGET,
  TOTAL,
} from "shared/constants";
import { tooltipStyle } from "./Chart.style";

interface chartProps {
  chartData: any;
}

interface payloadProp {
  color: string;
  dataKey: string;
  fill: string;
  name: string;
  payload: {
    carsToSell: number;
    month: string;
    monthGoal: number;
    soldAboveTarget: number;
    soldCars: number;
  };
  style: object;
  value: number;
}

interface tooltipProps {
  active?: boolean;
  payload?: Array<payloadProp>;
  label?: string;
}

function Chart({ chartData }: chartProps) {
  const renderLegendText = (value: string) => {
    return <span style={{ paddingRight: "100px" }}>{value}</span>;
  };

  const CustomTooltip = ({ active, payload, label }: tooltipProps) => {
    const { containerStyle, labelStyle, payloadStyle } = tooltipStyle;

    if (active && payload && payload.length) {
      return (
        <Box sx={containerStyle}>
          <Box sx={labelStyle}>{label}</Box>
          <div>
            {payload.map((payloadDetail) => (
              <>
                <Box sx={{ ...payloadStyle, color: payloadDetail.fill }}>
                  {payloadDetail.name}: {payloadDetail.value}
                </Box>
                {payloadDetail.dataKey === "soldAboveTarget" && (
                  <Box sx={payloadStyle}>
                    {TOTAL}:{" "}
                    {payloadDetail.payload.soldAboveTarget +
                      payloadDetail.payload.soldCars}
                  </Box>
                )}
              </>
            ))}
          </div>
        </Box>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={chartData}
        margin={{
          top: 20,
          right: -10,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3" vertical={false} />
        <XAxis dataKey="month" />
        <YAxis tickLine={false} axisLine={false} />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          style={{ paddingRight: "100px" }}
          formatter={renderLegendText}
        />
        <Bar
          dataKey="soldCars"
          name={SOLD_CARS}
          stackId="a"
          fill={mainColor}
          barSize={80}
          style={{
            filter: `drop-shadow(3px 0px 3px ${mainColor})`,
          }}
        />
        <Bar
          dataKey="carsToSell"
          barSize={80}
          name={CARS_TO_SELL}
          stackId="a"
          fill={darkBlue}
          style={{
            filter: `drop-shadow(3px 0px 3px ${darkBlue})`,
          }}
        />
        <Bar
          dataKey="soldAboveTarget"
          name={RETAILS_OVER_120}
          stackId="a"
          fill={performanceBlue}
          style={{
            filter: `drop-shadow(3px 0px 3px ${performanceBlue})`,
          }}
        />
        <Line
          type="linear"
          dataKey="monthGoal"
          stroke={orangeColor}
          name={TARGET}
          fill={orangeColor}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default Chart;
