import { Alert, Box } from "@mui/material";
import {
  CustomButton,
  FiltersBlock,
  DemoShowRoomModalWindow,
} from "components";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import PageTitle from "components/PageTitle/PageTitle";
import * as constants from "shared/constants";
import { useMemo, useState } from "react";
import { useSortData } from "hooks/useSortData";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { DemoShowRoomRequestsData } from "models/Tables";
import CustomTable from "components/tables/CustomTable/CustomTable";
import { Header } from "components/tables/interfaces";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { dispatchFilterChangedEvent } from "shared/events";

const statusTranslationMap: Record<string, string> = {
  pending: constants.PENDING,
  approved: constants.APPROVED,
  rejected: constants.REJECTED,
  error: constants.ERROR,
};

export function translateStatus(status: string): string {
  return statusTranslationMap[status?.toLowerCase()] || status;
}

function DemoShowRoomRequestsPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const [dealers, setDealers] = useState<Array<OptionsProps>>([]);
  const [brands, setBrands] = useState<Array<OptionsProps>>([]);
  const [statuses, setStatuses] = useState<Array<OptionsProps>>([]);
  const [soorts, setSoorts] = useState<Array<OptionsProps>>([]);
  const [reservationNumbers, setReservationNumbers] = useState<Array<string>>(
    [],
  );
  const [vinNumbers, setVinNumbers] = useState<Array<string>>([]);
  const { sortData, ordering } = useSortData();
  const [openModal, setOpenModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

  const handleOpen = () => setOpenModal(true);

  const queryParams = useMemo(() => {
    dispatchFilterChangedEvent();
    return {
      dealer: dealers.map((item: OptionsProps) => item.id).toString(),
      car_model: brands.map((item: OptionsProps) => item.id).toString(),
      soort: soorts.map((item: OptionsProps) => item.id).toString(),
      status: statuses.map((item: OptionsProps) => item.id).toString(),
      reservation_number: reservationNumbers.toString(),
      vin_number: vinNumbers.toString(),
    };
  }, [brands, dealers, reservationNumbers, soorts, statuses, vinNumbers]);
  const {
    data: tableData,
    isFetching: tableDataIsFetching,
    refetch,
  } = dealerPerformanceAPI.useGetDemoShowroomRequestsDataQuery({
    ...queryParams,
    limit: rowPerPage,
    offset: skip,
    ordering: ordering,
  });
  const [deleteRequest] =
    dealerPerformanceAPI.useDeleteDemoShowRoomRequestMutation({});

  function deleteDemoShowRoomRequest(id: number) {
    deleteRequest({ id: id }).then((response) => {
      refetch();
    });
  }

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map(
        (demoShowRoomRequests: DemoShowRoomRequestsData) => ({
          values: [
            demoShowRoomRequests.dealer_name,
            demoShowRoomRequests.order_number,
            demoShowRoomRequests.vin_code,
            demoShowRoomRequests.soort,
            demoShowRoomRequests.car_model,
            demoShowRoomRequests.vehicle_usage,
            demoShowRoomRequests.created_at,
            demoShowRoomRequests.created_by,
            translateStatus(demoShowRoomRequests.status),
            demoShowRoomRequests.processed_at,
            demoShowRoomRequests.status === "Pending" ? (
              <DeleteForeverIcon
                sx={{ cursor: "pointer" }}
                onClick={(e) =>
                  deleteDemoShowRoomRequest(demoShowRoomRequests.id)
                }
              />
            ) : (
              "-"
            ),
          ],
        }),
      );
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);
  const demoShowRoomRequestsHeaders: Header[] = [
    { id: "dealer_name", label: constants.DEALER, sortable: true },
    { id: "order_number", label: constants.SALES_DOCUMENT, sortable: true },
    { id: "vin_code", label: constants.VIN, sortable: true },
    { id: "soort", label: "Soort", sortable: true },
    { id: "car_model", label: constants.MODEL, sortable: true },
    { id: "vehicle_usage", label: constants.VEHICLE_USAGE, sortable: true },
    { id: "created_at", label: "Created at", sortable: true },
    { id: "created_by", label: "Created by", sortable: true },
    { id: "status", label: constants.STATUS, sortable: true },
    { id: "processed_at", label: "Processed at", sortable: true },
    { id: "cancel_request", label: "Annuleer aanmelding", sortable: false },
  ];

  return (
    <Box sx={{ marginTop: "120px" }}>
      <DemoShowRoomModalWindow
        open={openModal}
        setOpen={setOpenModal}
        setShowSuccessAlert={setShowSuccessAlert}
        setShowErrorAlert={setShowErrorAlert}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title="Demo/Showroom Aanmelden" />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          paddingTop: "20px",
        }}
      >
        <FiltersBlock
          hideCountryFilter
          setSelectedBrands={setBrands}
          setSelectedDealers={setDealers}
          setSelectedReservationNumbers={setReservationNumbers}
          setSelectedVinNumbers={setVinNumbers}
          setSelectedDemoShowRoomRequestsSoorts={setSoorts}
          setSelectedDemoShowRoomRequestsStatuses={setStatuses}
          selectedBrands={brands}
          selectedDealers={dealers}
          selectedReservationNumbers={reservationNumbers}
          selectedVinNumbers={vinNumbers}
          selectedDemoShowRoomRequestsSoorts={soorts}
          selectedDemoShowRoomRequestsStatuses={statuses}
        />
        <CustomButton
          label="Aanmelden demo - of showroom auto"
          onClick={handleOpen}
        />
      </Box>
      {showSuccessAlert && (
        <Alert severity="success">Uw aanvraag is met succes ontvangen</Alert>
      )}
      {showErrorAlert && (
        <Alert severity="error">Unknow error. Request wasn't sent</Alert>
      )}
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={demoShowRoomRequestsHeaders}
        rows={tableRows}
        count={tableData?.count}
        getItemsForPage={(items: number) => setSkip(items)}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        getSortedValue={(value: string) => sortData(value)}
        totalsColSpan={3}
      />
    </Box>
  );
}

export default DemoShowRoomRequestsPage;
