import { Box } from "@mui/material";
import LogoHImage from "shared/images/h-logo.png";
import { logoHStyle } from "./LogoH.style";

interface LogoHProps {
  bigLogo?: boolean;
  midiumLogo?: boolean;
}

function LogoH({ bigLogo, midiumLogo }: LogoHProps) {
  const { biglogoH, smallHWrapper, midiumlogoH } = logoHStyle;
  return (
    <Box
      sx={(bigLogo && biglogoH) || (midiumLogo && midiumlogoH) || smallHWrapper}
      component="img"
      alt="Logo"
      src={LogoHImage}
    />
  );
}

export default LogoH;
