import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { CustomTable, DateBlock, FiltersBlock } from "components";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import { dateToFilenameFormat, getFilterValue } from "shared/utils";
import { currencyFormatterEuro } from "shared/formatters";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import { useSortData } from "hooks/useSortData";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import { Claim } from "models/Claims";
import DownloadXlsxButton from "components/DownloadXlsxButton/DownloadXlsxButton";
import { dispatchFilterChangedEvent } from "shared/events";

function ClaimsPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const { sortData, ordering } = useSortData();
  const [dealers, setDealers] = useState<Array<OptionsProps>>([]);
  const [brands, setBrands] = useState<Array<OptionsProps>>([]);

  const queryParams = useMemo(() => {
    dispatchFilterChangedEvent();
    return {
      dealer: dealers.map((item: OptionsProps) => item.id).toString(),
      car_model: brands.map((item: OptionsProps) => item.id).toString(),
    };
  }, [brands, dealers]);
  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetClaimsDataQuery({
      ...queryParams,
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const {
    data: exportAllData,
    isFetching: exportAllDataIsFetching,
    isError: exportAllDataIsError,
  } = dealerPerformanceAPI.useGetClaimsExportDataQuery(queryParams);

  const generateDetailsViewURL = (
    page_title: string,
    dealers: Array<string | any> = [],
    car_models: Array<string | any> = [],
    category: string,
    grand_total: Boolean = false,
    page_total: Boolean = false,
  ) => {
    let url =
      `/main/claims-details-page?` +
      `dealers=${dealers.join(",") ? dealers : ""}&` +
      `car_models=${car_models.join(",") ? car_models : ""}&` +
      `category=${category}&` +
      `page_title=${page_title}&`;
    if (grand_total) {
      url += `&grand_total=${grand_total}`;
    }

    if (page_total) {
      url += `&page_total=${page_total}`;
    }
    return url;
  };

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map((claim: Claim) => ({
        values: [
          claim.dealer_name,
          claim.brand_code,
          {
            value: parseFloat(claim.fleet) || 0,
            href: generateDetailsViewURL(
              `${constants.CLAIMS} - ${claim.dealer_name} - Fleet`,
              [claim.dealer_id],
              [claim.brand_code],
              "Fleet",
            ),
          },
          {
            value: parseFloat(claim.campagne) || 0,
            href: generateDetailsViewURL(
              `${constants.CLAIMS} - ${claim.dealer_name} - Campagne`,
              [claim.dealer_id],
              [claim.brand_code],
              "Campagne",
            ),
          },
          {
            value: parseFloat(claim.demo) || 0,
            href: generateDetailsViewURL(
              `${constants.CLAIMS} - ${claim.dealer_name} - Demo`,
              [claim.dealer_id],
              [claim.brand_code],
              "Demo",
            ),
          },
          {
            value: parseFloat(claim.prijsprotectie) || 0,
            href: generateDetailsViewURL(
              `${constants.CLAIMS} - ${claim.dealer_name} - Prijsprotectie`,
              [claim.dealer_id],
              [claim.brand_code],
              "Prijsprotectie",
            ),
          },
          {
            value: parseFloat(claim.others) || 0,
            href: generateDetailsViewURL(
              `${constants.CLAIMS} - ${claim.dealer_name} - Others`,
              [claim.dealer_id],
              [claim.brand_code],
              "Other",
            ),
          },
        ],
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const ClaimsHeaders: Header[] = [
    { id: "dealer_name", label: constants.DEALER, sortable: true },
    { id: "brand_code", label: constants.MODEL, sortable: true },
    { id: "fleet", label: "Fleet", sortable: true },
    { id: "campagne", label: "Campagne", sortable: true },
    { id: "demo", label: "Demo", sortable: true },
    { id: "prijsprotectie", label: "Prijsprotectie", sortable: true },
    { id: "others", label: "Others", sortable: true },
  ];

  const getDealers = () => {
    if (tableData) {
      return [tableData.results.map((claim: Claim) => claim.dealer_id)];
    }
  };

  const getCarModels = () => {
    if (tableData) {
      return [tableData.results.map((claim: Claim) => claim.brand_code)];
    }
  };

  const date = dateToFilenameFormat(new Date());

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={constants.CLAIMS_PAGE} />
        <Box>
          <LastUpdateBlock fileType="claims" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <FiltersBlock
          hideCountryFilter
          setSelectedBrands={setBrands}
          setSelectedDealers={setDealers}
          selectedBrands={brands}
          selectedDealers={dealers}
        />
        <DownloadXlsxButton
          filename={`Claims_data_${date}.xlsx`}
          data={exportAllData}
          isFetching={exportAllDataIsFetching}
          isError={exportAllDataIsError}
        />
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={ClaimsHeaders}
        rows={tableRows}
        count={tableData?.count}
        getItemsForPage={(items: number) => setSkip(items)}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        getSortedValue={(value: string) => sortData(value)}
        pageTotals={
          tableData && [
            {
              value: tableData.page_total.fleet || 0,
              href: generateDetailsViewURL(
                `${constants.CLAIMS} - Fleet - ${constants.PAGE_TOTAL}`,
                getDealers(),
                getCarModels(),
                "Fleet",
                false,
                true,
              ),
            },
            {
              value: tableData.page_total.campagne || 0,
              href: generateDetailsViewURL(
                `${constants.CLAIMS} - Campagne - ${constants.PAGE_TOTAL}`,
                getDealers(),
                getCarModels(),
                "Campagne",
                false,
                true,
              ),
            },
            {
              value: tableData.page_total.demo || 0,
              href: generateDetailsViewURL(
                `${constants.CLAIMS} - Demo - ${constants.PAGE_TOTAL}`,
                getDealers(),
                getCarModels(),
                "Demo",
                false,
                true,
              ),
            },
            {
              value: tableData.page_total.prijsprotectie || 0,
              href: generateDetailsViewURL(
                `${constants.CLAIMS} - Prijsprotectie - ${constants.PAGE_TOTAL}`,
                getDealers(),
                getCarModels(),
                "Prijsprotectie",
                false,
                true,
              ),
            },
            {
              value: tableData.page_total.others || 0,
              href: generateDetailsViewURL(
                `${constants.CLAIMS} - Others - ${constants.PAGE_TOTAL}`,
                getDealers(),
                getCarModels(),
                "Other",
                false,
                true,
              ),
            },
          ]
        }
        grandTotals={
          tableData && [
            {
              value: tableData.full_total.fleet || 0,
              href: generateDetailsViewURL(
                `${constants.CLAIMS} - Fleet - ${constants.TOTAL}`,
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                "Fleet",
                true,
              ),
            },
            {
              value: tableData.full_total.campagne || 0,
              href: generateDetailsViewURL(
                `${constants.CLAIMS} - Campagne - ${constants.TOTAL}`,
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                "Campagne",
                true,
              ),
            },
            {
              value: tableData.full_total.demo || 0,
              href: generateDetailsViewURL(
                `${constants.CLAIMS} - Demo - ${constants.TOTAL}`,
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                "Demo",
                true,
              ),
            },
            {
              value: tableData.full_total.prijsprotectie || 0,
              href: generateDetailsViewURL(
                `${constants.CLAIMS} - Prijsprotectie - ${constants.TOTAL}`,
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                "Prijsprotectie",
                true,
              ),
            },
            {
              value: tableData.full_total.others || 0,
              href: generateDetailsViewURL(
                `${constants.CLAIMS} - Others - ${constants.TOTAL}`,
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                "Other",
                true,
              ),
            },
          ]
        }
        totalsColSpan={2}
        stickyColumnIndex={1}
        cellFormatters={[
          undefined,
          undefined,
          currencyFormatterEuro,
          currencyFormatterEuro,
          currencyFormatterEuro,
          currencyFormatterEuro,
          currencyFormatterEuro,
        ]}
      />
    </Box>
  );
}

export default ClaimsPage;
