import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  CustomTable,
  FiltersBlock,
  MainColorButton,
  BasicModal,
  ExportDataBlock,
  DateBlock,
  Loader,
} from "components";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { Stock } from "models/Stock";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import useModal from "hooks/useModal";
import { useSortData } from "hooks/useSortData";
import { getPercentageValue } from "helper/getPercentageValue";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import { dispatchFilterChangedEvent } from "shared/events";

function StockPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const [dealers, setDealers] = useState<Array<OptionsProps>>([]);
  const { sortData, ordering } = useSortData();
  const [brands, setBrands] = useState<Array<OptionsProps>>([]);
  const [factories, setFactories] = useState<Array<OptionsProps>>([]);
  const [vinNumbers, setVinNumbers] = useState<Array<string>>([]);
  const { open, openModal, closeModal } = useModal();
  const [skipExportTable, setSkipExportTable] = useState(true);
  const [skipExportData, setSkipExportData] = useState(true);

  const queryParams = useMemo(() => {
    dispatchFilterChangedEvent();
    return {
      dealer: dealers.map((item: OptionsProps) => item.id).toString(),
      car_model: brands.map((item: OptionsProps) => item.id).toString(),
      factory: factories.map((item: OptionsProps) => item.id).toString(),
      vin_number: vinNumbers.toString(),
    };
  }, [brands, dealers, factories, vinNumbers]);
  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetStockDataQuery({
      ...queryParams,
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const { data: exportTableData, isFetching: exportTableDataIsFetching } =
    dealerPerformanceAPI.useGetStockExportTableQuery(
      { ...queryParams, ordering: ordering },
      { skip: skipExportTable || !tableData?.results.length },
    );
  const { data: exportAllData, isFetching: exportAllDataIsFetching } =
    dealerPerformanceAPI.useGetStockExportDataQuery(queryParams, {
      skip: skipExportData,
    });

  const stockHeaders: Header[] = [
    { id: "dealer_name", label: constants.DEALER, sortable: true },
    { id: "factory", label: constants.FACTORY, sortable: true },
    { id: "brand_code", label: constants.MODEL, sortable: true },
    { id: "total_count", label: constants.POTENTIAL, sortable: true },
    {
      id: "stockAging",
      label: constants.STOCK_AGING,
      subHeaders: [
        {
          label: constants.FROM_0_T0_90,
          id: "aging_0_90",
          sortable: true,
        },
        {
          label: constants.FROM_91_T0_180,
          id: "aging_91_180",
          sortable: true,
        },
        {
          label: constants.FROM_181_T0_270,
          id: "aging_181_270",
          sortable: true,
        },
        {
          label: constants.FROM_271_T0_365,
          id: "aging_271_365",
          sortable: true,
        },
        {
          label: constants.FROM_366,
          id: "aging_366",
          sortable: true,
        },
      ],
    },
    { id: "old_stock", label: constants.OLD_STOCK, sortable: true },
    { id: "old_rate", label: constants.OLD_RATE },
  ];

  const generateDetailsViewURL = (
    page_title: string,
    age_from: string | null,
    age_to: string | null,
    dealers: Array<string | any> = [],
    car_models: Array<string | any> = [],
    factories: Array<string | any> = [],
    exclude: object = {},
    grand_total: Boolean = false,
  ) => {
    let url =
      `/main/stock-details-page?` +
      `dealers=${dealers.join(",") ? dealers : ""}&` +
      `car_models=${car_models.join(",") ? car_models : ""}&` +
      `factories=${factories.join(",") ? factories : ""}&` +
      `vin_number=${vinNumbers.join(",") ? vinNumbers : ""}&` +
      `page_title=${page_title}&`;

    if (age_from) {
      url += `age_from=${age_from}&`;
    }
    if (age_to) {
      url += `age_to=${age_to}&`;
    }

    const exclude_filters = JSON.stringify(exclude);
    if (exclude_filters !== "{}") {
      url += `&exclude=${exclude_filters}`;
    }
    if (grand_total) {
      url += `&grand_total=${grand_total}`;
    }
    return url;
  };

  const getFrom = (range: string, separator: string = "-") => {
    return range.split(separator)[0];
  };

  const getTo = (range: string, separator: string = "-") => {
    return range.split(separator)[1];
  };

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map((stock: Stock) => ({
        values: [
          stock.dealer_name,
          stock.factory,
          stock.brand_code,
          {
            value: stock.total_count,
            href: generateDetailsViewURL(
              constants.POTENTIAL,
              null,
              null,
              [stock.dealerID],
              [stock.brand_code],
              [stock.factory],
            ),
          },
          {
            value: stock.aging_0_90,
            href: generateDetailsViewURL(
              constants.STOCK_AGING,
              getFrom(constants.FROM_0_T0_90),
              getTo(constants.FROM_0_T0_90),
              [stock.dealerID],
              [stock.brand_code],
              [stock.factory],
            ),
          },
          {
            value: stock.aging_91_180,
            href: generateDetailsViewURL(
              constants.STOCK_AGING,
              getFrom(constants.FROM_91_T0_180),
              getTo(constants.FROM_91_T0_180),
              [stock.dealerID],
              [stock.brand_code],
              [stock.factory],
            ),
          },
          {
            value: stock.aging_181_270,
            href: generateDetailsViewURL(
              constants.STOCK_AGING,
              getFrom(constants.FROM_181_T0_270),
              getTo(constants.FROM_181_T0_270),
              [stock.dealerID],
              [stock.brand_code],
              [stock.factory],
            ),
          },
          {
            value: stock.aging_271_365,
            href: generateDetailsViewURL(
              constants.STOCK_AGING,
              getFrom(constants.FROM_271_T0_365),
              getTo(constants.FROM_271_T0_365),
              [stock.dealerID],
              [stock.brand_code],
              [stock.factory],
            ),
          },
          {
            value: stock.aging_366,
            href: generateDetailsViewURL(
              constants.STOCK_AGING,
              getFrom(constants.FROM_366, "+"),
              getTo(constants.FROM_366, "+"),
              [stock.dealerID],
              [stock.brand_code],
              [stock.factory],
            ),
          },
          {
            value: stock.old_stock,
            href: generateDetailsViewURL(
              constants.OLD_STOCK,
              getFrom(constants.FROM_181, "+"),
              getTo(constants.FROM_181, "+"),
              [stock.dealerID],
              [stock.brand_code],
              [stock.factory],
            ),
          },
          getPercentageValue(stock.old_rate),
        ],
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const getDealers = () => {
    if (tableData) {
      return [
        tableData.results.map((reservation: Stock) => reservation.dealerID),
      ];
    }
  };
  const getCarModels = () => {
    if (tableData) {
      return [
        tableData.results.map((reservation: Stock) => reservation.brand_code),
      ];
    }
  };
  const getFactories = () => {
    if (tableData) {
      return [
        tableData.results.map((reservation: Stock) => reservation.factory),
      ];
    }
  };
  const getFilterValue = (items: Array<any>, attr: string = "label") => {
    return items.map((item: any) => item[attr]);
  };

  function openExportsBlock() {
    setSkipExportTable(false);
    setSkipExportData(false);
    openModal();
  }

  return (
    <Box sx={{ marginTop: "120px" }}>
      <PageTitle title={constants.RETAIL_POTENTIAL} />
      <Box>
        <LastUpdateBlock fileType="stock" />
        <DateBlock />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <FiltersBlock
          hideCountryFilter
          setSelectedBrands={setBrands}
          setSelectedFactories={setFactories}
          setSelectedDealers={setDealers}
          setSelectedVinNumbers={setVinNumbers}
          selectedBrands={brands}
          selectedFactories={factories}
          selectedDealers={dealers}
          selectedVinNumbers={vinNumbers}
        />
        <MainColorButton
          onClick={() => openExportsBlock()}
          label={constants.EXPORT}
        />
        <BasicModal open={open} handleClose={() => closeModal()} centerPosition>
          <>
            <Loader
              isLoading={exportTableDataIsFetching || exportAllDataIsFetching}
            />
            <ExportDataBlock
              exportAllData={exportAllData}
              exportTableData={exportTableData}
              onClose={closeModal}
              exportTableName="Stock_table"
              exportDataName="Stock_data"
            />
          </>
        </BasicModal>
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={stockHeaders}
        rows={tableRows}
        count={tableData?.count}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        pageTotals={
          tableData && [
            {
              value: tableData.page_total.total,
              href: generateDetailsViewURL(
                `${constants.PAGE_TOTAL} - ${constants.POTENTIAL}`,
                null,
                null,
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.aging_0_90,
              href: generateDetailsViewURL(
                `${constants.PAGE_TOTAL} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_0_T0_90),
                getTo(constants.FROM_0_T0_90),
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.aging_91_180,
              href: generateDetailsViewURL(
                `${constants.PAGE_TOTAL} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_91_T0_180),
                getTo(constants.FROM_91_T0_180),
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.aging_181_270,
              href: generateDetailsViewURL(
                `${constants.PAGE_TOTAL} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_181_T0_270),
                getTo(constants.FROM_181_T0_270),
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.aging_271_365,
              href: generateDetailsViewURL(
                `${constants.PAGE_TOTAL} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_271_T0_365),
                getTo(constants.FROM_271_T0_365),
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.aging_366,
              href: generateDetailsViewURL(
                `${constants.PAGE_TOTAL} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_366, "+"),
                getTo(constants.FROM_366, "+"),
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            {
              value: tableData.page_total.old_stock,
              href: generateDetailsViewURL(
                `${constants.PAGE_TOTAL} - ${constants.OLD_STOCK}`,
                getFrom(constants.FROM_181, "+"),
                getTo(constants.FROM_181, "+"),
                getDealers(),
                getCarModels(),
                getFactories(),
              ),
            } || 0,
            tableData.page_total.old_rate
              ? getPercentageValue(tableData.page_total.old_rate)
              : "0%",
          ]
        }
        grandTotals={
          tableData && [
            {
              value: tableData.full_total.total,
              href: generateDetailsViewURL(
                `${constants.TOTAL} - ${constants.POTENTIAL}`,
                null,
                null,
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.aging_0_90,
              href: generateDetailsViewURL(
                `${constants.TOTAL} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_0_T0_90),
                getTo(constants.FROM_0_T0_90),
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.aging_91_180,
              href: generateDetailsViewURL(
                `${constants.TOTAL} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_91_T0_180),
                getTo(constants.FROM_91_T0_180),
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.aging_181_270,
              href: generateDetailsViewURL(
                `${constants.TOTAL} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_181_T0_270),
                getTo(constants.FROM_181_T0_270),
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.aging_271_365,
              href: generateDetailsViewURL(
                `${constants.TOTAL} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_271_T0_365),
                getTo(constants.FROM_271_T0_365),
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.aging_366,
              href: generateDetailsViewURL(
                `${constants.TOTAL} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_366, "+"),
                getTo(constants.FROM_366, "+"),
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            {
              value: tableData.full_total.old_stock,
              href: generateDetailsViewURL(
                `${constants.TOTAL} - ${constants.OLD_STOCK}`,
                getFrom(constants.FROM_181, "+"),
                getTo(constants.FROM_181, "+"),
                getFilterValue(dealers, "id"),
                getFilterValue(brands),
                getFilterValue(factories),
                {},
                true,
              ),
            } || 0,
            tableData.full_total.old_rate
              ? getPercentageValue(tableData.full_total.old_rate)
              : "0%",
          ]
        }
        differenceTotals={
          tableData && [
            {
              value: tableData.difference_with_page.total,
              href: generateDetailsViewURL(
                `${constants.OTHER} - ${constants.POTENTIAL}`,
                null,
                null,
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.aging_0_90,
              href: generateDetailsViewURL(
                `${constants.OTHER} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_0_T0_90),
                getTo(constants.FROM_0_T0_90),
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.aging_91_180,
              href: generateDetailsViewURL(
                `${constants.OTHER} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_91_T0_180),
                getTo(constants.FROM_91_T0_180),
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.aging_181_270,
              href: generateDetailsViewURL(
                `${constants.OTHER} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_181_T0_270),
                getTo(constants.FROM_181_T0_270),
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.aging_271_365,
              href: generateDetailsViewURL(
                `${constants.OTHER} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_271_T0_365),
                getTo(constants.FROM_271_T0_365),
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.aging_366,
              href: generateDetailsViewURL(
                `${constants.OTHER} - ${constants.STOCK_AGING}`,
                getFrom(constants.FROM_366, "+"),
                getTo(constants.FROM_366, "+"),
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            {
              value: tableData.difference_with_page.old_stock,
              href: generateDetailsViewURL(
                `${constants.OTHER} - ${constants.OLD_STOCK}`,
                getFrom(constants.FROM_181, "+"),
                getTo(constants.FROM_181, "+"),
                getDealers(),
                getCarModels(),
                getFactories(),
                {
                  dealers: getFilterValue(dealers, "id"),
                  car_models: getFilterValue(brands),
                  factories: getFilterValue(factories),
                },
                true,
              ),
            } || 0,
            tableData.difference_with_page.old_rate
              ? getPercentageValue(tableData.difference_with_page.old_rate)
              : "0%",
          ]
        }
        totalsColSpan={3}
        stickyColumnIndex={2}
        getItemsForPage={(items: number) => setSkip(items)}
        getSortedValue={(value: string) => sortData(value)}
      />
    </Box>
  );
}

export default StockPage;
