import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import { authAPI } from "./Auth";
import { carModelAPI } from "./CarModel";
import { dealerAPI } from "./Dealer";
import { dealerPerformanceAPI } from "./DealerPerformance";
import { importerAPI } from "./Importer";
import { orderAPI } from "./Order";
import { userAPI } from "./User";
import { userReducer } from "reducers/userReducer";

const rootReducer = combineReducers({
  [authAPI.reducerPath]: authAPI.reducer,
  [userAPI.reducerPath]: userAPI.reducer,
  [orderAPI.reducerPath]: orderAPI.reducer,
  [dealerAPI.reducerPath]: dealerAPI.reducer,
  [dealerPerformanceAPI.reducerPath]: dealerPerformanceAPI.reducer,
  [carModelAPI.reducerPath]: carModelAPI.reducer,
  [importerAPI.reducerPath]: importerAPI.reducer,
  user: userReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authAPI.middleware)
      .concat(userAPI.middleware)
      .concat(orderAPI.middleware)
      .concat(dealerAPI.middleware)
      .concat(dealerPerformanceAPI.middleware)
      .concat(carModelAPI.middleware)
      .concat(importerAPI.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
