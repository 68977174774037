export const welcome_page_link = "welcome-page";
export const main_page_link = "main";
export const backorder_page_link = "backorder-page";
export const backorder_allocatie_details_page_link = "allocatie-details-page";
export const future_sales_page_link = "future-sales-page";
export const dashboard_page_link = "dashboard-page";
export const dealer_performance_page_link = "dealer-performance-page";
export const change_password_page_link = "change-password-page";
export const forgot_password_page_link = "forgot-password";
export const set_new_password_page_link = "set-new-password/:key";
export const backorder_overview_page_link = "backorder-overview-page";
export const backorder_details_page_link = "backorder-details-page";
export const user_info_page_link = "user-info-page";
export const stock_page_link = "stock-page";
export const stock_details_page_link = "stock-details-page";
export const license_return_page_link = "license-return-page";
export const license_return_details_page_link = "license-return-details-page";
export const registration_page_link = "registration-page";
export const reservation_page_link = "reservation-page";
export const login_page_link = "/";
export const reservation_details_page_link = "reservation-details-page";
export const registration_details_page_link = "registration-details-page";
export const rft_page_link = "rft-page";
export const rft_details_page_link = "rft-details-page";
export const demo_showroom_page_link = "demo-showroom-page";
export const performance_overview_link = "performance-overview-page";
export const demo_showroom_details_page_link = "demo-showroom-details-page";
export const allocation_overview_page_link = "allocation-overview-page";
export const allocation_overview_details_page_link =
  "allocation-overview-details-page";
export const claims_page_link = "claims-page";
export const performance_overview_retail_details_page_link =
  "performance-overview-retail-details-page";
export const performance_overview_license_requests_details_page_link =
  "performance-overview-license-requests-details-page";
export const demo_and_showroom_overview_page_link =
  "demo-and-showroom-overview-page";
export const claims_details_page_link = "claims-details-page";
export const demo_showroom_requests_page_link = "demo-showroom-requests-page";
export const claim_codes_overview_page_link = "claim-codes-overview-page";
export const claim_codes_overview_details_page_link =
  "claim-codes-overview-details-page";
export const free_stock_page_link = "free-stock-page";
