import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import {
  BasicModal,
  CustomTable,
  DateBlock,
  ExportDataBlock,
  FiltersBlock,
  Loader,
  MainColorButton,
} from "components";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import * as validators from "shared/validation/filterValidators";
import * as buildUtils from "shared/validation/buildUtils";
import * as filterFieldsNames from "components/FiltersBlock/fieldsNames";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { Registration } from "models/RegistrationData";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import useModal from "hooks/useModal";
import { useSortData } from "hooks/useSortData";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import dayjs from "dayjs";
import {
  dateToOperationalFormat,
  getFilterValue,
  getFrom,
  getTo,
  mergeObjectOfArrays,
} from "shared/utils";
import { useFormik } from "formik";
import { useGetLastValid } from "hooks/useGetLastValid";
import {
  defaultLazyFieldNames,
  defaultValidators,
} from "shared/validation/filterValidators";
import { dispatchFilterChangedEvent } from "shared/events";

function RegistrationPage() {
  const defaultDateFrom = useMemo(() => dayjs().startOf("month").toDate(), []);
  const defaultDateTo = useMemo(
    () => new Date(new Date().setDate(new Date().getDate() - 1)),
    [],
  );

  const [mustBeTheSameYearAsDateTo, mustBeTheSameYearAsDateFrom] =
    validators.getDateFromAndDateToMustBeTheSameYearValidators();
  const filterSchema = useMemo(
    () =>
      buildUtils.buildObjectSchema(
        mergeObjectOfArrays(defaultValidators, {
          [filterFieldsNames.DATE_FROM]: [mustBeTheSameYearAsDateTo],
          [filterFieldsNames.DATE_TO]: [mustBeTheSameYearAsDateFrom],
        }),
        defaultLazyFieldNames,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const formik = useFormik({
    initialValues: {
      [filterFieldsNames.DATE_FROM]: defaultDateFrom,
      [filterFieldsNames.DATE_TO]: defaultDateTo,
    },
    validate: buildUtils.buildValidateFunc(filterSchema),
    onSubmit: () => {},
  });

  const setDateFrom = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_FROM, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const setDateTo = useCallback(
    (value: any) => formik.setFieldValue(filterFieldsNames.DATE_TO, value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const [dealers, setDealers] = useState<Array<OptionsProps>>([]);
  const [brands, setBrands] = useState<Array<OptionsProps>>([]);
  const [factories, setFactories] = useState<Array<OptionsProps>>([]);
  const [vinNumbers, setVinNumbers] = useState<Array<string>>([]);
  const { open, openModal, closeModal } = useModal();
  const { sortData, ordering } = useSortData();
  const [reservationNumbers, setReservationNumbers] = useState<Array<string>>(
    [],
  );
  const [skipExportTable, setSkipExportTable] = useState(true);
  const [skipExportData, setSkipExportData] = useState(true);
  const [expandedORStatus, setExpandedORStatus] = useState(true);

  const queryParams = useMemo(
    () => ({
      dealer: dealers.map((item: OptionsProps) => item.id).toString(),
      car_model: brands.map((item: OptionsProps) => item.id).toString(),
      factory: factories.map((item: OptionsProps) => item.id).toString(),
      date_from: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_FROM],
      ),
      date_to: dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_TO],
      ),
      reservation_number: reservationNumbers.toString(),
      vin_number: vinNumbers.toString(),
    }),
    [brands, formik.values, dealers, factories, reservationNumbers, vinNumbers],
  );
  const validatedQueryParams = useGetLastValid(
    queryParams,
    formik.isValid,
    formik.isValidating,
  );
  useEffect(() => {
    dispatchFilterChangedEvent();
  }, [validatedQueryParams]);
  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetRegistrationDataQuery({
      ...(validatedQueryParams || queryParams),
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const { data: exportTableData, isFetching: exportTableDataIsFetching } =
    dealerPerformanceAPI.useGetRegistrationExportTableQuery(
      { ...(validatedQueryParams || queryParams), ordering: ordering },
      { skip: skipExportTable || !tableData?.results.length },
    );
  const { data: exportAllData, isFetching: exportAllDataIsFetching } =
    dealerPerformanceAPI.useGetRegistrationExportDataQuery(
      validatedQueryParams || queryParams,
      {
        skip: skipExportData,
      },
    );

  const registrationHeaders: Header[] = [
    { id: "dealer_name", label: constants.DEALER, sortable: true },
    { id: "factory", label: constants.FACTORY, sortable: true },
    { id: "brand_code", label: constants.MODEL, sortable: true },
    { id: "year_sale", label: constants.REGISTRATION_YTD, sortable: true },
    { id: "month_sale", label: constants.REGISTRATION, sortable: true },
    {
      id: "o_r_count",
      label: constants.OR_AGAIN,
      sortable: true,
      expanded: expandedORStatus,
      expandable: true,
      setExpanded: setExpandedORStatus,
      ...(expandedORStatus && {
        subHeaders: [
          {
            label: constants.FROM_0_T0_30,
            id: "aging_0_30",
            sortable: true,
          },
          {
            label: constants.FROM_31_T0_45,
            id: "aging_31_45",
            sortable: true,
          },
          {
            label: constants.FROM_46_T0_90,
            id: "aging_46_90",
            sortable: true,
          },
          {
            label: constants.FROM_91_T0_180,
            id: "aging_91_180",
            sortable: true,
          },
          {
            label: constants.FROM_181_T0_365,
            id: "aging_181_365",
            sortable: true,
          },
          {
            label: constants.FROM_366,
            id: "aging_366",
            sortable: true,
          },
        ],
      }),
    },
  ];

  const goalsTooltip = (monthlyGoal: number, annualGoal: number) => (
    <Box sx={{ fontSize: "14px" }}>
      <Box sx={{ padding: "4px 10px" }}>
        {constants.CURRENT_MONTH_REGISTRATIONS}: {monthlyGoal}
      </Box>
      <Box sx={{ padding: "4px 10px" }}>
        {constants.CURRENT_YEAR_REGISTRATIONS}: {annualGoal}
      </Box>
    </Box>
  );

  const generateDetailsViewURL = (
    page_title: string,
    age_from: string | null,
    age_to: string | null,
    dealers: Array<string | any> = [],
    car_models: Array<string | any> = [],
    factories: Array<string | any> = [],
    or_status: boolean = true,
    sale_type: string | null = null,
    exclude: object = {},
    grand_total: Boolean = false,
  ) => {
    let url =
      `/main/registration-details-page?` +
      `dealers=${dealers.join(",") ? dealers : ""}&` +
      `car_models=${car_models.join(",") ? car_models : ""}&` +
      `factories=${factories.join(",") ? factories : ""}&` +
      `reservation_number=${
        reservationNumbers.join(",") ? reservationNumbers : ""
      }&` +
      `vin_number=${vinNumbers.join(",") ? vinNumbers : ""}&` +
      `page_title=${page_title}&` +
      `or_status=${or_status}&` +
      `sale_type=${sale_type}&` +
      `date_from=${dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_FROM],
      )}&` +
      `date_to=${dateToOperationalFormat(
        formik.values[filterFieldsNames.DATE_TO],
      )}`;

    if (age_from) {
      url += `&age_from=${age_from}`;
    }

    if (age_to) {
      url += `&age_to=${age_to}`;
    }

    const exclude_filters = JSON.stringify(exclude);
    if (exclude_filters !== "{}") {
      url += `&exclude=${exclude_filters}`;
    }
    if (grand_total) {
      url += `&grand_total=${grand_total}`;
    }
    return url;
  };

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map((registration: Registration) => ({
        values: [
          <Tooltip
            title={goalsTooltip(
              registration.current_month_sale,
              registration.current_year_sale,
            )}
            arrow
          >
            <div>{registration.dealer_name}</div>
          </Tooltip>,
          registration.factory,
          registration.brand_code,
          {
            value: registration.year_sale,
            href: generateDetailsViewURL(
              `Huidig jaar: Performance`,
              null,
              null,
              [registration.dealerID],
              [registration.brand_code],
              [registration.factory],
              false,
              "year",
            ),
          } || 0,
          {
            value: registration.month_sale,
            href: generateDetailsViewURL(
              `Performance`,
              null,
              null,
              [registration.dealerID],
              [registration.brand_code],
              [registration.factory],
              false,
              "month",
            ),
          } || 0,
          ...(expandedORStatus
            ? [
                {
                  value: registration.aging_0_30,
                  href: generateDetailsViewURL(
                    `OR status: ${constants.FROM_0_T0_30}`,
                    getFrom(constants.FROM_0_T0_30),
                    getTo(constants.FROM_0_T0_30),
                    [registration.dealerID],
                    [registration.brand_code],
                    [registration.factory],
                  ),
                } || 0,
                {
                  value: registration.aging_31_45,
                  href: generateDetailsViewURL(
                    `OR status: ${constants.FROM_31_T0_45}`,
                    getFrom(constants.FROM_31_T0_45),
                    getTo(constants.FROM_31_T0_45),
                    [registration.dealerID],
                    [registration.brand_code],
                    [registration.factory],
                  ),
                } || 0,
                {
                  value: registration.aging_46_90,
                  href: generateDetailsViewURL(
                    `OR status: ${constants.FROM_46_T0_90}`,
                    getFrom(constants.FROM_46_T0_90),
                    getTo(constants.FROM_46_T0_90),
                    [registration.dealerID],
                    [registration.brand_code],
                    [registration.factory],
                  ),
                } || 0,
                {
                  value: registration.aging_91_180,
                  href: generateDetailsViewURL(
                    `OR status: ${constants.FROM_91_T0_180}`,
                    getFrom(constants.FROM_91_T0_180),
                    getTo(constants.FROM_91_T0_180),
                    [registration.dealerID],
                    [registration.brand_code],
                    [registration.factory],
                  ),
                } || 0,
                {
                  value: registration.aging_181_365,
                  href: generateDetailsViewURL(
                    `OR status: ${constants.FROM_181_T0_365}`,
                    getFrom(constants.FROM_181_T0_365),
                    getTo(constants.FROM_181_T0_365),
                    [registration.dealerID],
                    [registration.brand_code],
                    [registration.factory],
                  ),
                } || 0,
                {
                  value: registration.aging_366,
                  href: generateDetailsViewURL(
                    `OR status: ${constants.FROM_366}`,
                    "366",
                    null,
                    [registration.dealerID],
                    [registration.brand_code],
                    [registration.factory],
                  ),
                } || 0,
              ]
            : [
                {
                  value: registration.o_r_count,
                  href: generateDetailsViewURL(
                    `OR status`,
                    null,
                    null,
                    [registration.dealerID],
                    [registration.brand_code],
                    [registration.factory],
                  ),
                } || 0,
              ]),
        ],
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, expandedORStatus]);

  function openExportsBlock() {
    setSkipExportTable(false);
    setSkipExportData(false);
    openModal();
  }

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={constants.REGISTRATION} />
        <Box>
          <LastUpdateBlock fileType="license_requests" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <FiltersBlock
          hideCountryFilter
          setSelectedBrands={setBrands}
          setSelectedFactories={setFactories}
          setSelectedDealers={setDealers}
          setSelectedReservationNumbers={setReservationNumbers}
          setSelectedVinNumbers={setVinNumbers}
          defaultDateFrom={defaultDateFrom}
          defaultDateTo={defaultDateTo}
          setSelectedDateFrom={setDateFrom}
          setSelectedDateTo={setDateTo}
          selectedBrands={brands}
          selectedFactories={factories}
          selectedDealers={dealers}
          selectedReservationNumbers={reservationNumbers}
          selectedVinNumbers={vinNumbers}
          errors={formik.errors as { [field: string]: string[] }}
        />
        <MainColorButton
          onClick={() => openExportsBlock()}
          label={constants.EXPORT}
        />
        <BasicModal open={open} handleClose={() => closeModal()} centerPosition>
          <>
            <Loader
              isLoading={exportTableDataIsFetching || exportAllDataIsFetching}
            />
            <ExportDataBlock
              exportAllData={exportAllData}
              exportTableData={exportTableData}
              onClose={closeModal}
              exportTableName="Registration_table"
              exportDataName="Registration_data"
            />
          </>
        </BasicModal>
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={registrationHeaders}
        rows={tableRows}
        count={tableData?.count}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        customTotals={
          tableData
            ? [
                {
                  title: constants.GRAND_TOTAL,
                  totals: [
                    {
                      value: tableData.full_total.year_sale,
                      href: generateDetailsViewURL(
                        `Huidig jaar: Total performance`,
                        null,
                        null,
                        getFilterValue(dealers, "id"),
                        getFilterValue(brands),
                        getFilterValue(factories),
                        false,
                        "year",
                        {},
                        true,
                      ),
                    } || 0,
                    {
                      value: tableData.full_total.month_sale,
                      href: generateDetailsViewURL(
                        `Total performance`,
                        null,
                        null,
                        getFilterValue(dealers, "id"),
                        getFilterValue(brands),
                        getFilterValue(factories),
                        false,
                        "month",
                        {},
                        true,
                      ),
                    } || 0,
                    ...(expandedORStatus
                      ? [
                          {
                            value: tableData.full_total.aging_0_30,
                            href: generateDetailsViewURL(
                              `OR status: ${constants.FROM_0_T0_30}`,
                              getFrom(constants.FROM_0_T0_30),
                              getTo(constants.FROM_0_T0_30),
                              getFilterValue(dealers, "id"),
                              getFilterValue(brands),
                              getFilterValue(factories),
                              true,
                              null,
                              {},
                              true,
                            ),
                          } || 0,
                          {
                            value: tableData.full_total.aging_31_45,
                            href: generateDetailsViewURL(
                              `OR status: ${constants.FROM_31_T0_45}`,
                              getFrom(constants.FROM_31_T0_45),
                              getTo(constants.FROM_31_T0_45),
                              getFilterValue(dealers, "id"),
                              getFilterValue(brands),
                              getFilterValue(factories),
                              true,
                              null,
                              {},
                              true,
                            ),
                          } || 0,
                          {
                            value: tableData.full_total.aging_46_90,
                            href: generateDetailsViewURL(
                              `OR status: ${constants.FROM_46_T0_90}`,
                              getFrom(constants.FROM_46_T0_90),
                              getTo(constants.FROM_46_T0_90),
                              getFilterValue(dealers, "id"),
                              getFilterValue(brands),
                              getFilterValue(factories),
                              true,
                              null,
                              {},
                              true,
                            ),
                          } || 0,
                          {
                            value: tableData.full_total.aging_91_180,
                            href: generateDetailsViewURL(
                              `OR status: ${constants.FROM_91_T0_180}`,
                              getFrom(constants.FROM_91_T0_180),
                              getTo(constants.FROM_91_T0_180),
                              getFilterValue(dealers, "id"),
                              getFilterValue(brands),
                              getFilterValue(factories),
                              true,
                              null,
                              {},
                              true,
                            ),
                          } || 0,
                          {
                            value: tableData.full_total.aging_181_365,
                            href: generateDetailsViewURL(
                              `OR status: ${constants.FROM_181_T0_365}`,
                              getFrom(constants.FROM_181_T0_365),
                              getTo(constants.FROM_181_T0_365),
                              getFilterValue(dealers, "id"),
                              getFilterValue(brands),
                              getFilterValue(factories),
                              true,
                              null,
                              {},
                              true,
                            ),
                          } || 0,
                          {
                            value: tableData.full_total.aging_366,
                            href: generateDetailsViewURL(
                              `OR status: ${constants.FROM_366}`,
                              "366",
                              null,
                              getFilterValue(dealers, "id"),
                              getFilterValue(brands),
                              getFilterValue(factories),
                              true,
                              null,
                              {},
                              true,
                            ),
                          } || 0,
                        ]
                      : [
                          {
                            value: tableData.full_total.o_r_count,
                            href: generateDetailsViewURL(
                              `OR status: Totaal`,
                              null,
                              null,
                              getFilterValue(dealers, "id"),
                              getFilterValue(brands),
                              getFilterValue(factories),
                              true,
                              null,
                              {},
                              true,
                            ),
                          },
                        ]),
                  ],
                },
              ]
            : []
        }
        totalsColSpan={3}
        stickyColumnIndex={2}
        getItemsForPage={(items: number) => setSkip(items)}
        getSortedValue={(value: string) => sortData(value)}
      />
    </Box>
  );
}

export default RegistrationPage;
