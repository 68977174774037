import * as yup from "yup";
import * as messages from "shared/messages";

const concatSchemas = (
  schemas: readonly (yup.AnySchema | (() => yup.AnySchema))[],
) =>
  schemas.reduce((resultingSchema: yup.AnySchema, schema) => {
    resultingSchema = resultingSchema.concat(
      schema instanceof Function ? schema() : schema,
    );
    return resultingSchema;
  }, yup.mixed());

const applySchemaCommonChecks = (
  schema: yup.AnySchema,
  isOptional: boolean,
) => {
  switch (schema.describe().type) {
    case "date":
      schema = schema.typeError(messages.INVALID_DATE_FORMAT);
      break;
  }

  return isOptional
    ? schema.notRequired()
    : schema.required(messages.FIELD_IS_REQUIRED);
};

export const buildObjectSchema = (
  fieldsSchemas: {
    [fieldName: string]: readonly (yup.AnySchema | (() => yup.AnySchema))[];
  },
  lazyFields?: readonly string[],
  optionalFields?: readonly string[],
): yup.ObjectSchema<{ [p: string]: any }> => {
  return yup.object().shape(
    Object.entries(fieldsSchemas).reduce(
      (
        resultingSchema: {
          [fieldName: string]: yup.AnySchema | yup.Lazy<any>;
        },
        [fieldName, fieldSchemas],
      ) => {
        const buildResultingFieldSchema = () =>
          applySchemaCommonChecks(
            concatSchemas(fieldSchemas),
            Boolean(optionalFields?.includes(fieldName)),
          );
        resultingSchema[fieldName] = lazyFields?.includes(fieldName)
          ? yup.lazy(buildResultingFieldSchema)
          : buildResultingFieldSchema();
        return resultingSchema;
      },
      {},
    ),
    [["dateFrom", "dateTo"]],
  );
};

export const buildValidateFunc = (schema: yup.AnySchema) => {
  return async (values: { [fieldName: string]: any }) => {
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      return err.inner.reduce(
        (accumulator: { [fieldName: string]: string[] }, error: any) => {
          accumulator[error.path] = (accumulator[error.path] || []).concat(
            error.errors,
          );
          return accumulator;
        },
        {},
      );
    }
  };
};
