import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import setupDayjs from "./setupDayjs";

setupDayjs();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      if (
        window.confirm(
          "Nieuwe versie beschikbaar! Druk op 'OK' om de website te updaten.",
        )
      ) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
        setTimeout(function () {
          window.location.reload();
        });
      }
    }
  },
});
