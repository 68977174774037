import { mainColor, performanceBlue } from "shared/colors";
import image from "shared/images/mainImage.jpg";

export const newPasswordPageStyle = {
  backgroundStyle: {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    height: "100vh",
  },
  inputStyle: {
    marginBottom: 1,
    marginTop: 2,
    width: { xs: "316px", sm: "416px" },
    fontFamily: "HyundaiFontHead",
  },
  buttonStyle: {
    marginTop: 2,
    marginBottom: 3,
    width: { xs: "316px", sm: "416px" },
    backgroundColor: performanceBlue,
    border: `1px solid ${mainColor}`,
  },
  titleStyle: {
    textTransform: "uppercase",
    color: mainColor,
    marginBottom: "32px",
    paddingBottom: "16px",
    marginTop: "24px",
    borderBottom: `2px solid ${mainColor}`,
    fontWight: 500,
  },
  logInLinkStyle: {
    margin: "0 auto",
  },
};
