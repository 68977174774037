import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  TooltipProps,
  DefaultTooltipContent,
} from "recharts";
import { mainColor, darkBlue, performanceBlue } from "shared/colors";
import { TOTAL_RESERVATION, SOLD, FLEET, OTHER } from "shared/constants";
import { useMemo } from "react";

interface chartProps {
  chartData: any;
}

function ReservationGraph({ chartData }: chartProps) {
  const renderLegendText = (value: string) => {
    return <span style={{ paddingRight: "100px" }}>{value}</span>;
  };

  const tooltipOrder = useMemo(() => {
    if (chartData && chartData[0]) {
      return Object.keys(chartData[0]);
    }
    return [];
  }, [chartData]);

  const renderTooltip = (props: TooltipProps<any, string>) => {
    if (!props.active || !props.payload?.length) {
      return null;
    }

    if (
      props.payload.findIndex((value) => value.name === TOTAL_RESERVATION) ===
      -1
    ) {
      const totalPayload = {
        ...props.payload[0],
        name: TOTAL_RESERVATION,
        dataKey: "totalReservation",
        get value(): number {
          return props.payload?.[0].payload[this.dataKey] || 0;
        },
        color: "black",
        fill: "black",
      };
      props.payload.push(totalPayload);
    }
    return <DefaultTooltipContent {...props} />;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={chartData}
        margin={{
          top: 20,
          right: -10,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3" vertical={false} />
        <XAxis dataKey="month" />
        <YAxis tickLine={false} axisLine={false} />
        <Tooltip
          itemSorter={(i) =>
            tooltipOrder.indexOf(i.dataKey ? i.dataKey.toString() : "")
          }
          content={renderTooltip}
        />
        <Legend
          style={{ paddingRight: "100px" }}
          formatter={renderLegendText}
          payload={[
            {
              id: "soldCount",
              type: "square",
              value: SOLD,
              color: mainColor,
            },
            {
              id: "fleetCount",
              type: "square",
              value: FLEET,
              color: darkBlue,
            },
            {
              id: "overigCount",
              type: "square",
              value: OTHER,
              color: performanceBlue,
            },
          ]}
        />
        <Bar
          dataKey="soldCount"
          name={SOLD}
          stackId="a"
          fill={mainColor}
          barSize={80}
          style={{
            filter: `drop-shadow(4px 0px 4px ${mainColor})`,
          }}
        />
        <Bar
          dataKey="fleetCount"
          name={FLEET}
          stackId="a"
          fill={darkBlue}
          barSize={80}
          style={{
            filter: `drop-shadow(4px 0px 4px ${mainColor})`,
          }}
        />
        <Bar
          dataKey="overigCount"
          name={OTHER}
          stackId="a"
          fill={performanceBlue}
          barSize={80}
          style={{
            filter: `drop-shadow(4px 0px 4px ${mainColor})`,
          }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default ReservationGraph;
