import { DropDownButton } from "components";
import {
  license_return_page_link,
  registration_page_link,
  reservation_page_link,
  stock_page_link,
  demo_showroom_page_link,
  demo_showroom_requests_page_link,
  free_stock_page_link,
} from "routers/paths";
import {
  LICENSE_RETURN,
  PERFORMANCE,
  REGISTRATION,
  RESERVATION,
  POTENTIAL,
  DEMO_SHOWROOM,
  DEMO_SHOWROOM_OVERVIEW,
  DEMO_SHOWROOM_REQUESTS,
  FREE_STOCK,
} from "shared/constants";
import { ModalHandles } from "hooks/useModal";

interface dealerPerformanceNavProps extends ModalHandles {
  demoShowroomSubmenuHandles: ModalHandles;
}

function DealerPerformanceNav({
  open,
  openModal,
  closeModal,
  demoShowroomSubmenuHandles,
}: dealerPerformanceNavProps) {
  const dealerPerformanceList = [
    {
      label: RESERVATION,
      link: reservation_page_link,
      title: PERFORMANCE,
    },
    {
      label: LICENSE_RETURN,
      link: license_return_page_link,
      title: PERFORMANCE,
    },
    {
      label: POTENTIAL,
      link: stock_page_link,
      title: PERFORMANCE,
    },
    {
      label: FREE_STOCK,
      link: free_stock_page_link,
      title: PERFORMANCE,
    },
    {
      label: REGISTRATION,
      link: registration_page_link,
      title: PERFORMANCE,
    },
    {
      label: DEMO_SHOWROOM,
      list: [
        {
          label: DEMO_SHOWROOM_OVERVIEW,
          link: demo_showroom_page_link,
          title: PERFORMANCE,
        },
        {
          label: DEMO_SHOWROOM_REQUESTS,
          link: demo_showroom_requests_page_link,
          title: PERFORMANCE,
        },
      ],
      title: PERFORMANCE,
      ...demoShowroomSubmenuHandles,
    },
  ];
  return (
    <DropDownButton
      label={PERFORMANCE}
      open={open}
      onOpen={openModal}
      onClose={closeModal}
      list={dealerPerformanceList}
    />
  );
}

export default DealerPerformanceNav;
