export const logoHStyle = {
  smallHWrapper: {
    width: "45px",
    height: "30px",
    marginTop: "6px",
  },
  biglogoH: {
    width: "350px",
    height: "200px",
    margin: "10px 0",
  },
  midiumlogoH: {
    width: "150px",
    margin: "10px 0",
  },
};
