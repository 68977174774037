import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import { useSortData } from "hooks/useSortData";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { useSearchParams } from "react-router-dom";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import { dateToFilenameFormat } from "shared/utils";
import { CustomTable, DateBlock } from "components";
import { DetailedRetailPerformanceOverview } from "models/Tables";
import DownloadXlsxButton from "components/DownloadXlsxButton/DownloadXlsxButton";

function PerformanceOverviewRetailDetailsPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const { sortData, ordering } = useSortData();
  const [searchParams] = useSearchParams();

  const queryParams = useMemo(
    () => ({
      dealers: searchParams.get("dealers") || "",
      dealer_zones: searchParams.get("zones") || "",
      date_from: searchParams.get("day_start") || "",
      date_to: searchParams.get("day_end") || "",
    }),
    [searchParams],
  );
  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetDetailedRetailPerformanceOverviewDataQuery({
      ...queryParams,
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const {
    data: exportTableData,
    isFetching: exportTableDataIsFetching,
    isError: exportTableDataIsError,
  } = dealerPerformanceAPI.useGetDetailedRetailPerformanceOverviewExportDataQuery(
    {
      ...queryParams,
      ordering: ordering,
    },
  );
  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map(
        (stock: DetailedRetailPerformanceOverview) => ({
          values: [
            stock.dealer_name,
            stock.sales_order,
            stock.vin_code,
            stock.model_description,
            stock.vehicle_usage,
            stock.description_of_vehicle_location,
          ],
        }),
      );
    }
    return [];
  }, [tableData]);

  const performance_overview_details_headers: Header[] = [
    { id: "dealer_name", label: "Dealer", sortable: true },
    { id: "sales_order", label: constants.RESERVATION_NUMBER, sortable: true },
    { id: "vin_code", label: constants.VIN, sortable: true },
    {
      id: "model_description",
      label: constants.MODEL_DESCRIPTION,
      sortable: true,
    },
    { id: "vehicle_usage", label: constants.VEHICLE_USAGE, sortable: true },
    {
      id: "description_of_vehicle_location",
      label: constants.LOCATION,
      sortable: true,
    },
  ];
  const date = dateToFilenameFormat(new Date());
  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={searchParams.get("page_title") || ""} />
        <Box>
          <LastUpdateBlock fileType="stock" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      ></Box>
      <Box>
        <DownloadXlsxButton
          filename={`Performance_Overview_data_${date}.xlsx`}
          data={exportTableData}
          isFetching={exportTableDataIsFetching}
          isError={exportTableDataIsError}
        />
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={performance_overview_details_headers}
        rows={tableRows}
        count={tableData?.count}
        getItemsForPage={(items: number) => setSkip(items)}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        getSortedValue={(value: string) => sortData(value)}
        totalsColSpan={3}
        stickyColumnIndex={2}
      />
    </Box>
  );
}

export default PerformanceOverviewRetailDetailsPage;
