import { Dispatch, SetStateAction } from "react";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";

interface FilterListControlsProps {
  getListQuery?: any;
  selectedOptions?: any[];
  setSelectedOptions?: Dispatch<SetStateAction<any[]>>;
}

export const generateFilterListControls = ({
  getListQuery,
  selectedOptions,
  setSelectedOptions,
}: FilterListControlsProps): {
  allOptions: any[];
  allOptionsIsLoading: boolean;
  removeOptionFromFilter: (optionToRemove: string) => void;
  clearFilter: () => void;
} => {
  const fakeQueryResult = { data: [], isLoading: false };
  if (!setSelectedOptions) {
    return {
      allOptions: fakeQueryResult.data,
      allOptionsIsLoading: fakeQueryResult.isLoading,
      removeOptionFromFilter: (option: any) => null,
      clearFilter: () => null,
    };
  }

  const { data: allOptions, isLoading: allOptionsIsLoading } = getListQuery
    ? getListQuery()
    : fakeQueryResult;

  const removeOptionFromFilter = (optionToRemove: string) => {
    if (setSelectedOptions && selectedOptions) {
      setSelectedOptions(
        selectedOptions.filter(
          (option: any) =>
            (typeof option === "object" && "id" in option
              ? option.id
              : option) !== optionToRemove,
        ),
      );
    }
  };

  const clearFilter = () => {
    if (setSelectedOptions) {
      setSelectedOptions([]);
    }
  };

  return {
    allOptions,
    allOptionsIsLoading,
    removeOptionFromFilter,
    clearFilter,
  };
};

export const arrayOfAnyToArrayOfOptions = (
  array: any[],
  labelKey: string,
  idKey?: string,
  labelTranslateFunc?: (label: any) => any,
): OptionsProps[] => {
  const idKeyFixed = idKey || labelKey;
  return labelTranslateFunc
    ? array.map((item: any) => ({
        label: labelTranslateFunc(item[labelKey]),
        id: item[idKeyFixed],
      }))
    : array.map((item: any) => ({
        label: item[labelKey],
        id: item[idKeyFixed],
      }));
};

export const generateCumulativeSetter =
  (setSelectedOptions: Dispatch<SetStateAction<OptionsProps[]>>) =>
  (selectedValues: OptionsProps) =>
    setSelectedOptions((current: OptionsProps[]) => [
      ...current,
      selectedValues,
    ]);

export const generateCumulativeSplittingSetter =
  (setSelectedOptions: Dispatch<SetStateAction<string[]>>) =>
  (itemsStr: string) => {
    setSelectedOptions((current: string[]) => {
      const items = itemsStr.split(/\s+/);
      const newValue = [...current];
      for (let item of items) {
        if (item.length > 0 && !newValue.includes(item)) {
          newValue.push(item);
        }
      }
      return newValue;
    });
  };
