import { useState } from "react";

export const useSortData = () => {
  const [sortOrder, setSortOrder] = useState("");
  const [ordering, setOrdering] = useState("");

  function sortData(value: string) {
    if (sortOrder === "-") {
      setSortOrder("");
      return setOrdering(`${value}`);
    }
    setSortOrder("-");
    return setOrdering(`-${value}`);
  }

  return { sortData, ordering };
};
