import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { Box } from "@mui/material";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import PageTitle from "components/PageTitle/PageTitle";
import { DateBlock, FiltersBlock, ZonedTable } from "components";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import { useEffect, useMemo, useState } from "react";
import { getPercentageValue } from "helper/getPercentageValue";

function DemoAndShowroomOverviewPage() {
  const [dealers, setDealers] = useState<Array<OptionsProps>>([]);
  const [zones, setZones] = useState<Array<OptionsProps>>([]);
  const [expandedPresentFactories, setExpandedPresentFactories] =
    useState(false);
  const [expandedMissingModelTypes, setExpandedMissingModelTypes] =
    useState(false);
  const [soort, setSoort] = useState("DEMO");

  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetDemoShowroomOverviewDataQuery({
      dealers: dealers.map((item: OptionsProps) => item.id).toString(),
      dealer_zones: zones.map((item: OptionsProps) => item.id).toString(),
      soort: soort,
    });

  const factories = useMemo<string[]>(() => {
    return tableData?.factories_names_ordered?.filter(
      (factory: string) => tableData?.factories?.[factory]?._total,
    );
  }, [tableData]);
  const [expandedPresentModelTypes, setExpandedPresentModelTypes] = useState<{
    [factory: string]: boolean;
  }>();
  useEffect(() => {
    setExpandedPresentModelTypes(
      factories?.reduce(
        (prev: { [factory: string]: boolean }, curr) => ({
          ...prev,
          [curr]: curr === constants.UNKNOWN_FACTORY,
        }),
        {},
      ),
    );
  }, [factories]);

  const modelTypesMissing = useMemo<string[]>(() => {
    return tableData?.model_types.filter(
      (modelType: string) => tableData?.missing[modelType],
    );
  }, [tableData]);

  const modelTypesByFactories = useMemo<{ [factory: string]: string[] }>(() => {
    const all = tableData?.model_types_by_factories;
    if (!all) {
      return all;
    }

    const result: { [factory: string]: string[] } = {};
    for (let factory of factories) {
      result[factory] = all[factory].filter(
        (modelType: string) => tableData.factories[factory][modelType],
      );
    }
    return result;
  }, [tableData, factories]);

  const transformData = (data: any): { values: any } => {
    const values: any = [];
    data.zones.forEach((zone: any) => {
      const { dealers, ...zoneWithoutDealers } = zone;
      zone.dealers.forEach((dealer: any) => {
        values.push({ ...dealer, type: "dealer" });
      });
      values.push({ ...zoneWithoutDealers, type: "zone" });
    });
    return { values };
  };

  const getPresentValuesForFactory = (data: any, factory: string) => {
    const modelTypesOfFactory = modelTypesByFactories[factory];
    return modelTypesOfFactory.reduce<number[]>((accumulator, modelType) => {
      accumulator.push(data.factories[factory][modelType]);
      return accumulator;
    }, []);
  };

  const tableRows = useMemo(() => {
    if (tableData && modelTypesByFactories) {
      const transformedData = transformData(tableData);
      const data = transformedData.values.map((data: any) => ({
        type: data.type,
        values: [
          data.name,
          data.goal,
          ...(expandedPresentFactories && factories?.length > 0
            ? factories.reduce<number[]>((accumulator, factory) => {
                accumulator.push(
                  ...(expandedPresentModelTypes &&
                  expandedPresentModelTypes[factory]
                    ? getPresentValuesForFactory(data, factory)
                    : [data.factories[factory]._total]),
                );
                return accumulator;
              }, [])
            : [data.total]),
          getPercentageValue(data.goal_diff),
          ...(expandedMissingModelTypes && modelTypesMissing?.length > 0
            ? modelTypesMissing?.map(
                (modelType: string) => data.missing[modelType],
              )
            : [data.total_missing]),
        ],
      }));
      data.push({
        type: "total",
        values: [
          "TOTAAL",
          tableData.goal,
          ...(expandedPresentFactories && factories?.length > 0
            ? factories.reduce<number[]>((accumulator, factory) => {
                accumulator.push(
                  ...(expandedPresentModelTypes &&
                  expandedPresentModelTypes[factory]
                    ? getPresentValuesForFactory(tableData, factory)
                    : [tableData.factories[factory]._total]),
                );
                return accumulator;
              }, [])
            : [tableData.total]),
          getPercentageValue(tableData.goal_diff),
          ...(expandedMissingModelTypes && modelTypesMissing?.length > 0
            ? modelTypesMissing?.map(
                (modelType: string) => tableData.missing[modelType],
              )
            : [tableData.total_missing]),
        ],
      });
      return data;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableData,
    expandedPresentFactories,
    expandedPresentModelTypes,
    expandedMissingModelTypes,
  ]);

  let demoShowroomOverviewHeaders: Header[] = [
    { id: "dealer_name", label: constants.DEALER },
    { id: "target", label: constants.TARGET_EN },
    {
      id: "all_models",
      label: constants.TOTAL,
      expandable: true,
      expanded: expandedPresentFactories,
      setExpanded:
        factories?.length > 0 ? setExpandedPresentFactories : undefined,
      ...(expandedPresentFactories &&
        factories?.length > 0 && {
          subHeaders: factories.map((factory) => ({
            id: factory,
            label: factory,
            expandable: true,
            expanded:
              expandedPresentModelTypes?.[factory] &&
              Object.keys(modelTypesByFactories[factory]).length > 0,
            setExpanded:
              factory !== constants.UNKNOWN_FACTORY
                ? (expanded) =>
                    setExpandedPresentModelTypes((prev) => ({
                      ...prev,
                      [factory]: expanded,
                    }))
                : undefined,
            ...(expandedPresentModelTypes?.[factory] &&
              modelTypesByFactories[factory].length > 0 && {
                subHeaders: modelTypesByFactories[factory].map((modelType) => ({
                  id: `${factory} - ${modelType}`,
                  label: modelType,
                })),
              }),
          })),
        }),
    },
    { id: "vs_target", label: constants.ACHIEVEMENT },
    {
      id: "all_models_missing",
      label: "Missing",
      expanded: expandedMissingModelTypes,
      expandable: true,
      setExpanded:
        modelTypesMissing?.length > 0
          ? setExpandedMissingModelTypes
          : undefined,
      ...(expandedMissingModelTypes &&
        modelTypesMissing?.length > 0 && {
          subHeaders: modelTypesMissing.map((model_type: any) => {
            return { id: model_type + " missing", label: model_type };
          }),
        }),
    },
  ];

  const colouredColumnsIndex = useMemo(() => {
    if (!expandedPresentFactories) {
      return [3];
    }

    let presentColumnsCount = 0;
    for (let factory of factories) {
      if (expandedPresentModelTypes && expandedPresentModelTypes[factory]) {
        presentColumnsCount += modelTypesByFactories[factory].length;
      } else {
        presentColumnsCount += 1;
      }
    }
    return [2 + presentColumnsCount];
  }, [
    expandedPresentFactories,
    expandedPresentModelTypes,
    factories,
    modelTypesByFactories,
  ]);

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={constants.DEMO_AND_SHOWROOM_OVERVIEW} />
        <Box>
          <LastUpdateBlock fileType="demo_showroom" />
          <DateBlock />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box>
          <RadioGroup
            defaultValue="DEMO"
            row
            onChange={(e) => {
              setSoort(e.currentTarget.value);
            }}
          >
            <FormControlLabel value="DEMO" control={<Radio />} label="Demo" />
            <FormControlLabel
              value="SHOW"
              control={<Radio />}
              label="Showroom"
            />
          </RadioGroup>
        </Box>
        <FiltersBlock
          hideCountryFilter
          setSelectedDealers={setDealers}
          setSelectedZones={setZones}
          selectedDealers={dealers}
          selectedZones={zones}
        />
      </Box>
      <Box>
        <ZonedTable
          headers={demoShowroomOverviewHeaders}
          isFetching={tableDataIsFetching}
          stickyColumnIndex={0}
          rows={tableRows}
          count={1}
          colouredColumnsIndex={colouredColumnsIndex}
        />
      </Box>
    </Box>
  );
}

export default DemoAndShowroomOverviewPage;
