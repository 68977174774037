import { mainWhite } from "shared/colors";
import image from "shared/images/mainImage.jpg";

export const loginPageStyle = {
  inputStyle: {
    marginBottom: 1,
    marginTop: 4,
    width: { xs: "316px", sm: "416px" },
  },
  buttonStyle: {
    width: { xs: "300px", sm: "416px" },
  },
  backgroundStyle: {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    height: "100vh",
    color: mainWhite,
  },
  loginFormWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  forgotPasswordLinkStyle: {
    margin: "0 auto",
  },
};
