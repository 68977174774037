import { Box, Link, Typography, Card, Container } from "@mui/material";
import { userAPI } from "api/services/User";
import { BasicModal, MainColorButton } from "components";
import useGetViewPort from "hooks/useGetViewPort";
import useModal from "hooks/useModal";
import { change_password_page_link, login_page_link } from "routers/paths";
import { mainColor } from "shared/colors";
import {
  CANCEL,
  CHANGE_PASSWORD,
  DELETE_ACCOUNT,
  SURE_ABOUT_ACCOUNT_DELETION,
  YES,
} from "shared/constants";
import { userInfoPageStyle } from "./UserInfoPage.style";
import { useNavigate } from "react-router-dom";

function UserInfoPage() {
  const { isMobile } = useGetViewPort();
  const { data: userData } = userAPI.useSelfQuery();
  const { open, openModal, closeModal } = useModal();
  const [deleteAccountQuery] = userAPI.useDeleteAccountMutation();

  const {
    blockWrapper,
    titleStyle,
    buttonsWrapper,
    rowWrapper,
    cardStyle,
    containerStyle,
    buttonStyle,
  } = userInfoPageStyle;
  const navigate = useNavigate();

  const deleteAccount = async () => {
    await deleteAccountQuery();
    closeModal();
    localStorage.clear();
    navigate(login_page_link);
  };

  return (
    <Container sx={containerStyle}>
      <Card sx={cardStyle}>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              margin: "150px auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={rowWrapper}>
              <Typography
                sx={{ color: mainColor }}
                variant={isMobile ? "h5" : "h4"}
              >
                {userData?.first_name} {userData?.last_name}
              </Typography>
            </Box>
            <Box sx={rowWrapper}>{userData?.email}</Box>
            <Box sx={rowWrapper}>
              Profile access:{" "}
              {userData?.user_type.replace("_", " ").toUpperCase()}
            </Box>
            <Box sx={rowWrapper}>
              <Link href={change_password_page_link}>{CHANGE_PASSWORD}</Link>
            </Box>
            <Box sx={rowWrapper}>
              <MainColorButton
                sx={{
                  width: { xs: "100%", md: "230px" },
                  minWidth: { xs: "100%", md: "230px" },
                }}
                onClick={openModal}
                label={DELETE_ACCOUNT}
              />
              <BasicModal open={open} handleClose={closeModal} centerPosition>
                <Box sx={blockWrapper}>
                  <Typography sx={titleStyle}>
                    {SURE_ABOUT_ACCOUNT_DELETION}
                  </Typography>
                  <Box sx={buttonsWrapper}>
                    <Box>
                      <MainColorButton
                        onClick={deleteAccount}
                        label={YES}
                        sx={buttonStyle}
                      />
                    </Box>
                    <Box>
                      <MainColorButton
                        onClick={closeModal}
                        label={CANCEL}
                        sx={buttonStyle}
                      />
                    </Box>
                  </Box>
                </Box>
              </BasicModal>
            </Box>
          </Box>
        </Box>
      </Card>
    </Container>
  );
}

export default UserInfoPage;
