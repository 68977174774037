import { FormControl, MenuItem, TextField } from "@mui/material";
import { MouseEvent, ReactNode, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { mainWhite, progressiveBlue } from "shared/colors";
import { dropDownButtonStyle } from "./DropDownButton.style";
import { ModalHandles } from "hooks/useModal";
import useMatchLinks from "hooks/useMatchLinks";

export interface Item extends Partial<ModalHandles> {
  label: string;
  title: string;
  list?: Array<Item>;
  link?: string;
  onItemClick?: () => void;
}

interface DropDownProps {
  onOpen: () => void;
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  label: string;
  list?: Array<Item>;
  link?: string;
  withoutSelect?: boolean;
  isNested?: boolean;
  closeTree?: () => void;
  extraMenuProps?: object;
}

const DropDownButton: React.FC<DropDownProps> = ({
  open,
  onOpen,
  onClose,
  label,
  list,
  link,
  withoutSelect,
  isNested,
  closeTree,
  extraMenuProps,
}: DropDownProps) => {
  const navigate = useNavigate();
  const { inputStyle, wrapper, menuStyle, menuItemStyle, selectStyle } =
    dropDownButtonStyle;

  const getListLinksFlat = useCallback((items: Item[]) => {
    const links: string[] = [];
    for (let item of items) {
      if (item.link) {
        links.push(item.link);
      }
      if (item.list) {
        links.push(...getListLinksFlat(item.list));
      }
    }
    return links;
  }, []);

  const allLinksFlat = useMemo(() => {
    const result: string[] = [];
    if (link) {
      result.push(link);
    }
    if (list) {
      result.push(...getListLinksFlat(list));
    }
    return result;
  }, [link, list, getListLinksFlat]);
  const buttonIsSelected = useMatchLinks(allLinksFlat);

  const closeTreeInitialized = useMemo(() => {
    if (closeTree) {
      return closeTree;
    }
    return () => {
      onClose();
    };
  }, [closeTree, onClose]);

  function handleSelect(e: MouseEvent) {
    e.stopPropagation();
    if (withoutSelect && link) {
      onOpen();
      return navigate(`${link}`);
    }
    if (open) {
      return onClose();
    }
    return onOpen();
  }

  function handleClick(link: string, value: string) {
    closeTreeInitialized();
    navigate(link);
  }

  return (
    <FormControl variant="standard" sx={wrapper}>
      <TextField
        value={label}
        select
        onClick={(e) => handleSelect(e)}
        defaultValue=""
        inputProps={{
          sx: {
            ...inputStyle,
            color: isNested
              ? undefined
              : buttonIsSelected
                ? progressiveBlue
                : mainWhite,
          },
        }}
        variant="standard"
        sx={{
          "& .MuiSelect-icon": { display: "none" },
        }}
        SelectProps={{
          variant: "standard",
          open: open && !withoutSelect,
          onClose: () => {
            closeTreeInitialized();
          },
          disableUnderline: !buttonIsSelected || open,
          displayEmpty: true,
          defaultValue: "",
          sx: selectStyle,
          renderValue: (value: any) => value,
          MenuProps: {
            sx: { menuStyle },
            ...extraMenuProps,
          },
        }}
      >
        {list &&
          list.map((item: Item) =>
            item.list ? (
              <DropDownButton
                key={item.label}
                open={item.open || false}
                onOpen={item.openModal || (() => {})}
                onClose={item.closeModal || (() => {})}
                label={`${item.open ? "\u25BC" : "\u25B6"} ${item.label}`}
                list={item.list}
                isNested={true}
                closeTree={() => {
                  if (item.closeModal) {
                    item.closeModal();
                  }
                  closeTreeInitialized();
                }}
                extraMenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
              />
            ) : (
              <MenuItem
                key={item.label}
                sx={menuItemStyle}
                value={item.label}
                onClick={() =>
                  item.onItemClick
                    ? item.onItemClick()
                    : item.link && handleClick(item.link, item.title)
                }
              >
                {item.label}
              </MenuItem>
            ),
          )}
      </TextField>
    </FormControl>
  );
};

export default DropDownButton;
