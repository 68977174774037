import { Box, Chip, Typography } from "@mui/material";
import { filtersChipsStyle } from "./FiltersChips.style";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import { CLEAR } from "shared/constants";
import useGetViewPort from "hooks/useGetViewPort";

interface AbstractFiltersChipsProps<T> {
  onDelete: (filter: string) => void;
  filters: Array<T>;
  filter: string;
  onClear: () => void;
  getKey: (filter: T) => string;
  getLabel: (filter: T) => string;
}

export interface ConcreteFiltersChipsProps<T>
  extends Omit<AbstractFiltersChipsProps<T>, "getKey" | "getLabel"> {}

function FiltersChips<T>({
  filters,
  onDelete,
  filter,
  onClear,
  getKey,
  getLabel,
}: AbstractFiltersChipsProps<T>) {
  const {
    chipStyle,
    filtersWrapper,
    clearWrapper,
    clearTextStyle,
    clearIconStyle,
    filterTextStyle,
    filterWrapper,
    wrapper,
  } = filtersChipsStyle;
  const { isMobile } = useGetViewPort();

  function handleDelete(filter: string) {
    onDelete(filter);
  }

  return (
    <Box sx={wrapper}>
      <Box sx={filterWrapper}>
        <Typography sx={filterTextStyle}>{filter}:</Typography>
      </Box>

      <Box sx={filtersWrapper}>
        {filters &&
          filters.map((filter: T) => (
            <Chip
              key={getKey(filter)}
              label={getLabel(filter)}
              onDelete={() => handleDelete(getKey(filter))}
              sx={chipStyle}
            />
          ))}
      </Box>
      <Box sx={clearWrapper} onClick={() => onClear()}>
        <BookmarkRemoveIcon sx={clearIconStyle} />
        {!isMobile && <Typography sx={clearTextStyle}>{CLEAR}</Typography>}
      </Box>
    </Box>
  );
}

export default FiltersChips;
