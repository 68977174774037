/// <reference path="./theme.d.ts" />
import { createTheme } from "@mui/material";
import { darkRed, mainColor } from "../shared/colors";

export const theme = createTheme({
  status: {
    danger: darkRed,
  },
  palette: {
    primary: {
      main: mainColor,
    },
  },
  typography: {
    regular: {
      fontFamily: "HyundaiFontMediumText",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            borderColor: mainColor,
            height: "16px",
          },
          label: {
            fontSize: 14,
            color: mainColor,
          },
          fieldset: {
            borderColor: mainColor,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "HyundaiFontHead",
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1.5px solid ${mainColor}`,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "HyundaiFontHead",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflowX: "scroll",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "main_button" },
          style: {
            height: 56,
            color: mainColor,
            fontWeight: 500,
            width: "416px",
            fontFamily: "HyundaiFontHead",
          },
        },
        {
          props: { variant: "selected_button" },
          style: {
            fontFamily: "HyundaiFontHead",
            color: mainColor,
            borderBottom: `1px solid ${mainColor}`,
          },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: mainColor,
          opacity: "50%",
          "&.Mui-selected": {
            color: mainColor,
            fontWeight: 700,
            opacity: "100%",
          },
        },
      },
    },
  },
});
