import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  BasicModal,
  CustomTable,
  DateBlock,
  ExportDataBlock,
  Loader,
  MainColorButton,
} from "components";
import { Header } from "components/tables/interfaces";
import * as constants from "shared/constants";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { DetailedStock } from "models/Stock";
import { useSearchParams } from "react-router-dom";
import useModal from "hooks/useModal";
import { useSortData } from "hooks/useSortData";
import PageTitle from "components/PageTitle/PageTitle";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";

function StockDetailsPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const { open, openModal, closeModal } = useModal();
  const [skipExportTable, setSkipExportTable] = useState(true);
  const [skipExportData, setSkipExportData] = useState(true);
  const { sortData, ordering } = useSortData();
  const [searchParams] = useSearchParams();

  const queryParams = useMemo(
    () => ({
      dealers: searchParams.get("dealers") || "",
      car_models: searchParams.get("car_models") || "",
      age_from: searchParams.get("age_from") || "",
      age_to: searchParams.get("age_to") || "",
      factories: searchParams.get("factories") || "",
      vin_number: searchParams.get("vin_number") || "",
      date_from: searchParams.get("date_from") || "",
      date_to: searchParams.get("date_to") || "",
      exclude: searchParams.get("exclude") || "",
      grand_total: searchParams.get("grand_total") || "",
    }),
    [searchParams],
  );
  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetDetailedStockDataQuery({
      ...queryParams,
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const { data: exportTableData, isFetching: exportTableDataIsFetching } =
    dealerPerformanceAPI.useGetDetailedStockDataExportTableQuery(
      { ...queryParams, ordering: ordering },
      { skip: skipExportTable || !tableData?.results.length },
    );
  const { data: exportAllData, isFetching: exportAllDataIsFetching } =
    dealerPerformanceAPI.useGetDetailedStockDataExportTableQuery(queryParams, {
      skip: skipExportData,
    });

  const stockHeaders: Header[] = [
    { id: "vin_code", label: constants.VIN, sortable: true },
    { id: "sales_order", label: constants.RESERVATION_NUMBER, sortable: true },
    {
      id: "brand_type_description",
      label: constants.MODEL_DESCRIPTION,
      sortable: true,
    },
    { id: "exterior_colour", label: constants.EXTERIOR_COLOUR, sortable: true },
    { id: "interior_colour", label: constants.INTERIOR_COLOUR, sortable: true },
    { id: "vehicle_usage", label: constants.VEHICLE_USAGE, sortable: true },
    {
      id: "description_of_vehicle_location",
      label: constants.LOCATION,
      sortable: true,
    },
    { id: "stock_age", label: constants.STOCK_AGE, sortable: true },
  ];

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map((stock: DetailedStock) => ({
        values: [
          stock.vin_code,
          stock.sales_order,
          stock.brand_type_description,
          stock.exterior_colour,
          stock.interior_colour,
          stock.vehicle_usage,
          stock.description_of_vehicle_location,
          stock.stock_age,
        ],
      }));
    }
    return [];
  }, [tableData]);

  function openExportsBlock() {
    setSkipExportTable(false);
    setSkipExportData(true);
    openModal();
  }

  const getPageTitle = () => {
    const car_model = searchParams.get("car_models") || "";
    const age_from = searchParams.get("age_from") || "";
    const age_to = searchParams.get("age_to") || "";

    let title = `${constants.RETAIL_POTENTIAL}`;

    if (car_model && car_model.split(",").length === 1) {
      title += ` - ${car_model}`;
    }
    title += ` - ${searchParams.get("page_title") || ""}`;

    if (age_from && age_to) {
      title += `: ${age_from}-${age_to}`;
    }
    return title;
  };

  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={getPageTitle()} />
        <Box>
          <LastUpdateBlock fileType="stock" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <MainColorButton
            onClick={() => openExportsBlock()}
            label={constants.EXPORT}
          />
          <BasicModal
            open={open}
            handleClose={() => closeModal()}
            centerPosition
          >
            <>
              <Loader
                isLoading={exportTableDataIsFetching || exportAllDataIsFetching}
              />
              <ExportDataBlock
                exportAllData={exportAllData}
                exportTableData={exportTableData}
                onClose={closeModal}
                exportTableName="stock_table"
                exportDataName="stock_data"
                skipExportData={skipExportData}
              />
            </>
          </BasicModal>
        </Box>
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={stockHeaders}
        rows={tableRows}
        count={tableData?.count}
        getItemsForPage={(items: number) => setSkip(items)}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        getSortedValue={(value: string) => sortData(value)}
        totalsColSpan={3}
        stickyColumnIndex={2}
      />
    </Box>
  );
}

export default StockDetailsPage;
