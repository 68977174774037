// export const USER_NOT_FOUND = "No user exists with such email"
// export const WRONG_CREDENTIONS =  "Incorrect email or password. Please try again"
// export const OPEN_DESKTOP_VERSION = "* to see more columns please open the desktop version"
// export const PASSWORD_IS_CHANGED = "The password was successfully changed.";

import dayjs from "dayjs";
import setupDayjs from "setupDayjs";
import { dateLowerLimit } from "./validation/constants";

setupDayjs();

export const USER_NOT_FOUND = "E-mail onbekend";
export const WRONG_CREDENTIALS =
  "Onjuist e-mailadres of wachtwoord. Probeer nogmaals.";
export const OPEN_DESKTOP_VERSION = "Open op desktop voor volledige pagina";
export const PASSWORD_IS_CHANGED = "Wachtwoord is gewijzigd";
export const CANNOT_BE_IN_THE_FUTURE = "Kan niet in de toekomst liggen";
export const NO_INTERNET_CONNECTION = "Geen internet verbinding";
export const MUST_BE_THE_SAME_YEAR_AS_DATE_FROM =
  "Moet hetzelfde jaar zijn als datum van";
export const MUST_BE_THE_SAME_YEAR_AS_DATE_TO =
  "Moet hetzelfde jaar zijn als datum tot";
export const MUST_BE_GREATER_THAN_LIMIT = `Moet groter zijn dan ${dayjs(
  new Date(dateLowerLimit).setDate(dateLowerLimit.getDate() - 1),
).format("L")}`;
export const INVALID_DATE_FORMAT = `Ongeldig datumformaat (verwacht ${
  dayjs.Ls[dayjs.locale()].formats.L
})`;
export const FIELD_IS_REQUIRED = "Veld is verplicht";
