import { mainColor, performanceBlue } from "shared/colors";

export const changePasswordPageStyle = {
  inputStyle: {
    marginBottom: "8px",
    marginTop: "24px",
    width: { xs: "316px", sm: "416px" },
    fontFamily: "HyundaiFontHead",
  },
  buttonStyle: {
    marginTop: "48px",
    width: { xs: "316px", sm: "416px" },
    backgroundColor: performanceBlue,
    border: `1px solid ${mainColor}`,
  },
  titleStyle: {
    textTransform: "uppercase",
    color: mainColor,
    marginBottom: "32px",
    paddingBottom: "16px",
    marginTop: "24px",
    borderBottom: `2px solid ${mainColor}`,
    fontWight: 500,
  },
};
