import { Outlet } from "react-router-dom";
import { NavBar, PageWrapper } from "components";
import { userAPI } from "api/services/User";
import { store } from "api/services/store";
import { signInUser } from "reducers/actions";

const MainPage = () => {
  const { data: userData } = userAPI.useSelfQuery();
  store.dispatch(signInUser(userData));
  return (
    <>
      <NavBar />
      <PageWrapper>{userData && <Outlet context={userData} />}</PageWrapper>
    </>
  );
};

export default MainPage;
