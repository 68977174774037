export const mainButtonStyle = {
  buttonStyle: {
    justifyContent: "flex-start",
    borderRadius: 0,
    height: "48px",
    marginBottom: "5px",
    fontWeight: 500,
    padding: "8px 16px",
    fontSize: "14px",
    fontFamily: "HyundaiFontHead",
  },
};
