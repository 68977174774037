import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import * as constants from "shared/constants";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import { Header } from "components/tables/interfaces";
import { ClaimCode } from "models/ClaimCodes";
import { useSortData } from "hooks/useSortData";
import { useMemo, useState } from "react";
import { CustomTable, DateBlock, FiltersBlock } from "components";
import { Box } from "@mui/material";
import LastUpdateBlock from "components/LastUpdateBlock/LastUpdateBlock";
import PageTitle from "components/PageTitle/PageTitle";
import { dateToFilenameFormat } from "shared/utils";
import DownloadXlsxButton from "components/DownloadXlsxButton/DownloadXlsxButton";
import { dispatchFilterChangedEvent } from "shared/events";

function ClaimCodesOverviewPage() {
  const [rowPerPage, setRowPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE);
  const [skip, setSkip] = useState(0);
  const [dealers, setDealers] = useState<Array<OptionsProps>>([]);
  const [salesOpportunities, setSalesOpportunities] = useState<
    Array<OptionsProps>
  >([]);
  const [claimCodes, setClaimCodes] = useState<Array<OptionsProps>>([]);
  const [clientCorporateNames, setClientCorporateNames] = useState<
    Array<OptionsProps>
  >([]);
  const { sortData, ordering } = useSortData();

  const queryParams = useMemo(() => {
    dispatchFilterChangedEvent();
    return {
      dealer: dealers.map((item: OptionsProps) => item.id).toString(),
      claim_code: claimCodes.map((item: OptionsProps) => item.id).toString(),
      sales_opportunity: salesOpportunities
        .map((item: OptionsProps) => item.id)
        .toString(),
      client_corporate_name: clientCorporateNames
        .map((item: OptionsProps) => item.id)
        .toString(),
    };
  }, [claimCodes, clientCorporateNames, dealers, salesOpportunities]);
  const { data: tableData, isFetching: tableDataIsFetching } =
    dealerPerformanceAPI.useGetClaimCodesOverviewDataQuery({
      ...queryParams,
      limit: rowPerPage,
      offset: skip,
      ordering: ordering,
    });
  const {
    data: exportAllData,
    isFetching: exportAllDataIsFetching,
    isError: exportAllDataIsError,
  } = dealerPerformanceAPI.useGetClaimCodesOverviewExportDataQuery({
    ...queryParams,
    ordering: ordering,
  });

  const generateDetailsUrl = (
    page_title: string,
    claim_code: string,
    dealer: string | null,
    client_corporate_name: string,
    sales_opportunity: string,
  ) => {
    let url =
      "/main/claim-codes-overview-details-page?" +
      `claim_code=${claim_code}&` +
      `client_corporate_name=${encodeURIComponent(client_corporate_name)}&` +
      `sales_opportunity=${encodeURIComponent(sales_opportunity)}&` +
      `page_title=${page_title}&`;
    if (dealer !== null) {
      url += `dealer=${dealer}`;
    }
    return url;
  };

  const claimCodesOverviewHeaders: Header[] = [
    { id: "dealer_name", label: constants.DEALER, sortable: true },
    { id: "claim_code", label: constants.CLAIM_CODE, sortable: true },
    {
      id: "sales_opportunity",
      label: constants.SALES_OPPORTUNITY,
      sortable: true,
    },
    {
      id: "client_corporate_name",
      label: constants.CLIENT_CORPORATE_NAME,
      sortable: true,
    },
  ];

  const tableRows = useMemo(() => {
    if (tableData) {
      return tableData.results.map((claimCode: ClaimCode) => ({
        values: [
          claimCode.dealer_name || "Decentraal",
          {
            value: claimCode.claim_code,
            href: generateDetailsUrl(
              `Claim Codes - ${claimCode.claim_code} (${claimCode.sales_opportunity}) - ${claimCode.client_corporate_name}`,
              claimCode.claim_code,
              claimCode.dealer_id,
              claimCode.client_corporate_name,
              claimCode.sales_opportunity,
            ),
          },
          claimCode.sales_opportunity,
          claimCode.client_corporate_name,
        ],
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const date = dateToFilenameFormat(new Date());
  return (
    <Box sx={{ marginTop: "120px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <PageTitle title={constants.CLAIM_CODES_OVERVIEW_PAGE} />
        <Box>
          <LastUpdateBlock fileType="claim_codes" />
          <DateBlock />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <FiltersBlock
          hideCountryFilter
          includeDecentraalDealer
          setSelectedDealers={setDealers}
          setSelectedClaimCodes={setClaimCodes}
          setSelectedSalesOpportunities={setSalesOpportunities}
          setSelectedClientCorporateNames={setClientCorporateNames}
          selectedDealers={dealers}
          selectedClaimCodes={claimCodes}
          selectedSalesOpportunities={salesOpportunities}
          selectedClientCorporateNames={clientCorporateNames}
          claimCodesSyncMode
        />
        <Box>
          <DownloadXlsxButton
            filename={`Claims_codes_overview_data_${date}.xlsx`}
            data={exportAllData}
            isFetching={exportAllDataIsFetching}
            isError={exportAllDataIsError}
          />
        </Box>
      </Box>
      <CustomTable
        isFetching={tableDataIsFetching}
        headers={claimCodesOverviewHeaders}
        rows={tableRows}
        count={tableData?.count}
        getItemsForPage={(items: number) => setSkip(items)}
        rowsPerPage={rowPerPage}
        changeRowsPerPage={setRowPerPage}
        getSortedValue={(value: string) => sortData(value)}
        totalsColSpan={3}
        stickyColumnIndex={2}
      />
    </Box>
  );
}

export default ClaimCodesOverviewPage;
