import { useEffect, useState } from "react";

export const useGetLastValid = <T>(
  data: T,
  isValid: boolean,
  isValidating: boolean,
): T | undefined => {
  const [validatedData, setValidatedData] = useState<T>();

  useEffect(() => {
    if (!isValidating && isValid) {
      setValidatedData(data);
    }
  }, [data, isValidating, isValid]);
  return validatedData;
};
