import FiltersChips, { ConcreteFiltersChipsProps } from "./FiltersChips";

function StringFiltersChips({
  filters,
  onDelete,
  filter,
  onClear,
}: ConcreteFiltersChipsProps<string>) {
  return (
    <FiltersChips<string>
      filters={filters}
      onDelete={onDelete}
      filter={filter}
      onClear={onClear}
      getKey={(filter) => filter}
      getLabel={(filter) => filter}
    />
  );
}

export default StringFiltersChips;
