import { Box, Typography } from "@mui/material";
import { importerAPI } from "api/services/Importer";
import { LAST_UPDATE, NONE } from "shared/constants";

interface LastUpdateProps {
  fileType: string;
}

function LastUpdateBlock({ fileType }: LastUpdateProps) {
  const { data: importedFile } = importerAPI.useGetLastProcessedQuery(fileType);

  return (
    <Box
      sx={{
        marginTop: { md: "0px", xs: "8px" },
        marginBottom: "8px",
        display: "flex",
        justifyContent: { md: "flex-end", xs: "flex-start" },
      }}
    >
      <Typography variant="regular">
        {LAST_UPDATE} - {importedFile?.processed_at || NONE}
      </Typography>
    </Box>
  );
}

export default LastUpdateBlock;
