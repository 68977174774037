import { EMAIL_IS_INVALID, ENTER_EMAIL } from "shared/constants";
import * as yup from "yup";
import { emailRegex } from "./constants";

export const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, EMAIL_IS_INVALID)
    .required(ENTER_EMAIL),
  password: yup.string().required("Please enter a password"),
});
