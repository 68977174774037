import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import FilterIcon from "@mui/icons-material/FilterAlt";
import FilterListIcon from "@mui/icons-material/FilterList";
import { carModelAPI } from "api/services/CarModel";
import { dealerAPI } from "api/services/Dealer";
import { orderAPI } from "api/services/Order";
import {
  AutocompleteTextField,
  CustomTextField,
  CustomDatePicker,
  Loader,
} from "components";
import { OptionsProps } from "components/AutocompleteTextField/AutocompleteTextField";
import OptionsFiltersChips from "components/FiltersChips/OptionsFiltersChips";
import { SetStateAction, useMemo } from "react";
import * as constants from "shared/constants";
import { filtersBlockStyle } from "./FiltersBlock.style";
import useGetViewPort from "hooks/useGetViewPort";
import * as models from "models";
import { autocompleteTextFieldStyle } from "components/AutocompleteTextField/AutocompleteTextField.style";
import ConsumingTextField from "../ConsumingTextField/ConsumingTextField";
import StringFiltersChips from "../FiltersChips/StringFiltersChips";
import { dealerPerformanceAPI } from "api/services/DealerPerformance";
import { translateStatus } from "pages/DemoShowRoomRequestsPage/DemoShowRoomRequestsPage";
import * as fieldsNames from "./fieldsNames";
import ClaimCodesFiltersSynchronizer from "./ClaimCodesFiltersSynchronizer";
import {
  arrayOfAnyToArrayOfOptions,
  generateCumulativeSetter,
  generateCumulativeSplittingSetter,
  generateFilterListControls,
} from "./utils";

interface FiltersBlockProps {
  selectedDealers?: OptionsProps[];
  setSelectedDealers?: (i: SetStateAction<OptionsProps[]>) => void;
  includeDecentraalDealer?: boolean;
  selectedZones?: OptionsProps[];
  setSelectedZones?: (i: SetStateAction<OptionsProps[]>) => void;
  setSelectedRound?: (i: SetStateAction<string>) => void;
  selectedBrands?: OptionsProps[];
  setSelectedBrands?: (i: SetStateAction<OptionsProps[]>) => void;
  selectedModelTypes?: OptionsProps[];
  setSelectedModelTypes?: (i: SetStateAction<OptionsProps[]>) => void;
  selectedDemoShowRoomRequestsSoorts?: OptionsProps[];
  setSelectedDemoShowRoomRequestsSoorts?: (
    i: SetStateAction<OptionsProps[]>,
  ) => void;
  selectedDemoShowRoomRequestsStatuses?: OptionsProps[];
  setSelectedDemoShowRoomRequestsStatuses?: (
    i: SetStateAction<OptionsProps[]>,
  ) => void;
  selectedClaimCodes?: OptionsProps[];
  setSelectedClaimCodes?: (i: SetStateAction<OptionsProps[]>) => void;
  selectedSalesOpportunities?: OptionsProps[];
  setSelectedSalesOpportunities?: (i: SetStateAction<OptionsProps[]>) => void;
  selectedClientCorporateNames?: OptionsProps[];
  setSelectedClientCorporateNames?: (i: SetStateAction<OptionsProps[]>) => void;
  selectedFactories?: OptionsProps[];
  setSelectedFactories?: (i: SetStateAction<OptionsProps[]>) => void;
  selectedReservationNumbers?: string[];
  setSelectedReservationNumbers?: (i: SetStateAction<string[]>) => void;
  selectedVinNumbers?: string[];
  setSelectedVinNumbers?: (i: SetStateAction<string[]>) => void;
  setSelectedDateFrom?: (i: SetStateAction<Date | null>) => void;
  defaultDateFrom?: Date;
  setSelectedDateTo?: (i: SetStateAction<Date | null>) => void;
  defaultDateTo?: Date;
  hideCountryFilter?: boolean;
  claimCodesSyncMode?: boolean;
  errors?: { [fieldName: string]: string[] };
}

interface ListQueryResponse<T> {
  data?: T[];
  isLoading?: boolean;
}

const FiltersBlock = ({
  selectedDealers,
  setSelectedDealers,
  includeDecentraalDealer,
  selectedZones,
  setSelectedZones,
  setSelectedRound,
  selectedBrands,
  setSelectedBrands,
  selectedModelTypes,
  setSelectedModelTypes,
  selectedDemoShowRoomRequestsSoorts,
  setSelectedDemoShowRoomRequestsSoorts,
  selectedDemoShowRoomRequestsStatuses,
  setSelectedDemoShowRoomRequestsStatuses,
  selectedClaimCodes,
  setSelectedClaimCodes,
  selectedSalesOpportunities,
  setSelectedSalesOpportunities,
  selectedClientCorporateNames,
  setSelectedClientCorporateNames,
  selectedFactories,
  setSelectedFactories,
  selectedReservationNumbers,
  setSelectedReservationNumbers,
  selectedVinNumbers,
  setSelectedVinNumbers,
  setSelectedDateFrom,
  defaultDateFrom,
  setSelectedDateTo,
  defaultDateTo,
  hideCountryFilter,
  claimCodesSyncMode,
  errors,
}: FiltersBlockProps) => {
  const {
    allOptions: dealers,
    allOptionsIsLoading: dealersIsLoading,
    removeOptionFromFilter: removeDealerFilter,
    clearFilter: clearDealersFilter,
  } = generateFilterListControls({
    getListQuery: dealerAPI.useGetDealersListQuery,
    selectedOptions: selectedDealers,
    setSelectedOptions: setSelectedDealers,
  });
  const dealersSupplemented = useMemo(() => {
    if (!dealers || !includeDecentraalDealer) {
      return dealers;
    }
    return [...dealers, { id: "decentraal", name: "Decentraal" }];
  }, [dealers, includeDecentraalDealer]);
  const {
    allOptions: zones,
    allOptionsIsLoading: zonesIsLoading,
    removeOptionFromFilter: removeZoneFilter,
    clearFilter: clearZonesFilter,
  } = generateFilterListControls({
    getListQuery: dealerAPI.useGetDealersZonesQuery,
    selectedOptions: selectedZones,
    setSelectedOptions: setSelectedZones,
  });
  const { data: rounds, isLoading: roundsIsLoading } = setSelectedRound
    ? orderAPI.useGetRoundsListQuery()
    : ({} as ListQueryResponse<models.Round>);
  const handleRoundChange = (value: any) => {
    if (setSelectedRound) {
      setSelectedRound(value.id);
    }
  };
  const {
    allOptions: brands,
    allOptionsIsLoading: brandsIsLoading,
    removeOptionFromFilter: removeBrandFilter,
    clearFilter: clearBrandsFilter,
  } = generateFilterListControls({
    getListQuery: carModelAPI.useGetCarModelBrandListQuery,
    selectedOptions: selectedBrands,
    setSelectedOptions: setSelectedBrands,
  });
  const {
    allOptions: modelTypes,
    allOptionsIsLoading: modelTypesIsLoading,
    removeOptionFromFilter: removeModelTypeFilter,
    clearFilter: clearModelTypesFilter,
  } = generateFilterListControls({
    getListQuery: carModelAPI.useGetCarModelTypeListQuery,
    selectedOptions: selectedModelTypes,
    setSelectedOptions: setSelectedModelTypes,
  });
  const {
    allOptions: factories,
    allOptionsIsLoading: factoriesIsLoading,
    removeOptionFromFilter: removeFactoryFilter,
    clearFilter: clearFactoriesFilter,
  } = generateFilterListControls({
    getListQuery: carModelAPI.useGetCarModelFactoryListQuery,
    selectedOptions: selectedFactories,
    setSelectedOptions: setSelectedFactories,
  });
  const {
    allOptions: demoShowRoomRequestsSoorts,
    allOptionsIsLoading: demoShowRoomRequestsSoortsIsLoading,
    removeOptionFromFilter: removeDemoShowRoomRequestsSoortFilter,
    clearFilter: clearDemoShowRoomRequestsSoortsFilter,
  } = generateFilterListControls({
    getListQuery: dealerPerformanceAPI.useGetDemoShowRoomRequestsSoortListQuery,
    selectedOptions: selectedDemoShowRoomRequestsSoorts,
    setSelectedOptions: setSelectedDemoShowRoomRequestsSoorts,
  });
  const {
    allOptions: demoShowRoomRequestsStatuses,
    allOptionsIsLoading: demoShowRoomRequestsStatusesIsLoading,
    removeOptionFromFilter: removeDemoShowRoomRequestsStatusFilter,
    clearFilter: clearDemoShowRoomRequestsStatusesFilter,
  } = generateFilterListControls({
    getListQuery:
      dealerPerformanceAPI.useGetDemoShowRoomRequestsStatusListQuery,
    selectedOptions: selectedDemoShowRoomRequestsStatuses,
    setSelectedOptions: setSelectedDemoShowRoomRequestsStatuses,
  });
  const {
    allOptions: claimCodes,
    allOptionsIsLoading: claimCodesIsLoading,
    removeOptionFromFilter: removeClaimCodeFilter,
    clearFilter: clearClaimCodesFilter,
  } = generateFilterListControls({
    getListQuery: dealerPerformanceAPI.useGetClaimCodeListQuery,
    selectedOptions: selectedClaimCodes,
    setSelectedOptions: setSelectedClaimCodes,
  });
  const {
    allOptions: salesOpportunities,
    allOptionsIsLoading: salesOpportunitiesIsLoading,
    removeOptionFromFilter: removeSalesOpportunityFilter,
    clearFilter: clearSalesOpportunitiesFilter,
  } = generateFilterListControls({
    getListQuery: dealerPerformanceAPI.useGetSalesOpportunityListQuery,
    selectedOptions: selectedSalesOpportunities,
    setSelectedOptions: setSelectedSalesOpportunities,
  });
  const {
    allOptions: clientCorporateNames,
    allOptionsIsLoading: clientCorporateNamesIsLoading,
    removeOptionFromFilter: removeClientCorporateNameFilter,
    clearFilter: clearClientCorporateNamesFilter,
  } = generateFilterListControls({
    getListQuery: dealerPerformanceAPI.useGetClientCorporateNameListQuery,
    selectedOptions: selectedClientCorporateNames,
    setSelectedOptions: setSelectedClientCorporateNames,
  });
  const {
    removeOptionFromFilter: removeReservationNumberFilter,
    clearFilter: clearReservationNumbersFilter,
  } = generateFilterListControls({
    selectedOptions: selectedReservationNumbers,
    setSelectedOptions: setSelectedReservationNumbers,
  });
  const {
    removeOptionFromFilter: removeVinNumberFilter,
    clearFilter: clearVinNumbersFilter,
  } = generateFilterListControls({
    selectedOptions: selectedVinNumbers,
    setSelectedOptions: setSelectedVinNumbers,
  });

  const {
    dealerRelevantValues,
    claimCodeRelevantValues,
    salesOpportunityRelevantValues,
    clientCorporateNameRelevantValues,
    isLoading: claimCodesFilterSynchronizerIsLoading,
  } = claimCodesSyncMode
    ? ClaimCodesFiltersSynchronizer({
        allDealers: dealersSupplemented,
        selectedDealers: selectedDealers,
        allClaimCodes: claimCodes,
        selectedClaimCodes: selectedClaimCodes,
        allSalesOpportunities: salesOpportunities,
        selectedSalesOpportunities: selectedSalesOpportunities,
        allClientCorporateNames: clientCorporateNames,
        selectedClientCorporateNames: selectedClientCorporateNames,
      })
    : {
        dealerRelevantValues: dealersSupplemented,
        claimCodeRelevantValues: claimCodes,
        salesOpportunityRelevantValues: salesOpportunities,
        clientCorporateNameRelevantValues: clientCorporateNames,
        isLoading: false,
      };

  const { isTabletAndMobile } = useGetViewPort();
  const { wrapperStyle } = filtersBlockStyle;

  const { autocompleteStyle, textFieldStyle, filterIconStyle } =
    autocompleteTextFieldStyle;

  const filtersBlockContent = (
    <>
      <Box sx={wrapperStyle}>
        <Loader
          isLoading={
            dealersIsLoading ||
            zonesIsLoading ||
            roundsIsLoading ||
            brandsIsLoading ||
            modelTypesIsLoading ||
            factoriesIsLoading ||
            demoShowRoomRequestsSoortsIsLoading ||
            demoShowRoomRequestsStatusesIsLoading ||
            claimCodesIsLoading ||
            salesOpportunitiesIsLoading ||
            clientCorporateNamesIsLoading ||
            claimCodesFilterSynchronizerIsLoading
          }
        />
        {!hideCountryFilter && (
          <CustomTextField label={constants.NETHERLANDS} disabled />
        )}
        {dealerRelevantValues && setSelectedDealers && (
          <AutocompleteTextField
            options={arrayOfAnyToArrayOfOptions(
              dealerRelevantValues,
              "name",
              "id",
            )}
            label={constants.DEALER}
            addSelectedValue={generateCumulativeSetter(setSelectedDealers)}
            selectedValues={selectedDealers}
          />
        )}
        {zones && setSelectedZones && (
          <AutocompleteTextField
            options={arrayOfAnyToArrayOfOptions(zones, "name", "id")}
            label={constants.ZONE}
            addSelectedValue={generateCumulativeSetter(setSelectedZones)}
            selectedValues={selectedZones}
          />
        )}
        {rounds && setSelectedRound && (
          <Autocomplete
            disableClearable
            autoComplete
            options={rounds.map((option: models.Round) => ({
              label: `${constants.ROUND} ${option.round}`,
              id: option.round,
            }))}
            defaultValue={{ label: `${constants.ROUND} 1`, id: "1" }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={constants.ROUND}
                sx={textFieldStyle}
              />
            )}
            onChange={(event, value) => handleRoundChange(value)}
            sx={autocompleteStyle}
            popupIcon={<FilterListIcon sx={filterIconStyle} />}
          />
        )}
        {brands && setSelectedBrands && (
          <AutocompleteTextField
            options={arrayOfAnyToArrayOfOptions(brands, "brand_code")}
            label={constants.MODEL}
            addSelectedValue={generateCumulativeSetter(setSelectedBrands)}
            selectedValues={selectedBrands}
          />
        )}
        {modelTypes && setSelectedModelTypes && (
          <AutocompleteTextField
            options={arrayOfAnyToArrayOfOptions(modelTypes, "model_type")}
            label={constants.MODEL_TYPE}
            addSelectedValue={generateCumulativeSetter(setSelectedModelTypes)}
            selectedValues={selectedModelTypes}
          />
        )}
        {factories && setSelectedFactories && (
          <AutocompleteTextField
            options={arrayOfAnyToArrayOfOptions(factories, "name", "id")}
            label={constants.FACTORY}
            addSelectedValue={generateCumulativeSetter(setSelectedFactories)}
            selectedValues={selectedFactories}
          />
        )}
        {demoShowRoomRequestsSoorts &&
          setSelectedDemoShowRoomRequestsSoorts && (
            <AutocompleteTextField
              options={arrayOfAnyToArrayOfOptions(
                demoShowRoomRequestsSoorts,
                "soort",
              )}
              label={constants.SOORT}
              addSelectedValue={generateCumulativeSetter(
                setSelectedDemoShowRoomRequestsSoorts,
              )}
              selectedValues={selectedDemoShowRoomRequestsSoorts}
            />
          )}
        {demoShowRoomRequestsStatuses &&
          setSelectedDemoShowRoomRequestsStatuses && (
            <AutocompleteTextField
              options={arrayOfAnyToArrayOfOptions(
                demoShowRoomRequestsStatuses,
                "status",
                undefined,
                translateStatus,
              )}
              label={constants.STATUS}
              addSelectedValue={generateCumulativeSetter(
                setSelectedDemoShowRoomRequestsStatuses,
              )}
              selectedValues={selectedDemoShowRoomRequestsStatuses}
            />
          )}
        {claimCodeRelevantValues && setSelectedClaimCodes && (
          <AutocompleteTextField
            options={arrayOfAnyToArrayOfOptions(
              claimCodeRelevantValues,
              "claim_code",
            )}
            label={constants.CLAIM_CODE}
            addSelectedValue={generateCumulativeSetter(setSelectedClaimCodes)}
            selectedValues={selectedClaimCodes}
          />
        )}
        {salesOpportunityRelevantValues && setSelectedSalesOpportunities && (
          <AutocompleteTextField
            options={arrayOfAnyToArrayOfOptions(
              salesOpportunityRelevantValues,
              "sales_opportunity",
            )}
            label={constants.SALES_OPPORTUNITY}
            addSelectedValue={generateCumulativeSetter(
              setSelectedSalesOpportunities,
            )}
            selectedValues={selectedSalesOpportunities}
          />
        )}
        {clientCorporateNameRelevantValues &&
          setSelectedClientCorporateNames && (
            <AutocompleteTextField
              options={arrayOfAnyToArrayOfOptions(
                clientCorporateNameRelevantValues,
                "client_corporate_name",
              )}
              label={constants.CLIENT_CORPORATE_NAME}
              addSelectedValue={generateCumulativeSetter(
                setSelectedClientCorporateNames,
              )}
              selectedValues={selectedClientCorporateNames}
            />
          )}
        {setSelectedReservationNumbers && (
          <ConsumingTextField
            label={constants.SEARCH_RESERVATION_NUMBER}
            addSelectedValue={generateCumulativeSplittingSetter(
              setSelectedReservationNumbers,
            )}
          />
        )}
        {setSelectedVinNumbers && (
          <ConsumingTextField
            label={constants.SEARCH_VIN_NUMBER}
            addSelectedValue={generateCumulativeSplittingSetter(
              setSelectedVinNumbers,
            )}
          />
        )}
        {setSelectedDateFrom && setSelectedDateTo && (
          <>
            <CustomDatePicker
              label={constants.FROM}
              defaultDate={defaultDateFrom}
              getDate={setSelectedDateFrom}
              errorMessages={errors?.[fieldsNames.DATE_FROM]}
            />
            <CustomDatePicker
              label={constants.TO}
              defaultDate={defaultDateTo}
              getDate={setSelectedDateTo}
              errorMessages={errors?.[fieldsNames.DATE_TO]}
            />
          </>
        )}
      </Box>
      {!!selectedDealers && !!selectedDealers.length && (
        <OptionsFiltersChips
          filters={selectedDealers}
          onDelete={removeDealerFilter}
          filter={constants.DEALER}
          onClear={clearDealersFilter}
        />
      )}
      {!!selectedBrands && !!selectedBrands.length && (
        <OptionsFiltersChips
          filters={selectedBrands}
          onDelete={removeBrandFilter}
          filter={constants.MODEL}
          onClear={clearBrandsFilter}
        />
      )}
      {!!selectedModelTypes && !!selectedModelTypes.length && (
        <OptionsFiltersChips
          filters={selectedModelTypes}
          onDelete={removeModelTypeFilter}
          filter={constants.MODEL_TYPE}
          onClear={clearModelTypesFilter}
        />
      )}
      {!!selectedFactories && !!selectedFactories.length && (
        <OptionsFiltersChips
          filters={selectedFactories}
          onDelete={removeFactoryFilter}
          filter={constants.FACTORY}
          onClear={clearFactoriesFilter}
        />
      )}
      {!!selectedDemoShowRoomRequestsSoorts &&
        !!selectedDemoShowRoomRequestsSoorts.length && (
          <OptionsFiltersChips
            filters={selectedDemoShowRoomRequestsSoorts}
            onDelete={removeDemoShowRoomRequestsSoortFilter}
            filter={constants.SOORT}
            onClear={clearDemoShowRoomRequestsSoortsFilter}
          />
        )}
      {!!selectedDemoShowRoomRequestsStatuses &&
        !!selectedDemoShowRoomRequestsStatuses.length && (
          <OptionsFiltersChips
            filters={selectedDemoShowRoomRequestsStatuses}
            onDelete={removeDemoShowRoomRequestsStatusFilter}
            filter={constants.STATUS}
            onClear={clearDemoShowRoomRequestsStatusesFilter}
          />
        )}
      {!!selectedZones && !!selectedZones.length && (
        <OptionsFiltersChips
          filters={selectedZones}
          onDelete={removeZoneFilter}
          filter={constants.ZONE}
          onClear={clearZonesFilter}
        />
      )}
      {!!selectedReservationNumbers && !!selectedReservationNumbers.length && (
        <StringFiltersChips
          filters={selectedReservationNumbers}
          onDelete={removeReservationNumberFilter}
          filter={constants.RESERVATION_NUMBER}
          onClear={clearReservationNumbersFilter}
        />
      )}
      {!!selectedVinNumbers && !!selectedVinNumbers.length && (
        <StringFiltersChips
          filters={selectedVinNumbers}
          onDelete={removeVinNumberFilter}
          filter={constants.VIN}
          onClear={clearVinNumbersFilter}
        />
      )}
      {!!selectedClaimCodes && !!selectedClaimCodes.length && (
        <OptionsFiltersChips
          filters={selectedClaimCodes}
          onDelete={removeClaimCodeFilter}
          filter={constants.CLAIM_CODE}
          onClear={clearClaimCodesFilter}
        />
      )}
      {!!selectedSalesOpportunities && !!selectedSalesOpportunities.length && (
        <OptionsFiltersChips
          filters={selectedSalesOpportunities}
          onDelete={removeSalesOpportunityFilter}
          filter={constants.SALES_OPPORTUNITY}
          onClear={clearSalesOpportunitiesFilter}
        />
      )}
      {!!selectedClientCorporateNames &&
        !!selectedClientCorporateNames.length && (
          <OptionsFiltersChips
            filters={selectedClientCorporateNames}
            onDelete={removeClientCorporateNameFilter}
            filter={constants.CLIENT_CORPORATE_NAME}
            onClear={clearClientCorporateNamesFilter}
          />
        )}
    </>
  );

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "80%" },
        marginBottom: "16px",
        marginRight: "16px",
      }}
    >
      {isTabletAndMobile ? (
        <Accordion>
          <AccordionSummary
            expandIcon={<FilterIcon />}
            aria-controls="filter-content"
            id="filter-header"
          >
            <Typography>{constants.FILTER}</Typography>
          </AccordionSummary>
          <AccordionDetails>{filtersBlockContent}</AccordionDetails>
        </Accordion>
      ) : (
        filtersBlockContent
      )}
    </Box>
  );
};

export default FiltersBlock;
