import { mainColor, mainWhite } from "shared/colors";
import { theme } from "helper/getTheme";

export const navBarStyle = {
  toolBarStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&.MuiToolbar-root": {
      paddingLeft: { md: "30px" },
      paddingRight: { md: "30px" },
      [theme.breakpoints.down("tablet")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  tabStyle: {
    width: "auto",
    margin: "0 8px",
  },
  tabsStyle: {
    display: "flex",
    justifyContent: "center",
  },
  nameStyle: {
    color: mainColor,
    fontWeight: 500,
    cursor: "pointer",
    minWidth: { sm: "100px", xs: "auto" },
  },
  tabsWrapper: {
    color: mainColor,
  },
  logoStyle: {
    cursor: "pointer",
    width: { md: "250px" },
    [theme.breakpoints.down("tablet")]: {
      width: "120px",
    },
  },
  menuIconStyle: {
    color: mainWhite,
    width: "80px",
  },
};
