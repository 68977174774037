import { Button, Menu, PopoverOrigin, SxProps, Theme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useRef, useState } from "react";
import { DropDownRegularButtonStyle } from "./DropDownRegularButton.style";

interface DropDownRegularButtonProps {
  sx?: SxProps<Theme>;
  label: string;
  popoverAnchorOrigin: PopoverOrigin;
  popoverTransformOrigin: PopoverOrigin;
  children: JSX.Element | JSX.Element[];
}

const DropDownRegularButton = ({
  sx,
  label,
  popoverAnchorOrigin,
  popoverTransformOrigin,
  children,
}: DropDownRegularButtonProps) => {
  const [popperOpen, setPopperOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const handleClose = () => {
    setPopperOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          ...DropDownRegularButtonStyle.buttonStyle,
          ...sx,
        }}
        endIcon={<ArrowDropDownIcon />}
        ref={anchorRef}
        onClick={() => {
          setPopperOpen((currOpen) => !currOpen);
        }}
      >
        {label}
      </Button>
      <Menu
        open={popperOpen}
        anchorEl={anchorRef.current}
        onClick={handleClose}
        onClose={handleClose}
        anchorOrigin={popoverAnchorOrigin}
        transformOrigin={popoverTransformOrigin}
      >
        {children}
      </Menu>
    </>
  );
};

export default DropDownRegularButton;
