import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Box, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { userAPI } from "api/services/User";
import {
  DEALER_SALES_ROLE,
  ADMIN_ROLE,
  ACCOUNT_MANAGER_ROLE,
} from "shared/constants";
import {
  CustomDivider,
  DealerPerformanceNav,
  Logo,
  OrderDeliveryNav,
  UserNav,
  CustomDrawer,
  FutureSalesNav,
  MobileNav,
  ReservationNav,
  OverviewNav,
  ClaimsNav,
  FreeStockNav,
} from "components";
import useGetViewPort from "hooks/useGetViewPort";
import { mainColor } from "shared/colors";
import { navBarStyle } from "./NavBar.style";
import { main_page_link } from "routers/paths";
import useModal from "hooks/useModal";

function NavBar() {
  const navigate = useNavigate();
  const { isTabletAndMobile } = useGetViewPort();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const { data: userData } = userAPI.useSelfQuery();
  const {
    open: isOpenPerformanceNav,
    openModal: openPerformanceNav,
    closeModal: closePerformanceNav,
  } = useModal();
  const {
    open: isOpenClaimsNav,
    openModal: openClaimsNav,
    closeModal: closeClaimsNav,
  } = useModal();
  const {
    open: isOpenDeliveryNav,
    openModal: openDeliveryNav,
    closeModal: closeDeliveryNav,
  } = useModal();
  const { openModal: openFutureSaleNav, closeModal: closeFutureSaveNav } =
    useModal();
  const { openModal: openReservationNav, closeModal: closeReservationNav } =
    useModal();
  const { openModal: openFreeStockNav, closeModal: closeFreeStockNav } =
    useModal();
  const {
    open: isOpenUserNav,
    openModal: openUserNav,
    closeModal: closeUserNav,
  } = useModal();
  const {
    open: isOpenOverviewNav,
    openModal: openOverviewNav,
    closeModal: closeOverviewNav,
  } = useModal();
  const demoShowroomSubmenuHandles = useModal();

  const { toolBarStyle, tabsStyle, logoStyle, menuIconStyle } = navBarStyle;

  const openMobileDrawer = () => {
    if (isOpenDrawer) {
      return setIsOpenDrawer(false);
    }
    return setIsOpenDrawer(true);
  };

  function switchToOverviewNav() {
    openOverviewNav();
    closeDeliveryNav();
    closeFutureSaveNav();
    closeReservationNav();
    closeFreeStockNav();
    closePerformanceNav();
    closeUserNav();
    closeClaimsNav();
    demoShowroomSubmenuHandles.closeModal();
  }

  function switchToPerformanceNav() {
    openPerformanceNav();
    closeDeliveryNav();
    closeFutureSaveNav();
    closeReservationNav();
    closeFreeStockNav();
    closeUserNav();
    closeOverviewNav();
    closeClaimsNav();
    demoShowroomSubmenuHandles.closeModal();
  }

  function switchToDeliveryNav() {
    openDeliveryNav();
    closePerformanceNav();
    closeFutureSaveNav();
    closeReservationNav();
    closeFreeStockNav();
    closeUserNav();
    closeOverviewNav();
    closeClaimsNav();
    demoShowroomSubmenuHandles.closeModal();
  }

  function switchToFutureSalesNav() {
    openFutureSaleNav();
    closeDeliveryNav();
    closePerformanceNav();
    closeReservationNav();
    closeFreeStockNav();
    closeUserNav();
    closeOverviewNav();
    closeClaimsNav();
    demoShowroomSubmenuHandles.closeModal();
  }

  function switchToReservationNav() {
    openReservationNav();
    closeDeliveryNav();
    closeFutureSaveNav();
    closePerformanceNav();
    closeFreeStockNav();
    closeUserNav();
    closeOverviewNav();
    closeClaimsNav();
    demoShowroomSubmenuHandles.closeModal();
  }

  function switchToFreeStockNav() {
    openFreeStockNav();
    closeDeliveryNav();
    closePerformanceNav();
    closeFutureSaveNav();
    closeReservationNav();
    closeUserNav();
    closeOverviewNav();
    closeClaimsNav();
    demoShowroomSubmenuHandles.closeModal();
  }

  function switchToUserNav() {
    openUserNav();
    closeDeliveryNav();
    closeFutureSaveNav();
    closeReservationNav();
    closeFreeStockNav();
    closePerformanceNav();
    closeOverviewNav();
    closeClaimsNav();
    demoShowroomSubmenuHandles.closeModal();
  }

  function switchToClaimsNav() {
    openClaimsNav();
    closeUserNav();
    closeDeliveryNav();
    closeFutureSaveNav();
    closeReservationNav();
    closeFreeStockNav();
    closePerformanceNav();
    closeOverviewNav();
    demoShowroomSubmenuHandles.closeModal();
  }

  function logoClick() {
    navigate(`/${main_page_link}`);
    closeUserNav();
    closeDeliveryNav();
    closeFutureSaveNav();
    closeReservationNav();
    closeFreeStockNav();
    closePerformanceNav();
    closeOverviewNav();
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: mainColor,
        zIndex: (theme) => theme.zIndex.modal + 1 || theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar sx={toolBarStyle}>
        {userData && (
          <>
            {!isTabletAndMobile && userData.user_type !== DEALER_SALES_ROLE && (
              <>
                <Box onClick={() => logoClick()} sx={logoStyle}>
                  <Logo smallVersion />
                </Box>
                <Box sx={tabsStyle}>
                  {(userData.user_type === ACCOUNT_MANAGER_ROLE ||
                    userData.user_type === ADMIN_ROLE) && (
                    <>
                      <OverviewNav
                        open={isOpenOverviewNav}
                        openModal={switchToOverviewNav}
                        closeModal={closeOverviewNav}
                      />
                      <CustomDivider />
                    </>
                  )}
                  <DealerPerformanceNav
                    open={isOpenPerformanceNav}
                    openModal={switchToPerformanceNav}
                    closeModal={closePerformanceNav}
                    demoShowroomSubmenuHandles={demoShowroomSubmenuHandles}
                  />
                  <CustomDivider />
                  <OrderDeliveryNav
                    open={isOpenDeliveryNav}
                    openModal={switchToDeliveryNav}
                    closeModal={closeDeliveryNav}
                  />
                  <CustomDivider />
                  <FutureSalesNav
                    open={false}
                    openModal={switchToFutureSalesNav}
                    closeModal={closeFutureSaveNav}
                  />
                  <CustomDivider />
                  <ClaimsNav
                    open={isOpenClaimsNav}
                    openModal={switchToClaimsNav}
                    closeModal={closeClaimsNav}
                  />
                </Box>
                <UserNav
                  title={`${userData.first_name} ${userData.last_name}`}
                  open={isOpenUserNav}
                  openModal={switchToUserNav}
                  closeModal={closeUserNav}
                />
              </>
            )}
            {!isTabletAndMobile && userData.user_type === DEALER_SALES_ROLE && (
              <>
                <Box onClick={() => logoClick()} sx={logoStyle}>
                  <Logo smallVersion />
                </Box>
                <Box sx={tabsStyle}>
                  <FreeStockNav
                    open={false}
                    openModal={switchToFreeStockNav}
                    closeModal={closeFreeStockNav}
                  />
                  <CustomDivider />
                  <ReservationNav
                    open={false}
                    openModal={switchToReservationNav}
                    closeModal={closeReservationNav}
                  />
                  <CustomDivider />
                  <OrderDeliveryNav
                    open={isOpenDeliveryNav}
                    openModal={switchToDeliveryNav}
                    closeModal={closeDeliveryNav}
                  />
                  <CustomDivider />
                  <FutureSalesNav
                    open={false}
                    openModal={switchToFutureSalesNav}
                    closeModal={closeFutureSaveNav}
                  />
                </Box>
                <UserNav
                  title={`${userData.first_name} ${userData.last_name}`}
                  open={isOpenUserNav}
                  openModal={switchToUserNav}
                  closeModal={closeUserNav}
                />
              </>
            )}
            {isTabletAndMobile && (
              <>
                <MenuIcon
                  sx={menuIconStyle}
                  onClick={() => openMobileDrawer()}
                />
                <Box
                  onClick={() => navigate(`/${main_page_link}`)}
                  sx={logoStyle}
                >
                  <Logo smallVersion />
                </Box>
                <UserNav
                  title={`${userData.first_name} ${userData.last_name}`}
                  open={isOpenUserNav}
                  openModal={switchToUserNav}
                  closeModal={closeUserNav}
                />
                <CustomDrawer
                  open={isOpenDrawer}
                  handleClose={() => setIsOpenDrawer(false)}
                >
                  <MobileNav />
                </CustomDrawer>
              </>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
