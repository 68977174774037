import { Alert } from "@mui/material";
import { alertInfoStyle } from "./AlertInfo.style";

interface AlertInfoProps {
  message: string;
}

function AlertInfo({ message }: AlertInfoProps) {
  const { alertStyle } = alertInfoStyle;

  return (
    <Alert severity="info" sx={alertStyle}>
      {message}
    </Alert>
  );
}

export default AlertInfo;
