import {
  BaseQueryFn,
  createApi,
  FetchArgs,
} from "@reduxjs/toolkit/dist/query/react";
import { CustomError } from "models/CustomError";
import { Dealer } from "models/Dealer";
import { Zone } from "models/Zone";
import { baseQueryWithReauth } from "./baseQueryWithReauth";

export const dealerAPI = createApi({
  reducerPath: "dealerAPI",
  baseQuery: baseQueryWithReauth as BaseQueryFn<
    string | FetchArgs,
    unknown,
    CustomError,
    {}
  >,
  tagTypes: ["Dealer"],
  endpoints: (build) => ({
    getDealersList: build.query<Array<Dealer>, void>({
      query: () => ({
        url: "dealer/dealer_short_list/",
      }),
    }),
    getDealersLocation: build.query<
      Array<{ id: string; name: string }>,
      { dealer?: string }
    >({
      query: ({ dealer }) => ({
        url: "dealer/dealer_location_short_list",
        params: {
          dealer: dealer,
        },
      }),
    }),
    getDealersZones: build.query<Array<Zone>, void>({
      query: () => ({
        url: "dealer/dealer_zone_short_list",
      }),
    }),
  }),
});
