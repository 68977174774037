import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";
import { Mutex } from "async-mutex";
import { CustomError } from "models/CustomError";

const mutex = new Mutex();

interface Data {
  data: {
    refresh: string;
    access: string;
  };
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${window.appConfig.apiUrl}`,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (token) {
      headers.set("authorization", `JWT ${token}`);
    }

    return headers;
  },
}) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>;

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshToken = localStorage.getItem("REFRESH_TOKEN");

    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await baseQuery(
          {
            url: "auth/token/refresh/",
            method: "POST",
            body: { refresh: refreshToken },
          },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          localStorage.setItem(
            "ACCESS_TOKEN",
            (refreshResult as Data).data.access,
          );
          result = await baseQuery(args, api, extraOptions);
        } else {
          localStorage.clear();
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};
